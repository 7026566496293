import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import { IconButton, Menu, MenuItem, Stack } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import SpeakerphoneOutline from 'assets/img/icons/speakerphone_outline.svg';

// redux
import { connect } from 'react-redux';
import {
    ToggleOnboardingItemActive,
    DeleteOnboardingItem,
    CreateMerchantOnboardingItem,
    EditMerchantOnboardingItem,
} from 'store/actions/onboardingActions';
// import { accept } from 'validate';
// import PolicyOverwriteModal from './PolicyOverwriteModal';
import AppButton from 'components/new_components/AppButton';

//translation
import { useTranslation } from 'react-i18next';
import { getType } from 'utils';
import LoadingState from 'components/new_components/LoadingState';
import { GetDownloadLink } from 'store/actions/generalActions';

const OnboardTableActions = (props) => {
    const theme = useTheme();
    const {
        row,
        // ToggleOnboardingItemActive,
        // DeleteOnboardingItem,
        merchant,
        type,
        openDeleteModal,
        openEnableModal,
        openDisableModal,
        openOverwriteModal,

        // CreateMerchantOnboardingItem,
        // EditMerchantOnboardingItem,
    } = props;
    //translation hooks
    const { t } = useTranslation('onboardingPolicy');
    // state
    const [anchor, setAnchor] = useState(null);

    const [loadingLink, setLoadingLink] = useState(false);
    const role = getType('role');

    // logic functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);

    // async functions
    /*    const handleActiveItemToggle = async () => {
        closeMenu();
        const payload = {
            is_deactivated: !row.is_deactivated,
            video_id: row?.id,
        };
        setLoading(true);
        const res = await ToggleOnboardingItemActive(payload);
        // this is to effect the delay in changing the state in the reedux store
        setTimeout(() => setLoading(false), 500);
        if (res?.success) {
            toast?.success('Item updated successfully');
        } else {
            toast?.error(res?.message);
        }
    }; */

    const getDownloadLink = async () => {
        if (row.file && !loadingLink) {
            setLoadingLink(true);
            const res = await GetDownloadLink('compliance', 'onboarding', row.id)();
            setLoadingLink(false);
            if (res?.success) {
                // open decoded url
                window.open(res?.data, '_blank');
            } else {
                toast.error('Something went wrong!');
                // open original file url
                window.open(row.file, '_blank');
            }
        }
    };

    return (
        <>
            <Stack direction="row" justifyContent="flex-end">
                {row?.notify_list?.includes(merchant) && !row?.has_clicked_admin_policy && (
                    <AppButton
                        name={<img src={SpeakerphoneOutline} alt="notification icon" />}
                        variant="text"
                        disableRipple={true}
                        sx={{
                            minWidth: 'unset',
                            px: 0,
                            py: 0,
                            backgroundColor: 'white !important',
                            '&:hover': {
                                backgroundColor: 'white',
                            },
                        }}
                        onClick={() => openOverwriteModal('')}
                    />
                )}
                <IconButton sx={{ color: theme.palette.gray[900] }} onClick={openMenu}>
                    <MoreHoriz sx={{ color: '#64748B' }} />
                </IconButton>
            </Stack>

            <Menu
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={closeMenu}
                sx={{
                    fontSize: '14px',
                    marginTop: 0.5,
                    p: 0,
                    transform: 'translateX(1.8rem)',
                    boxShadow: '0px 11.76px 22.06px 0px #00000014',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    sx={{
                        borderBottom: '1px solid #F1F5F9',
                        fontSize: '14px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        '&:hover': {
                            backgroundColor: '#F1F5F9',
                        },
                    }}
                    onClick={getDownloadLink}
                >
                    {type === 'video' ? t('videoTable.tableAction.download') : t('policyTable.tableAction.download')}
                    {loadingLink && <LoadingState size={14} styles={{ mr: 0.5, width: 'fit-content' }} />}
                </MenuItem>
                <MenuItem
                    sx={{
                        borderBottom: '1px solid #F1F5F9',
                        position: 'relative',
                        fontSize: '14px',
                        width: '181px',
                        '&:hover': {
                            backgroundColor: '#F1F5F9',
                        },
                    }}
                    onClick={() => {
                        openOverwriteModal();
                        closeMenu();
                    }}
                >
                    {type === 'video' ? t('videoTable.tableAction.overwrite') : t('policyTable.tableAction.overwrite')}
                </MenuItem>
                {row?.status === 'enabled' && role !== 'auditor' && (
                    <MenuItem
                        sx={{
                            borderBottom: '1px solid #F1F5F9',
                            fontSize: '14px',
                            '&:hover': {
                                backgroundColor: '#F1F5F9',
                            },
                        }}
                        onClick={() => {
                            openDisableModal();
                            closeMenu();
                        }}
                    >
                        {type === 'video' ? t('videoTable.tableAction.disable') : t('policyTable.tableAction.disable')}
                    </MenuItem>
                )}
                {row?.status === 'disabled' && role !== 'auditor' && (
                    <MenuItem
                        sx={{
                            borderBottom: '1px solid #F1F5F9',
                            fontSize: '14px',
                            '&:hover': {
                                backgroundColor: '#F1F5F9',
                            },
                        }}
                        onClick={() => {
                            openEnableModal();
                            closeMenu();
                        }}
                    >
                        {type === 'video' ? t('videoTable.tableAction.enable') : t('policyTable.tableAction.enable')}
                    </MenuItem>
                )}
                {role !== 'auditor' && !row.admin && (
                    <MenuItem
                        sx={{
                            color: '#FF5449',
                            fontSize: '14px',
                            '&:hover': {
                                backgroundColor: '#F1F5F9',
                            },
                        }}
                        onClick={() => {
                            openDeleteModal();
                            closeMenu();
                        }}
                    >
                        {type === 'video' ? t('videoTable.tableAction.delete') : t('policyTable.tableAction.delete')}
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        merchant: state?.generalReducers?.user_info?.organization?.id,
    };
};
export default connect(mapStateToProps, {
    ToggleOnboardingItemActive,
    DeleteOnboardingItem,
    CreateMerchantOnboardingItem,
    EditMerchantOnboardingItem,
})(OnboardTableActions);
