import { Skeleton } from '@mui/material';

export default function TableLoading() {
    return (
        <div className="flex flex-col !gap-4 !py-6 !px-4">
            <Skeleton variant="rounded" width="100%" height={42} />

            <Skeleton variant="rounded" width="25%" height={36} />

            <Skeleton variant="rounded" width="100%" height={524} />

            <div className="flex justify-end">
                <Skeleton variant="rounded" width="25%" height={26} />
            </div>
        </div>
    );
}
