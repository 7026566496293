import { ClickAwayListener, Popper } from '@mui/material';
import { Highlighter } from 'lucide-react';
import { useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useDispatch, useSelector } from 'react-redux';
import { setColorPickerBackgroundColor, setSelectedBackgroundColor } from 'store/actions/editorActions';

export default function BackgroundTool({ editorRef }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const selectedBackgroundColor = useSelector((state) => state.editorReducers.selectedBackgroundColor);
    const colorPickerBackgroundColor = useSelector((state) => state.editorReducers.colorPickerBackgroundColor);

    const dispatch = useDispatch();

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    function handleClick(event) {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }

    function handleColorChange(color) {
        if (editorRef.current) {
            const quill = editorRef.current;
            quill.format('background', color);
            dispatch(setSelectedBackgroundColor(color));
            dispatch(setColorPickerBackgroundColor(color));
            setAnchorEl(null);
            quill.focus();
        }
    }

    useEffect(() => {
        if (editorRef.current) {
            const quill = editorRef.current;

            // eslint-disable-next-line no-inner-declarations
            function handleSelectionChange() {
                if (!quill.hasFocus()) return;
                const format = quill.getFormat();

                if (format['background']) {
                    dispatch(setSelectedBackgroundColor(format['background']));
                    dispatch(setColorPickerBackgroundColor(format['background']));
                } else {
                    dispatch(setSelectedBackgroundColor('#ffffff'));
                    dispatch(setColorPickerBackgroundColor('#ffffff'));
                }
            }

            quill.on('selection-change', handleSelectionChange);

            return () => {
                quill.off('selection-change', handleSelectionChange);
            };
        }
    }, [editorRef]);

    return (
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <div className="grid place-items-center">
                <button
                    aria-describedby={id}
                    className="!p-1 flex flex-col !gap-0 !rounded bg-white transition-all hover:brightness-95"
                    onClick={handleClick}
                >
                    <Highlighter className="!h-5 !w-5 text-[#1E293B]" />

                    <span
                        className="!h-1 !w-5 !rounded-md"
                        style={{
                            backgroundColor: selectedBackgroundColor,
                        }}
                    />
                </button>

                <Popper
                    id={id}
                    anchorEl={anchorEl}
                    placement="bottom"
                    open={open}
                    sx={{
                        zIndex: 1200,
                        borderRadius: '4px',
                        boxShadow: '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                        backgroundColor: '#FFFFFF',
                    }}
                >
                    <div className="flex w-fit flex-col !gap-2 !p-2">
                        <HexColorPicker
                            color={colorPickerBackgroundColor}
                            onChange={(newColor) => dispatch(setColorPickerBackgroundColor(newColor))}
                            className="!w-full"
                        />

                        <input
                            type="text"
                            className="w-full !h-9 !p-2 !border !border-[#E2E8F0] text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                            value={colorPickerBackgroundColor}
                            onChange={(event) => {
                                if (event.target.value.length > 7) return;

                                if (event.target.value.length === 0) {
                                    dispatch(setColorPickerBackgroundColor(selectedBackgroundColor));
                                    return;
                                }

                                if (event.target.value[0] !== '#') {
                                    dispatch(setColorPickerBackgroundColor('#' + event.target.value));
                                    return;
                                }

                                dispatch(setColorPickerBackgroundColor(event.target.value));
                            }}
                        />

                        <div className="!mt-1 flex items-center justify-between !gap-4">
                            <button
                                className="flex-1 bg-[#fff] !border !border-[#E2E8F0] !rounded !px-2 !py-2 hover:bg-red-500 hover:text-white transition-colors text-[#334155] text-sm font-medium"
                                onClick={() => setAnchorEl(null)}
                            >
                                Cancel
                            </button>

                            <button
                                className="flex-1 font-semibold underline decoration-[334155] cursor-pointer transition-all hover:no-underline"
                                onClick={() => {
                                    handleColorChange('#ffffff');
                                }}
                            >
                                Reset
                            </button>

                            <button
                                className="flex-1 bg-[#202D66] !rounded !px-2 !py-2 hover:bg-[#151e44] transition-all text-white text-sm font-semibold disabled:opacity-50 disabled:pointer-events-none"
                                onClick={() => {
                                    handleColorChange(colorPickerBackgroundColor);
                                }}
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                </Popper>
            </div>
        </ClickAwayListener>
    );
}
