// import { DoNotDisturb } from '@mui/icons-material';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AppLinearProgress from 'components/new_components/AppLinearProgress';
import { forwardRef } from 'react';
import ProgressFile from '../../../../assets/img/progressfile.svg';

// core component
// import ComplianceProgressBar from './ComplianceProgressBar';

const GroupCard = forwardRef((props, ref) => {
    const theme = useTheme();
    const {
        children,
        percentCompleted = 0,
        totalValue = 0,
        totalAttendedTo = 0,
        // resourceLabel = '',
        onCardClick,
        // onHover,
        // onHoverOut,
        empty,
        id,
        // exempted,
        isCategoryAssigned,
        // isSubCatgoryAssigned,
        // isResourceAssigned,
        // standard,
        // tag,
        // isTaskMissed,
        isExceptionTab,
    } = props;
    return (
        <Grid item component="span" xs={12} sm={6} lg={4} xl={4} ref={ref} id={id}>
            <Box
                variant="outlined"
                sx={{
                    opacity: empty ? 0.6 : 1,
                    filter: empty ? 'grayscale(1)' : 'none',
                    backgroundColor: '#FFFFFF',
                    borderRadius: '4px',
                    color: 'red',
                    minHeight: '186px',
                    height: '100%',
                    display: 'grid',
                    gap: '24px',
                    border: '1px solid #F1F5F9',
                    padding: '18px',
                    alignContent: 'space-between',
                    // cursor: empty ? 'not-allowed' : isExceptionTab || exempted ? 'not-allowed' : 'pointer',
                    cursor: empty ? 'not-allowed' : isExceptionTab ? 'not-allowed' : 'pointer',
                    position: 'relative',
                    ...(isExceptionTab
                        ? {}
                        : {
                              '&:hover': {
                                  backgroundColor: '#F1F5F9',
                              },
                          }),
                }}
                // onClick={(!isExceptionTab || !exempted) && onCardClick}
                onClick={!isExceptionTab && onCardClick}
                // onMouseEnter={onHover && (() => onHover(id))}
                // onMouseLeave={onHoverOut && (() => onHoverOut(id))}
            >
                <Box>{children}</Box>
                <Box sx={{ position: 'relative' }}>
                    {/* {tag && (
                        <Typography sx={{ fontSize: '11px', fontWeight: 600, color: '#DE3730', position: 'absolute' }}>
                            {tag}
                        </Typography>
                    )} */}
                    {/* <ComplianceProgressBar
                        percent={percentCompleted}
                        standard={standard}
                        isCategoryAssigned={isCategoryAssigned}
                        isTaskMissed={isTaskMissed}
                    /> */}
                    <AppLinearProgress value={percentCompleted} sx={{ mb: '4px', color: '#395BA9' }} />
                    <Stack direction="row" justifyContent="space-between">
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <img
                                src={ProgressFile}
                                alt="ProgressFile"
                                // className={`${exempted ? 'mt-[-4px]' : '0'}`}
                            />
                            <Typography
                                sx={{
                                    alignSelf: 'flex-start',
                                    fontSize: 11,
                                    color: '#94A3B8',
                                    fontWeight: 500,
                                }}
                            >
                                <Typography
                                    component="span"
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: 11,
                                        color: '#475569',
                                        pr: 0.5,
                                    }}
                                >
                                    {totalAttendedTo}/{totalValue}
                                </Typography>
                                policy statement
                            </Typography>
                        </Box>
                        {/* {exempted && standard === 'sslc' && (
                            <Typography
                                sx={{
                                    fontSize: 11,
                                    fontWeight: 600,
                                    color: '#395BA9',
                                    backgroundColor: '#FFDAD6',
                                    px: 0.5,
                                    py: 0.3,
                                    borderRadius: 1,
                                }}
                            >
                                Exempted
                            </Typography>
                        )} */}

                        <Typography
                            sx={{
                                alignSelf: 'flex-end',
                                fontWeight: 400,
                                fontSize: 11,
                                color: '#64748B',
                                // top: standard === 'soc2' ? 25 : 0,
                            }}
                        >
                            <Typography
                                component="span"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: 11,
                                    color: '#64748B',
                                }}
                            >
                                {percentCompleted?.toFixed(2) || 0}%
                            </Typography>{' '}
                            done
                        </Typography>

                        {/* {exempted && standard === 'soc2' && (
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    color: theme.palette.error.main,
                                    // mt: 4
                                }}
                            >
                                <DoNotDisturb sx={{ color: theme.palette.neutral[50] }} fontSize={'12px'} /> Exempted
                            </Typography>
                        )} */}
                    </Stack>
                </Box>
                {!isExceptionTab && isCategoryAssigned ? (
                    <Stack
                        sx={{
                            position: 'absolute',
                            right: '-1.5%',
                            top: '-1.5%',
                            backgroundColor: theme.palette.shades.white,
                            width: '12px',
                            height: '12px',
                            display: 'grid',
                            placeItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                backgroundColor: '#EE2424',
                            }}
                            className="absolute !top-1/2 !left-1/2 -translate-x-1/2 -translate-y-1/2"
                        />
                        <Box
                            sx={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                backgroundColor: '#EE2424',
                            }}
                            className="animate-ping"
                        />
                    </Stack>
                ) : null}
            </Box>
        </Grid>
    );
});

export default GroupCard;
