/* eslint-disable no-unused-vars */

import { Drawer, Fade } from '@mui/material';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import MainContent from './MainContent';
import NavBar from './NavBar';
import Sidebar from './Sidebar';

const Transition = forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

export default function PolicyHistoryDrawer({ isOpen, setIsOpen, standard, document }) {
    const [currentPolicyDocument, setCurrentPolicyDocument] = useState(null);
    const [selectedVersionId, setSelectedVersionId] = useState(null);

    const all_merchant_documents = useSelector((state) => state?.complianceReducers?.all_merchant_documents);

    const allDocuments = useMemo(
        () =>
            all_merchant_documents
                ?.filter((doc) => doc?.tag === standard && !doc?.parent_version)
                ?.map((doc) => ({ ...doc, default: doc?.document })),
        [all_merchant_documents]
    );

    function handleClose() {
        setIsOpen(false);
        setSelectedVersionId(null);
        setCurrentPolicyDocument(null);
    }

    useEffect(() => {
        if (isOpen) {
            if (!currentPolicyDocument && !selectedVersionId) {
                if (document) {
                    const currentDocument = allDocuments?.find((doc) => doc?.id === document?.id);
                    setCurrentPolicyDocument(currentDocument);
                    setSelectedVersionId(currentDocument?.id);
                } else {
                    setCurrentPolicyDocument(allDocuments[0]);
                    setSelectedVersionId(allDocuments[0]?.id);
                }
            } else {
                const currentDocument = allDocuments?.find((doc) => doc?.id === selectedVersionId);
                setCurrentPolicyDocument(currentDocument);
            }
        }
    }, [isOpen, document, allDocuments]);

    return (
        <Drawer
            open={isOpen}
            anchor="right"
            TransitionComponent={Transition}
            transitionDuration={0}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    width: '100%',
                    flex: 1,
                    overflow: 'auto',
                    zIndex: 9999,
                },
            }}
            scroll="paper"
        >
            <NavBar
                currentPolicyDocument={currentPolicyDocument}
                setCurrentPolicyDocument={setCurrentPolicyDocument}
                selectedVersionId={selectedVersionId}
                setSelectedVersionId={setSelectedVersionId}
                allDocuments={allDocuments}
                handleClose={handleClose}
            />

            <div className="flex !h-full bg-[#F8FAFC]">
                <Sidebar
                    selectedVersionId={selectedVersionId}
                    setSelectedVersionId={setSelectedVersionId}
                    currentPolicyDocument={currentPolicyDocument}
                />

                <MainContent
                    selectedVersionId={selectedVersionId}
                    currentPolicyDocument={currentPolicyDocument}
                    standard={standard}
                />
            </div>
        </Drawer>
    );
}
