import React, { memo, useEffect, useState } from 'react';

// core components
import AppTable from 'components/new_components/app-table';
import OnboardTableActions from './OnboardTableActions';
import AppTag from 'components/new_components/AppTags';
import { Box, Typography, useTheme } from '@mui/material';
//translation
import { useTranslation } from 'react-i18next';
import CircleIcon from '@mui/icons-material/Circle';
import moment from 'moment';
import OnboardTableTitle from './OnboardTableTitle';
import UploadPolicyModal from './UploadPolicyModal';
import DeletePolicyModal from './DeletePolicyModal';
import { DisablePolicyModal, EnablePolicyModal } from './PolicyModals';
import PolicyOverwriteModal from './PolicyOverwriteModal';
import { useDebounce } from 'hooks/useDebounce';

const NO_PER_PAGE = 8;

const onboardTableStyles = {
    container: {
        p: '0 1rem !important',
        boxShadow: 'none',
    },
    tableWrapper: {
        p: 0,
        boxShadow: 'none',
        fontSize: '14px',
        color: '#64748B',
    },
    tableRow: {
        m: '0 !important',
        py: '5px  !important',
        color: '#64748B',
        borderColor: '#E2E8F0 !important',
    },
    tableCell: {
        color: '#64748B',
        p: '8px 16px  !important',
        fontSize: '14px',
        borderColor: '#E2E8F0 !important',
    },
    tableTopbar: {
        pt: 4,
        px: '0 !important',
        alignItems: 'center',
        boxShadow: 'none',
    },
    tableHeader: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        borderColor: '#E2E8F0 !important',
        boxShadow: 'none',
        p: 0,
        textOverflow: 'ellipsis',
        backgroundColor: '#F8FAFC',
        m: 0,
    },
    tableHeadCell: {
        borderBottom: '1px solid #E2E8F0 !important',
    },
};

const OnboardTable = memo((props) => {
    const { data, loading, type, refetch } = props;
    const theme = useTheme();
    const [modal, showModal] = useState(null);
    const [row, setRow] = useState(null);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [filters, setFilters] = useState({
        source: '',
        status: '',
        search: '',
    });

    const debouncedFilters = useDebounce(filters);

    const { t } = useTranslation('onboardingPolicy');

    const handleSearch = (e) => {
        setFilters((prev) => ({
            ...prev,
            search: e.target.value,
        }));
    };

    const handleFilterChange = ({ name, value }) => {
        setFilters((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const VIDEO_TABLE_COLUMNS = [
        { title: t('videoTable.column1'), render: (row) => <>{row?.name}</> },

        {
            title: t('videoTable.column2'),
            align: 'left',
            render: (row) => {
                // const type = row?.file?.split('.')?.slice(-1)?.[0];
                return (
                    <AppTag
                        hasIcon={true}
                        icon={
                            <>
                                <CircleIcon
                                    sx={{
                                        color: `${row?.status === 'enabled' ? `#0B885A` : `#94A3B8`}`,
                                        fontSize: '10px',
                                        mx: '2px',
                                        height: 'fit-content',
                                        fontStyle: 'normal',
                                        my: 0,
                                        mt: -0.2,
                                    }}
                                />
                            </>
                        }
                        text={`${row?.status === 'disabled' ? t('table.status.disabled') : t('table.status.enabled')} `}
                        sx={{
                            borderRadius: '4px',
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '4px 8px',
                            gap: '4px',
                            fontStyle: 'normal',
                            maxWidth: 'fit-content',
                            borderColor: theme.palette.grey[200],
                            m: 0,
                        }}
                    />
                );
            },
        },
        {
            title: t('videoTable.column3'),
            align: 'left',
            render: (row) => <>{moment(row.date_updated).format('MMM D, YYYY')}</>,
        },
        {
            title: t('videoTable.column4'),
            align: 'left',
            render: (row) => {
                const type = row?.file?.split('.')?.slice(-1)?.[0];
                return <AppTag text={type} sx={{ border: 'none', width: 'fit-content', fonWeight: 600, py: 1 }} />;
            },
        },
        {
            title: t('videoTable.column5'),
            align: 'left',
            render: (row) => (
                <Typography
                    sx={{
                        color: row?.admin ? '#94A3B8' : '#64748B',
                        fontSize: '14px',
                    }}
                >
                    {row.admin ? 'Admin' : 'Merchant'}
                </Typography>
            ),
        },
        {
            title: '',
            align: 'right',
            render: (row) => (
                <OnboardTableActions
                    row={row}
                    type={type}
                    openDeleteModal={() => {
                        setRow(row);
                        showModal('delete');
                    }}
                    openEnableModal={() => {
                        setRow(row);
                        showModal('enable');
                    }}
                    openDisableModal={() => {
                        setRow(row);
                        showModal('disable');
                    }}
                    openOverwriteModal={() => {
                        setRow(row);
                        showModal('overwrite');
                    }}
                    refetch={refetch}
                />
            ),
        },
    ];

    const POLICY_TABLE_COLUMNS = [
        { title: t('policyTable.column1'), render: (row) => <>{row?.name}</>, width: '43%' },
        {
            title: t('policyTable.column2'),
            align: 'left',
            render: (row) => {
                // const type = row?.file?.split('.')?.slice(-1)?.[0];
                return (
                    <AppTag
                        hasIcon={true}
                        icon={
                            <>
                                <CircleIcon
                                    sx={{
                                        color: `${row?.status === 'enabled' ? `#0B885A` : `#94A3B8`}`,
                                        fontSize: '10px',
                                        mx: '2px',
                                        height: 'fit-content',
                                        fontStyle: 'normal',
                                        my: 0,
                                        mt: -0.2,
                                    }}
                                />
                            </>
                        }
                        text={`${row?.status === 'disabled' ? t('table.status.disabled') : t('table.status.enabled')} `}
                        sx={{
                            borderRadius: '4px',
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '4px 8px',
                            gap: '4px',
                            fontStyle: 'normal',
                            maxWidth: 'fit-content',
                            borderColor: theme.palette.grey[200],
                            m: 0,
                        }}
                    />
                );
            },
        },
        {
            title: t('policyTable.column3'),
            align: 'left',
            render: (row) => <>{moment(row.date_updated).format('MMM D, YYYY')}</>,
        },
        {
            title: t('policyTable.column4'),
            align: 'left',
            render: (row) => (
                <Typography
                    sx={{
                        color: row?.admin ? '#94A3B8' : '#64748B',
                        fontSize: '14px',
                    }}
                >
                    {row.admin ? 'Admin' : 'Merchant'}
                </Typography>
            ),
        },
        {
            title: '',
            align: 'center',
            render: (row) => (
                <OnboardTableActions
                    row={row}
                    type={type}
                    openDeleteModal={() => {
                        setRow(row);
                        showModal('delete');
                    }}
                    openEnableModal={() => {
                        setRow(row);
                        showModal('enable');
                    }}
                    openDisableModal={() => {
                        setRow(row);
                        showModal('disable');
                    }}
                    openOverwriteModal={() => {
                        setRow(row);
                        showModal('overwrite');
                    }}
                    refetch={refetch}
                    existingFiles={data}
                />
            ),
        },
    ];

    const handleModalClose = () => {
        showModal(null);
        setRow(null);
    };
    //functions
    const handlePageChange = (page) => {
        setPage(page);
    };

    useEffect(() => {
        refetch(debouncedFilters);
    }, [debouncedFilters]);

    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;
        setPageData(data?.length ? data?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []);

        return () => setPageData([]);
    }, [page, data]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, p: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, p: 0, background: '#fff', overflow: 'auto' }}>
                {' '}
                <OnboardTableTitle
                    onSearch={handleSearch}
                    openModal={() => showModal('add')}
                    onFilterChange={handleFilterChange}
                    filter={filters}
                    loading={false}
                    type={type}
                    refetch={refetch}
                />
                <AppTable
                    columns={type === 'video' ? VIDEO_TABLE_COLUMNS : POLICY_TABLE_COLUMNS}
                    noPerPage={NO_PER_PAGE}
                    page={page}
                    data={pageData}
                    dataLength={data?.length || 0}
                    showTitleBar={false}
                    count={
                        <>
                            <strong>{loading ? '' : data?.length || ''}</strong>
                            {!loading && <span> {type === 'video' ? 'videos' : 'policy documents'}</span>}
                        </>
                    }
                    onPageChange={handlePageChange}
                    sx={onboardTableStyles}
                    sorter={(a, b) => (a.name > b.name ? 1 : -1)}
                    loading={loading}
                    borderStyles={{
                        borderSpacing: '0',
                        borderCollapse: 'separate',
                        borderRadius: '5px',
                        color: '#E2E8F0',
                        p: 0,
                    }}
                />
                <PolicyOverwriteModal
                    open={modal === 'overwrite'}
                    handleClose={handleModalClose}
                    data={row}
                    type={type}
                    refetch={refetch}
                    existingFiles={data}
                />
                <UploadPolicyModal
                    refetch={refetch}
                    open={modal === 'add'}
                    handleClose={handleModalClose}
                    data={data}
                    type={type}
                    existingFiles={data}
                />
                <EnablePolicyModal
                    refetch={refetch}
                    open={modal === 'enable'}
                    handleClose={handleModalClose}
                    row={row}
                    type={type}
                />
                <DisablePolicyModal
                    refetch={refetch}
                    open={modal === 'disable'}
                    handleClose={handleModalClose}
                    row={row}
                    type={type}
                />
                <DeletePolicyModal
                    refetch={refetch}
                    open={modal === 'delete'}
                    handleClose={handleModalClose}
                    row={row}
                    type={type}
                />
            </Box>
        </Box>
    );
});

export default OnboardTable;
