import { Pagination, PaginationItem, Stack } from '@mui/material';
import { useMemo } from 'react';

// core component
import TableEndDivider from './TableEndDivider';
import { getNoOfPages } from './utils';

const AppTablePagination = (props) => {
    const { page, onPageChange, dataLength, noPerPage } = props;

    const noOfPages = useMemo(() => getNoOfPages(dataLength, noPerPage), [noPerPage, dataLength]);
    return (
        <Stack sx={{ my: 0.5 }} direction="row" alignItems="center" justifyContent="flex-end">
            <TableEndDivider />
            <Pagination
                count={noOfPages || 0}
                page={page}
                shape="rounded"
                color="primary"
                sx={{ p: 1 }}
                onChange={(e, page) => onPageChange(page)}
                renderItem={(item) => {
                    const isActive = item.page === page;
                    const isNav = item.type !== 'page';
                    return (
                        <PaginationItem
                            {...item}
                            sx={{
                                border: isActive || isNav ? '1px solid #46464A' : '1px solid #E4E2E6',
                                background: isActive || isNav ? '#202D66' : 'white',
                                color: isActive || isNav ? 'white' : '#46464A',
                                borderRadius: '10px',
                                '&.MuiPaginationItem-previousNext': {
                                    border: 'none',
                                    background: 'transparent',
                                },
                            }}
                        />
                    );
                }}
            />
        </Stack>
    );
};

export default AppTablePagination;
