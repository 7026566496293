/* eslint-disable no-unused-vars */

import PageHeader from 'components/new_components/PageHeader';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GetAddOns } from 'store/actions/generalActions';
import DescriptionContent from '../../../common/document_review/components/DescriptionContent';
import PolicyStatementsTable from '../../../common/document_review/components/PolicyStatementsTable';
import TitleContent from '../../../common/document_review/components/TitleContent';

export default function PCIDSSRequirementID({ match }) {
    const history = useHistory();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetAddOns());
    }, []);

    return (
        <>
            <PageHeader browserTitle="PCIDSS Requirements - Compliance | SmartComplyApp" />

            <div className="flex items-center !gap-6 !border-b !border-[#F1F5F9] bg-white !py-4 !px-6 fixed top-0 !w-full">
                <button className="transition-all hover:scale-90" onClick={() => history.goBack()}>
                    <img src="/img/frameworks/arrow-narrow-left.svg" alt="arrow left" className="!w-6 !h-6" />
                </button>

                <h3 className="text-[#002C72] font-bold text-lg">
                    PCIDSS: Requirement {match?.params?.requirement_id}
                </h3>
            </div>

            <div className="!mt-4 !mx-6 bg-white !rounded-lg !border !border-[#E2E8F0] flex flex-col">
                <TitleContent />

                <DescriptionContent />

                <PolicyStatementsTable />
            </div>
        </>
    );
}
