import React, { useMemo, useState } from 'react';
import { Box, Typography, Stack, FormHelperText } from '@mui/material';
import FolderIcon from 'assets/img/folder.svg';
import Pdf from 'assets/img/pngfile.png';
import Docx from 'assets/img/docxfile.svg';
import Light from 'assets/img/Light.svg';
import { accept } from 'validate';
import AppButton from 'components/new_components/AppButton';
import SubTab from 'components/new_components/tabs/SubTab';
import AppSelectSearchPinned from 'components/new_components/AppSelectSearchPinned';
import Vid from 'assets/img/movFile.svg';
import { useTranslation } from 'react-i18next';

const tabs = [
    {
        index: 0,
        label: 'New',
    },
    {
        index: 1,
        label: 'Existing Policy',
    },
];

const StepOne = (props) => {
    const { pdf, video } = accept;
    const {
        dragDrop,
        dragOver,
        uploadImage,
        img,
        newDocument,
        toNextStep,
        data,
        type,
        existing,
        setExisting,
        imageLoading,
        existingFiles,
    } = props;

    const getFileName = (url) => {
        const fileName = url?.split('/')?.[4];
        return fileName;
    };

    const [stepOneError, setStepOneError] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!newDocument && currentTab == 0) return setStepOneError(true);

        if (!existing && currentTab == 1) return setStepOneError(true);

        setStepOneError(false);
        toNextStep();
    };

    const { t } = useTranslation('onboardingPolicy');

    const existingFileOptions = useMemo(() => {
        return (
            existingFiles?.map((file) => {
                return {
                    label: getFileName(file.file) || '',
                    value: file.id,
                    tag: file.name,
                };
            }) || []
        );
    }, [existingFiles]);

    const handleTabChange = (val) => {
        setExisting(null);
        setCurrentTab(val);
    };
    return (
        <>
            {type !== 'video' && (
                <SubTab
                    tabs={tabs}
                    currentTab={currentTab}
                    setCurrentTab={handleTabChange}
                    labelProps={{
                        activeColor: '#1B295F',
                        defaultColor: '#64748B',
                    }}
                    sx={{
                        wrapper: {
                            gap: 2,
                            overflow: 'hidden',
                            fontFamily: 'poppins',
                            width: '10rem',
                            justifyContent: 'flex-start',
                            borderBottom: 'none',
                        },
                        label: {
                            mt: 0,
                            width: 'fit-content',
                            whiteSpace: 'nowrap',
                        },
                        divider: {
                            backgroundColor: 'transparent',
                            width: '8.9rem',
                            ml: 0,
                            border: 'none',
                            borderBottom: '1px solid #F1F5F9',
                        },
                    }}
                />
            )}
            <Stack
                flexDirection="row"
                sx={{
                    border: `1px solid #F0F0FA`,
                    backgroundColor: '#F8FAFC',
                    borderRadius: '4px',
                    p: '12px',
                    pt: '8px',
                    mt: 5,
                    mb: 3,
                }}
            >
                <img src={Light} alt="file question" className="h-fit w-fit" />
                <Typography
                    sx={{
                        color: '#64748B',
                        fontWeight: 400,
                        fontSize: '13px',
                        ml: 1,
                    }}
                >
                    {type === 'video' ? t('overwriteVideoInfo') : t('overwritePolicyInfo')}
                </Typography>
            </Stack>
            <Typography sx={{ fontSize: 13, fontWeight: 500, mb: 1 }}>
                Current {type === 'video' ? 'video' : 'policy'} ({data?.name})
            </Typography>
            <Stack direction="row" alignItems="center" sx={{ borderRadius: '8px', p: 0.5 }} gap={2}>
                <img src={type == 'video' ? Vid : Pdf} alt="file icon" />
                <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#475569', letterSpacing: '0.5px' }}>
                    {getFileName(data?.file)}
                </Typography>
            </Stack>

            <form onSubmit={handleSubmit}>
                {currentTab === 0 ? (
                    <Box>
                        <Box
                            sx={{
                                border: '1px dashed #CBD5E1',
                                borderRadius: 2,
                                padding: 1,
                                minHeight: 150,
                                position: 'relative',
                                mt: 2,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <label
                                htmlFor="response-input"
                                className="w-full m-0"
                                style={{ display: 'block', height: '100%' }}
                            >
                                <Box
                                    id="response-drop-zone"
                                    onDrop={dragDrop}
                                    onDragOver={dragOver}
                                    sx={{ height: '100%', p: 2 }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <img src={FolderIcon} alt={FolderIcon} />
                                        <Typography sx={{ color: '#64748B', fontSize: '12px', fontWeight: 500 }}>
                                            Drop your files or click to upload
                                        </Typography>
                                        <Typography sx={{ color: '#94A3B8', fontSize: '12px', fontWeight: 400 }}>
                                            Supported file type: {type === 'video' ? 'Video' : 'PDF'}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            mt: 1,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                border: '1px solid #E2E8F0',
                                                cursor: 'pointer',
                                                padding: 0.5,
                                            }}
                                        >
                                            <Typography sx={{ color: '#475569', fontWeight: 400, fontSize: 12 }}>
                                                Browse
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </label>
                            <input
                                type="file"
                                accept={`${type === 'video' ? video : pdf}`}
                                onChange={(e) => uploadImage(e)}
                                hidden
                                id="response-input"
                            />
                        </Box>
                    </Box>
                ) : (
                    <div className="h-[10rem]">
                        <AppSelectSearchPinned
                            label={`Select Existing document`}
                            options={existingFileOptions}
                            onChange={(e, val) => {
                                if (val) {
                                    setExisting({
                                        value: val?.value,
                                        label: e.target.textContent,
                                        tag: val?.tag,
                                    });

                                    setStepOneError(null);
                                }
                            }}
                            noDataLabel="No File available"
                            value={existing?.value ?? 'Select from existing policies'}
                            placeholder={existing?.label ?? 'Select from existing policies'}
                            labelStyle={{
                                color: '#64748B',
                                fontSize: '13px',
                                fontWeight: 500,
                            }}
                            sx={{
                                cursor: 'pointer',
                            }}
                        />

                        {existing && (
                            <Typography sx={{ fontSize: '13px', fontWeight: 400, color: '#64748B', mt: 1 }}>
                                This policy file exist in {`{${existing?.tag}}`}. Changes made there will reflect here.
                            </Typography>
                        )}
                    </div>
                )}

                {stepOneError && <FormHelperText sx={{ color: '#D91B1B' }}>choose a file</FormHelperText>}

                {newDocument?.name && (
                    <>
                        <Typography sx={{ marginTop: 3, color: '#64748B', fontSize: '13px', fontWeight: '500' }}>
                            New {type === 'video' ? 'Video' : 'Policy'}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: '1rem',
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                    src={
                                        newDocument?.type === 'application/pdf'
                                            ? Pdf
                                            : newDocument?.type ===
                                              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                            ? Docx
                                            : newDocument?.type === 'application/png'
                                            ? img
                                            : newDocument?.type === 'application/jpeg'
                                            ? img
                                            : type == 'video'
                                            ? Vid
                                            : null
                                    }
                                    alt="img"
                                />
                                <Typography sx={{ marginLeft: '1rem', color: '#475569', fontSize: '14px' }}>
                                    {newDocument.name}
                                </Typography>
                            </Box>
                        </Box>
                    </>
                )}

                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{
                        mt: 3,
                        backgroundColor: '#F8FAFC',
                        height: '80px',
                        mx: '-1.65rem',
                        mb: '-1rem',
                        px: '1.9rem',
                        pt: '3rem',
                        pb: '3rem',
                    }}
                >
                    <AppButton
                        name={imageLoading ? 'Please wait..' : 'Next'}
                        color="primary"
                        variant="contained"
                        disableElevation
                        type="submit"
                        disabled={imageLoading}
                    />
                </Stack>
            </form>
        </>
    );
};

export default StepOne;
