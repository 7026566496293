/* eslint-disable no-unused-vars */

import { Dialog } from '@mui/material';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { UpdateMerchantDocument } from 'store/actions/complianceActions';

export default function UseCurrentVersionDialog({ isOpen, setIsOpen, currentPolicyDocument, selectedVersionId }) {
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    async function updateDocument() {
        try {
            setIsLoading(true);

            const currentVersion = currentPolicyDocument?.policy_versions?.find((version) => version?.is_current);

            const response = await dispatch(
                UpdateMerchantDocument(
                    {
                        update_versions: {
                            set_true: selectedVersionId,
                            set_false: currentVersion?.id,
                        },
                    },
                    selectedVersionId
                )
            );
            if (!response.success) throw new Error(response?.message);

            toast.success('Policy document updated successfully');
            handleClose();
        } catch (error) {
            toast.error(error?.message || 'Request failed, please try again');
        } finally {
            setIsLoading(false);
        }
    }

    function handleClose() {
        setIsOpen(false);
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: '4px !important',
                    padding: 0,
                    boxShadow: '0px 16px 24px 0px #00000026',
                    width: '440px',
                },
            }}
        >
            <div className="!p-6 flex flex-col !gap-2 justify-center items-center">
                <h5 className="text-lg font-semibold text-[#475569]">Use as current version?</h5>

                <p className="text-center text-[#64748B] text-[13px]">
                    This policy will automatically be used as the most recent version of your policy
                </p>
            </div>

            <div className="flex items-center justify-center !p-6 bg-[#F8FAFC] !gap-2">
                <button
                    className="bg-[#fff] !border !border-[#E2E8F0] !rounded-sm !px-4 !py-2 hover:brightness-90 transition-all text-[#334155] text-sm font-medium"
                    onClick={handleClose}
                >
                    Cancel
                </button>

                <button
                    className="bg-[#395BA9] !rounded-sm !px-4 !py-2 hover:brightness-90 transition-all text-white text-sm font-medium disabled:opacity-50 disabled:pointer-events-none flex items-center !gap-1"
                    disabled={isLoading}
                    onClick={updateDocument}
                >
                    {isLoading && <Loader2 className="!h-5 !w-5 animate-spin" />}
                    Yes, use
                </button>
            </div>
        </Dialog>
    );
}
