// redux
import { connect } from 'react-redux';
// import { GetAllDocumentsByAnnexTwo } from 'store/actions/adminActions';
import Isogroups from './components/Isogroups';
// import ResourceView from './components/ResourceView';

const Documents = (props) => {
    // props
    const {
        loading: loadingIsogroups,
        // getIsogroups,
        // category,
        // GetAllDocumentsByAnnexTwo,
        // merchant_documents,
        // isogroup_documents,
        // view,
        // handleViewChange,
        // goBack,
        // selectedIsogroup,
        openTaskAssignModal,
        openExemptModal,
        openRevertModal,
        all_compliance_assigned_tasks,
        isExceptionTab,
    } = props;

    // state
    // const [loading, setLoading] = useState({ content: false });

    // async functions
    // const getIsogroupDocuments = async (isogroupId) => {
    //     setLoading((curr) => ({ ...curr, content: true }));
    //     const res = await GetAllDocumentsByAnnexTwo(isogroupId);
    //     setLoading((curr) => ({ ...curr, content: false }));
    //     if (!res?.success) {
    //         toast.error('Something went wrong!');
    //         console.log('Error:::', res?.message);
    //     }
    // };
    // const handleDocumentExempted = (isogroupId) => {
    //     getIsogroups(category);
    //     getIsogroupDocuments(isogroupId);
    // };

    // useeffect
    // useEffect(() => {
    //     if (selectedIsogroup) {
    //         getIsogroupDocuments(selectedIsogroup);
    //     }
    // }, [selectedIsogroup]);

    // return view === 0 ? (
    return (
        <Isogroups
            // resource="doc"
            // onViewChange={handleViewChange}
            loading={loadingIsogroups}
            openTaskAssignModal={openTaskAssignModal}
            openExemptModal={openExemptModal}
            openRevertModal={openRevertModal}
            all_compliance_assigned_tasks={all_compliance_assigned_tasks}
            isExceptionTab={isExceptionTab}
        />
        // ) : (
        //     <>
        //         <ResourceView
        //             goBack={goBack}
        //             isogroupID={selectedIsogroup}
        //             resource="doc"
        //             merchantResources={merchant_documents}
        //             isogroupResources={isogroup_documents}
        //             loading={loading?.content}
        //             handleResourceExempted={handleDocumentExempted}
        //             openTaskAssignModal={openTaskAssignModal}
        //             all_compliance_assigned_tasks={all_compliance_assigned_tasks}
        //         />
        //     </>
    );
};
const mapStateToProps = (state) => {
    return {
        // merchant_documents: state?.complianceReducers?.all_merchant_documents,
        // isogroup_documents: state?.adminReducers?.all_iso_2_documents,
        all_compliance_assigned_tasks: state?.generalReducers?.user_info?.compliance_assigned_tasks,
    };
};

export default connect(mapStateToProps, {
    // GetAllDocumentsByAnnexTwo
})(Documents);
