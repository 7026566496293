export const complianceSubroutes = [
    {
        name: 'PCISLC',
        key: 'pcislc',
        path: '/compliance/frameworks/pcislc',
        type: 'link',
        subroutes: [],
        features: [
            {
                name: 'Documents',
                key: 'documents',
            },

            {
                name: 'Exceptions',
                key: 'exceptions',
            },

            {
                name: 'Tasks for you',
                key: 'tasks',
            },
        ],
    },
    {
        name: 'PCIDSS',
        key: 'pcidss',
        path: '/compliance/frameworks/pcidss',
        type: 'link',
        subroutes: [],
        features: [
            {
                name: 'Documents',
                key: 'documents',
            },

            {
                name: 'Exceptions',
                key: 'exceptions',
            },
        ],
    },
    {
        name: 'PCIDSS 4.0.1',
        key: 'pcidss4.0.1',
        path: '/compliance/frameworks/pcidss4.0.1',
        type: 'link',
        subroutes: [],
        features: [
            {
                name: 'Documents',
                key: 'documents',
            },

            {
                name: 'Exceptions',
                key: 'exceptions',
            },
        ],
    },
    {
        name: 'NDPR',
        key: 'ndpr',
        path: '/compliance/frameworks/ndpr',
        type: 'link',
        subroutes: [],
        features: [
            {
                name: 'Documents',
                key: 'documents',
            },

            {
                name: 'Exceptions',
                key: 'exceptions',
            },
        ],
    },
    {
        name: 'ISO27001',
        key: 'iso27001',
        path: '/compliance/frameworks/iso27001',
        type: 'link',
        subroutes: [],
        features: [
            {
                name: 'Documents',
                key: 'documents',
            },

            {
                name: 'Exceptions',
                key: 'exceptions',
            },
        ],
    },
    {
        name: 'blockchain',
        key: 'blockchain',
        path: '/compliance/frameworks/blockchain',
        type: 'link',
        subroutes: [],
        features: [
            {
                name: 'Criteria',
                key: 'criteria',
            },
            {
                name: 'Exceptions',
                key: 'exceptions',
            },
        ],
    },
    {
        name: 'SOC2',
        key: 'soc2',
        path: '/compliance/frameworks/soc2',
        type: 'link',
        subroutes: [],
        features: [
            {
                name: 'Documents',
                key: 'documents',
            },

            {
                name: 'Exceptions',
                key: 'exceptions',
            },
            {
                name: 'Type I',
                key: 'typei',
            },
            {
                name: 'Type II',
                key: 'typeii',
            },

            {
                name: 'Tasks for you',
                key: 'tasks',
            },
        ],
    },
    {
        name: 'ISO 22301',
        key: 'iso22301',
        path: '/compliance/frameworks/iso22301',
        type: 'link',
        subroutes: [],
        features: [
            {
                name: 'Documents',
                key: 'documents',
            },

            {
                name: 'Exceptions',
                key: 'exceptions',
            },
        ],
    },
    {
        name: 'ISO27001 - 2022',
        key: 'iso27001-2022',
        path: '/compliance/frameworks/iso27001_2',
        type: 'link',
        subroutes: [],
        features: [
            {
                name: 'Documents',
                key: 'documents',
            },

            {
                name: 'Exceptions',
                key: 'exceptions',
            },
            {
                name: 'Management Clauses',
                key: 'managementClauses',
            },
            {
                name: 'Annexes',
                key: 'annexes',
            },
        ],
    },
    {
        name: 'ISO 9001',
        key: 'iso9001',
        path: '/compliance/frameworks/iso9001',
        type: 'link',
        subroutes: [],
        features: [
            {
                name: 'Documents',
                key: 'documents',
            },

            {
                name: 'Exceptions',
                key: 'exceptions',
            },

            {
                name: 'Tasks for you',
                key: 'tasks',
            },
        ],
    },
    {
        name: 'ISO 27017 + ISO 27032',
        key: 'iso27017+iso27032',
        path: '/compliance/frameworks/iso27012+iso27032',
        type: 'link',
        subroutes: [],
        features: [
            {
                name: 'Documents',
                key: 'documents',
            },

            {
                name: 'Exceptions',
                key: 'exceptions',
            },
        ],
    },
    {
        name: 'GDPA',
        key: 'gdpa',
        path: '/compliance/frameworks/gdpa',
        type: 'link',
        subroutes: [],
        features: [
            {
                name: 'Documents',
                key: 'documents',
            },

            {
                name: 'Exceptions',
                key: 'exceptions',
            },
        ],
    },
    {
        name: 'KDPA',
        key: 'kdpa',
        path: '/compliance/frameworks/kdpa',
        type: 'link',
        subroutes: [],
        features: [
            {
                name: 'Documents',
                key: 'documents',
            },

            {
                name: 'Exceptions',
                key: 'exceptions',
            },
        ],
    },
    {
        name: 'CII Directive',
        key: 'ciidirective',
        path: '/compliance/frameworks/cii_directive',
        type: 'link',
        subroutes: [],
        features: [
            {
                name: 'CII & Risk Management Directives',
                key: 'directives',
            },
            {
                name: 'Exceptions',
                key: 'exceptions',
            },
        ],
    },
];

export const RouteMap = [
    {
        name: 'Compliance',
        key: 'compliance',
        path: '/compliance',
        notExists: true,
        type: 'link',
        subroutes: [
            // frameworks
            {
                name: 'View Frameworks',
                key: 'frameworks',
                path: '/compliance/frameworks',
                type: 'link',
                subroutes: [...complianceSubroutes],
            },
            //  audit
            {
                name: 'Audit',
                key: 'audit',
                path: '/compliance/audits',
                type: 'link',
                features: [],
            },
            // data room
            {
                name: 'Data Room',
                key: 'dataroom',
                path: '/compliance/data-room',
                type: 'link',
                subroutes: [],
            },
        ],
    },
    {
        name: 'Onboarding',
        key: 'onboarding',
        path: '/onboarding',
        notExists: true,
        type: 'link',
        subroutes: [
            {
                name: 'View Employees',
                key: 'frameworks',
                path: '/onboarding/employee',
                type: 'link',
                features: [
                    {
                        name: 'Add employee',
                        key: 'addEmployee',
                        isModal: true,
                    },
                    {
                        name: 'Upload Employee Details from CSV',
                        key: 'uploadCSV',
                        isModal: true,
                    },
                ],
            },
            {
                name: 'People and Computers',
                key: 'computers',
                path: '/onboarding/computers',
                type: 'link',
                features: [],
            },
            {
                name: 'Policies',
                key: 'policies',
                path: '/onboarding/policies',
                type: 'link',
                features: [
                    {
                        name: 'Policy Documents',
                        key: 'documents',
                    },
                    {
                        name: 'Awareness Videos',
                        key: 'awarenessVideos',
                    },
                ],
            },
            {
                name: 'Employee Training',
                key: 'training',
                path: '/onboarding/training',
                type: 'link',
                features: [],
            },
        ],
    },
    {
        name: 'Risk Management',
        key: 'risk_assessment',
        path: '/assessment/risk-asessement',
        type: 'link',
        subroutes: [
            {
                name: 'Risk Management',
                key: 'risk_assessment',
                path: '/assessment/risk-asessement',
                type: 'link',
                features: [
                    {
                        name: 'Risk Register',
                        key: 'riskRegister',
                    },
                    {
                        name: 'Run Assessment',
                        key: 'runAssessment',
                        isModal: true,
                    },
                ],
            },
            {
                name: 'Risk Management Settings',
                key: 'risk_assessment_settings',
                path: '/risk-assessment/settings',
                type: 'link',
                features: [
                    {
                        name: 'General Risk Settings',
                        key: 'general',
                    },
                    {
                        name: 'Risk Matrix',
                        key: 'matrix',
                    },
                    {
                        name: 'Risk Templates',
                        key: 'templates',
                    },
                    {
                        name: 'New Risk Template',
                        key: 'template',
                        isModal: true,
                    },
                    {
                        name: 'Reset to Default',
                        key: 'reset',
                        isModal: true,
                    },
                ],
            },
        ],
    },
    {
        name: 'Integrations',
        key: 'integrations',
        path: '/integrations',
        notExists: true,
        type: 'link',
        subroutes: [
            {
                name: 'Integrations',
                key: 'integrations',
                path: '/integrations',
                type: 'link',
                features: [
                    {
                        name: 'Tools',
                        key: 'tools',
                    },
                    {
                        name: 'Assets',
                        key: 'assets',
                    },
                    {
                        name: 'Add Asset',
                        key: 'addAsset',
                        isModal: true,
                    },
                    {
                        name: 'Request For a Tool',
                        key: 'requestTool',
                        isModal: true,
                    },
                ],
            },
        ],
    },
    {
        name: 'Settings',
        key: 'settings',
        path: '/settings',
        notExists: true,
        type: 'link',
        subroutes: [
            {
                name: 'Settings',
                key: 'settings',
                path: '/settings',
                type: 'link',
                features: [
                    {
                        name: 'Profile',
                        key: 'profile',
                    },
                    {
                        name: 'Users',
                        key: 'users',
                    },
                    {
                        name: 'Subscriptions',
                        key: 'subscriptions',
                    },
                    {
                        name: '2FA',
                        key: '2fa',
                    },
                    {
                        name: 'Advanced',
                        key: 'advanced',
                    },
                    {
                        name: 'Company Info',
                        key: 'company info',
                    },
                    {
                        name: 'Password Management',
                        key: 'password management',
                    },
                ],
            },
        ],
    },
    {
        name: 'Activities',
        key: 'activities',
        path: '/activities',
        notExists: true,
        type: 'link',
        subroutes: [
            {
                name: 'Activities',
                key: 'activities',
                path: '/activities',
                type: 'link',
                features: [
                    {
                        name: 'Activity Log',
                        key: 'activity',
                    },
                    {
                        name: 'Task Log',
                        key: 'task',
                    },
                ],
            },
        ],
    },
    {
        name: 'AML/KYC',
        key: 'aml',
        path: '/aml/kyc',
        notExists: true,
        type: 'link',
        subroutes: [
            {
                name: 'AML/KYC',
                key: 'aml',
                path: '/aml/kyc',
                type: 'link',
                features: [
                    {
                        name: 'Run KYC Check',
                        key: 'kycCheck',
                    },
                    {
                        name: 'Upload from EXCEL',
                        key: 'uploadExcel',
                    },
                ],
            },
        ],
    },
    {
        name: 'Transaction Fraud Monitoring',
        key: 'fraud-monitoring',
        path: '/aml/fraud-monitoring',
        notExists: true,
        type: 'link',
        subroutes: [
            {
                name: 'Transaction Fraud Monitoring',
                key: 'fraud-monitoring',
                path: '/aml/fraud-monitoring',
                type: 'link',
                features: [
                    {
                        name: 'Fraud Monitoring Register',
                        key: 'register',
                    },
                    {
                        name: 'Fraud Monitoring Settings',
                        key: 'settings',
                    },
                ],
            },
        ],
    },
    {
        name: 'Vendors',
        key: 'vendors',
        path: '/aml/vendors',
        notExists: true,
        type: 'link',
        subroutes: [
            {
                name: 'Vendors',
                key: 'vendors',
                path: '/aml/vendors',
                type: 'link',
                features: [
                    {
                        name: 'View Vendors',
                        key: 'view',
                    },
                    {
                        name: 'Acting as a Vendor',
                        key: 'acting',
                    },
                    {
                        name: 'Add New Vendor',
                        key: 'newVendor',
                    },
                    {
                        name: 'Add Bulk Vendors',
                        key: 'bulkVendors',
                    },
                    {
                        name: 'Upload Questionnaire',
                        key: 'uploadQ',
                    },
                    {
                        name: 'Upload Questionnaire Response',
                        key: 'uploadQR',
                    },
                ],
            },
        ],
    },
    {
        name: 'Infrastructure Scans',
        key: 'infrastructures',
        path: '/scans/automated-scans',
        notExists: true,
        type: 'link',
        subroutes: [
            {
                name: 'View Infrastructure Scans',
                key: 'infrastructures',
                path: '/scans/automated-scans',
                type: 'link',
                features: [
                    {
                        name: 'Exempted Scans',
                        key: 'exempt',
                    },
                    {
                        name: 'Tasks for You',
                        key: 'task',
                    },
                ],
            },
        ],
    },
    {
        name: 'Penetration Testing',
        key: 'penetration',
        path: '/scans/pentest',
        notExists: true,
        type: 'link',
        subroutes: [
            {
                name: 'Penetration Testing',
                key: 'penetration',
                path: '/scans/pentest',
                notExists: true,
                type: 'link',
                features: [
                    {
                        name: 'View Requested Pentests',
                        key: 'requested',
                    },
                    {
                        name: 'View Uploaded Pentests',
                        key: 'uploaded',
                    },
                    {
                        name: 'Request Penetration Testing',
                        key: 'request',
                    },
                    {
                        name: 'Upload Penetration Testing',
                        key: 'upload',
                    },
                ],
            },
        ],
    },
    {
        name: 'PCI Scans',
        key: 'pciscan',
        path: '/scans/pciscans',
        notExists: true,
        type: 'link',
        subroutes: [
            {
                name: 'View PCI Scans',
                key: 'pciscan',
                path: '/scans/pciscans',
                type: 'link',
                features: [
                    {
                        name: 'Request PCI Scan',
                        key: 'request',
                    },
                    {
                        name: 'Upload PCI Report',
                        key: 'upload',
                    },
                ],
            },
        ],
    },
    {
        name: 'API Compliance Scans',
        key: 'apicompliance',
        path: '/scans/api-scans',
        notExists: true,
        type: 'link',
        subroutes: [
            {
                name: 'API Compliance Scans',
                key: 'apicompliance',
                path: '/scans/api-scans',
                type: 'link',
                features: [
                    {
                        name: 'New API Compliance Scan',
                        key: 'new',
                    },
                ],
            },
        ],
    },
    {
        name: 'Codebase Security Scans',
        key: 'codebase',
        path: '/scans/codebase',
        notExists: true,
        type: 'link',
        subroutes: [
            {
                name: 'Codebase Security Scans',
                key: 'codebase',
                path: '/scans/codebase',
                type: 'link',
                features: [
                    {
                        name: 'New Code Review',
                        key: 'new',
                    },
                ],
            },
        ],
    },
    {
        name: 'Vulnerability Scans',
        key: 'vulnerability',
        path: '/scans/vulnerability',
        notExists: true,
        type: 'link',
        subroutes: [
            {
                name: 'View Vulnerability Scans',
                key: 'vulnerability',
                path: '/vulnerability',
                type: 'link',
                features: [
                    {
                        name: 'Scan IP',
                        key: 'scanIP',
                    },
                ],
            },
            {
                name: 'Vulnerability Scans',
                key: 'vulnerability',
                path: '/scans/vulnerability',
                type: 'link',
                features: [
                    {
                        name: 'Continuous Monitoring',
                        key: 'continuousMonitoring',
                    },
                ],
            },
        ],
    },
];

export const generateBreadcrumbs = (path = '') => {
    const pathSegments = path.split('/').filter(Boolean);
    const breadcrumbs = [];

    // console.log(path, pathSegments);

    for (let i = 0; i < pathSegments?.length; i++) {
        // route exists
        const matchedRoute = true;

        if (matchedRoute) {
            breadcrumbs.push({ name: pathSegments[i], path: pathSegments.slice(0, i + 1)?.join('/') });
        } else {
            break;
        }
    }

    // console.log(fullPath, breadcrumbs);

    return breadcrumbs;
};
