import { IndentDecrease } from 'lucide-react';

export default function DecreaseIndentTool({ editorRef }) {
    function handleDecreaseIndent() {
        if (editorRef.current) {
            const quill = editorRef.current;
            const selection = quill.getSelection();
            const format = quill.getFormat();

            const currentIndent = format['indent'] || 0;

            if (!selection) return;

            quill.format('indent', Math.max(0, currentIndent - 1));

            quill.focus();
        }
    }

    return (
        <button
            className="!h-7 !w-7 !rounded grid place-items-center transition-all hover:brightness-95 text-[#1E293B] bg-white"
            onClick={handleDecreaseIndent}
        >
            <IndentDecrease className="!h-5 !w-5" />
        </button>
    );
}
