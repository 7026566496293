import { Minus, Plus } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedSize } from 'store/actions/editorActions';
import { ptToPx } from '../utils/fns';

export default function SizeTool({ editorRef }) {
    const selectedSize = useSelector((state) => state.editorReducers.selectedSize);

    const [inputSize, setInputSize] = useState(selectedSize);

    const dispatch = useDispatch();

    function decreaseSize() {
        if (selectedSize === '8') return;
        const newSize = parseInt(selectedSize) - 1;
        handleSizeChange(newSize.toString());
    }

    function increaseSize() {
        if (selectedSize === '72') return;
        const newSize = parseInt(selectedSize) + 1;
        handleSizeChange(newSize.toString());
    }

    function handleInputOnChange(event) {
        const currentSize = parseInt(event.target.value);

        if (isNaN(currentSize)) {
            setInputSize('');
            return;
        }

        if (currentSize > 72) {
            setInputSize('72');
            return;
        }

        setInputSize(event.target.value);
    }

    function handleInputOnBlur() {
        if (!inputSize) {
            setInputSize(selectedSize);
            return;
        }

        handleSizeChange(inputSize);
    }

    function handleSizeChange(size) {
        if (editorRef.current) {
            const quill = editorRef.current;
            quill.format('size', size + 'px');
            dispatch(setSelectedSize(size));
            quill.focus();
        }
    }

    useEffect(() => {
        setInputSize(selectedSize);
    }, [selectedSize]);

    useEffect(() => {
        if (editorRef.current) {
            const quill = editorRef.current;

            // eslint-disable-next-line no-inner-declarations
            function handleSelectionChange() {
                if (!quill.hasFocus()) return;
                const format = quill.getFormat();

                if (format['size']) {
                    if (format['size']?.includes('px')) {
                        dispatch(setSelectedSize(format['size']?.replace('px', '')));
                    } else if (format['size']?.includes('pt')) {
                        dispatch(setSelectedSize(ptToPx(parseInt(format['size']?.replace('pt', ''))).toString()));
                    }
                } else {
                    dispatch(setSelectedSize('15'));
                }
            }

            quill.on('selection-change', handleSelectionChange);

            return () => {
                quill.off('selection-change', handleSelectionChange);
            };
        }
    }, [editorRef]);

    return (
        <div className="flex items-center !gap-0.5">
            <button
                className="!h-7 !w-7 !rounded grid place-items-center bg-white transition-all hover:brightness-95"
                onClick={decreaseSize}
            >
                <Minus className="!h-4 !w-4 text-[#1E293B]" />
            </button>

            <input
                type="text"
                inputMode="numeric"
                className="!h-7 !w-8 text-center !border !border-[#E2E8F0] text-sm font-normal text-[#1E293B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                value={inputSize}
                onChange={handleInputOnChange}
                onBlur={handleInputOnBlur}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                        handleInputOnBlur();
                    }
                }}
            />

            <button
                className="!h-7 !w-7 !rounded grid place-items-center bg-white transition-all hover:brightness-95"
                onClick={increaseSize}
            >
                <Plus className="!h-4 !w-4 text-[#1E293B]" />
            </button>
        </div>
    );
}
