import { Route, Switch } from 'react-router-dom';
import CategoryPage from './CategoryPage';
import KdpaDashboard from './KdpaDashboard';
// import FormsAndDocumentsView from './FormsAndDocumentsView';
// import KdpaFormCreation from '../Operations/FormCreation';

const Kdpa = () => {
    return (
        <Switch>
            <Route path="/admin/kdpa" exact component={KdpaDashboard} />
            <Route path="/admin/kdpa/category" exact component={CategoryPage} />
            {/* <Route path="/admin/kdpa/form" component={(props) => <KdpaFormCreation tag="kdpa" {...props} />} /> */}
        </Switch>
    );
};

export default Kdpa;
