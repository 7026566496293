import * as actionTypes from '../constants';

const initialState = {
    blotLinkAnchorEl: null,
    blotDetails: {
        startingIndex: 0,
        blotElementLength: 0,
        blotElementText: '',
        blotElementLink: '',
    },
    currentTemplate: null,
    selectedFont: 'sans-serif',
    selectedSize: '15',
    selectedAlignment: 'left',
    selectedBackgroundColor: '#ffffff',
    colorPickerBackgroundColor: '#ffffff',
    isBold: false,
    isItalic: false,
    isUnderline: false,
    isStrike: false,
    isCode: false,
    selectedColor: '#64748B',
    colorPickerColor: '#64748B',
    isCodeBlock: false,
    commentDialogAnchorEl: null,
    commentMode: 'create',
    commentPopUpAnchorEl: null,
    isLoadingDocument: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_BLOT_LINK_ANCHOR_EL:
            return { ...state, blotLinkAnchorEl: action.payload };
        case actionTypes.SET_BLOT_DETAILS:
            return { ...state, blotDetails: action.payload };
        case actionTypes.SET_CURRENT_TEMPLATE:
            return { ...state, currentTemplate: action.payload };
        case actionTypes.SET_SELECTED_FONT:
            return { ...state, selectedFont: action.payload };
        case actionTypes.SET_SELECTED_SIZE:
            return { ...state, selectedSize: action.payload };
        case actionTypes.SET_SELECTED_ALIGNMENT:
            return { ...state, selectedAlignment: action.payload };
        case actionTypes.SET_SELECTED_BACKGROUND_COLOR:
            return { ...state, selectedBackgroundColor: action.payload };
        case actionTypes.SET_COLOR_PICKER_BACKGROUND_COLOR:
            return { ...state, colorPickerBackgroundColor: action.payload };
        case actionTypes.SET_IS_BOLD:
            return { ...state, isBold: action.payload };
        case actionTypes.SET_IS_ITALIC:
            return { ...state, isItalic: action.payload };
        case actionTypes.SET_IS_UNDERLINE:
            return { ...state, isUnderline: action.payload };
        case actionTypes.SET_IS_STRIKE:
            return { ...state, isStrike: action.payload };
        case actionTypes.SET_IS_CODE:
            return { ...state, isCode: action.payload };
        case actionTypes.SET_SELECTED_COLOR:
            return { ...state, selectedColor: action.payload };
        case actionTypes.SET_COLOR_PICKER_COLOR:
            return { ...state, colorPickerColor: action.payload };
        case actionTypes.SET_IS_CODE_BLOCK:
            return { ...state, isCodeBlock: action.payload };
        case actionTypes.CLEAR_FORMATTING:
            return { ...initialState };
        case actionTypes.SET_COMMENT_DIALOG_ANCHOR_EL:
            return { ...state, commentDialogAnchorEl: action.payload };
        case actionTypes.SET_COMMENT_MODE:
            return { ...state, commentMode: action.payload };
        case actionTypes.SET_COMMENT_POP_UP_ANCHOR_EL:
            return { ...state, commentPopUpAnchorEl: action.payload };
        case actionTypes.SET_IS_LOADING_DOCUMENT:
            return { ...state, isLoadingDocument: action.payload };
        default:
            return state;
    }
};

export default reducer;
