import { combineReducers } from 'redux';

import adminReducers from './adminReducers';
import apiComplianceReducers from './apiComplianceReducers';
import articlesReducers from './articlesReducers';
import auditorReducers from './auditorReducers';
import auditReducers from './auditReducers';
import authReducers from './authReducers';
import automatedScansReducers from './automatedScansReducers';
import CISOReducers from './cisoReducers';
import complianceReducers from './complianceReducers';
import dashboardReducers from './dashboardReducers';
import editorReducers from './editorReducers';
import fraudGuardianReducers from './fraudGuardianReducers';
import generalReducers from './generalReducers';
import guideReducers from './guideReducers';
import implementerReducer from './implementerReducer';
import integrationsReducers from './integrationsReducers';
import iso27001plusiso27035Reducers from './iso270001+iso27035Reducers';
import ISO37301Reducers from './iso37301Reducers';
import ISO9001Reducers from './iso9001Reducers';
import merchantReducers from './merchantReducers';
import ndprReducers from './ndprReducers';
import notificationReducers from './notificationReducers';
import onboardingReducers from './onboardingReducers';
import pcislcReducers from './pci_slcReducers';
import pcidssReducers from './pcidssReducers';
import riskAssessmentReducers from './riskAssessmentReducers';
import subscriptionReducers from './subscriptionReducers';
import tasksReducers from './taskReducers';
import trainingReducers from './trainingReducers';
import userGuideReducers from './userGuideReducers';
import vendorReducers from './vendorReducers';

export default combineReducers({
    authReducers,
    auditReducers,
    auditorReducers,
    merchantReducers,
    generalReducers,
    adminReducers,
    vendorReducers,
    notificationReducers,
    guideReducers,
    onboardingReducers,
    integrationsReducers,
    complianceReducers,
    automatedScansReducers,
    subscriptionReducers,
    dashboardReducers,
    trainingReducers,
    fraudGuardianReducers,
    apiComplianceReducers,
    iso27001plusiso27035Reducers,
    pcidssReducers,
    ndprReducers,
    tasksReducers,
    articlesReducers,
    pcislcReducers,
    implementerReducer,
    userGuideReducers,
    ISO9001Reducers,
    ISO37301Reducers,
    CISOReducers,
    riskAssessmentReducers,
    editorReducers,
});
