export const TABS = [
    { name: 'Onboarding Policies', id: 0 },
    { name: 'Awareness Training', id: 1 },
];
export function formatAcknowledgementTimestamp(timestamp) {
    const date = new Date(timestamp);
    const pad = (num) => num.toString().padStart(2, '0');
    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Months are 0-based
    const year = date.getFullYear();
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

export function capitalizeFirstLetter(str) {
    if (!str) return str;
    const [first, ...rest] = str;
    return first.toUpperCase() + rest.join('');
}
