/* eslint-disable no-unused-vars */

import EmptyState from 'components/new_components/EmptyState';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { policyStatementsTabs } from '../../../db';
import { extractDocName } from '../../../utils';
import AwarenessDialog from '../../AwarenessDialog';
import ViewDocumentDialog from '../../ViewDocumentDialog';
import StatementCard from './StatementCard';
import TabBar from './TabBar';

export default function MainContent({
    mode,
    document,
    currentStatement,
    setCurrentStatement,
    isPaid,
    currentRequirement,
    moduleTitle,
    statements,
    isNativeFile,
}) {
    const [currentTab, setCurrentTab] = useState(policyStatementsTabs[0]);
    const [isCopied, setIsCopied] = useState(false);
    const [isAwarenessDialogOpen, setIsAwarenessDialogOpen] = useState(false);
    const [isViewDocumentDialogOpen, setIsViewDocumentDialogOpen] = useState(false);

    const totalImplemented = useMemo(
        () => statements?.filter((statement) => statement?.status === 'implemented').length,
        [statements]
    );

    const totalGaps = useMemo(() => statements?.filter((statement) => statement?.gaps).length, [statements]);

    const docExtension = isNativeFile
        ? document?.name?.split('.').pop()
        : (document?.filename || document?.default)?.split('.').pop();

    function copyToClipboard(event) {
        event.stopPropagation();

        const gaps = statements
            ?.filter((statement) => statement?.gaps)
            .map((statement) => statement?.policy)
            ?.join('\n');

        navigator.clipboard.writeText(gaps).then(() => {
            setIsCopied(true);

            setTimeout(() => {
                setIsCopied(false);
            }, 3000);
        });
    }

    function formatFileSize(file) {
        if (!file || !file.size) return 'Invalid file';

        const sizeInKB = file.size / 1024;

        if (sizeInKB >= 1000) {
            return (sizeInKB / 1024).toFixed(2) + ' MB';
        } else {
            return sizeInKB.toFixed(2) + ' KB';
        }
    }

    useEffect(() => {
        setCurrentStatement(0);
    }, [currentTab]);

    return (
        <>
            <div className="!px-6">
                <TabBar
                    isPaid={isPaid}
                    mode={mode}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    statements={statements}
                />

                <div className="flex flex-col items-center !gap-2 !pt-6 !h-[calc(100vh-119px)] overflow-y-auto custom-scroll !w-full">
                    {/* {currentTab === policyStatementsTabs[0] && mode === 'upload' && (
                        <div className="bg-[#F8FAFC] !border !border-[#F0F0FA] !rounded flex !gap-1 !px-4 !py-2 items-start !max-w-[922px]">
                            <img src="/img/frameworks/lightbulb.svg" alt="Bulb Icon" className="!mt-0.5 !w-6 !h-6" />

                            <p className="text-[#395BA9] font-normal text-sm leading-5">
                                <span className="font-bold">5</span> policy statement in this template has been
                                fulfilled in another document.
                            </p>
                        </div>
                    )} */}

                    {currentTab === policyStatementsTabs[0] && mode === 'upload' && document && (
                        <div className="!border !border-[#E2E8F0] !w-full !p-4 !rounded-2xl flex flex-col !gap-1 bg-white !mb-2">
                            <div className="flex items-center justify-between !py-2">
                                <div className="flex items-center !gap-2">
                                    {docExtension === 'pdf' ? (
                                        <img src="/img/frameworks/pdf.svg" alt="file" className="!w-6 !h-6" />
                                    ) : (
                                        <img src="/img/frameworks/docx.svg" alt="file" className="!w-6 !h-6" />
                                    )}

                                    <div className="flex flex-col">
                                        <p className="text-[#475569] text-sm">
                                            {isNativeFile
                                                ? document?.name?.replaceAll('_', ' ')
                                                : extractDocName(
                                                      (document?.filename || document?.default)?.replaceAll('_', ' ')
                                                  )}
                                        </p>

                                        <p className="text-[#94A3B8] flex items-center !gap-1 text-xs">
                                            <span>
                                                {moment(
                                                    isNativeFile ? document?.lastModifiedDate : document?.date_updated
                                                ).format('DD MMM, YYYY')}
                                            </span>

                                            {isNativeFile && (
                                                <>
                                                    <span className="text-[#64748B]">•</span>

                                                    <span>{formatFileSize(document)}</span>
                                                </>
                                            )}
                                        </p>
                                    </div>
                                </div>

                                <div className="flex items-center !gap-3">
                                    {isPaid ? (
                                        <button
                                            className="bg-white !h-9 !border !border-[#E2E8F0] flex items-center !gap-1 !rounded-lg !py-1.5 !px-3 hover:brightness-95 transition-colors text-[#64748B] text-sm font-medium disabled:opacity-50 disabled:pointer-events-none"
                                            onClick={copyToClipboard}
                                        >
                                            {isCopied ? (
                                                <span>Copied</span>
                                            ) : (
                                                <>
                                                    <img
                                                        src="/img/frameworks/copy.svg"
                                                        alt="pdf"
                                                        className="!h-6 !w-6"
                                                    />

                                                    <span>Copy all gaps</span>
                                                </>
                                            )}
                                        </button>
                                    ) : (
                                        <button
                                            className="bg-[#F1F5F9] !h-9 !border !border-[#E2E8F0] !rounded-lg !py-1 !px-3 hover:brightness-95 transition-colors text-[#64748B] text-sm font-medium disabled:opacity-50 disabled:pointer-events-none"
                                            onClick={() => setIsViewDocumentDialogOpen(true)}
                                        >
                                            View doc
                                        </button>
                                    )}
                                </div>
                            </div>

                            {isPaid && totalImplemented ? (
                                <p className="text-[#37A372] font-semibold text-[15px]">
                                    {totalImplemented} policy statements available in this file
                                </p>
                            ) : null}

                            <div className="flex items-center !gap-2">
                                <img src="/img/frameworks/warning_amber.svg" alt="warning" className="!w-6 !h-6" />

                                {isPaid ? (
                                    <p className="text-[#755B00] font-medium text-[15px]">
                                        <span className="font-bold">{totalGaps}</span> gaps left in this requirement.
                                        You can edit this file with the editor to add the gaps or you can upload another
                                        file.
                                    </p>
                                ) : (
                                    <p className="text-[#755B00] font-bold text-lg">
                                        We noticed this file has some gaps that has not been implemented in{' '}
                                        {moduleTitle + ' ' + currentRequirement?.id}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}

                    {currentTab === policyStatementsTabs[2] && !isPaid && mode === 'view-gaps' && (
                        <div className="bg-[#F8FAFC] !border !border-[#F0F0FA] !rounded flex !gap-1 !px-4 !py-2 items-start !max-w-[922px]">
                            <img src="/img/frameworks/lightbulb.svg" alt="Bulb Icon" className="!mt-0.5 !w-6 !h-6" />

                            <p className="text-[#395BA9] font-normal text-[13px] leading-5">
                                All identified gaps are policy statements that have not been implemented in{' '}
                                {moduleTitle + ' ' + currentRequirement?.id}. You can copy these gaps and either
                                incorporate them into a new document or add them to an existing one by using the{' '}
                                <span
                                    className="font-medium underline decoration-[#395BA9] cursor-pointer transition-all hover:no-underline"
                                    role="button"
                                >
                                    Use uploaded file
                                </span>{' '}
                                menu.
                            </p>
                        </div>
                    )}

                    {isPaid ? (
                        statements?.filter((statement) => {
                            if (currentTab === 'all') return true;

                            if (currentTab === 'gaps') return statement?.gaps;

                            return statement?.status === currentTab;
                        })?.length ? (
                            statements
                                ?.filter((statement) => {
                                    if (currentTab === 'all') return true;

                                    if (currentTab === 'gaps') return statement?.gaps;

                                    return statement?.status === currentTab;
                                })
                                ?.map((statement, index) => (
                                    <StatementCard
                                        currentStatement={currentStatement}
                                        setCurrentStatement={setCurrentStatement}
                                        statement={statement}
                                        statementIndex={index}
                                        key={index}
                                    />
                                ))
                        ) : (
                            <EmptyState description="No Statements found" />
                        )
                    ) : (
                        <div className="relative">
                            <img
                                src="/img/frameworks/gaps-preview.svg"
                                alt="gaps feature preview"
                                className="!h-[611px] !w-[954px]"
                            />

                            <div
                                className="absolute !top-1/2 !left-1/2 !-translate-y-1/2 !-translate-x-1/2 bg-white/20 !w-max !py-2 !px-6 !border !border-[#E4E4E4] flex items-center !gap-1 transition-all hover:bg-white hover:brightness-95 !rounded-lg cursor-pointer"
                                onClick={() => setIsAwarenessDialogOpen(true)}
                                role="button"
                            >
                                <img
                                    src="/img/frameworks/solar_star-fall-bold.svg"
                                    alt="start fall"
                                    className="!h-[30px] !w-[30px]"
                                />

                                <p className="text-[#395BA9] font-semibold text-xl !w-fit">
                                    Try out our policy editor to edit files directly and save yourself the hassle
                                </p>

                                <img
                                    src="/img/frameworks/arrow-up-right.svg"
                                    alt="arrow up right"
                                    className="!h-4 !w-4"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <AwarenessDialog isOpen={isAwarenessDialogOpen} setIsOpen={setIsAwarenessDialogOpen} />

            <ViewDocumentDialog
                document={document}
                isNativeFile={isNativeFile}
                isOpen={isViewDocumentDialogOpen}
                handleCallback={() => setIsViewDocumentDialogOpen(false)}
            />
        </>
    );
}
