import Delta from 'quill-delta';

export function formatParsedQuillTableData(parsedQuill) {
    const paragraphs = parsedQuill.paragraphs;

    let table = null;
    const formattedParagraphs = [];

    paragraphs.forEach((paragraph) => {
        if (paragraph.attributes?.row) {
            if (!table) {
                table = {
                    attributes: {
                        table: true,
                    },
                    rows: [],
                };
            }

            let row = table.rows?.find((row) => row.attributes?.row === paragraph.attributes?.row);

            if (!row) {
                row = {
                    attributes: {
                        row: paragraph.attributes.row,
                    },
                    cells: [],
                };

                table.rows?.push(row);
            }

            row.cells?.push(paragraph);
        } else {
            if (table) {
                formattedParagraphs.push(table);

                table = null;
            }

            formattedParagraphs.push(paragraph);
        }
    });

    if (table) {
        formattedParagraphs.push(table);
    }

    return formattedParagraphs;
}

export function base64ToArrayBuffer(base64) {
    const binaryString = atob(base64); // Decode base64 to binary string
    const length = binaryString.length;
    const bytes = new Uint8Array(length);

    for (let i = 0; i < length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes.buffer;
}

export function getFileExtension(base64String) {
    const match = base64String.match(/^data:image\/([a-zA-Z]+);base64,/);
    return match ? match[1] : null;
}

export function pxToHalfPt(px) {
    return Math.round(px * 1.5);
}

export function ptToHalfPt(pt) {
    return Math.round(pt * 2);
}

export function ptToPx(pt) {
    return Math.round(pt * 1.33);
}

export function getDiffDelta(oldDelta, newDelta) {
    const diff = oldDelta.diff(newDelta);
    const formattedOps = [];

    diff.ops.forEach((op) => {
        if (op.delete) {
            formattedOps.push({
                retain: op.delete,
                attributes: {
                    ...op.attributes,
                    strike: true,
                    color: '#395BA9',
                },
            });
        } else if (op.insert) {
            formattedOps.push({
                insert: op.insert,
                attributes: {
                    ...op.attributes,
                    color: '#55BE8B',
                    background: '#E8FFEE',
                },
            });
        } else {
            formattedOps.push(op);
        }
    });

    return {
        formattedDelta: new Delta(formattedOps),
        editsCount: diff.ops.filter((op) => op.retain).length,
    };
}

export function composeDeltas(oldDelta, formattedDelta) {
    return oldDelta.compose(formattedDelta);
}
