/* eslint-disable no-unused-vars */

import { Dialog } from '@mui/material';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { GetDocumentLink } from 'store/actions/complianceActions';
import { extractDocName } from '../../utils';
import ApprovePolicyDialog from '../PolicyEditorDrawer/NavBar/ApprovePolicyDialog';
import RejectPolicyDialog from '../PolicyEditorDrawer/NavBar/RejectPolicyDialog';
import ReadOnlyEditor from '../PolicyEditorDrawer/ReadOnlyEditor';

export default function ViewDocumentDialog({ document, isNativeFile, isOpen, handleCallback, fetchDocReviewStats }) {
    const [isLoading, setIsLoading] = useState(false);
    const [isApprovePolicyDialogOpen, setIsApprovePolicyDialogOpen] = useState(false);
    const [isRejectPolicyDialogOpen, setIsRejectPolicyDialogOpen] = useState(false);
    const [rejectPolicyMode, setRejectPolicyMode] = useState('approve');

    const user_id = useSelector((state) => state?.authReducers?.user_id);

    const dispatch = useDispatch();

    const docExtension = isNativeFile
        ? document?.name?.split('.').pop()
        : (document?.filename || document?.default)?.split('.').pop();

    const isApprover = isNativeFile
        ? false
        : document?.approved_by === user_id && document?.policy_status === 'awaiting_approval';

    function handleClose() {
        handleCallback();
    }

    function convertDocumentObjectToUrlLink(document) {
        const url = URL.createObjectURL(document);
        window.open(url, '_blank');
    }

    async function getDocumentLink(id) {
        setIsLoading(true);
        const res = await dispatch(GetDocumentLink(document?.document ? 'userdoc' : 'document', id));
        setIsLoading(false);
        if (res?.success) {
            window.open(res?.data, '_blank');
        } else {
            toast.error('Something went wrong!');
        }
    }

    if (!document || !['pdf', 'docx'].includes(docExtension)) {
        return null;
    }

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        borderRadius: '8px !important',
                        padding: 0,
                        boxShadow: '0px 16px 24px 0px #00000026',
                        maxWidth: '1152px',
                        width: '100%',
                        height: '80vh',
                    },
                }}
            >
                <div className="flex items-center justify-between !px-6 !py-3 !border-b !border-[#E2E8F0]">
                    <div className="flex items-center !gap-2">
                        {docExtension === 'pdf' ? (
                            <img src="/img/frameworks/pdf.svg" alt="file" className="!w-6 !h-6" />
                        ) : (
                            <img src="/img/frameworks/docx.svg" alt="file" className="!w-6 !h-6" />
                        )}

                        <h5 className="text-xs font-semibold text-[#475569]">
                            {isNativeFile
                                ? document?.name?.replaceAll('_', ' ')
                                : extractDocName((document?.filename || document?.default)?.replaceAll('_', ' '))}
                        </h5>
                    </div>

                    <button
                        className="border-[#E2E8F0] border rounded-full !p-1 !h-6 !w-6 grid place-items-center hover:bg-[#E2E8F0] transition-colors"
                        onClick={handleClose}
                    >
                        <img src="/img/automated-scan/close.svg" alt="close icon" className="object-contain" />
                    </button>
                </div>

                <div className="!p-4 !h-[calc(80vh-96px)] overflow-y-auto overflow-x-auto custom-scroll !w-full">
                    <ReadOnlyEditor
                        readOnlyDocument={document}
                        isNativeFile={isNativeFile}
                        delta={isNativeFile ? null : document?.operations}
                    />
                </div>

                <div className="flex items-center !gap-3 justify-end !border-t !border-[#E2E8F0] !py-2 !px-4">
                    <button
                        onClick={() =>
                            isNativeFile ? convertDocumentObjectToUrlLink(document) : getDocumentLink(document?.id)
                        }
                        className="transition-all hover:brightness-95 bg-white !rounded-lg !border !border-[#E2E8F0] !p-1 flex items-center disabled:opacity-50 disabled:pointer-events-none"
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <Loader2 className="!h-5 !w-5 animate-spin" />
                        ) : (
                            <img src="/img/frameworks/print.svg" alt="download" className="!w-6 !h-6" />
                        )}
                    </button>

                    {isApprover && (
                        <>
                            <button
                                className="transition-all hover:brightness-95 bg-[#202D66] !rounded !h-9 !p-2 text-white font-medium text-sm"
                                onClick={() => setIsApprovePolicyDialogOpen(true)}
                            >
                                Approve
                            </button>

                            <button
                                className="transition-all hover:brightness-95 bg-white !rounded !h-9 !p-2 !border !border-[#E2E8F0] text-[#FF5449] font-medium text-sm"
                                onClick={() => {
                                    setRejectPolicyMode('approve');
                                    setIsRejectPolicyDialogOpen(true);
                                }}
                            >
                                Reject
                            </button>
                        </>
                    )}
                </div>
            </Dialog>

            <ApprovePolicyDialog
                document={document}
                isOpen={isApprovePolicyDialogOpen}
                setIsOpen={setIsApprovePolicyDialogOpen}
                handleCallback={() => {
                    handleClose();
                    fetchDocReviewStats();
                }}
            />

            <RejectPolicyDialog
                document={document}
                mode={rejectPolicyMode}
                isOpen={isRejectPolicyDialogOpen}
                setIsOpen={setIsRejectPolicyDialogOpen}
                handleCallback={() => {
                    handleClose();
                    fetchDocReviewStats();
                }}
            />
        </>
    );
}
