import { IndentIncrease } from 'lucide-react';

export default function IncreaseIndentTool({ editorRef }) {
    function handleIncreaseIndent() {
        if (editorRef.current) {
            const quill = editorRef.current;
            const selection = quill.getSelection();
            const format = quill.getFormat();

            const currentIndent = format['indent'] || 0;

            if (!selection) return;

            quill.format('indent', currentIndent + 1);

            quill.focus();
        }
    }

    return (
        <button
            className="!h-7 !w-7 !rounded grid place-items-center transition-all hover:brightness-95 text-[#1E293B] bg-white"
            onClick={handleIncreaseIndent}
        >
            <IndentIncrease className="!h-5 !w-5" />
        </button>
    );
}
