import { ClickAwayListener, Popper } from '@mui/material';
import { AlignCenter, AlignJustify, AlignLeft, AlignRight, ChevronDown } from 'lucide-react';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAlignment } from 'store/actions/editorActions';
import { alignments } from '../utils/db';

export default function AlignmentTool({ editorRef }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const selectedAlignment = useSelector((state) => state.editorReducers.selectedAlignment);

    const dispatch = useDispatch();

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    function handleClick(event) {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }

    function handleAlignmentChange(align) {
        if (editorRef.current) {
            const quill = editorRef.current;
            quill.format('align', align === 'left' ? '' : align);
            dispatch(setSelectedAlignment(align));
            setAnchorEl(null);
            quill.focus();
        }
    }

    function RenderAlignmentIcon({ align }) {
        switch (align) {
            case 'left':
                return <AlignLeft className="!h-5 !w-5" />;

            case 'center':
                return <AlignCenter className="!h-5 !w-5" />;

            case 'right':
                return <AlignRight className="!h-5 !w-5" />;

            case 'justify':
                return <AlignJustify className="!h-5 !w-5" />;

            default:
                return <AlignLeft className="!h-5 !w-5" />;
        }
    }

    useEffect(() => {
        if (editorRef.current) {
            const quill = editorRef.current;

            // eslint-disable-next-line no-inner-declarations
            function handleSelectionChange() {
                if (!quill.hasFocus()) return;
                const format = quill.getFormat();

                if (format['align']) {
                    dispatch(setSelectedAlignment(format['align']));
                } else {
                    dispatch(setSelectedAlignment('left'));
                }
            }

            quill.on('selection-change', handleSelectionChange);

            return () => {
                quill.off('selection-change', handleSelectionChange);
            };
        }
    }, [editorRef]);

    return (
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <div>
                <button
                    aria-describedby={id}
                    className="capitalize !py-1 !px-2 !rounded bg-white text-[#1E293B] flex items-center !gap-0.5 transition-all hover:brightness-95"
                    onClick={handleClick}
                >
                    <RenderAlignmentIcon align={selectedAlignment} />

                    <ChevronDown className="!h-4 !w-4" />
                </button>

                <Popper
                    id={id}
                    anchorEl={anchorEl}
                    placement="bottom"
                    open={open}
                    sx={{
                        zIndex: 1200,
                        borderRadius: '4px',
                        boxShadow: '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                        backgroundColor: '#FFFFFF',
                    }}
                >
                    <div className="flex w-fit flex-row !gap-0.5 !p-2">
                        {alignments.map((align) => (
                            <Fragment key={align}>
                                <button
                                    className={`capitalize !py-1 !px-2 !rounded flex items-center !gap-0.5 transition-all hover:brightness-95 ${
                                        selectedAlignment === align
                                            ? 'text-[#395BA9] bg-[#395BA910]'
                                            : 'text-[#1E293B] bg-white'
                                    }`}
                                    onClick={() => handleAlignmentChange(align)}
                                >
                                    <RenderAlignmentIcon align={align} />
                                </button>

                                {align !== alignments[alignments.length - 1] && (
                                    <div className="!w-px !h-full bg-[#F1F5F9]" />
                                )}
                            </Fragment>
                        ))}
                    </div>
                </Popper>
            </div>
        </ClickAwayListener>
    );
}
