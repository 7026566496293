import { Button, Tooltip, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { MdOutlineArrowForwardIos } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import { isFullFunctionPlan } from 'utils';
import InfoIcon from '../../../assets/img/dashboard/InfoIcon.png';
import InfoIcon2 from '../../../assets/img/dashboard/InfoIcon2.png';
import { CardContainer } from '../dashboard-utils/DashboardUtils';
import AllScanModal from './AllScanModal';
import BLOCKCHAINReport from './report/BLOCKCHAINReport';
import CIIReport from './report/CII';
import CONTINUITYReport from './report/CONTINUITYReport';
import ISOReport from './report/ISOReport';
import ISOTWOReport from './report/ISOTWOReport';
import NDPRReport from './report/NDPRReport';
import PCIDSS4Point0Report from './report/PCIDSS4Point0Report';
import PCIReport from './report/PCIReport';
import PCISSLCReport from './report/PCISSLCReport';
import PenetrationResult from './report/PenetrationResult';
import SOCReport from './report/SOCReport';
import ISO37301Report from './report/iso37301';
import ISO9001Report from './report/iso9001';
// import { Tooltip } from 'antd';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ComplianceHealthSuggestions from '../smartDashboardAI/ComplianceHealthSuggestions';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTheme } from '@mui/styles';

// import VulnerabilitiesModal from '../OtherReports/VulnerabilitiesModal';

//translations
import { Progress } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const SecondLayerReport = ({
    pricing_plan,
    dashboardResult,
    standards,
    openSuggestionsModal,
    suggestionsModalOpen,
    closeSuggestionsModal,
    smartComplianceReport,
    ai_recommendations,
    ref1,
    ref2,
    organization,
}) => {
    const [open, setOpen] = useState(false);

    //translation
    const { t } = useTranslation('overview');

    //functions
    const openModal = () => {
        setOpen(true);
    };
    const CloseModal = () => {
        setOpen(false);
    };
    const router = useHistory();
    const gotoPage = (link) => {
        router.push(link);
    };

    const availableStandardCards = [
        {
            name: 'iso27001',
            component: (
                <div>
                    <ISOReport percentage={dashboardResult?.iso_percentage} />
                </div>
            ),
            pathname: '/merchant/compliance/iso27001',
        },
        {
            name: 'iso27001-2022',
            component: (
                <div>
                    <ISOTWOReport percentage={dashboardResult?.iso_two_percentage} />
                </div>
            ),
            pathname: '/merchant/compliance/iso27001_2',
        },
        {
            name: 'ndpr',
            component: (
                <div>
                    <NDPRReport percentage={dashboardResult?.ndpr_percentage} />
                </div>
            ),
            pathname: '/merchant/compliance/ndpr',
        },
        {
            name: 'soc2',
            component: (
                <div>
                    <SOCReport percentage={dashboardResult?.soc2_percentage} />
                </div>
            ),
            pathname: '/merchant/compliance/soc2',
        },
        {
            name: 'pcidss',
            component: (
                <div>
                    <PCIReport percentage={dashboardResult?.pci_percentage} />
                </div>
            ),
            pathname: '/merchant/compliance/pcidss',
        },
        {
            name: 'blockchain',
            component: (
                <div>
                    <BLOCKCHAINReport percentage={dashboardResult?.blockchain_percentage?.toFixed(2)} />
                </div>
            ),
            pathname: '/merchant/compliance/blockchain',
        },
        {
            name: 'iso22301',
            component: (
                <div>
                    <CONTINUITYReport percentage={dashboardResult?.continuity_percentage} />
                </div>
            ),
            pathname: '/merchant/compliance/blockchain',
        },
        {
            name: 'pcidss4.0',
            component: (
                <div>
                    <PCIDSS4Point0Report percentage={dashboardResult?.card4_percentage} />
                </div>
            ),
            pathname: '/merchant/compliance/pcidss4.0.1',
        },
        {
            name: 'cii',
            component: (
                <div>
                    <CIIReport percentage={dashboardResult?.cii_percentage?.toFixed(2)} />
                </div>
            ),
            pathname: '/merchant/compliance/cii_directive',
        },
        {
            name: 'pcislc',
            component: (
                <div>
                    <PCISSLCReport percentage={dashboardResult?.sslc_percentage?.toFixed(2)} />
                </div>
            ),
            pathname: '/merchant/compliance/pcislc',
        },
        {
            name: 'iso9001',
            component: (
                <div>
                    <ISO9001Report percentage={dashboardResult?.iso9001_percentage?.toFixed(2)} />
                </div>
            ),
            pathname: '/merchant/compliance/iso9001',
        },
        {
            name: 'iso37301',
            component: (
                <div>
                    <ISO37301Report percentage={dashboardResult?.iso37301_percentage?.toFixed(2)} />
                </div>
            ),
            pathname: '/merchant/compliance/iso37301',
        },
    ];
    const availableCards = useMemo(() => {
        return availableStandardCards.filter((card) => {
            return standards?.includes(card.name);
        });
    }, [standards, availableStandardCards]);

    //compute guage percentage score
    const complianceHealthPercentage = useMemo(() => {
        const totalScans = smartComplianceReport?.total_passed + smartComplianceReport?.total_failed;
        const complianceHealth = (smartComplianceReport?.total_failed / totalScans) * 100;
        return complianceHealth;
    }, [smartComplianceReport?.total_passed, smartComplianceReport?.total_failed]);
    return (
        <div>
            <div
                className={`${
                    isFullFunctionPlan(pricing_plan?.tag) ? 'grid' : 'flex'
                } lg:grid-cols-2 grid-cols-1 gap-5`}
            >
                <div className="mt-3">
                    {organization?.merchant_plan?.name !== 'free_version' && (
                        <div className="h-[100px] bg-white cursor-pointer" onClick={openSuggestionsModal}>
                            <div className="flex flex-row align-center h-full pl-4">
                                <div className="flex flex-row gap-4 items-center pr-5" ref={ref1}>
                                    <Progress
                                        type="dashboard"
                                        percent={Math.round(100 - complianceHealthPercentage)}
                                        success={{
                                            percent: Math.round(100 - complianceHealthPercentage),
                                        }}
                                        trailColor="#FF5449"
                                        width={50}
                                    />
                                    <div>
                                        <p className="text-sm font-semibold pb-2">Compliance health</p>
                                        <div className="flex flex-row items-center gap-1">
                                            <Tooltip
                                                placement="top"
                                                disableFocusListener
                                                disableTouchListener
                                                title="Add smomething fam"
                                            >
                                                <ComplianceTag
                                                    text="Needs attention"
                                                    type="high"
                                                    fontSize={10}
                                                    margin={0}
                                                    frequency={smartComplianceReport?.total_failed}
                                                />
                                            </Tooltip>
                                            <Tooltip
                                                placement="top"
                                                disableFocusListener
                                                disableTouchListener
                                                title="Add smomething fam"
                                            >
                                                <ComplianceTag
                                                    text="Looks okay"
                                                    type="success"
                                                    fontSize={10}
                                                    margin={0}
                                                    frequency={smartComplianceReport?.total_passed}
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {availableCards?.length > 4 ? (
                        <Button
                            variant="text"
                            sx={{
                                float: 'right',
                                fontSize: '14px',
                                color: '#0E2C66',
                                fontWeight: 600,
                                textTransform: 'lowercase',
                            }}
                            onClick={openModal}
                            endIcon={<ArrowForwardIcon />}
                        >
                            {t('viewStandards')}
                        </Button>
                    ) : (
                        ''
                    )}
                    <div
                        className={`w-full ${
                            isFullFunctionPlan(pricing_plan?.tag) ? 'grid' : 'flex'
                        } lg:grid-cols-2 grid-cols-1 gap-5`}
                    >
                        {pricing_plan?.tag !== 'free' ? (
                            availableCards?.slice(0, 4)?.map((card, index) => {
                                return (
                                    <Link
                                        style={{ textDecoration: 'none' }}
                                        to={{
                                            pathname: card.pathname,
                                        }}
                                        key={index}
                                    >
                                        <React.Fragment key={card?.name}>{card?.component}</React.Fragment>
                                    </Link>
                                );
                            })
                        ) : (
                            <div className={`${isFullFunctionPlan(pricing_plan?.tag) ? 'w-auto' : 'w-[290px]'}`}>
                                <PCIReport percentage={dashboardResult?.ndpr_percentage} />
                            </div>
                        )}
                        {/* {pricing_plan?.tag === 'free' && (
                           
                        )} */}
                    </div>
                </div>
                <div style={{ marginTop: availableCards?.length > 4 ? '3.22rem' : '1rem' }}>
                    <div>
                        <div className="flex lg:flex-row flex-col gap-5">
                            <div className="lg:w-[55%]">
                                <CardContainer cardStyle="h-[fit-content] w-full">
                                    <div className="transition">
                                        <PenetrationResult
                                            pricing_plan={pricing_plan}
                                            dashboardResult={dashboardResult}
                                        />
                                    </div>
                                </CardContainer>
                            </div>
                            <div className="lg:w-[45%]">
                                <CardContainer cardStyle="px-4 py-3 mb-2 h-[120px]">
                                    <div>
                                        <h4 className="textbrandColor text-[11px] font-medium">
                                            {t('vulnerabilities')}
                                        </h4>
                                        <div className="flex w-full mt-1">
                                            <div className="flex-1 flex">
                                                <img
                                                    src={InfoIcon}
                                                    alt="icon"
                                                    width={40}
                                                    className="object-contain"
                                                    height={50}
                                                />
                                                <div className="ml-3 pt-1">
                                                    <h3 className="text-[12px] textbrandColor font-medium">
                                                        {t('discovery')}
                                                    </h3>
                                                    <h2 className="text-[#FF5449] text-[20px] font-[700]">
                                                        {dashboardResult?.vulnerability_count}
                                                    </h2>
                                                </div>
                                            </div>
                                            <div className="ml-auto mt-2">
                                                <div
                                                    className="flex w-[35px] cursor-pointer items-center justify-center h-[35px] rounded-full bg-[#EBEDF5]"
                                                    onClick={() => gotoPage('/merchant/scans/automated-scans')}
                                                >
                                                    <MdOutlineArrowForwardIos className="text-[14px]" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardContainer>
                                <CardContainer cardStyle="px-4 py-3 mb-3 h-[160px]">
                                    <div>
                                        <h4 className="textbrandColor text-[11px] font-medium">{t('awareness')}</h4>
                                        <div className="flex w-full mt-1">
                                            <div className="flex-1 flex">
                                                <img
                                                    src={InfoIcon2}
                                                    alt="icon"
                                                    width={40}
                                                    className="object-contain"
                                                    height={50}
                                                />
                                                <div className="ml-3 pt-1">
                                                    <h3 className="text-[12px] textbrandColor font-medium">
                                                        {' '}
                                                        {dashboardResult?.people_with_training === 1
                                                            ? 'Person'
                                                            : 'People'}
                                                    </h3>
                                                    <h2 className="textbrandColor text-[20px] font-[700]">
                                                        {dashboardResult?.people_with_training}
                                                    </h2>
                                                </div>
                                            </div>
                                            <div className="ml-auto mt-2">
                                                <div
                                                    className="flex w-[35px] cursor-pointer items-center justify-center h-[35px] rounded-full bg-[#EBEDF5]"
                                                    onClick={() => gotoPage('/merchant/onboarding/employee')}
                                                >
                                                    <MdOutlineArrowForwardIos className="text-[14px]" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardContainer>
                                <CardContainer cardStyle="px-3 py-3">
                                    <div
                                        className="flex items-center cursor-pointer"
                                        onClick={() => gotoPage('/merchant/vulnerability')}
                                    >
                                        <AiOutlineInfoCircle size="25px" className="mx-1" />
                                        <div className="text-[13px] !pb-0 pl-1 font-bold textbrandColor">
                                            {t('viewVulnerability')}
                                        </div>
                                    </div>
                                </CardContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AllScanModal
                open={open}
                handleClose={CloseModal}
                dashboardResult={dashboardResult}
                standards={standards}
            />
            <ComplianceHealthSuggestions
                open={suggestionsModalOpen}
                handleClose={closeSuggestionsModal}
                ref={ref2}
                complianceHealthPercentage={complianceHealthPercentage}
                smartComplianceReport={smartComplianceReport}
                ai_recommendations={ai_recommendations}
            />
        </div>
    );
};

const ComplianceTag = (props) => {
    const { text, type, frequency, fontSize } = props;
    const theme = useTheme();
    return (
        <Typography
            sx={{
                fontSize: fontSize ? fontSize : 12,
                fontWeight: 600,
                color: theme.palette.neutral[50],
                textTransform: 'capitalize',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                border: `1px solid ${theme.palette.neutral[95]}`,
                borderRadius: '4px',
            }}
            component="span"
        >
            <FiberManualRecordIcon
                sx={{
                    width: 10,
                    height: 10,
                    marginRight: theme.spacing(0.2),
                }}
                color={type}
            />
            {frequency || frequency === 0 ? <span className="text-[10px] ml-[1px] mr-[3px]">{frequency}</span> : null}
            {text}
        </Typography>
    );
};

const mapStateToProps = (state) => {
    return {
        organization: state?.generalReducers?.user_info?.organization,
    };
};

export default connect(mapStateToProps, {})(SecondLayerReport);
