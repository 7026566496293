// import { useTheme } from '@mui/material/styles';
import { Badge, Box, Button, ButtonGroup, Container, Stack } from '@mui/material';

// core components
import AppTabsBarNew from 'components/new_components/tabs/AppTabsBarNew';

//translation
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
// import { MoreHoriz } from '@mui/icons-material';
// import AppStyledTabNew from 'components/new_components/tabs/AppStyledTabNew';

const ComplianceLayoutNew = (props) => {
    // const theme = useTheme();
    const {
        children,
        onTabChange,
        tab,
        standard,
        openTasksModal,
        // openPolicyModal,
        // openDataRoomModal,
        // standards,
        type,
        setType,
        // view,
        company_details,
        ref1,
        // ref2,
        ref3,
        // newPolicy,
        // newTask,
    } = props;

    // const [anchor, setAnchor] = useState(null);

    const user_type = useSelector((state) => state?.authReducers?.user_type);
    //translation
    const { t } = useTranslation('compliance');

    const standards = company_details?.standards?.split(',');

    // const handleTabChange = (_, tab) => {
    //     onTabChange(tab);
    // };
    const tabs = [
        {
            index: 0,
            label: t('documents'),
        },
        // {
        //     index: 1,
        //     label: t('auditQuestion'),
        // },
        {
            index: 2,
            label: t('exceptions'),
        },
    ];

    // const openMenu = (e) => setAnchor(e.target);
    // const closeMenu = () => setAnchor(null);
    return (
        <Container
            maxWidth={false}
            elevation={0}
            sx={{
                p: '0 !important',
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backgroundColor: 'transparent',
                // borderTop: '1px solid #F1F5F9',
                mb: 5,
                flex: 1,
            }}
        >
            {/* {view === 0 && ( */}
            <AppTabsBarNew
                currentTab={tab}
                tabs={tabs}
                onChange={onTabChange}
                // standard={standard}
                ref1={ref1}
                // ref2={ref2}
                ref3={ref3}
                others={
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 3,
                        }}
                    >
                        {standard === 'soc2' && (
                            <ButtonGroup
                                sx={{
                                    border: 'none',
                                    '&:hover': {
                                        border: 'none',
                                    },
                                }}
                            >
                                {type.includes('soc2') && (
                                    <Button
                                        variant={type === 'soc2' ? 'contained' : 'outlined'}
                                        sx={{
                                            backgroundColor: type === 'soc2' ? '#395BA9' : 'outlined',
                                            border: type === 'soc2' ? 'none' : '1px solid #F1F5F9',
                                            textTransform: 'inherit',
                                            '&:hover': {
                                                backgroundColor: type === 'soc2' ? '#395BA9' : '',
                                                border: 'none',
                                            },
                                        }}
                                        onClick={() => setType('soc2')}
                                    >
                                        Type I
                                    </Button>
                                )}
                                {standards?.includes('soc2type2') && (
                                    <Button
                                        variant={type === 'soc2type2' ? 'contained' : 'outlined'}
                                        sx={{
                                            backgroundColor: type === 'soc2type2' ? '#395BA9' : 'outlined',
                                            border: type === 'soc2type2' ? 'none' : '1px solid #F1F5F9',
                                            textTransform: 'inherit',
                                            '&:hover': {
                                                backgroundColor: type === 'soc2type2' ? '#395BA9' : 'outlined',
                                                border: 'none',
                                            },
                                        }}
                                        onClick={() => setType('soc2type2')}
                                    >
                                        Type II
                                    </Button>
                                )}
                            </ButtonGroup>
                        )}
                        {standards.includes(standard) &&
                            (user_type !== 'auditor' && user_type !== 'merchant' ? (
                                <Badge
                                    variant="dot"
                                    invisible={false}
                                    color="error"
                                    onClick={() => {
                                        openTasksModal();
                                    }}
                                >
                                    <Button
                                        sx={{
                                            border: 'none',
                                            backgroundColor: '#E1E2EC',
                                            color: '#002C72',
                                            textTransform: 'inherit',
                                            fontSize: '13px',
                                            '&:hover': {
                                                backgroundColor: '#E1E2EC',
                                            },
                                        }}
                                    >
                                        Tasks for you
                                    </Button>
                                </Badge>
                            ) : null)}
                        {/* <Badge variant="dot" invisible={false} color="error" sx={{ ml: 2 }}>
                            <Button
                                sx={{
                                    border: 'none',
                                    backgroundColor: '#F1F5F9',
                                    color: '#002C72',
                                    textTransform: 'inherit',
                                    fontSize: 13,
                                    fontWeight: 600,
                                    '&:hover': {
                                        backgroundColor: '#F1F5F9',
                                    },
                                }}
                                onClick={openDataRoomModal}
                            >
                                {standard === 'soc2' ? 'Additional Evidences' : 'Data Room'}
                            </Button>
                        </Badge> */}
                        {/* {(standard === 'pcislc' || standard === 'iso9001' || standard === 'iso37301') && (
                            <Badge variant="dot" invisible={newPolicy > 0 ? false : true} color="error" sx={{ ml: 2 }}>
                                <Button
                                    sx={{
                                        backgroundColor: 'none',
                                        color: '#002C72',
                                        textTransform: 'inherit',
                                        fontSize: 13,
                                        fontWeight: 600,
                                        border: '1px solid #E2E8F0',
                                        '&:hover': {
                                            backgroundColor: 'none',
                                        },
                                    }}
                                    onClick={() => {
                                        openPolicyModal();
                                        closeMenu();
                                    }}
                                >
                                    Policy Approval
                                </Button>
                            </Badge>
                        )} */}
                        {/* {user_type !== 'merchant' && ( */}
                        {/* <Badge variant="dot" invisible={newTask > 0 ? false : true} color="error" sx={{ ml: 2 }}>
                            <Box
                                sx={{
                                    border: '1px solid #E1E2EC',
                                    p: 0.5,
                                    cursor: 'pointer',
                                    borderRadius: standard === 'pcislc' ? 1 : 0.5,
                                }}
                                onClick={openMenu}
                            >
                                <MoreHoriz />
                            </Box>
                        </Badge> */}
                        {/* )} */}
                        {/* <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu}>
                            {standard === 'soc2' && (
                                <MenuItem
                                    sx={{ fontSize: '14px', fontWeight: 500, color: '#64748B' }}
                                    onClick={() => {
                                        openPolicyModal();
                                        closeMenu();
                                    }}
                                >
                                    <Badge
                                        variant="dot"
                                        invisible={newPolicy > 0 ? false : true}
                                        color="error"
                                        sx={{ ml: 2 }}
                                    >
                                        Policy Approval
                                    </Badge>
                                </MenuItem>
                            )}
                            {user_type !== 'merchant' && <Divider />}
                            <MenuItem sx={{ fontSize: '14px', fontWeight: 500, color: '#64748B' }}> */}
                        {/* {user_type !== 'merchant' ? ( */}
                        {/* <Badge
                                    variant="dot"
                                    invisible={newTask > 0 ? false : true}
                                    color="error"
                                    onClick={() => {
                                        openTasksModal();
                                        closeMenu();
                                    }}
                                >
                                    Tasks for you
                                </Badge> */}
                        {/* ) : null} */}
                        {/* </MenuItem>
                        </Menu> */}
                    </Box>
                }
            />
            {/* // )} */}
            <Stack px={{ flex: 1 }}>{children}</Stack>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        company_details: state?.authReducers?.company_details,
    };
};
export default connect(mapStateToProps, {})(ComplianceLayoutNew);
