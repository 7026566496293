import { Button, Col, Drawer, Input, Row, Space } from 'antd';
import { Plus, Trash } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
const { TextArea } = Input;
const CategoryModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const { open, handleCancel, one_req, CreateCategory, EditCategory } = props;

    const formRef = useRef(null);

    // function
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = open === 'Add' ? await CreateCategory(data) : await EditCategory(data, one_req.id);
        setLoading(false);
        if (res.success) {
            setData({});
            handleCancel();
            open === 'Add'
                ? toast.success('Category Added Successfully')
                : toast.success('Category Updated Successfully');
        } else {
            toast.error(res?.message);
        }
    };

    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    // useEffect
    useEffect(() => {
        if (one_req) {
            setData(one_req);
        } else {
            setData({});
        }
    }, [one_req]);
    return (
        <Drawer
            title={`${open} Category`}
            open={open}
            onClose={handleCancel}
            width={600}
            extra={
                <Space>
                    <Button
                        className="ant-btn"
                        htmlType="submit"
                        type="primary"
                        loading={loading}
                        onClick={() => formRef?.current?.requestSubmit()}
                    >
                        Submit
                    </Button>
                </Space>
            }
        >
            <form onSubmit={handleSubmit} ref={formRef}>
                <Row justify="end">
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="title">Category Title</label>
                        <Input
                            type="text"
                            size="large"
                            name="title"
                            id="title"
                            onChange={handleTextChange}
                            value={data.title || ''}
                            required
                        />
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="description">Category Description</label>
                        <TextArea
                            type="text"
                            size="large"
                            name="description"
                            id="description"
                            autoSize={{
                                minRows: 4,
                            }}
                            onChange={handleTextChange}
                            value={data.description || ''}
                            required
                        />
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="policy_statements">Policy Statements</label>

                        <div className="flex flex-col !gap-4 !border !border-gray-300 !border-dashed !rounded !p-2">
                            {data?.policy_statements?.length > 1 ? (
                                data?.policy_statements?.map((statement, index) => (
                                    <div className="relative flex flex-col items-end" key={index}>
                                        <Input.TextArea
                                            type="text"
                                            size="large"
                                            name={`policy_statements_${index}`}
                                            id={`policy_statements_${index}`}
                                            value={statement}
                                            onChange={(event) => {
                                                setData((prev) => ({
                                                    ...prev,
                                                    policy_statements: prev.policy_statements.map((item, i) =>
                                                        i === index ? event.target.value : item
                                                    ),
                                                }));
                                            }}
                                        />

                                        {index === 0 ? null : (
                                            <Button
                                                size="small"
                                                type="text"
                                                color="danger"
                                                iconPosition="start"
                                                icon={<Trash className="!h-4 !w-4" />}
                                                className="!mt-1 text-red-500"
                                                onClick={() => {
                                                    setData((prev) => ({
                                                        ...prev,
                                                        policy_statements: prev.policy_statements.filter(
                                                            (_, i) => i !== index
                                                        ),
                                                    }));
                                                }}
                                            >
                                                Delete this statement
                                            </Button>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <Input.TextArea
                                    type="text"
                                    size="large"
                                    name="policy_statements_0"
                                    id="policy_statements_0"
                                    value={data?.policy_statements?.[0] || ''}
                                    onChange={(event) => {
                                        setData((prev) => ({
                                            ...prev,
                                            policy_statements: [event.target.value],
                                        }));
                                    }}
                                />
                            )}
                        </div>

                        <Button
                            type="dashed"
                            iconPosition="start"
                            icon={<Plus className="!h-4 !w-4" />}
                            className="!mt-2"
                            onClick={() => {
                                setData((prev) => ({
                                    ...prev,
                                    policy_statements: prev?.policy_statements?.length
                                        ? [...prev.policy_statements, '']
                                        : ['', ''],
                                }));
                            }}
                        >
                            Add New Statement
                        </Button>
                    </Col>
                </Row>
            </form>
        </Drawer>
    );
};

export default CategoryModal;
