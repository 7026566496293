/* eslint-disable no-unused-vars */

import { Drawer, Slide } from '@mui/material';
import { forwardRef } from 'react';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

export default function ViewPolicyStatementDrawer({ activeRow, setActiveRow }) {
    const isImplemented = activeRow?.row?.status === 'implemented';

    function handleClose() {
        setActiveRow((prev) => ({
            ...prev,
            isOpen: false,
            viewMode: 'idle',
        }));
    }

    return (
        <Drawer
            open={activeRow.isOpen && activeRow.viewMode === 'view'}
            anchor="right"
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={handleClose}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    minWidth: 370,
                    width: 479,
                    flex: 1,
                    overflow: 'auto',
                    zIndex: 9999,
                },
            }}
            scroll="paper"
        >
            <div className="flex flex-col gap-4">
                <div className="border-b border-[#F1F5F9] !px-2 !py-4 flex items-center gap-2">
                    <img
                        src="/img/automated-scan/arrow-left-icon.svg"
                        alt="arrow-left-icon"
                        onClick={handleClose}
                        className="cursor-pointer !pl-2 object-contain"
                    />

                    <h3 className="!pl-2 text-[#202D66] capitalize font-semibold text-base">Policy statement</h3>
                </div>

                <div className="!border !border-[#E2E8F0] !mx-2 !rounded-lg !p-4 flex flex-col !gap-4">
                    {isImplemented && (
                        <div className="flex items-center !gap-1 !py-1">
                            <img src="/img/frameworks/check-verified.svg" alt="checkmark" className="!h-4 !w-4" />

                            <p className="text-[#37A372] font-medium text-[13px]">Implemented</p>
                        </div>
                    )}

                    <p className="text-[#475569] text-sm !leading-4">{activeRow?.row?.name}</p>

                    {activeRow?.row?.source && (
                        <div className="flex flex-col !gap-1">
                            <p className="text-sm font-bold text-[#64748B]">Policy statement found in</p>

                            <div className="flex items-center !gap-2 !py-2.5">
                                <img src="/img/frameworks/notification-status.svg" alt="notify" className="!h-4 !w-4" />

                                <p className="text-[#64748B] capitalize font-medium text-sm">
                                    {activeRow?.row?.source?.replaceAll('_', ' ')}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Drawer>
    );
}
