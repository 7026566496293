/* eslint-disable no-unused-vars */

import { Dialog } from '@mui/material';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { UploadMerchantDocument } from 'store/actions/complianceActions';
import { policyChangesOptions } from '../../../db';

export default function ChangesDialog({
    isOpen,
    setIsOpen,
    handleCallback,
    document,
    documentNotes,
    standard,
    policyInformation,
    existingPolicyId,
    useExistingPolicy,
    setIsPolicyApprovalDrawerOpen,
    filename,
    delta,
}) {
    const [currentChangeType, setCurrentChangeType] = useState('minor');
    const [isLoading, setIsLoading] = useState(false);

    const user_id = useSelector((state) => state?.authReducers?.user_id);

    const dispatch = useDispatch();

    function handleClose() {
        setIsOpen(false);
        setCurrentChangeType('minor');
    }

    async function uploadMinorDocumentChanges() {
        try {
            setIsLoading(true);

            const nativeFormData = new FormData();
            existingPolicyId && nativeFormData.append('existing_policy', existingPolicyId);
            useExistingPolicy && nativeFormData.append('use_existing_policy', 'true');
            nativeFormData.append('document', document);
            nativeFormData.append('filename', filename);
            nativeFormData.append('tag', standard);
            nativeFormData.append('current', 'false');
            nativeFormData.append('notes', documentNotes);
            nativeFormData.append('policy_info', JSON.stringify(policyInformation));
            delta && nativeFormData.append('operations', JSON.stringify(delta));
            nativeFormData.append('requested_by', user_id);

            const response = await dispatch(UploadMerchantDocument(nativeFormData));
            if (!response.success) throw new Error(response?.message);

            toast.success('Document saved successfully');
            handleClose();
            handleCallback();
        } catch (error) {
            toast.error(error?.message || 'Failed to save document');
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        borderRadius: '4px !important',
                        padding: 0,
                        boxShadow: '0px 16px 24px 0px #00000026',
                        width: '511px',
                    },
                }}
            >
                <div className="flex items-center justify-between !px-6 !pt-6">
                    <h5 className="text-lg font-semibold text-[#202D66]">Are changes made minor or major?</h5>

                    <button
                        className="border-[#E2E8F0] border rounded-full !p-1 !h-6 !w-6 grid place-items-center hover:bg-[#E2E8F0] transition-colors"
                        onClick={handleClose}
                    >
                        <img src="/img/automated-scan/close.svg" alt="close icon" className="object-contain" />
                    </button>
                </div>

                <div className="!p-6 flex flex-col !gap-4">
                    {policyChangesOptions.map((option) => {
                        const isSelected = currentChangeType === option.changeType;

                        return (
                            <div
                                key={option.id}
                                className={`!rounded-lg !p-4 flex items-start !gap-2 bg-white transition-all hover:brightness-95 ${
                                    isSelected ? '!border-2 !border-[#202D66]' : '!border !border-[#E2E8F0]'
                                }`}
                                role="button"
                                onClick={() => setCurrentChangeType(option.changeType)}
                            >
                                {isSelected ? (
                                    <img
                                        src="/img/frameworks/radio_button_checked.svg"
                                        alt="checkbox"
                                        className="!h-5 !w-5"
                                    />
                                ) : (
                                    <img
                                        src="/img/frameworks/radio_button_unchecked.svg"
                                        alt="checkbox"
                                        className="!h-5 !w-5"
                                    />
                                )}

                                <div className="flex flex-col !gap-1 text-[#475569]">
                                    <p className="font-semibold capitalize">{option.changeType} changes</p>

                                    <p className="text-[11px]">{option.description}</p>
                                </div>
                            </div>
                        );
                    })}

                    <div className="bg-[#F8FAFC] !border !border-[#F0F0FA] !rounded flex !gap-2 !px-3 !py-2 items-start">
                        <img src="/img/frameworks/lightbulb.svg" alt="Bulb Icon" className="!mt-0.5 !w-6 !h-6" />

                        <p className="text-[#64748B] font-normal text-[13px] leading-5">
                            {currentChangeType === 'minor'
                                ? "No approval or review needed for this policy change. Employees won't be notified to accept the policy."
                                : 'Approval or review needed for this policy change. Employees will be notified to accept the policy.'}
                        </p>
                    </div>
                </div>

                <div className="flex items-center justify-end !p-6 bg-[#F8FAFC] !gap-2">
                    <button
                        className="bg-[#fff] !border !border-[#E2E8F0] !rounded-sm !px-4 !py-2 hover:bg-red-500 hover:text-white transition-colors text-[#334155] text-sm font-medium"
                        onClick={handleClose}
                    >
                        Cancel
                    </button>

                    <button
                        className="bg-[#202D66] !rounded-sm !px-4 !py-2 hover:bg-[#151e44] transition-all text-white text-sm font-semibold disabled:opacity-50 disabled:pointer-events-none flex items-center !gap-1"
                        disabled={isLoading}
                        onClick={() => {
                            if (currentChangeType === 'minor') {
                                uploadMinorDocumentChanges();
                            } else {
                                setIsPolicyApprovalDrawerOpen(true);
                                handleClose();
                            }
                        }}
                    >
                        {isLoading && <Loader2 className="!h-5 !w-5 animate-spin" />}
                        Continue
                    </button>
                </div>
            </Dialog>
        </>
    );
}
