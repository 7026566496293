import React, { useEffect, useState, useMemo } from 'react';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import CloseIcon from '@mui/icons-material/Close';
import {
    Typography,
    IconButton,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormHelperText,
    Chip,
    Stack,
    Box,
} from '@mui/material';

//redux
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { GetMerchantPeople } from 'store/actions/merchantActions';
import AppButton from 'components/new_components/AppButton';
import AppSelectSearchPinned from 'components/new_components/AppSelectSearchPinned';

const EmployeeSelect = (props) => {
    const { open, handleClose, notifyList, handleNotifyListSelect, setNotifyList } = props;

    const all_employees = useSelector((state) => state?.merchantReducers?.all_merchant_people);
    const [fetching, setFetching] = useState(false);
    const [notifyAll, setNotifyAll] = useState(false);
    const [showError, setShowError] = useState(false);

    const dispatch = useDispatch();
    const departments = useMemo(() => {
        if (!Array.isArray(all_employees) || !Array.isArray(notifyList)) return [];

        // Normalize notifyList into a Set for fast O(1) lookups
        const notifySet = new Set(
            notifyList.map((dept) => (typeof dept === 'string' ? dept.toLowerCase() : '')).filter(Boolean)
        );

        const uniqueDepartments = new Map(); // Store unique case-insensitive values

        for (const emp of all_employees) {
            const department = emp?.department;
            if (typeof department !== 'string') continue; // Skip invalid values

            const normalizedDept = department.toLowerCase(); // Normalize case

            // Skip if department is in notifyList (fast lookup with Set)
            if (notifySet.has(normalizedDept)) continue;

            if (!uniqueDepartments.has(normalizedDept)) {
                uniqueDepartments.set(normalizedDept, {
                    label: department, // Preserve original casing
                    value: department,
                });
            }
        }

        return Array.from(uniqueDepartments.values());
    }, [all_employees, notifyList]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!notifyList?.length) {
            setShowError(true);
        } else {
            setShowError(false);
            handleClose();
        }
    };

    const notifyAllCheck = (e) => {
        setNotifyAll(e.target.checked);
        if (e.target.checked) {
            let all_departments = departments?.map((emp) => {
                if (emp?.label) {
                    return emp.label;
                }
            });
            setNotifyList(all_departments);
        }
    };

    const handleDeleteItem = (itemToDelete) => {
        setNotifyList((prevList) => prevList.filter((item) => item !== itemToDelete));
    };

    useEffect(() => {
        const getAllEmployees = async () => {
            setFetching(true);
            const res = await dispatch(GetMerchantPeople());
            setFetching(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllEmployees();
    }, []);

    useEffect(() => {
        if (!notifyAll) {
            setNotifyList([]);
        }
    }, [notifyAll]);

    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            title={
                <Typography sx={{ fontSize: 18, fontWeight: 600, color: '#202D66' }}>
                    Select Notification Recipients
                </Typography>
            }
            headerAction={
                <IconButton
                    color="disabled"
                    onClick={handleClose}
                    sx={{
                        border: '1px solid #E2E8F0',
                        borderRadius: 30,
                        width: 30,
                        height: 30,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <CloseIcon sx={{ width: 20, height: 20 }} />
                </IconButton>
            }
            titleStyle={{ mx: 3, px: 0 }}
            width={511}
            sx={{
                height: 'auto',
                minHeight: '372px',
                maxHeight: '90vh',
                p: 0,
                borderRadius: '4px',
            }}
        >
            <form
                onSubmit={handleSubmit}
                className="flex flex-col justify-between w-full"
                style={{
                    minHeight: '306px',
                    height: 'auto',
                }}
            >
                <Box>
                    <AppSelectSearchPinned
                        label="Select Departments"
                        options={departments}
                        name="notify_list"
                        loading={fetching}
                        onChange={handleNotifyListSelect}
                        noDataLabel="No employees available"
                        placeholder="Select departments to be informed"
                        error={showError}
                        multiple
                    />
                    {showError ? <FormHelperText>Please Select an employee</FormHelperText> : null}

                    <Stack
                        direction="row"
                        sx={{
                            my: 2,
                            flexWrap: 'wrap',
                            gap: 0.7,
                            maxHeight: 'none',
                            height: 'auto',
                        }}
                    >
                        {notifyList?.map((item, ind) => (
                            <Chip
                                sx={{
                                    background: '#F8FAFC',
                                    color: '#475569',
                                    border: '1px solid #E2E8F0',
                                    mb: 0.5, // Add bottom margin to prevent chip stacking
                                }}
                                key={'dep-' + ind}
                                deleteIcon={
                                    <CloseIcon
                                        sx={{
                                            color: '#475569',
                                        }}
                                    />
                                }
                                label={item}
                                onDelete={() => handleDeleteItem(item)}
                            />
                        ))}
                    </Stack>
                </Box>

                <FormGroup sx={{ mt: 'auto' }}>
                    <FormControlLabel
                        control={<Checkbox checked={notifyAll} size="small" onChange={notifyAllCheck} />}
                        sx={{
                            '& .MuiTypography-root ': {
                                fontSize: 13,
                                fontWeight: 500,
                                color: '#395BA9',
                            },
                        }}
                        label="Notify all departments"
                    />
                </FormGroup>

                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{
                        backgroundColor: '#F8FAFC',
                        height: '80px',
                        mx: '-1.65rem',
                        mb: '-1rem',
                        px: '1.9rem',
                        pt: '3rem',
                        pb: '3rem',
                    }}
                >
                    <AppButton
                        name="Cancel"
                        color="primary"
                        variant="outlined"
                        disableElevation
                        sx={{
                            mr: 1,
                            border: '1px solid #E2E8F0',
                            background: '#FFFFFF',
                            textTransform: 'inherit',
                            color: '#334155',
                        }}
                        onClick={handleClose}
                    />
                    <AppButton name="Continue" color="primary" variant="contained" disableElevation type="submit" />
                </Stack>
            </form>
        </AppCenteredModal>
    );
};

export default EmployeeSelect;
