/* eslint-disable no-unused-vars */

import { Dialog } from '@mui/material';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { DeleteFromExemptedControlList } from 'store/actions/complianceActions';

export default function RevertRequirementDialog({ title, requirementData, isOpen, handleCallback }) {
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    async function handleSubmit() {
        try {
            setIsLoading(true);

            const res = await dispatch(DeleteFromExemptedControlList(requirementData?.id));

            if (res?.success) {
                toast.success(`${title || 'Requirement'} reverted successfully`);
                handleClose();
            } else {
                throw new Error(res?.message);
            }
        } catch (error) {
            console.error(error);
            toast.error(error?.message || `Failed to revert ${title || 'requirement'}`);
        } finally {
            setIsLoading(false);
        }
    }

    function handleClose() {
        handleCallback();
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: '4px !important',
                    padding: 0,
                    boxShadow: '0px 16px 24px 0px #00000026',
                    width: '440px',
                },
            }}
        >
            <div className="!p-6 flex flex-col !gap-2 justify-center items-center">
                <h5 className="text-lg font-semibold text-[#475569]">Revert {title || 'requirement'}?</h5>

                <p className="text-center text-[#64748B] text-[13px]">
                    Are you sure you want to revert this {title || 'requirement'} to its previous state?
                </p>
            </div>

            <div className="flex items-center justify-center !p-6 bg-[#F8FAFC] !gap-2">
                <button
                    className="bg-[#fff] !border !border-[#E2E8F0] !rounded-sm !px-4 !py-2 hover:brightness-90 transition-all text-[#334155] text-sm font-medium"
                    onClick={handleClose}
                >
                    Cancel
                </button>

                <button
                    className="bg-[#395BA9] !rounded-sm !px-4 !py-2 hover:brightness-90 transition-all text-white text-sm font-medium disabled:opacity-50 disabled:cursor-not-allowed flex items-center !gap-2"
                    disabled={isLoading}
                    onClick={handleSubmit}
                >
                    {isLoading && <Loader2 className="!h-4 !w-4 animate-spin" />}
                    Yes, revert
                </button>
            </div>
        </Dialog>
    );
}
