import { Box, Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import QuestionOne from '../questions/QuestionOne';
import { questionsList } from '../testdata';
import SidebarStepQuestion from './SidebarStepQuestion';

// redux
import LoadingState from 'components/new_components/LoadingState';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { GetAllAuditAnswers, GetAllAuditCategories } from 'store/actions/auditActions';
import FormItem from '../questions/FormItem';

const AuditStandardsIndex = (props) => {
    const { GetAllAuditCategories, all_audit_categories, GetAllAuditAnswers, all_audit_answers } = props;

    // hooks
    const history = useHistory();
    const { standard } = useParams();

    // state
    const [currentStep, setCurrentStep] = useState(1);
    const [loading, setLoading] = useState(false);

    // sort audit categories by id, the array should start with the default "contact information" category.
    const standardCategories = useMemo(() => {
        if (!all_audit_categories || !standard) return [];
        const sortedStandardCategories = all_audit_categories?.sort((a, b) => a?.id - b?.id);
        const standardsWithIndex = [
            { name: 'Contact Information', description: 'Assessed Entity', isDone: true },
            ...sortedStandardCategories,
        ].map((category, idx) => ({
            ...category,
            index: idx + 1,
        }));
        return standardsWithIndex;
    }, [standard, all_audit_categories]);

    const activeCategory = useMemo(() => {
        return standardCategories?.find((category) => category.index === currentStep);
    }, [currentStep, standardCategories]);

    // functions
    const goBack = () => {
        history.goBack();
    };
    const isLastCategory = () => {
        return standardCategories?.length === currentStep;
    };
    // navigate the categories
    const prev = () => {
        if (currentStep === 1) return;
        setCurrentStep((prevState) => prevState - 1);
    };
    const next = () => {
        setCurrentStep((prevState) => prevState + 1);
    };
    const goToStep = (sn) => {
        setCurrentStep(sn);
    };
    const handleCategoryNavigation = async () => {
        if (isLastCategory()) {
            return history.push('/merchant/audits');
        } else {
            return next();
        }
    };
    // async functions
    const getAllAuditCategories = async () => {
        setLoading(true);
        const res = await GetAllAuditCategories();
        setLoading(false);
        if (!res?.success) {
            toast.error("Something went wrong, couldn't fetch questions.");
        }
    };
    const getAllAuditAnswers = async () => {
        setLoading(true);
        const res = await GetAllAuditAnswers();
        setLoading(false);
        if (!res?.success) {
            toast.error("Something went wrong, couldn't fetch questions.");
        }
    };

    // useEffect
    useEffect(() => {
        getAllAuditCategories();
        getAllAuditAnswers();
    }, []);

    useEffect(() => {
        if (location?.pathname !== '/merchant/audits/edit/pcidss4.0.1' && all_audit_answers?.length) {
            setCurrentStep(all_audit_answers?.length + 2);
        }
    }, [all_audit_answers?.length]);
    return (
        <Box sx={{ position: 'relative', height: '100%' }}>
            {loading ? (
                <LoadingState />
            ) : (
                <Box className="flex transition rounded-[8px] h-full overflow-hidden">
                    <SidebarStepQuestion
                        questionsList={questionsList}
                        standard={standard}
                        goBack={goBack}
                        goToStep={goToStep}
                        currentStep={currentStep}
                        categories={standardCategories}
                        answers={all_audit_answers}
                    />
                    <div className="bg-[#FFFFFF] w-full">
                        <h3 className="audit_title">{activeCategory?.name}</h3>
                        <div className="h-[calc(100%_-_53.59px)] overflow-y-auto flex flex-col">
                            <div className="w-full py-3 border-b border-[#B8BCCC80] px-4">
                                <h3 className="text-[#46464A] font-normal text-lg">{activeCategory?.description}</h3>
                            </div>
                            <Stack sx={{ p: 3, flex: 1 }}>
                                {currentStep === 1 ? (
                                    <QuestionOne goNext={next} />
                                ) : (
                                    <FormItem
                                        category={activeCategory}
                                        goPrev={prev}
                                        answers={all_audit_answers}
                                        navigateCategory={handleCategoryNavigation}
                                        isLast={isLastCategory}
                                    />
                                )}
                            </Stack>
                        </div>
                    </div>
                </Box>
            )}
        </Box>
    );
};
const mapStateToProps = (state) => ({
    all_audit_categories: state?.auditReducers?.all_audit_categories,
    all_audit_answers: state?.auditReducers?.all_audit_answers,
});
export default connect(mapStateToProps, { GetAllAuditCategories, GetAllAuditAnswers })(AuditStandardsIndex);
