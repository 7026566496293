import React, { useState } from 'react';
import AppButton from 'components/new_components/AppButton';
import {
    FormControl,
    RadioGroup,
    FormControlLabel,
    FormHelperText,
    Radio,
    Stack,
    Typography,
    Box,
} from '@mui/material';
import EmployeeSelect from './EmployeeSelect';
import Light from 'assets/img/Light.svg';
import { useTranslation } from 'react-i18next';
import AppLoadingButton from 'components/new_components/AppLoadingButton';

const StepTwo = (props) => {
    const {
        handleRadioChange,
        handleSubmit,
        error,
        value,
        toPreviousStep,
        handleNotifyListSelect,
        notifyList,
        setNotifyList,
        isNotifyIconClicked,
        loading,
    } = props;

    const [employeeModal, setEmployeeModal] = useState(false);

    const { t } = useTranslation('onboardingPolicy');
    //  employee Modal
    const openEmployeeModal = () => {
        setEmployeeModal(true);
    };
    const closeEmployeeModal = () => {
        setEmployeeModal(false);
    };

    const notifyOptions = [
        {
            value: true,
            label: {
                title: t('notifyOptions.notify.title'),
                description: t('notifyOptions.notify.text'),
            },
        },
        {
            value: false,
            label: {
                title: t('notifyOptions.dontNotify.title'),
                description: t('notifyOptions.dontNotify.text'),
            },
        },
    ];
    return (
        <>
            {/*  {!isNotifyIconClicked ? (
                <Typography
                    sx={{
                        fontSize: 11,
                        color: theme.palette.gray[40],
                        fontWeight: 400,
                        backgroundColor: '#F8F8F8',
                        px: 3,
                        py: 1,
                        borderRadius: '8px',
                        my: 2,
                        border: `1px solid ${theme.palette.neutral[95]}`,
                    }}
                >
                    There was an update to this policy by SmartcomplyApp
                </Typography>
            ) : null} */}
            <form onSubmit={handleSubmit} className="w-[517px] pt-3">
                <RadioInput
                    notifyOptions={notifyOptions}
                    handleRadioChange={handleRadioChange}
                    error={error}
                    value={value}
                    openEmployeeModal={openEmployeeModal}
                    notifyList={notifyList}
                    isNotifyIconClicked={isNotifyIconClicked}
                />
                {!!(value === 'true') && (
                    <Stack
                        flexDirection="row"
                        sx={{
                            border: `1px solid #F0F0FA`,
                            backgroundColor: '#F8FAFC',
                            borderRadius: '4px',
                            p: '12px',
                            pt: '8px',
                            mt: 1,
                        }}
                    >
                        <img src={Light} alt="file question" className="h-fit w-fit" />
                        <Typography
                            sx={{
                                color: '#64748B',
                                fontWeight: 400,
                                fontSize: '13px',
                                ml: 1,
                            }}
                        >
                            All employees will be notified by default. Click{' '}
                            <strong
                                onClick={openEmployeeModal}
                                className="font-medium underline text-[#0E2C66] cursor-pointer inline-block"
                            >
                                here
                            </strong>{' '}
                            to select specific employees if you wish to adjust the recipient list.
                        </Typography>
                    </Stack>
                )}

                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{
                        backgroundColor: '#F8FAFC',
                        height: '80px',
                        mx: '-1.65rem',
                        mb: '-1rem',
                        px: '1.9rem',
                        pt: '3rem',
                        pb: '3rem',
                        mt: 3,
                    }}
                >
                    <AppButton
                        name={t('buttonText.cancel')}
                        color="primary"
                        variant="outlined"
                        disableElevation
                        sx={{
                            mr: 1,
                            border: '1px solid #E2E8F0',
                            background: '#FFFFFF',
                            textTransform: 'inherit',
                            color: '#334155',
                        }}
                        onClick={toPreviousStep}
                    />
                    <AppLoadingButton
                        text={t('buttonText.overwrite')}
                        variant="contained"
                        disableElevation
                        color="primary"
                        type="submit"
                        loadingPosition="center"
                        sx={{ textTransform: 'inherit', fontWeight: 600 }}
                        loading={loading}
                    />
                </Stack>
            </form>
            <EmployeeSelect
                open={employeeModal}
                handleClose={closeEmployeeModal}
                handleNotifyListSelect={handleNotifyListSelect}
                notifyList={notifyList}
                setNotifyList={setNotifyList}
            />
        </>
    );
};

const RadioInput = (props) => {
    const { error, value, handleRadioChange, notifyOptions } = props;

    return (
        <>
            <FormControl error={error} variant="standard">
                <RadioGroup name="notify" value={value} onChange={handleRadioChange}>
                    {notifyOptions?.map((option, index) => {
                        const { label } = option;
                        const boolVal = value === 'true' ? true : value === null ? '' : false;
                        return (
                            <React.Fragment key={index}>
                                <FormControlLabel
                                    value={option.value}
                                    sx={{
                                        border: option?.value !== boolVal ? `1px solid #E2E8F0` : `2px solid #202D66`,
                                        backgroundColor: 'transparent',
                                        width: '100%',
                                        m: 0,
                                        py: 2,
                                        borderRadius: '8px',
                                        my: 1,
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                    }}
                                    control={
                                        <Radio
                                            sx={{
                                                color: '#475569',
                                                '&.Mui-checked': { color: '#202D66' },
                                                alignSelf: 'flex-start',
                                                mt: 0.3,
                                            }}
                                        />
                                    }
                                    label={
                                        <Box
                                            sx={{
                                                pr: 2,
                                            }}
                                        >
                                            <Typography sx={{ fontSize: 16, fontWeight: 600, color: '#475569' }}>
                                                {label.title}
                                            </Typography>
                                            <Typography sx={{ fontSize: 11, fontWeight: 400, color: '#475569' }}>
                                                {label.description}
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </React.Fragment>
                        );
                    })}
                </RadioGroup>
                {error ? <FormHelperText>Please Select an option</FormHelperText> : null}
            </FormControl>
        </>
    );
};
export default StepTwo;
