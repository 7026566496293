import { Route, Switch } from 'react-router-dom';
import ComplianceDashboard from './ComplianceDashboard';
import RequirementPage from './RequirementPage';
// import SubReqPage from './SubReqPage';
// import FormCreation from '../Operations/FormCreation';

const Compliance = () => {
    return (
        <Switch>
            <Route path="/admin/pcidss4.0.1" exact component={ComplianceDashboard} />
            <Route path="/admin/pcidss4.0.1/requirement/" exact component={RequirementPage} />
            {/* <Route path="/admin/pcidss4.0.1/requirement/subrequirement/" exact component={SubReqPage} />
            <Route
                path="/admin/pcidss4.0.1/requirement/subrequirement/form"
                component={(props) => <FormCreation {...props} tag="card4" />}
            /> */}
        </Switch>
    );
};

export default Compliance;
