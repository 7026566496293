/* eslint-disable no-unused-vars */

import { Dialog, Menu, MenuItem } from '@mui/material';
import { Loader2 } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { UpdateMerchantDocument } from 'store/actions/complianceActions';

export default function ReviewPolicyDialog({ document, isOpen, setIsOpen, handleCallback }) {
    const [isLoading, setIsLoading] = useState(false);
    const [approval, setApproval] = useState(null);
    const [approvalAnchorEl, setApprovalAnchorEl] = useState(null);
    const [search, setSearch] = useState('');

    const dispatch = useDispatch();

    const raw_all_employees = useSelector((state) => state?.generalReducers?.all_employees);
    const employee_id = useSelector((state) => state?.authReducers?.user_info?.employee?.id);

    const all_employees = useMemo(
        () =>
            raw_all_employees
                ?.filter((employee) => employee?.id !== employee_id)
                ?.map((employee) => ({
                    ...employee,
                    name: employee?.user_detail?.first_name
                        ? `${employee?.user_detail?.first_name} ${employee?.user_detail?.last_name}`
                        : employee?.user_detail?.email,
                }))
                ?.filter((employee) => employee?.name?.toLowerCase().includes(search.toLowerCase())),
        [raw_all_employees, search]
    );

    function handleClose() {
        setIsOpen(false);
        setApproval(null);
    }

    async function updateDocumentStatus() {
        try {
            setIsLoading(true);

            const nativeFormData = {
                policy_status: 'awaiting_approval',
                approved_by: approval?.id,
            };

            const response = await dispatch(UpdateMerchantDocument(nativeFormData, document?.id));
            if (!response.success) throw new Error(response?.message);

            toast.success('Document reviewed successfully');
            handleClose();
            handleCallback();
        } catch (error) {
            toast.error(error?.message || 'Failed to review document');
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: '4px !important',
                    padding: 0,
                    boxShadow: '0px 16px 24px 0px #00000026',
                    width: '440px',
                },
            }}
        >
            <div className="!p-6 flex flex-col !gap-4">
                <div className="flex flex-col !gap-2 justify-center items-center">
                    <h5 className="text-lg font-semibold text-[#475569]">Mark policy as reviewed?</h5>

                    <p className="text-center text-[#64748B] text-[13px]">
                        By accepting this policy, you confirm you've reviewed and understand its contents
                    </p>
                </div>

                <div className="flex flex-col !gap-4 !border-t !border-[#CBD5E1] !py-2">
                    <p className="text-[#395BA9] font-semibold text-[13px]">Approval</p>

                    <div className="flex flex-col !gap-2">
                        <p className="text-xs text-[#64748B]">
                            Policy needs to be approved after successfully reviewing.
                        </p>

                        <div>
                            <button
                                aria-controls={approvalAnchorEl ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={approvalAnchorEl ? 'true' : undefined}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setApprovalAnchorEl(event.currentTarget);
                                }}
                                className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors !h-9"
                            >
                                <p
                                    className={`font-normal text-sm ${
                                        approval ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                                    }`}
                                >
                                    {approval ? approval?.name : 'Select an approver'}
                                </p>

                                <img
                                    src="/img/frameworks/chevron-down.svg"
                                    alt="Arrow Down Icon"
                                    className="object-contain"
                                />
                            </button>

                            <Menu
                                anchorEl={approvalAnchorEl}
                                open={Boolean(approvalAnchorEl)}
                                onClose={() => setApprovalAnchorEl(null)}
                                sx={{
                                    '& .MuiPaper-root': {
                                        borderRadius: '4px',
                                        boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                    },
                                    '& .MuiList-root': {
                                        padding: 0,
                                        width: '392px',
                                    },
                                }}
                            >
                                <div className="min-h-[200px] max-h-[300px] overflow-auto custom-scroll">
                                    <div className="mx-2 mt-4">
                                        <div
                                            className="relative"
                                            onKeyDown={(event) => {
                                                event.stopPropagation();
                                            }}
                                        >
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                className="w-full !py-2 !pr-9 !pl-2 !border !border-[#E2E8F0] h-9 text-[13px] font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                                value={search}
                                                onChange={(event) => {
                                                    event.stopPropagation();
                                                    setSearch(event.target.value);
                                                }}
                                            />

                                            <img
                                                src="/img/frameworks/search.svg"
                                                alt="Search Icon"
                                                className="absolute object-contain top-1/2 -translate-y-1/2 !right-2"
                                            />
                                        </div>
                                    </div>

                                    <div className="!p-2 flex flex-col !w-full">
                                        {all_employees?.length ? (
                                            all_employees?.map((employee, index) => (
                                                <MenuItem
                                                    key={index}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setApproval(employee);
                                                        setApprovalAnchorEl(null);
                                                        setSearch('');
                                                    }}
                                                    sx={{
                                                        color: '#64748B',
                                                        fontWeight: 500,
                                                        fontSize: '13px',
                                                        textTransform: 'capitalize',
                                                        padding: '8px 8px',
                                                        width: '100%',
                                                    }}
                                                >
                                                    {employee?.name}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <p className="flex items-center justify-center !my-4 text-sm text-[#64748B] font-medium">
                                                User not found
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </Menu>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex items-center justify-center !p-6 bg-[#F8FAFC] !gap-2">
                <button
                    className="bg-[#fff] !border !border-[#E2E8F0] !rounded-sm !px-4 !py-2 hover:brightness-90 transition-all text-[#334155] text-sm font-medium"
                    onClick={handleClose}
                >
                    Cancel
                </button>

                <button
                    className="bg-[#395BA9] !rounded-sm !px-4 !py-2 hover:brightness-90 transition-all text-white text-sm font-medium disabled:opacity-50 disabled:pointer-events-none flex items-center !gap-1"
                    disabled={isLoading || !approval}
                    onClick={updateDocumentStatus}
                >
                    {isLoading && <Loader2 className="!h-5 !w-5 animate-spin" />}
                    Yes, reviewed
                </button>
            </div>
        </Dialog>
    );
}
