import BreadCrumb from 'components/Breadcrumb';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

//antd
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Table } from 'antd';
import ProjectManagementModal from '../IsoActions/ProjectManagementModal';

//redux
import { connect } from 'react-redux';
import { CreateAnnexNew, DeleteAnnexNew, EditAnnexNew, GetAllProjectManagements } from 'store/actions/adminActions';
import ProjectManagementOperations from './ProjectManagementOperations';

// sub-components
// const { Title } = Typography;
const { confirm } = Modal;

const ProjectManagementView = (props) => {
    //state
    const [loading, setLoading] = useState({
        content: false,
    });
    const [modalOpen, setModalOpen] = useState(null);
    const [oneReq, setOneReq] = useState({});

    //props
    const { GetAllProjectManagements, CreateAnnexNew, EditAnnexNew, DeleteAnnexNew, all_project_management } = props;
    //functions
    const openAddModal = (mode, data) => {
        setModalOpen(mode);
        setOneReq(data);
    };
    const closeAddModal = () => {
        setModalOpen(false);
    };

    const deleteProjectManagement = (id, category) => {
        confirm({
            title: 'Are you sure you want to delete this project management? Note: Deleting this project management means you will be deleting this project management, the documents, audit questions and merchant data associated with this project management',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteAnnexNew(id, category);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    // data
    const columns = [
        {
            title: 'Title',
            render: (record) => record.title,
            sorter: (a, b) => a.title.localeCompare(b.title),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Policy Statements',
            render: (record) => (
                <ul className="list-disc flex flex-col !gap-1 !mx-4">
                    {record.policy_statements?.length ? (
                        record.policy_statements?.map((policy, index) => <li key={index}>{policy}</li>)
                    ) : (
                        <li>No policy statement</li>
                    )}
                </ul>
            ),
        },
        {
            title: 'Actions',
            align: 'right',
            render: (record) => (
                <ProjectManagementOperations
                    openEditModal={openAddModal}
                    project_management={record}
                    deleteProjectManagement={deleteProjectManagement}
                />
            ),
        },
    ];

    useEffect(() => {
        const getAllCybersecurities = async () => {
            setLoading(true);
            const res = await GetAllProjectManagements();
            setLoading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllCybersecurities();
    }, []);
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb
                        views={[
                            { label: 'ISO27017 + 27032', link: '/iso27017+27032' },
                            { label: 'Project Management' },
                        ]}
                    />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openAddModal('Add')}
                    >
                        Add Project Management
                    </Button>
                </Col>
            </Row>

            <section className="my-3">
                <Table
                    columns={columns}
                    dataSource={all_project_management}
                    loading={loading?.content}
                    rowKey={(project_management) => project_management.id}
                />
            </section>

            {/* <section className="my-3">
                {all_project_management && all_project_management?.length > 0 ? (
                    <Row wrap gutter={24}>
                        {all_project_management
                            ?.sort((a, b) => a.id - b.id)
                            ?.map((project_management, index) => (
                                <Col
                                    xs={24}
                                    md={12}
                                    lg={8}
                                    xxl={6}
                                    key={project_management.id}
                                    style={{ marginBottom: '1rem' }}
                                >
                                    <Card
                                        title={`#${index}`}
                                        loading={false}
                                        actions={[
                                            <Tooltip title="View this project management" color="blue">
                                                <Link
                                                    key="preview"
                                                    to={{
                                                        pathname: '/admin/iso27017+27032/project_management',
                                                        state: {
                                                            project_management,
                                                        },
                                                    }}
                                                >
                                                    <EyeOutlined key="preview" />
                                                </Link>
                                            </Tooltip>,
                                            <Tooltip title="Edit this project management" color="blue">
                                                <EditOutlined
                                                    key="edit"
                                                    onClick={() => openAddModal('Edit', project_management)}
                                                />
                                            </Tooltip>,
                                            <Tooltip title="Delete this project management" color="red">
                                                <DeleteOutlined
                                                    key="delete"
                                                    onClick={() =>
                                                        deleteAnnex(project_management.id, project_management.category)
                                                    }
                                                />
                                            </Tooltip>,
                                        ]}
                                    >
                                        <Title level={5} style={{ textAlign: 'center' }}>
                                            {project_management.title}
                                        </Title>
                                    </Card>
                                </Col>
                            ))}
                    </Row>
                ) : (
                    <Row justify="center">
                        <Col>
                            <Empty
                                description={
                                    <div>
                                        <h3>No Project Management</h3>
                                        <span>Add a new Project Management </span>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                )}
            </section> */}

            <ProjectManagementModal
                open={modalOpen}
                handleCancel={closeAddModal}
                CreateClause={CreateAnnexNew}
                EditClause={EditAnnexNew}
                one_req={oneReq}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_project_management: state?.adminReducers?.all_project_management,
    };
};

export default connect(mapStateToProps, {
    GetAllProjectManagements,
    CreateAnnexNew,
    EditAnnexNew,
    DeleteAnnexNew,
})(ProjectManagementView);
