import { Skeleton } from '@mui/material';
import Quill from 'quill';
import QuillBetterTable from 'quill-better-table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { importDocumentIntoEditor } from 'store/actions/editorActions';
import { lineBreakMatcher } from '../Editor/utils/db';

export default function ReadOnlyEditor({ delta, readOnlyDocument, isNativeFile }) {
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const editorRef = useRef(null);

    const containerRef = useCallback((node) => {
        if (node === null) {
            editorRef.current = null;
            return;
        }

        node.innerHTML = '';

        const editor = document.createElement('div');

        node.appendChild(editor);

        const quill = new Quill(editor, {
            theme: 'snow',
            modules: {
                toolbar: false,
                history: {
                    delay: 2000,
                    maxStack: 500,
                    userOnly: true,
                },
                resize: {
                    locale: {},
                },
                imageDrop: true,
                table: false,
                'better-table': {
                    operationMenu: {
                        items: {
                            unmergeCells: {
                                text: 'Unmerge Cells',
                            },
                        },
                    },
                },
                clipboard: {
                    matchers: [['BR', lineBreakMatcher]],
                },
                keyboard: {
                    bindings: QuillBetterTable.keyboardBindings,
                },
                syntax: true,
            },
            // formats: [
            //     'font',
            //     'size',
            //     'align',
            //     'color',
            //     'bold',
            //     'italic',
            //     'underline',
            //     'strike',
            //     'code',
            //     'background',
            //     'list',
            //     'indent',
            //     'link',
            //     'image',
            //     'code-block',
            //     'blockquote',
            //     // 'pageBreak',
            // ],
            readOnly: true,
            bounds: document.body,
        });

        editorRef.current = quill;
    }, []);

    useEffect(() => {
        if (!editorRef.current) return;

        const quill = editorRef.current;

        async function loadDocument() {
            const response = await dispatch(
                importDocumentIntoEditor({
                    document: readOnlyDocument,
                    isNativeFile,
                    quill,
                    isReadOnly: true,
                    setIsLoading,
                    delta,
                })
            );
            if (!response?.success) {
                toast.error(response?.message);
            }
        }

        loadDocument();
    }, [delta, readOnlyDocument, isNativeFile]);

    return (
        <div className="relative !h-full !w-full">
            <div className="read-only" ref={containerRef} />

            {isLoading && (
                <div className="absolute inset-0 bg-white">
                    <Skeleton variant="rounded" width="100%" height="100%" />
                </div>
            )}
        </div>
    );
}
