/* eslint-disable no-unused-vars */

import { Close } from '@mui/icons-material';
import { Box, Menu, MenuItem, Typography } from '@mui/material';
import FileIcon from 'assets/img/file4.svg';
import FolderIcon from 'assets/img/folder.svg';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AnalyzeDocument, GetDocumentLink } from 'store/actions/complianceActions';
import { accept } from 'validate';
import { extractDocName } from '../../utils';
import AwarenessDialog from '../AwarenessDialog';
import DownloadTemplateDialog from '../DownloadTemplateDialog';
import ReuseablePolicyViewDrawer from '../ReuseablePolicyViewDrawer';
import LoadingOverlay from '../ReuseablePolicyViewDrawer/LoadingOverlay';

export default function NewUploadTab({
    isPaid,
    isOpen,
    handleCallback,
    setIsUsePolicyEditorDrawerOpen,
    standard,
    templates,
    currentRequirement,
    title,
    fetchDocReviewStats,
}) {
    const [formData, setFormData] = useState({
        mode: 'new',
        document: null,
        policy: null,
        comment: '',
    });
    const [formErrors, setFormErrors] = useState({
        document: '',
        policy: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [isDownloadTemplateDialogOpen, setIsDownloadTemplateDialogOpen] = useState(false);
    const [isAwarenessDialogOpen, setIsAwarenessDialogOpen] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);
    const [isReuseablePolicyViewDrawerOpen, setIsReuseablePolicyViewDrawerOpen] = useState(false);
    const [policyInformation, setPolicyInformation] = useState({});

    const all_merchant_documents = useSelector((state) => state?.complianceReducers?.all_merchant_documents);

    const dispatch = useDispatch();

    const fileInputRef = useRef(null);

    const { pdf, docx } = accept;

    const allDocuments = useMemo(
        () =>
            all_merchant_documents?.filter((doc) => doc?.tag === standard && doc?.policy_status !== 'awaiting_review'),
        [all_merchant_documents]
    );

    const dragEnter = () => {
        const target = document.getElementById('drop-zone');
        target.style.borderColor = '#C4C4C4';
        target.style.backgroundColor = '#A58980';
    };

    const dragLeave = () => {
        const target = document.getElementById('drop-zone');
        target.style.borderColor = '#A58980';
        target.style.backgroundColor = 'transparent';
    };

    const dragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && `${pdf},${docx}`.includes(file.type)) {
            const reader = new FileReader();
            reader.onload = () => {
                setFormData((prev) => ({
                    ...prev,
                    document: reader.result,
                }));
                setFormErrors((prev) => ({
                    ...prev,
                    document: '',
                }));
            };
            reader.readAsDataURL(file);
            // convertedBase64(file).then(() => {});
        } else {
            // enqueueSnackbar('Invalid Data Dropped', { variant: 'error' });
            setFormErrors((prev) => ({
                ...prev,
                document: 'Invalid file type',
            }));
        }
        dragLeave();
    };

    const uploadDoc = async (event) => {
        const input = event.target;
        const file = input.files?.item(0);
        if (!file) return;
        const fileSize = file?.size / 1000 / 1000;
        if (fileSize > 5) {
            toast.info('File too large, minimum of 5MB');
            setFormData((prev) => ({
                ...prev,
                document: null,
            }));
        } else {
            // const base64 = await convertedBase64(file);
            setFormData((prev) => ({
                ...prev,
                document: file,
            }));
            setFormErrors((prev) => ({
                ...prev,
                document: '',
            }));
        }
    };

    function validateForm() {
        const errors = {
            document: '',
            policy: '',
        };

        let isValid = true;

        if (!formData.document) {
            errors.document = 'Document is required';
            isValid = false;
        }

        if (formData.mode === 'update' && !formData.policy) {
            errors.policy = 'Policy is required';
            isValid = false;
        }

        setFormErrors(errors);

        return isValid;
    }

    function resetForm() {
        setFormData({
            mode: 'new',
            document: null,
            policy: null,
            comment: '',
        });

        setFormErrors({
            document: '',
            policy: '',
        });

        setPolicyInformation({});
    }

    function shortenDocumentTitle(text) {
        const ext = text?.split('.')?.pop();

        if (text?.length > 20) {
            return `${text?.substring(0, 17)}...${ext}`;
        }

        return text;
    }

    async function handleSubmit() {
        if (!validateForm()) {
            return;
        }

        try {
            setIsLoading(true);
            setShowLoadingOverlay(true);
            setPolicyInformation({});

            if (isPaid) {
                const nativeFormData = new FormData();
                nativeFormData.append('file', formData.document);
                nativeFormData.append('standard', standard);
                nativeFormData.append('id', currentRequirement?.id);

                const response = await dispatch(AnalyzeDocument(nativeFormData));
                if (!response.success) throw new Error(response?.message);
                setPolicyInformation(response.data);
            } else {
                await new Promise((resolve) => setTimeout(resolve, 4000));
            }

            setIsReuseablePolicyViewDrawerOpen(true);
        } catch (error) {
            toast.error(error?.message || 'Failed to upload document');
        } finally {
            setIsLoading(false);
        }
    }

    async function getDocumentLink(id) {
        setIsDownloading(true);
        const res = await dispatch(GetDocumentLink('userdoc', id));
        setIsDownloading(false);
        if (res?.success) {
            window.open(res?.data, '_blank');
        } else {
            toast.error('Something went wrong!');
        }
    }

    function handleClose() {
        resetForm();
        handleCallback();
    }

    useEffect(() => {
        if (isOpen) {
            resetForm();
        }
    }, [isOpen]);

    return (
        <>
            <div className="!h-full flex flex-col !gap-4 justify-between">
                <div className="flex flex-col !gap-4 !py-4 !px-6">
                    <div className="bg-[#F8FAFC] !border !border-[#F0F0FA] !rounded flex !gap-1 !px-4 !py-2 items-start">
                        <img
                            src={isPaid ? '/img/frameworks/lightbulb-warn.svg' : '/img/frameworks/lightbulb.svg'}
                            alt="Bulb Icon"
                            className="!mt-0.5 !w-6 !h-6"
                        />

                        {isPaid ? (
                            <p className="text-[#937300] font-normal text-[13px] leading-5">
                                You have the policy editor feature. We advise you{' '}
                                <span
                                    className="font-semibold underline decoration-[#937300] cursor-pointer transition-all hover:no-underline"
                                    role="button"
                                    onClick={() => {
                                        handleClose();
                                        setIsUsePolicyEditorDrawerOpen(true);
                                    }}
                                >
                                    use with policy editor
                                </span>{' '}
                                to edit your policies.
                            </p>
                        ) : (
                            <p className="text-[#395BA9] font-normal text-[13px] leading-5">
                                For a smoother experience and faster auditing, try out our{' '}
                                <span
                                    className="font-semibold underline decoration-[#395BA9] cursor-pointer transition-all hover:no-underline"
                                    role="button"
                                    onClick={() => setIsAwarenessDialogOpen(true)}
                                >
                                    policy editor
                                </span>{' '}
                                feature
                            </p>
                        )}
                    </div>

                    <div className="flex items-center !gap-4">
                        <div
                            className={`flex-1 !h-[107px] !rounded-lg !p-4 flex items-start !gap-2 bg-white transition-all hover:brightness-95 !outline ${
                                formData.mode === 'new'
                                    ? '!outline-2 !outline-[#202D66]'
                                    : '!outline-1 !outline-[#E2E8F0]'
                            }`}
                            role="button"
                            onClick={() => setFormData((prev) => ({ ...prev, mode: 'new' }))}
                        >
                            {formData.mode === 'new' ? (
                                <img
                                    src="/img/frameworks/radio_button_checked.svg"
                                    alt="checkbox"
                                    className="!h-5 !w-5"
                                />
                            ) : (
                                <img
                                    src="/img/frameworks/radio_button_unchecked.svg"
                                    alt="checkbox"
                                    className="!h-5 !w-5"
                                />
                            )}

                            <div className="flex flex-col !gap-1 text-[#475569]">
                                <p className="font-semibold">New policy</p>

                                <p className="text-[11px] !leading-4">
                                    Use this option only if you the file is a new file and not an update to an existing
                                    file.
                                </p>
                            </div>
                        </div>

                        <div
                            className={`flex-1 !h-[107px] !rounded-lg !p-4 flex items-start !gap-2 bg-white transition-all hover:brightness-95 !outline ${
                                formData.mode === 'update'
                                    ? '!outline-2 !outline-[#202D66]'
                                    : '!outline-1 !outline-[#E2E8F0]'
                            }`}
                            role="button"
                            onClick={() => setFormData((prev) => ({ ...prev, mode: 'update' }))}
                        >
                            {formData.mode === 'update' ? (
                                <img
                                    src="/img/frameworks/radio_button_checked.svg"
                                    alt="checkbox"
                                    className="!h-5 !w-5"
                                />
                            ) : (
                                <img
                                    src="/img/frameworks/radio_button_unchecked.svg"
                                    alt="checkbox"
                                    className="!h-5 !w-5"
                                />
                            )}

                            <div className="flex flex-col !gap-1 text-[#475569]">
                                <p className="font-semibold">Updating existing policy</p>

                                <p className="text-[11px] !leading-4">
                                    Use this option only if you the file is an update to a previously uploaded file.
                                </p>
                            </div>
                        </div>
                    </div>

                    {formData.mode === 'update' && (
                        <div className="flex flex-col !gap-2">
                            <p className="font-medium text-[13px] text-[#64748B]">Choose the existing file:</p>

                            <div>
                                <button
                                    aria-controls={anchorEl ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={anchorEl ? 'true' : undefined}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setAnchorEl(event.currentTarget);
                                    }}
                                    className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors !h-9"
                                >
                                    <p
                                        className={`font-normal text-sm ${
                                            formData.policy ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                                        }`}
                                    >
                                        {formData.policy
                                            ? shortenDocumentTitle(
                                                  extractDocName(formData.policy?.filename || formData.policy?.document)
                                              )?.replaceAll('_', ' ')
                                            : 'Select policy file'}
                                    </p>

                                    <img
                                        src="/img/frameworks/chevron-down.svg"
                                        alt="Arrow Down Icon"
                                        className="object-contain"
                                    />
                                </button>

                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={() => setAnchorEl(null)}
                                    sx={{
                                        '& .MuiPaper-root': {
                                            borderRadius: '4px',
                                            boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                        },
                                        '& .MuiList-root': {
                                            padding: 0,
                                            width: '572px',
                                        },
                                    }}
                                >
                                    <div className="max-h-[300px] overflow-auto custom-scroll">
                                        <div className="mx-2 mt-2">
                                            <div
                                                className="relative"
                                                onKeyDown={(event) => {
                                                    event.stopPropagation();
                                                }}
                                            >
                                                <input
                                                    type="text"
                                                    placeholder="Search"
                                                    className="w-full !py-2 !pr-9 !pl-2 !border !border-[#E2E8F0] !h-9 text-[13px] font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                                    value={searchValue}
                                                    onChange={(event) => {
                                                        event.stopPropagation();
                                                        setSearchValue(event.target.value);
                                                    }}
                                                />

                                                <img
                                                    src="/img/frameworks/search.svg"
                                                    alt="Search Icon"
                                                    className="absolute object-contain top-1/2 -translate-y-1/2 !right-2"
                                                />
                                            </div>
                                        </div>

                                        <div className="!p-2 flex flex-col">
                                            {allDocuments
                                                ?.filter((doc) =>
                                                    extractDocName(
                                                        (doc?.filename || doc?.document)?.replaceAll('_', ' ')
                                                    )
                                                        ?.toLowerCase()
                                                        .includes(searchValue.toLowerCase())
                                                )
                                                ?.map((doc) => (
                                                    <MenuItem
                                                        key={doc?.id}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            setFormData((prev) => ({
                                                                ...prev,
                                                                policy: doc,
                                                            }));
                                                            setFormErrors((prev) => ({
                                                                ...prev,
                                                                policy: '',
                                                            }));
                                                            setAnchorEl(null);
                                                            setSearchValue('');
                                                        }}
                                                        sx={{
                                                            color: '#64748B',
                                                            fontWeight: 500,
                                                            fontSize: '14px',
                                                            padding: '8px 8px',
                                                            whiteSpace: 'pre-wrap',
                                                        }}
                                                    >
                                                        {extractDocName(
                                                            (doc?.filename || doc?.document)?.replaceAll('_', ' ')
                                                        )}{' '}
                                                        {doc?.version_no}
                                                    </MenuItem>
                                                ))}
                                        </div>
                                    </div>
                                </Menu>
                            </div>

                            {formErrors?.policy && (
                                <span className="text-[10px] !-mt-1 text-[#FF0000] font-normal">
                                    {formErrors?.policy}
                                </span>
                            )}

                            {formData?.policy && (
                                <p className="text-[13px] text-[#64748B]">
                                    Download{' '}
                                    <button
                                        className="text-[#395BA9] decoration-[#395BA9] underline cursor-pointer transition-all hover:no-underline disabled:opacity-50 disabled:pointer-events-none disabled:cursor-progress"
                                        disabled={isDownloading}
                                        onClick={() => getDocumentLink(formData.policy?.id)}
                                    >
                                        {extractDocName(
                                            formData.policy?.filename || formData.policy?.document
                                        )?.replaceAll('_', ' ')}
                                    </button>
                                </p>
                            )}
                        </div>
                    )}

                    <div className="flex flex-col !gap-2">
                        <Box
                            sx={{
                                border: '1px dashed #CBD5E1',
                                borderRadius: '2px',
                                minHeight: 150,
                                position: 'relative',
                                display: 'grid',
                                placeItems: 'center',
                                cursor: 'pointer',
                                '&:hover': {
                                    borderColor: '#64748B',
                                    backgroundColor: '#F8FAFC',
                                },
                            }}
                            onClick={() => fileInputRef?.current?.click()}
                        >
                            <Box
                                id="drop-zone"
                                onDragEnter={dragEnter}
                                onDragLeave={dragLeave}
                                onDrop={handleDrop}
                                onDragOver={dragOver}
                                sx={{ p: 2 }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <img src={FolderIcon} alt="Folder Icon" />
                                    <Typography sx={{ color: '#64748B', fontSize: '12px', fontWeight: 500 }}>
                                        Drop your files or click to upload
                                    </Typography>
                                    <Typography sx={{ color: '#94A3B8', fontSize: '12px', fontWeight: 400 }}>
                                        Supported file types: PDF, DOCX
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                                    <label htmlFor="">
                                        <Box
                                            sx={{
                                                border: '1px solid #E2E8F0',
                                                cursor: 'pointer',
                                                padding: 0.5,
                                            }}
                                        >
                                            <Typography sx={{ color: '#475569', fontWeight: 400, fontSize: 12 }}>
                                                Browse
                                            </Typography>
                                        </Box>
                                    </label>
                                    <input
                                        type="file"
                                        accept={`${pdf}, ${docx}`}
                                        onChange={(e) => uploadDoc(e)}
                                        hidden
                                        id=""
                                        ref={fileInputRef}
                                    />
                                </Box>
                            </Box>
                        </Box>

                        {formErrors?.document && (
                            <span className="text-[10px] !-mt-1 text-[#FF0000] font-normal">
                                {formErrors?.document}
                            </span>
                        )}

                        <p className="text-[13px] text-[#64748B]">
                            We recommend using file names that clearly describe the content.
                        </p>

                        {formData.document && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <img src={FileIcon} alt="icon" />

                                <Box sx={{ ml: 1 }}>
                                    <Typography
                                        sx={{
                                            color: '#475569',
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            wordBreak: 'break-word',
                                        }}
                                    >
                                        {formData.document.name}
                                    </Typography>
                                </Box>

                                <button
                                    className="text-red-500 ml-auto !border !border-[#E2E8F0] !h-5 !w-5 !rounded-full grid place-items-center transition-all hover:brightness-90 bg-[#fff]"
                                    onClick={() =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            document: null,
                                        }))
                                    }
                                >
                                    <Close sx={{ width: 13, height: 13 }} />
                                </button>
                            </Box>
                        )}
                    </div>

                    <textarea
                        className="w-full !p-2 !border !border-[#E2E8F0] !min-h-[76px] text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                        placeholder="Any note (optional)"
                        value={formData.comment}
                        onChange={(e) => {
                            setFormData((prev) => ({ ...prev, comment: e.target.value }));
                            setFormErrors((prev) => ({ ...prev, comment: '' }));
                        }}
                    />

                    <div className="flex items-center !gap-2">
                        <img src="/img/frameworks/file-download.svg" alt="file download" className="!w-5 !h-5" />

                        <p className="text-[#475569] font-semibold text-sm">
                            Don't have a template for your policy?.{' '}
                            <span
                                className="text-[#395BA9] decoration-[#395BA9] underline font-bold cursor-pointer transition-all hover:no-underline"
                                role="button"
                                onClick={() => setIsDownloadTemplateDialogOpen(true)}
                            >
                                See all template
                            </span>
                        </p>
                    </div>
                </div>

                <div className="flex items-center justify-end !p-6 bg-[#F8FAFC] !gap-2">
                    <button
                        className="bg-[#fff] !border !border-[#E2E8F0] !rounded-sm !px-4 !py-2 hover:bg-red-500 hover:text-white transition-colors text-[#334155] text-sm font-medium"
                        onClick={handleClose}
                    >
                        Cancel
                    </button>

                    <button
                        className="bg-[#202D66] !rounded-sm !px-4 !py-2 hover:bg-[#151e44] transition-all text-white text-sm font-semibold disabled:opacity-50 disabled:pointer-events-none flex items-center !gap-1"
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        Continue
                    </button>
                </div>
            </div>

            <AwarenessDialog isOpen={isAwarenessDialogOpen} setIsOpen={setIsAwarenessDialogOpen} />

            <DownloadTemplateDialog
                isOpen={isDownloadTemplateDialogOpen}
                setIsOpen={setIsDownloadTemplateDialogOpen}
                setIsUsePolicyEditorDrawerOpen={setIsUsePolicyEditorDrawerOpen}
                standard={standard}
                templates={templates}
                isPaid={isPaid}
                currentRequirement={currentRequirement}
                fetchDocReviewStats={fetchDocReviewStats}
            />

            <LoadingOverlay
                isOpen={showLoadingOverlay}
                handleCallback={() => setShowLoadingOverlay(false)}
                isLoading={isLoading}
                isPaid={isPaid}
            />

            <ReuseablePolicyViewDrawer
                title={formData?.document?.name}
                moduleTitle={title}
                mode="upload"
                document={formData?.document}
                documentNotes={formData?.comment}
                policyInformation={policyInformation}
                existingPolicyId={formData?.mode === 'new' ? '' : formData?.policy?.id}
                standard={standard}
                isOpen={isReuseablePolicyViewDrawerOpen}
                setIsOpen={setIsReuseablePolicyViewDrawerOpen}
                handleCallback={handleClose}
                currentRequirement={currentRequirement}
                showChangesDialog={formData?.mode === 'update'}
                fetchDocReviewStats={fetchDocReviewStats}
                isNativeFile={true}
                templates={templates}
                filename={formData?.document?.name}
            />
        </>
    );
}
