/* eslint-disable no-unused-vars */

import EmptyState from 'components/new_components/EmptyState';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { extractDocName, shortenDocumentTitle } from '../../utils';
import AwarenessDialog from '../AwarenessDialog';
import PolicyEditorDrawer from '../PolicyEditorDrawer';
import ViewDocumentDialog from '../ViewDocumentDialog';

export default function MyFilesTab({ isPaid, standard, templates, currentRequirement, title, fetchDocReviewStats }) {
    const [document, setDocument] = useState(null);
    const [isViewDocumentDialogOpen, setIsViewDocumentDialogOpen] = useState(false);
    const [isAwarenessDialogOpen, setIsAwarenessDialogOpen] = useState(false);
    const [role, setRole] = useState('');
    const [isPolicyEditorDrawerOpen, setIsPolicyEditorDrawerOpen] = useState(false);

    const all_merchant_documents = useSelector((state) => state?.complianceReducers?.all_merchant_documents);
    const user_id = useSelector((state) => state?.authReducers?.user_id);

    const allDocuments = useMemo(
        () =>
            all_merchant_documents
                ?.filter((doc) => doc?.tag === standard && doc?.current)
                ?.map((doc) => ({ ...doc, default: doc?.document })),
        [all_merchant_documents]
    );

    function handleClose() {
        setIsViewDocumentDialogOpen(false);
        setDocument(null);
        setRole('');
    }

    return (
        <>
            <div className="!py-4 flex flex-col !gap-4">
                <div className="bg-[#F8FAFC] !border !border-[#F0F0FA] !rounded flex !gap-1 !mx-4 !px-4 !py-2 items-start">
                    <img src="/img/frameworks/lightbulb.svg" alt="Bulb Icon" className="!mt-0.5 !w-6 !h-6" />

                    {isPaid ? (
                        <p className="text-[#395BA9] font-normal text-[13px] leading-5">
                            All recent versions of policy files you have uploaded in {title} {currentRequirement?.id}{' '}
                            are displayed here. You can make an edit to files by opening it with the policy editor.
                        </p>
                    ) : (
                        <p className="text-[#395BA9] font-normal text-[13px] leading-5">
                            All recent versions of policy files you have uploaded in {title} {currentRequirement?.id}{' '}
                            are displayed here. You can only view the file but cannot make an edit because you do not
                            have the{' '}
                            <span
                                className="font-semibold underline decoration-[#395BA9] cursor-pointer transition-all hover:no-underline"
                                role="button"
                                onClick={() => setIsAwarenessDialogOpen(true)}
                            >
                                policy editor feature
                            </span>
                        </p>
                    )}
                </div>

                <div className="!px-4 !h-[calc(100vh-239px)] overflow-y-auto custom-scroll">
                    <div className="!border !border-[#E2E8F0] !rounded-lg !flex !flex-col !gap-4 !p-4">
                        {allDocuments?.length ? (
                            allDocuments?.map((doc) => {
                                const isApprover =
                                    doc?.approved_by === user_id && doc?.policy_status === 'awaiting_approval';
                                const isReviewer =
                                    doc?.reviewer === user_id && doc?.policy_status === 'awaiting_review';

                                return (
                                    <div
                                        key={doc.id}
                                        className="relative !border !border-[#E2E8F0] bg-white !rounded-lg !py-2 !px-4 flex items-center justify-between cursor-pointer transition-all hover:brightness-95"
                                        role="button"
                                        onClick={() => {
                                            setDocument(doc);
                                            if (isPaid) {
                                                setRole(isReviewer ? 'reviewer' : isApprover ? 'approver' : '');
                                                setIsPolicyEditorDrawerOpen(true);
                                            } else {
                                                setIsViewDocumentDialogOpen(true);
                                            }
                                        }}
                                    >
                                        {(isApprover || isReviewer) && (
                                            <>
                                                <div className="absolute !-left-1 !-top-1 !h-2 !w-2 rounded-full bg-[#FF5449]" />

                                                <div className="absolute !-left-1 !-top-1 !h-2 !w-2 animate-ping rounded-full bg-[#FF5449] opacity-75" />
                                            </>
                                        )}

                                        <div className="flex flex-col !gap-1">
                                            <p className="text-[#475569] font-medium text-sm">
                                                {shortenDocumentTitle(
                                                    extractDocName(
                                                        (doc?.filename || doc?.default)?.replaceAll('_', ' ')
                                                    )
                                                )}{' '}
                                                {doc?.version_no}
                                            </p>

                                            <div className="!py-1 text-[#64748B] font-medium text-[13px] flex items-center !gap-1">
                                                <p>
                                                    {doc?.policy_info?.implemented?.length || 0} policy statements
                                                    available
                                                </p>

                                                {doc?.policy_status && (
                                                    <>
                                                        <span className="bg-[#94A3B8] !w-1 !h-1 !rounded-full" />

                                                        <p
                                                            className={`capitalize ${
                                                                doc?.policy_status === 'awaiting_approval'
                                                                    ? 'text-[#395BA9]'
                                                                    : doc?.policy_status === 'awaiting_review'
                                                                    ? 'text-[#D2A500]'
                                                                    : doc?.policy_status === 'approved'
                                                                    ? 'text-[#37A372]'
                                                                    : doc?.policy_status?.includes('rejected')
                                                                    ? 'text-[#FF5449]'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {doc?.policy_status?.replaceAll('_', ' ')}
                                                        </p>
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        <button className="!border !border-[#E2E8F0] !rounded-lg !py-1 !px-3 text-[#64748B] font-medium text-sm">
                                            {isPaid ? 'Open' : 'View'}
                                        </button>
                                    </div>
                                );
                            })
                        ) : (
                            <EmptyState description="No files uploaded yet" />
                        )}
                    </div>
                </div>
            </div>

            <AwarenessDialog isOpen={isAwarenessDialogOpen} setIsOpen={setIsAwarenessDialogOpen} />

            <ViewDocumentDialog
                document={document}
                isOpen={isViewDocumentDialogOpen}
                handleCallback={handleClose}
                fetchDocReviewStats={fetchDocReviewStats}
            />

            <PolicyEditorDrawer
                isOpen={isPolicyEditorDrawerOpen}
                setIsOpen={setIsPolicyEditorDrawerOpen}
                handleCallback={() => {
                    handleClose();
                    fetchDocReviewStats();
                }}
                mode="update"
                standard={standard}
                templates={templates}
                currentRequirement={currentRequirement}
                document={document}
                role={role}
                existingPolicyId={document?.id}
                filename={extractDocName(document?.filename || document?.default)?.replaceAll('_', ' ')}
            />
        </>
    );
}
