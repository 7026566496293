import React, { useState, useRef, useEffect } from 'react';
import {
    TextField,
    InputAdornment,
    Paper,
    Box,
    useTheme,
    MenuList,
    MenuItem,
    Typography,
    CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import AppFormControl from './forms/AppFormControl';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import AppTag from './AppTags';
import { truncateText } from 'utils';

const AppSelectSearchPinned = ({
    label,
    labelStyle,
    name,
    options = [],
    value,
    error,
    onChange,
    onSearchChange,
    searchPlaceholder = 'Search...',
    placeholder = 'Select...',
    loading = false,
    multiple = false,
    sx,
    ...restProps
}) => {
    const theme = useTheme();
    const [searchText, setSearchText] = useState('');
    const [open, setOpen] = useState(false);
    const searchInputRef = useRef(null);
    const anchorRef = useRef(null);

    useEffect(() => {
        if (open && searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [open, searchText]);

    const handleToggle = () => {
        if (!loading) {
            setOpen((prevOpen) => !prevOpen);
        }
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleMenuItemClick = (option) => (event) => {
        if (onChange) {
            onChange(event, option);
        }

        // If multiple is false, close the dropdown after selection
        if (!multiple) {
            setOpen(false);
        }
    };

    // Get display text based on selected value(s)
    const getDisplayText = () => {
        if (loading) {
            return 'Loading...';
        }

        return placeholder;
    };

    const isOptionSelected = (optionValue) => {
        return value === optionValue;
    };

    const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(searchText.toLowerCase()));

    return (
        <AppFormControl name={name} label={<>{label}</>} error={error} labelStyle={labelStyle}>
            <Box ref={anchorRef}>
                <Box
                    onClick={handleToggle}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: 'auto',
                        minHeight: 36,
                        border: '1px solid',
                        borderColor: error ? theme.palette.error[700] : '#e2e8f0',
                        color: '#94A3B8',
                        textTransform: 'capitalize !important',
                        overflow: 'hidden',
                        fontSize: '13px',
                        fontWeight: 500,
                        borderRadius: '4px',
                        padding: '6px 12px',
                        width: '100%',
                        cursor: loading ? 'default' : 'pointer',
                        '&.Mui-disabled': { borderColor: theme.palette.gray[100] },
                        ...sx,
                    }}
                    {...restProps}
                >
                    <Typography
                        sx={{
                            fontSize: '13px',
                            color: '#94A3B8',
                            wordBreak: 'break-word',
                            whiteSpace: 'normal',
                            lineHeight: 1.4,
                        }}
                    >
                        {getDisplayText()}
                    </Typography>

                    {loading ? (
                        <CircularProgress size={16} sx={{ color: '#94A3B8', ml: 1 }} />
                    ) : (
                        <ExpandMoreIcon sx={{ color: '#94A3B8', ml: 1 }} />
                    )}
                </Box>

                {open && !loading && (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Paper
                            sx={{
                                position: 'absolute',
                                zIndex: 1,
                                minWidth: anchorRef.current.offsetWidth,
                                boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                fontSize: '13px',
                            }}
                        >
                            <Box
                                sx={{
                                    p: 1,
                                    position: 'sticky',
                                    top: 0,
                                    bgcolor: 'background.paper',
                                    zIndex: 1,
                                }}
                            >
                                <TextField
                                    inputRef={searchInputRef}
                                    fullWidth
                                    value={searchText}
                                    placeholder={searchPlaceholder}
                                    onChange={(e) => {
                                        setSearchText(e.target.value);
                                        if (onSearchChange) {
                                            onSearchChange(e);
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon sx={{ color: '#94A3B8' }} />
                                            </InputAdornment>
                                        ),
                                        disableUnderline: true,
                                        sx: { fontSize: '13px' },
                                    }}
                                    variant="standard"
                                    onMouseDown={(e) => e.stopPropagation()}
                                    onClick={(e) => e.stopPropagation()}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' || e.key === 'Escape') {
                                            e.stopPropagation();
                                        }
                                    }}
                                    sx={{
                                        border: '1px solid #E2E8F0',
                                        p: 0.5,
                                        color: '#94A3B8',
                                        fontSize: '13px',
                                        borderRadius: '2px',
                                        '& .MuiInputBase-root': { fontSize: '13px' },
                                        ...sx?.search,
                                    }}
                                />
                            </Box>
                            <MenuList
                                sx={{
                                    maxHeight: 200,
                                    overflowY: 'auto',
                                    fontSize: '13px',
                                    color: '#64748B',
                                    width: '100%',
                                }}
                            >
                                {filteredOptions.length === 0 ? (
                                    <MenuItem disabled sx={{ fontSize: '13px', color: '#94A3B8' }}>
                                        No options found
                                    </MenuItem>
                                ) : (
                                    filteredOptions.map((option) => {
                                        const isSelected = isOptionSelected(option.value);
                                        return (
                                            <MenuItem
                                                key={option.value}
                                                onClick={handleMenuItemClick(option)}
                                                sx={{
                                                    fontSize: '13px',
                                                    color: isSelected ? theme.palette.primary.main : '#64748B',
                                                    backgroundColor: isSelected ? '#f8fafc' : 'transparent',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    '&:hover': {
                                                        backgroundColor: isSelected
                                                            ? 'rgba(59, 130, 246, 0.12)'
                                                            : 'rgba(59, 130, 246, 0.1)',
                                                    },
                                                }}
                                                value={option.label}
                                                id={option.id}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 2,
                                                        width: '100%',
                                                        position: 'relative',
                                                        justifyContent: 'flex-start',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: '13px',
                                                            wordBreak: 'break-word',
                                                            whiteSpace: 'normal',
                                                            width: 'fit-content',
                                                            lineHeight: 1.4,
                                                        }}
                                                    >
                                                        {truncateText(option.label, 40)}
                                                    </Typography>

                                                    {option?.tag && (
                                                        <AppTag
                                                            text={truncateText(option?.tag, 14)}
                                                            sx={{
                                                                textTransform: 'capitalize',
                                                                fontSize: '11px',
                                                                fontWeight: 500,
                                                                color: '#64748B',
                                                                bgcolor: '#F8FAFC',
                                                                borderColor: '#E2E8F0',
                                                                borderRadius: '4px',
                                                                px: 0.5,
                                                                py: 0.2,
                                                                wordBreak: 'break-word',
                                                                whiteSpace: 'normal',
                                                                width: 'fit-content',
                                                                m: 0,
                                                            }}
                                                        />
                                                    )}
                                                </Box>
                                                {isSelected && (
                                                    <CheckIcon
                                                        sx={{
                                                            fontSize: 18,
                                                            color: theme.palette.primary.main,
                                                            ml: 1,
                                                        }}
                                                    />
                                                )}
                                            </MenuItem>
                                        );
                                    })
                                )}
                            </MenuList>
                        </Paper>
                    </ClickAwayListener>
                )}
            </Box>
        </AppFormControl>
    );
};

export default AppSelectSearchPinned;
