/* eslint-disable no-unused-vars */

import { acceptanceHistoryTabs } from '../../../db';

export default function TabBar({ currentTab, setCurrentTab }) {
    return (
        <div className="flex items-center !gap-12 !border-b-2 !border-[#F1F5F9] !w-fit">
            {acceptanceHistoryTabs.map((tab) => (
                <button
                    key={tab}
                    className={`relative transition-all overflow-hidden capitalize !py-2 text-sm before:absolute before:-bottom-1 before:left-1/2 before:h-2 before:-translate-x-1/2 before:rounded-full before:bg-[#1B295F] before:transition-all before:duration-500 hover:brightness-75 ${
                        currentTab === tab
                            ? 'before:w-full font-semibold text-[#1B295F]'
                            : 'before:w-0 font-medium text-[#64748B]'
                    }`}
                    onClick={() => setCurrentTab(tab)}
                >
                    {tab}
                </button>
            ))}
        </div>
    );
}
