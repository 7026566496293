import { Minus } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { clearFormatting } from 'store/actions/editorActions';

export default function ClearTool({ editorRef }) {
    const dispatch = useDispatch();

    function handleClear() {
        if (editorRef.current) {
            const quill = editorRef.current;

            quill.format('font', 'sans-serif');
            quill.format('size', '15px');
            quill.format('align', 'left');
            quill.format('background', '#ffffff');
            quill.format('bold', false);
            quill.format('italic', false);
            quill.format('underline', false);
            quill.format('strike', false);
            quill.format('code', false);
            quill.format('code-block', false);
            quill.format('color', '#64748B');

            dispatch(clearFormatting());
        }
    }

    return (
        <button
            className="!h-7 !w-7 !rounded bg-white text-[#1E293B] grid place-items-center transition-all hover:brightness-95"
            onClick={handleClear}
        >
            <Minus className="!h-5 !w-5" />
        </button>
    );
}
