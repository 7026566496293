/* eslint-disable no-unused-vars */

import { Drawer, Fade } from '@mui/material';
import { forwardRef, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import MainContent from './MainContent';
import NavBar from './NavBar';

const Transition = forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

export default function ReuseablePolicyViewDrawer({
    title,
    moduleTitle,
    mode,
    document,
    documentNotes,
    policyInformation,
    existingPolicyId,
    standard,
    isOpen,
    setIsOpen,
    handleCallback,
    currentRequirement,
    showChangesDialog,
    fetchDocReviewStats,
    isNativeFile,
    formattedDocReviewStats,
    useExistingPolicy,
    templates,
    filename,
}) {
    const [currentPolicyDocument, setCurrentPolicyDocument] = useState('all');
    const [currentStatement, setCurrentStatement] = useState(0);

    const all_addons = useSelector((state) => state?.generalReducers?.all_addons);

    const isPaid = all_addons?.[0]?.policy_editor?.status === 'approved';

    const statements = useMemo(() => {
        if (mode !== 'view-gaps' && policyInformation) {
            const implementedPolicies = policyInformation?.implemented
                ? policyInformation?.implemented?.map((policy) => ({
                      policy: policy,
                      status: 'implemented',
                      gaps: false,
                  }))
                : [];

            const gapPolicies = policyInformation?.gaps
                ? policyInformation?.gaps?.map((policy) => ({
                      policy: policy,
                      status: 'not implemented',
                      gaps: true,
                  }))
                : [];

            return implementedPolicies.concat(gapPolicies);
        } else if (formattedDocReviewStats?.length) {
            return formattedDocReviewStats
                ?.filter((data) => {
                    if (currentPolicyDocument === 'all') {
                        return true;
                    }

                    return data?.document === currentPolicyDocument;
                })
                ?.map((data) => ({
                    policy: data?.name,
                    status: data?.status,
                    gaps: data?.gaps,
                }));
        } else {
            return [];
        }
    }, [policyInformation, mode, formattedDocReviewStats, currentPolicyDocument]);

    function handleClose() {
        setIsOpen(false);
        setCurrentPolicyDocument('all');
        setCurrentStatement(0);
    }

    return (
        <Drawer
            open={isOpen}
            anchor="right"
            TransitionComponent={Transition}
            transitionDuration={0}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    width: '100%',
                    flex: 1,
                    overflow: 'auto',
                    zIndex: 9999,
                },
            }}
            scroll="paper"
        >
            <NavBar
                title={title}
                mode={mode}
                currentPolicyDocument={currentPolicyDocument}
                setCurrentPolicyDocument={setCurrentPolicyDocument}
                isPaid={isPaid}
                handleClose={handleClose}
                handleCallback={handleCallback}
                document={document}
                documentNotes={documentNotes}
                policyInformation={policyInformation}
                existingPolicyId={existingPolicyId}
                standard={standard}
                showChangesDialog={showChangesDialog}
                fetchDocReviewStats={fetchDocReviewStats}
                formattedDocReviewStats={formattedDocReviewStats}
                useExistingPolicy={useExistingPolicy}
                currentRequirement={currentRequirement}
                isNativeFile={isNativeFile}
                templates={templates}
                filename={filename}
            />

            <div className="!h-full bg-[#F8FAFC]">
                <MainContent
                    mode={mode}
                    document={document}
                    currentStatement={currentStatement}
                    setCurrentStatement={setCurrentStatement}
                    isPaid={isPaid}
                    currentRequirement={currentRequirement}
                    moduleTitle={moduleTitle}
                    statements={statements}
                    isNativeFile={isNativeFile}
                />
            </div>
        </Drawer>
    );
}
