/* eslint-disable no-unused-vars */

import { useEffect, useState } from 'react';
import { policyStatementsTabs } from '../../../db';

export default function TabBar({ isPaid, mode, currentTab, setCurrentTab, statements }) {
    const [isCopied, setIsCopied] = useState(false);

    function copyToClipboard(event) {
        event.stopPropagation();

        const gaps = statements
            ?.filter((statement) => statement?.gaps)
            .map((statement) => statement?.policy)
            ?.join('\n');

        navigator.clipboard.writeText(gaps).then(() => {
            setIsCopied(true);

            setTimeout(() => {
                setIsCopied(false);
            }, 3000);
        });
    }

    useEffect(() => {
        if (mode === 'view-gaps') {
            setCurrentTab(policyStatementsTabs[2]);
        }
    }, [mode]);

    return (
        <div className="!border-b !border-[#F1F5F9] flex items-center justify-between">
            <div className="flex items-center !gap-4 ">
                {policyStatementsTabs.map((tab) => (
                    <button
                        key={tab}
                        className={`relative transition-all overflow-hidden capitalize !pt-3 !pb-2.5 text-sm before:absolute before:-bottom-1 before:left-1/2 before:h-2 before:-translate-x-1/2 before:rounded-full before:bg-[#1B295F] before:transition-all before:duration-500 hover:brightness-75 ${
                            currentTab === tab
                                ? 'before:w-full font-semibold text-[#1B295F]'
                                : 'before:w-0 font-medium text-[#64748B]'
                        }`}
                        onClick={() => setCurrentTab(tab)}
                    >
                        {tab}
                    </button>
                ))}
            </div>

            {currentTab === policyStatementsTabs[2] && mode === 'view-gaps' && isPaid && (
                <button
                    className="bg-[#F8FAFC] !h-8 !border !border-[#E2E8F0] flex items-center !gap-1 !rounded !py-1 !px-3 hover:brightness-95 transition-colors text-[#64748B] text-sm font-medium disabled:opacity-50 disabled:pointer-events-none"
                    onClick={copyToClipboard}
                >
                    {isCopied ? (
                        <span>Copied</span>
                    ) : (
                        <>
                            <img src="/img/frameworks/copy.svg" alt="pdf" className="!h-6 !w-6" />

                            <span>Copy all gaps</span>
                        </>
                    )}
                </button>
            )}
        </div>
    );
}
