import { Route, Switch } from 'react-router-dom';
import IsoDashboard from './IsoDashboard';
// import FormsAndDocumentsView from './Annex/FormsAndDocumentsView';
// import IsoFormCreation from '../Operations/FormCreation';
import AnnexesView from './Annex/AnnexesView';
import AnnexPage from './Annex/AnnexPage';
import ClausePage from './ManagementClause/ClausePage';
import ManagementClausesView from './ManagementClause/ManagementClausesView';
// import ClauseFormsAndDocumentsView from './ManagementClause/ClauseFormsAndDocumentView';

const Iso27001 = () => {
    return (
        <Switch>
            <Route path="/admin/iso27001_2022" exact component={IsoDashboard} />
            <Route path="/admin/iso27001_2022/annexes" exact component={AnnexesView} />
            <Route path="/admin/iso27001_2022/annexes/annex" exact component={AnnexPage} />
            <Route path="/admin/iso27001_2022/clauses" exact component={ManagementClausesView} />
            <Route path="/admin/iso27001_2022/clauses/clause" exact component={ClausePage} />
            {/* <Route path="/admin/iso27001_2022/clause" exact component={ClauseFormsAndDocumentsView} />
            <Route path="/admin/iso27001_2022/annex" exact component={FormsAndDocumentsView} />
            <Route path="/admin/iso27001_2022/form" component={(props) => <IsoFormCreation tag="isms" {...props} />} /> */}
        </Switch>
    );
};

export default Iso27001;
