import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DeleteMerchantPeople } from 'store/actions/merchantActions';
import AppViewModal from 'components/new_components/AppViewModal';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { ArrowBackIosNewRounded, DownloadRounded } from '@mui/icons-material';
// import { viewFile } from 'utils';
import { ReactComponent as ReportIcon } from 'assets/img/icons/ROC.svg';
import { ReactComponent as Certification } from 'assets/img/icons/certification.svg';
import { ReactComponent as OtherReports } from 'assets/img/icons/other-report-doc.svg';
import LoadingState from 'components/new_components/LoadingState';
import { toast } from 'react-toastify';
import { GetDownloadLink } from 'store/actions/generalActions';

const AuditDownloadModal = (props) => {
    const theme = useTheme();
    const { open, handleClose, data } = props;
    const [links, setLinks] = useState([]);
    const [loadingLinks, setLoadingLinks] = useState(false);

    const documentsName = {
        attestation_doc: 'Attestation',
        thank_you_doc: 'Thank you card',
    };

    const getDownloadLink = useCallback(async () => {
        if (data.id) {
            setLoadingLinks(true);
            const res = await GetDownloadLink('compliance', 'spool_report', data.id, true)();
            setLoadingLinks(false);
            if (res?.success) {
                // open decoded url
                Array.isArray(res.data) && setLinks(res.data);
                // window.open(res?.data, '_blank');
            } else {
                toast.error('Something went wrong!');
            }
        }
    }, [data.id]);

    const getUrlByKey = (key) => {
        const link = links.filter((el) => key in el);
        return link?.[0]?.[key] ?? '';
    };

    useEffect(() => {
        if (open) {
            getDownloadLink();
        }
    }, [data.id, open]);

    const uploadedDocs = Array.isArray(data?.uploaded_docs) ? data?.uploaded_docs : [];

    return (
        <>
            {loadingLinks ? (
                <LoadingState size={20} />
            ) : (
                <AppViewModal
                    open={open}
                    handleClose={handleClose}
                    title={
                        <>
                            <IconButton onClick={handleClose}>
                                <ArrowBackIosNewRounded sx={{ color: theme.palette.gray[30] }} />
                            </IconButton>
                            <Box sx={{ ml: 2.5 }}>Downloads</Box>
                        </>
                    }
                    width={540}
                    noClose
                >
                    <Stack sx={{ mx: 2.5, my: 4, gap: 1 }}>
                        {uploadedDocs?.map((docs) => {
                            return docs?.doc_name?.toLowerCase()?.includes('roc') ? (
                                <DownloadCard
                                    Icon={ReportIcon}
                                    title={docs?.doc_name}
                                    url={getUrlByKey(docs?.doc_name)}
                                    key={docs?.id}
                                    id={data?.id}
                                />
                            ) : docs?.doc_name?.toLowerCase()?.includes('certification') ? (
                                <DownloadCard
                                    Icon={Certification}
                                    title={docs?.doc_name}
                                    url={getUrlByKey(docs?.doc_name)}
                                    key={docs?.id}
                                    id={data?.id}
                                />
                            ) : (
                                <DownloadCard
                                    Icon={OtherReports}
                                    title={docs?.doc_name}
                                    url={getUrlByKey(docs?.doc_name)}
                                    key={docs?.id}
                                    id={data?.id}
                                />
                            );
                        })}
                        {data?.certification && (
                            <DownloadCard
                                Icon={Certification}
                                title="Certification"
                                url={getUrlByKey('certification')}
                                id={data?.id}
                            />
                        )}
                        {data?.extra_docs &&
                            Object.entries(data?.extra_docs).map(([key, value]) => (
                                <DownloadCard Icon={OtherReports} title={documentsName[key]} url={getUrlByKey(value)} />
                            ))}
                    </Stack>
                </AppViewModal>
            )}
        </>
    );
};
const DownloadCard = (props) => {
    const { Icon, title, url } = props;
    const theme = useTheme();

    return (
        <Box
            sx={{
                border: `1.5px solid ${theme.palette.gray[95]}`,
                p: 2,
                py: 1,
                borderRadius: 1,
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Icon />
            <Typography sx={{ fontSize: 16, fontWeight: 600, color: theme.palette.primary[20], flex: 1, ml: 3 }}>
                {title}
            </Typography>
            <IconButton
                sx={{ color: theme.palette.primary[900] }}
                component="a"
                href={url}
                target="_blank"
                disabled={!url}
            >
                <DownloadRounded />
            </IconButton>
        </Box>
    );
};
const mapStateToProps = (state) => {
    return { ...state };
};
export default connect(mapStateToProps, {
    DeleteMerchantPeople,
})(AuditDownloadModal);
