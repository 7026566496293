// core components
import Categories from './components/Categories';
// import ResourceView from './components/ResourceView';

// redux
import { useSelector } from 'react-redux';
// import { GetAllDocumentsByCategory } from 'store/actions/adminActions';

const Documents = (props) => {
    const {
        loading: loadingCategory,
        // getCategories,
        openTaskAssignModal,
        openExemptModal,
        openRevertModal,
        isExceptionTab,
    } = props;

    // state
    // const [view, setView] = useState(0);
    // const [selectedCategory, setSelectedCategory] = useState(null);
    // const [loading, setLoading] = useState({ content: false });

    // redux
    // const dispatch = useDispatch();
    // const merchant_documents = useSelector((state) => state?.complianceReducers?.all_merchant_documents);
    // const documents_by_category = useSelector((state) => state?.adminReducers?.all_ndpr_documents);
    const all_compliance_assigned_tasks = useSelector(
        (state) => state?.generalReducers?.user_info?.compliance_assigned_tasks
    );

    // logic functions
    // const handleViewChange = (categoryID) => {
    //     setView(1);
    //     setSelectedCategory(categoryID);
    // };
    // const goBack = () => {
    //     setView(0);
    //     setSelectedCategory(null);
    // };

    // async functions
    // const getCategoryDocuments = async (cat_id) => {
    //     setLoading((current) => ({ ...current, content: true }));
    //     const res = await dispatch(GetAllDocumentsByCategory(cat_id));
    //     setLoading((current) => ({ ...current, content: false }));
    //     if (!res?.success) {
    //         toast.error('Something went wrong!');
    //         console.log(res?.message);
    //     }
    // };
    // const handleDocumentExempted = (cat_id) => {
    //     getCategories();
    //     getCategoryDocuments(cat_id);
    // };

    // useEffect(() => {
    //     if (selectedCategory) {
    //         getCategoryDocuments(selectedCategory);
    //     }
    // }, [selectedCategory]);

    // return view === 0 ? (
    return (
        <Categories
            // resource="doc"
            // onViewChange={handleViewChange}
            loading={loadingCategory}
            openTaskAssignModal={openTaskAssignModal}
            openExemptModal={openExemptModal}
            openRevertModal={openRevertModal}
            all_compliance_assigned_tasks={all_compliance_assigned_tasks}
            isExceptionTab={isExceptionTab}
        />
        // ) : (
        //     <ResourceView
        //         goBack={goBack}
        //         categoryID={selectedCategory}
        //         resource="doc"
        //         merchantResources={merchant_documents}
        //         categoryResources={documents_by_category}
        //         loading={loading.content}
        //         handleResourceExempted={handleDocumentExempted}
        //         openTaskAssignModal={openTaskAssignModal}
        //         all_compliance_assigned_tasks={all_compliance_assigned_tasks}
        //     />
    );
};
export default Documents;
