import { MoreVert } from '@mui/icons-material';
import { IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AppTableActionMenu from 'components/new_components/app-table/AppTableActionMenu';
import { useState } from 'react';
import { useSelector } from 'react-redux';
// import { getType } from 'utils';
import { useHistory } from 'react-router-dom';
import GroupCard from './GroupCard';

const CategoryCard = (props) => {
    const {
        metric,
        // resourceLabel,
        // onViewChange,
        id,
        title,
        openTaskAssignModal,
        openExemptModal,
        openRevertModal,
        isCategoryAssigned,
        // resourceAssigned,
        standard,
        // tag,
        description,
        isExceptionTab,
    } = props;
    // hooks
    const [anchor, setAnchor] = useState(null);

    const history = useHistory();

    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const organization = useSelector((state) => state?.generalReducers?.user_info?.organization);
    // const role = getType('role');

    const theme = useTheme();

    const handleOpenTaskModal = (e) => {
        e.stopPropagation();
        openTaskAssignModal();
        closeMenu();
    };

    const handleOpenExemptModal = (e) => {
        e.stopPropagation();
        openExemptModal();
        closeMenu();
    };

    const handleOpenRevertModal = (e) => {
        e.stopPropagation();
        openRevertModal();
        closeMenu();
    };
    // functions
    const openMenu = (event) => {
        event.stopPropagation();
        setAnchor(event.target);
    };

    const closeMenu = () => setAnchor(null);

    const handleCardClick = () => {
        if (metric?.totalValue > 0) {
            // onViewChange(id);
            history.push(`/merchant/compliance/frameworks/${standard}/${id}`);
        }
    };
    return (
        <GroupCard
            percentCompleted={
                metric?.totalAttendedTo === metric?.totalValue
                    ? 100
                    : (metric?.totalAttendedTo / metric?.totalValue) * 100 || 0
            }
            totalValue={metric?.totalValue}
            totalAttendedTo={metric?.totalAttendedTo}
            // resourceLabel={resourceLabel}
            onCardClick={handleCardClick}
            empty={metric?.totalValue == 0}
            isCategoryAssigned={isCategoryAssigned}
            // isResourceAssigned={resourceAssigned?.includes(id)}
            standard={standard}
            // tag={tag}
            isExceptionTab={isExceptionTab}
        >
            <Stack direction="row" justifyContent="space-between" onClick={(event) => event.stopPropagation()}>
                <Typography variant="h3" component="h3" sx={{ fontWeight: 600, mb: 0, color: '#475569', fontSize: 15 }}>
                    {title}
                </Typography>
                {metric?.totalValue > 0 &&
                    (!isExceptionTab ? (
                        !isCategoryAssigned && user_type !== 'auditor' ? (
                            <>
                                {organization?.merchant_plan?.name !== 'free_version' &&
                                    organization?.merchant_plan?.name !== '3_days_trial' && (
                                        <IconButton
                                            sx={{ color: theme.palette.gray[900], minWidth: 'unset', p: 0 }}
                                            onClick={openMenu}
                                        >
                                            <MoreVert
                                                sx={{
                                                    color: '#475569',
                                                }}
                                            />
                                        </IconButton>
                                    )}
                                <AppTableActionMenu
                                    anchor={anchor}
                                    closeMenu={closeMenu}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <MenuItem
                                        sx={{ fontSize: 14, fontWeight: 500, color: '#64748B' }}
                                        onClick={handleOpenTaskModal}
                                    >
                                        Assign to
                                    </MenuItem>

                                    <MenuItem
                                        sx={{ fontSize: 14, fontWeight: 500, color: '#64748B' }}
                                        onClick={handleOpenExemptModal}
                                    >
                                        Exempt
                                    </MenuItem>
                                </AppTableActionMenu>
                            </>
                        ) : null
                    ) : (
                        <>
                            {organization?.merchant_plan?.name !== 'free_version' &&
                                organization?.merchant_plan?.name !== '3_days_trial' && (
                                    <IconButton
                                        sx={{ color: theme.palette.gray[900], minWidth: 'unset', p: 0 }}
                                        onClick={openMenu}
                                    >
                                        <MoreVert
                                            sx={{
                                                color: '#475569',
                                            }}
                                        />
                                    </IconButton>
                                )}
                            <AppTableActionMenu
                                anchor={anchor}
                                closeMenu={closeMenu}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem
                                    sx={{ fontSize: 14, fontWeight: 500, color: '#64748B' }}
                                    onClick={handleOpenRevertModal}
                                >
                                    Revert
                                </MenuItem>
                            </AppTableActionMenu>
                        </>
                    ))}
                {/* {role !== 'auditor' && (
                    <>
                        {standard && !isCategoryAssigned && metric?.totalValue > 0 ? (
                            <>
                                <IconButton
                                    sx={{ color: theme.palette.gray[900], minWidth: 'unset', p: 0 }}
                                    onClick={openMenu}
                                >
                                    <MoreVert />
                                </IconButton>
                                <AppTableActionMenu
                                    anchor={anchor}
                                    closeMenu={closeMenu}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                > */}
                {/* <MenuItem
                                sx={{
                                    fontSize: 13,
                                    fontWeight: 500,
                                    color: theme.palette.gray[700],
                                }}
                            >
                                Generate Report
                            </MenuItem> */}
                {/* {!isCategoryAssigned && user_type !== 'auditor' ? (
                                        <MenuItem sx={{ fontSize: 12 }} onClick={handleOpenTaskModal}>
                                            Assign to:
                                        </MenuItem>
                                    ) : null}
                                </AppTableActionMenu>
                            </>
                        ) : null}
                    </>
                )} */}
            </Stack>

            {/* {standard === 'cii' && ( */}
            <Typography component="p" sx={{ fontSize: 12, fontWeight: 500, color: '#64748B' }}>
                {description}
            </Typography>
            {/* )} */}
        </GroupCard>
    );
};

export default CategoryCard;
