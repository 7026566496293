import { Grid } from '@mui/material';
import { forwardRef, useMemo } from 'react';

// core components
import LoadingState from 'components/new_components/LoadingState';
import RequirementCard from './RequirementCard';

//translation
import EmptyState from 'components/new_components/EmptyState';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';

const Requirements = forwardRef((props, ref) => {
    const {
        all_requirements,
        all_requirements_metrics,
        // resource,
        // onViewChange,
        loadingContent,
        openTaskAssignModal,
        openExemptModal,
        openRevertModal,
        all_compliance_assigned_tasks,
        // all_pcidss4point0_documents_by_subreq,
        // all_pcidss4point0_forms,
        isExceptionTab,
    } = props;

    //translation
    const { t } = useTranslation('compliance');

    const all_exempted_controls = useSelector((state) => state?.complianceReducers?.all_exempted_controls);

    const exmeptedRequirementsId = useMemo(
        () => all_exempted_controls?.filter((item) => item?.standard === 'card4')?.map((item) => item?.card4),
        [all_exempted_controls]
    );

    const filteredRequirements = useMemo(() => {
        if (isExceptionTab) {
            return all_requirements?.filter((item) => exmeptedRequirementsId?.includes(item?.id));
        } else {
            return all_requirements?.filter((item) => !exmeptedRequirementsId?.includes(item?.id));
        }
    }, [all_requirements, exmeptedRequirementsId, isExceptionTab]);

    // const subRequirementAssignedChecker = (assignedSubReq, requirement_no) => {
    //     let matchedRequirements;
    //     matchedRequirements = assignedSubReq?.filter((item) => {
    //         return parseInt(item?.toString()?.split('.')?.[0]) === requirement_no;
    //     });
    //     return matchedRequirements?.length;
    // };

    // const documentsByRequirement = useMemo(() => {
    //     return all_pcidss4point0_documents_by_subreq
    //         ?.filter((document) => {
    //             const pcidssDocuments = all_compliance_assigned_tasks?.documents?.forms?.pcidss || [];

    //             const res = pcidssDocuments?.length ? pcidssDocuments.filter((item) => document?.id === item) : [];

    //             return res?.length ? document?.requirement_sub_no : null;
    //         })
    //         .map((document) => Math.floor(document?.requirement_sub_no));
    // }, [all_pcidss4point0_documents_by_subreq, all_compliance_assigned_tasks?.documents?.forms?.card4]);

    // const formsByRequirement = useMemo(() => {
    //     return all_pcidss4point0_forms
    //         ?.filter((form) => {
    //             const pcidssForms = all_compliance_assigned_tasks?.questions?.forms?.pcidss || [];

    //             const res = pcidssForms?.length ? pcidssForms.filter((item) => form?.id === item) : [];

    //             return res?.length ? form?.requirement_sub_no : null;
    //         })
    //         .map((form) => Math.floor(form?.requirement_sub_no));
    // }, [all_pcidss4point0_forms, all_compliance_assigned_tasks?.documents?.forms?.card4]);

    return !loadingContent ? (
        <Grid container spacing={2.5} sx={{ p: 4 }}>
            {filteredRequirements?.length ? (
                filteredRequirements
                    ?.sort((a, b) => a?.requirement_number - b?.requirement_number)
                    ?.map((requirement) => {
                        const requirement_number = requirement?.requirement_number;
                        const {
                            // total_form,
                            // total_doc,
                            // user_doc,
                            // user_form,
                            doc_review_stats,
                        } = all_requirements_metrics?.[requirement_number?.toFixed(1)] || {};

                        const metric =
                            // resource === 'doc'
                            // ? { totalValue: total_doc, totalAttendedTo: user_doc }
                            {
                                totalValue: doc_review_stats?.split('/')[1],
                                totalAttendedTo: doc_review_stats?.split('/')[0],
                            };
                        // : { totalValue: total_form, totalAttendedTo: user_form };
                        // const resourceLabel = resource === 'doc' ? t('fileUploaded') : t('questionAnswered');
                        const isRequirementAssigned =
                            // resource === 'doc'
                            // ? all_compliance_assigned_tasks?.documents?.category?.card4.includes(requirement?.id)
                            all_compliance_assigned_tasks?.documents?.category?.card4.includes(requirement?.id);
                        // : all_compliance_assigned_tasks?.questions?.category?.card4?.includes(requirement?.id);
                        // const isSubRequirementAssigned =
                        //     resource === 'doc'
                        //         ? subRequirementAssignedChecker(
                        //               all_compliance_assigned_tasks?.documents?.sub_category?.card4,
                        //               requirement_number
                        //           )
                        //         : subRequirementAssignedChecker(
                        //               all_compliance_assigned_tasks?.questions?.sub_category?.card4,
                        //               requirement_number
                        //           );
                        return (
                            <RequirementCard
                                key={requirement?.id}
                                id={requirement?.id}
                                requirement_number={requirement_number}
                                title={requirement?.requirement_title}
                                metric={metric}
                                // resourceLabel={resourceLabel}
                                //   onViewChange={onViewChange}
                                ref={ref}
                                isRequirementAssigned={isRequirementAssigned}
                                // isSubRequirementAssigned={isSubRequirementAssigned}
                                openTaskAssignModal={openTaskAssignModal}
                                openExemptModal={openExemptModal}
                                openRevertModal={openRevertModal}
                                // resourceAssigned={resource === 'doc' ? documentsByRequirement : formsByRequirement}
                                isExceptionTab={isExceptionTab}
                            />
                        );
                    })
            ) : (
                <EmptyState description={isExceptionTab ? t('noException') : t('noRequirement')} />
            )}
        </Grid>
    ) : (
        <LoadingState />
    );
});

const mapStateToProps = (state) => {
    return {
        all_requirements: state?.adminReducers?.all_pcidss4point0_requirements,
        all_requirements_metrics: state?.complianceReducers?.all_pcidss4point0_requirements_metrics,
        // all_pcidss4point0_documents_by_subreq: state?.adminReducers?.all_documents_by_tags,
        // all_pcidss4point0_forms: state?.adminReducers?.all_forms_by_tag,
    };
};

export default connect(mapStateToProps, {}, null, { forwardRef: true })(Requirements);
