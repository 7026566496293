import {
    ALL_MERCHANT_ONBOARDING_ITEMS,
    ALL_MERCHANT_ONBOARDING_POLICIES,
    ALL_MERCHANT_ONBOARDING_RESOURCE,
    ALL_MERCHANT_ONBOARDING_VIDEOS,
    ALL_ONBOARDING_ITEMS_FOR_MERCHANTS,
} from '../constants';
import axiosInstance from '../../utils/https';

// onboarding
export const GetAllOnboardingItemsForMerchant = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/onboarding/?admin=true`);
        if (res?.status === 'fail') throw new Error(res?.message);
        dispatch({
            type: ALL_ONBOARDING_ITEMS_FOR_MERCHANTS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllMerchantOnboardingItems = (merchant) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/onboarding/?merchant=${merchant || company_id}`);
        if (res?.status === 'fail') throw new Error(res?.message);
        dispatch({ type: ALL_MERCHANT_ONBOARDING_ITEMS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllMerchantOnboardingResource = (token) => async (dispatch) => {
    // const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/employee_onboarding_resources/?employee_token=${token}`);
        if (res?.status === 'fail') throw new Error(res?.message);
        dispatch({ type: ALL_MERCHANT_ONBOARDING_RESOURCE, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateMerchantOnboardingResource = (credentials, token) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/employee_onboarding/`, {
            ...credentials,
            employee_token: token,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantOnboardingResource(token));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateMerchantOnboardingItem = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    credentials.append('merchant', company_id);
    try {
        const res = await axiosInstance().post(`/compliance/onboarding/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantOnboardingItems());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditMerchantOnboardingItem = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/onboarding/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantOnboardingItems());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const NotifyMerchantEmployees = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/click_admin_policy/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantOnboardingItems());
        dispatch(GetAllOnboardingItemsForMerchant());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteOnboardingItem = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/onboarding/${id}/`);
        console.log(res);
        dispatch(GetAllMerchantOnboardingItems());
        return {
            success: true,
            message: 'Item deleted successfully.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const ToggleOnboardingItemActive = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/deactivate_video/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantOnboardingItems());
        dispatch(GetAllOnboardingItemsForMerchant());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const RequestNameChange = (credentials) => async () => {
    try {
        const res = await axiosInstance().post(`compliance/request_name_change/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// new endpoint for getting all onboarding policies
export const GetAllMerchantOnboardingPolicies = (filters) => async (dispatch) => {
    try {
        const query = new URLSearchParams(filters).toString();
        const res = await axiosInstance().get(`onboarding/policies/?${query}`);
        if (res?.status === 'fail') throw new Error(res?.message);
        dispatch({ type: ALL_MERCHANT_ONBOARDING_POLICIES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// new endpoint for getting all onboarding videos
export const GetAllMerchantOnboardingVideos = (filters) => async (dispatch) => {
    try {
        const query = new URLSearchParams(filters).toString();
        const res = await axiosInstance().get(`onboarding/awareness_training/?${query}`);
        if (res?.status === 'fail') throw new Error(res?.message);

        dispatch({ type: ALL_MERCHANT_ONBOARDING_VIDEOS, payload: res?.data });

        return { success: true, message: res?.message };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// new endpoint to disable/enable a policy
export const ChangeMerchantOnboardingPolicyStatus = (credentials) => async () => {
    const { id, ...body } = credentials;
    try {
        const res = await axiosInstance().patch(`onboarding/policies/${id}/`, body);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const ChangeMerchantOnboardingVideoStatus = (credentials) => async () => {
    const { id, ...body } = credentials;
    try {
        const res = await axiosInstance().patch(`onboarding/awareness_training/${id}/`, body);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const OverwriteMerchantOnboardingPolicy = (credentials, id) => async () => {
    try {
        const res = await axiosInstance().patch(`onboarding/policies/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const OverwriteMerchantOnboardingVideo = (credentials, id) => async () => {
    try {
        const res = await axiosInstance().patch(`onboarding/awareness_training/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const AddMerchantOnboardingPolicy = (credentials) => async () => {
    try {
        const res = await axiosInstance().post(`onboarding/policies/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const AddMerchantOnboardingVideo = (credentials) => async () => {
    try {
        const res = await axiosInstance().post(`onboarding/awareness_training/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
