import { styled, Tooltip, tooltipClasses } from '@mui/material';

export default function CustomTooltip({ children, title, placement, width }) {
    const CustomizedTooltip = styled(({ className, ...props }) => (
        <Tooltip
            {...props}
            placement={placement || 'top'}
            disableFocusListener
            disableTouchListener
            arrow
            classes={{ popper: className }}
        />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            borderRadius: '2px',
            textAlign: 'center',
            padding: '4px 8px',
            maxWidth: width || '120px',
        },
    }));

    return <CustomizedTooltip title={title}>{children}</CustomizedTooltip>;
}
