import { DeleteOutlined, EditOutlined, EyeOutlined, MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';

const CatOperations = ({ openEditModal, category, deleteCategory }) => {
    // const history = useHistory();
    const menu = (
        <Menu className="tableaction">
            <Menu.Item key="0">
                <Link
                    to={{
                        pathname: '/admin/ndpr/category',
                        state: {
                            category,
                        },
                    }}
                >
                    <EyeOutlined /> View
                </Link>
            </Menu.Item>
            <Menu.Item key="1" onClick={() => openEditModal('Edit', category)}>
                <EditOutlined /> Edit
            </Menu.Item>
            <Menu.Item key="2" style={{ color: 'red' }} onClick={() => deleteCategory(category?.id)}>
                <DeleteOutlined /> Delete
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <a href="#" className="ant-dropdown-link">
                <MoreOutlined />
            </a>
        </Dropdown>
    );
};

export default CatOperations;
