import { useSelector } from 'react-redux';

export default function Profile() {
    const user_info = useSelector((state) => state?.generalReducers?.user_info);

    const userInitials =
        user_info?.first_name || user_info?.last_name
            ? user_info.first_name.charAt(0) + user_info.last_name.charAt(0)
            : user_info?.email?.charAt(0) ?? '' + user_info?.email?.charAt(1);

    return (
        <div className="flex !h-8 !w-8 items-center justify-center rounded-full !border !border-[#749BCB] bg-[#EAF0F766]">
            <span className="text-sm text-[#395BA9] font-bold capitalize">{userInitials}</span>
        </div>
    );
}
