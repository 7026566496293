/* eslint-disable no-unused-vars */

import moment from 'moment';

export default function Sidebar({ selectedVersionId, setSelectedVersionId, currentPolicyDocument }) {
    return (
        <div className="!w-full max-w-[396px] bg-white flex flex-col !gap-px !h-full overflow-y-auto custom-scroll">
            {currentPolicyDocument?.policy_versions?.map((version) => (
                <div
                    key={version?.id}
                    className={`cursor-pointer transition-all !border !border-[#F1F5F9] flex flex-col !gap-[2px] !py-3 !px-4 ${
                        version?.id === selectedVersionId ? 'bg-[#F1F5F9]' : 'bg-white hover:brightness-95'
                    }`}
                    role="button"
                    onClick={() => setSelectedVersionId(version?.id)}
                >
                    <div className="flex items-center justify-between">
                        <div className="flex items-center !gap-2">
                            <div
                                className={`!h-[11px] !w-[11px] !rounded-full ${
                                    version?.id === selectedVersionId ? 'bg-[#395BA9]' : 'bg-[#CBD5E1]'
                                }`}
                            />

                            <p className="text-[#002C72] font-bold">Version {version?.version_no}</p>
                        </div>

                        {version?.is_current && (
                            <p className="!border !border-[#E2E8F0] !rounded !py-1 !px-2 text-[#395BA9] font-medium text-xs">
                                Current
                            </p>
                        )}
                    </div>

                    <p className="!py-0.5 !px-2 text-[#395BA9] text-sm font-medium">
                        {moment(version?.date_time).format('DD MMMM, YYYY')}
                    </p>

                    <p className="!py-0.5 !px-2 text-[#395BA9] text-sm">
                        Change No: <span className="font-medium text-[#475569]">{version?.change_no}</span>
                    </p>
                </div>
            ))}
        </div>
    );
}
