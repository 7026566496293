import { DeleteOutlined, EditOutlined, EyeOutlined, MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';

const ProjectManagementOperations = ({ openEditModal, project_management, deleteProjectManagement }) => {
    // const history = useHistory();
    const menu = (
        <Menu className="tableaction">
            <Menu.Item key="0">
                <Link
                    to={{
                        pathname: '/admin/iso27017+27032/project_managements/project_management',
                        state: {
                            project_management,
                        },
                    }}
                >
                    <EyeOutlined /> View
                </Link>
            </Menu.Item>
            <Menu.Item key="1" onClick={() => openEditModal('Edit', project_management)}>
                <EditOutlined /> Edit
            </Menu.Item>
            <Menu.Item key="2" style={{ color: 'red' }} onClick={() => deleteProjectManagement(project_management?.id)}>
                <DeleteOutlined /> Delete
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <a href="#" className="ant-dropdown-link">
                <MoreOutlined />
            </a>
        </Dropdown>
    );
};

export default ProjectManagementOperations;
