import { Box } from '@mui/material';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';

//components
import PageHeader from 'components/new_components/PageHeader';
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import AllTaskModal from '../common/AllTaskModal';
// import AssignTaskModal from '../common/AssignTaskModal';
import ComplianceLayout from '../common/ComplianceLayout';
import TaskIntroductionModal from '../common/TaskIntroductionModal';
import Documents from './Documents';
//redux
import { connect, useDispatch, useSelector } from 'react-redux';
import { GetAllContinuityClauses } from 'store/actions/adminActions';
import {
    // GetAllMerchantAnswers,
    // GetAllMerchantDocuments,
    GetContinuityGroupMetrics,
} from 'store/actions/complianceActions';
import {
    CreateModuleGuide,
    GetModuleGuides,
    GetUserDetails,
    UpdateModuleGuide,
    UpdateUserDetails,
} from 'store/actions/generalActions';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';
// import AuditQuestion from './AuditQuestion';

//userguide
import { Tour } from 'antd';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideHeader } from 'components/new_components/AppUserGuide';
import { GetAllExemptedControls } from 'store/actions/complianceActions';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import AssignToDialog from '../common/document_review/components/AssignToDialog';
import ExemptRequirementDialog from '../common/document_review/components/ExemptRequirementDialog';
import RevertRequirementDialog from '../common/document_review/components/RevertRequirementDialog';

const index = (props) => {
    const {
        GetAllContinuityClauses,
        GetContinuityGroupMetrics,
        // GetAllMerchantDocuments,
        // GetAllMerchantAnswers,
        GetUserDetails,
        // UpdateUserDetails,
        GetModuleGuides,
        UpdateModuleGuide,
        CreateModuleGuide,
        // GetAllDocumentsByTags,
        // GetAllFormsByTags,
        continuitygroup_fetched,
        guideModal,
        userguide_info,
        all_article_header,
    } = props;

    const [currentTab, setCurrentTab] = useState(0);
    // const [view, setView] = useState(0);
    const [loading, setLoading] = useState({ content: false, exceptions: false, metrics: false });
    // const [selectedContinuityGroup, setSelectedContinuityGroup] = useState(null);
    const [introModalOpen, setIntroModalOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [tasksModalOpen, setTaskModalOpen] = useState(false);
    const [exmeptModalOpen, setExemptModalOpen] = useState(false);
    const [revertModalOpen, setRevertModalOpen] = useState(false);
    // const [modalType, setModalType] = useState(null);
    const [taskInfo, setTaskInfo] = useState({});
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    const { activeMerchant } = useContext(SelectedMerchantContext);
    const user_type = useSelector((state) => state.authReducers.user_type);

    //userguide
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);
    const ref1 = useRef(null);
    // const ref2 = useRef(null);
    const ref3 = useRef(null);

    const dispatch = useDispatch();
    const STEPS_LENGTH = 2;

    const steps = [
        {
            title: <UserGuideHeader title="Documents" id={1} length={STEPS_LENGTH} />,
            description: 'Here you can Upload compliance policies.',
            target: () => ref1.current,
            placement: 'rightTop',
        },
        // {
        //     title: <UserGuideHeader title="Audit Questions" id={2} length={STEPS_LENGTH} />,
        //     description: 'Respond to audit-related queries here.',
        //     target: () => ref2.current,
        //     placement: 'rightTop',
        // },
        {
            title: <UserGuideHeader title="Exceptions" id={3} length={STEPS_LENGTH} />,
            description: 'This tab displays requirements that are exempted',
            target: () => ref3.current,
            placement: 'rightTop',
        },
    ];

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Compliance'),
        [all_article_header]
    );

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ compliance: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, compliance: false });
        setOpenArticle(false);
    };

    // functions
    // logic functions
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
    };
    //task introduction/explanation modal
    const closeIntroModal = () => {
        setIntroModalOpen(false);
    };

    const openTaskAssignModal = (type, info) => {
        setModalOpen(true);
        // setModalType(type);
        setTaskInfo(info);
    };
    const closeTaskAssignModal = () => {
        setModalOpen(false);
        // setTimeout(() => setModalType(null), 500);
        setTaskInfo({});
    };

    const openExemptModal = (type, info) => {
        setExemptModalOpen(true);
        setTaskInfo(info);
    };
    const closeExemptModal = () => {
        setExemptModalOpen(false);
        setTaskInfo({});
    };

    const openRevertModal = (type, info) => {
        setRevertModalOpen(true);
        setTaskInfo(info);
    };
    const closeRevertModal = () => {
        setRevertModalOpen(false);
        setTaskInfo({});
    };

    const openTasksModal = () => {
        setTaskModalOpen(true);
    };
    const closeTasksModal = () => {
        setTaskModalOpen(false);
    };
    // handle the view on the standard
    // const handleViewChange = (continuityGroupId) => {
    //     setView(1);
    //     setSelectedContinuityGroup(continuityGroupId);
    // };
    // const goBack = () => {
    //     setView(0);
    //     setSelectedContinuityGroup(null);
    // };

    // async functions
    const getAllExemptedControls = async () => {
        setLoading((current) => ({
            ...current,
            exceptions: true,
        }));
        const res = await dispatch(GetAllExemptedControls());
        setLoading((current) => ({ ...current, exceptions: false }));
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch exempted controls.");
        }
    };
    const getAllContinuityClauses = async (category, shouldLoad) => {
        setLoading((curr) => ({
            ...curr,
            content: shouldLoad || !continuitygroup_fetched,
        }));
        const res = await GetAllContinuityClauses(category);
        setLoading((curr) => ({ ...curr, content: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log('Error:::', res?.message);
        }
    };
    const getContinuityGroupMetrics = async () => {
        setLoading((curr) => ({ ...curr, metrics: !continuitygroup_fetched }));
        const res = await GetContinuityGroupMetrics(activeMerchant);
        setLoading((curr) => ({ ...curr, metrics: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };
    // const getAllMerchantDocuments = async () => {
    //     const res = await GetAllMerchantDocuments(activeMerchant);
    //     if (!res.success) {
    //         toast.error("Something went wrong! Couldn't fetch your documents.");
    //         console.log(res.message);
    //     }
    // };
    // const getMerchantAnswers = async () => {
    //     const res = await GetAllMerchantAnswers(activeMerchant);
    //     if (!res?.success) {
    //         toast.error("Something went wrong! Couldn't fetch previous answers.");
    //         console.log('Error: ', res);
    //     }
    // };

    const getUserDetails = async () => {
        const res = await GetUserDetails();
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch user details");
        }
    };
    const getModuleGuides = async () => {
        const res = await GetModuleGuides();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    // const getAllDocumentsByTags = async () => {
    //     const res = await GetAllDocumentsByTags('continuity');
    //     if (!res?.success) {
    //         toast.error(res?.message);
    //     }
    // };
    // const getAllFormsByTags = async () => {
    //     const res = await GetAllFormsByTags('continuity');
    //     if (!res?.success) {
    //         toast.error(res?.message);
    //     }
    // };

    const updateModuleIntroModal = async () => {
        const res =
            guideModal && guideModal[0]
                ? await UpdateModuleGuide({ compliance_task: true }, guideModal[0]?.id)
                : await CreateModuleGuide({ compliance_task: true });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        getAllExemptedControls();
        getAllContinuityClauses();
        getContinuityGroupMetrics();
        // getAllMerchantDocuments();
        // getMerchantAnswers();
    }, [activeMerchant]);

    useEffect(() => {
        if (user_type !== 'auditor') {
            getUserDetails();
            getModuleGuides();
            // getAllDocumentsByTags();
            // getAllFormsByTags();
        }
    }, [user_type]);

    useEffect(() => {
        // Check if guideModal is empty or its first element has api_compliance set to false
        const shouldOpenModal =
            guideModal && (guideModal.length === 0 || (guideModal[0] && !guideModal[0]?.compliance_task));

        // Only update the state if needed to avoid unnecessary renders
        if (shouldOpenModal !== introModalOpen) {
            setIntroModalOpen(shouldOpenModal);
        }
    }, [guideModal, introModalOpen]);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.compliance ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.compliance]);

    return (
        <>
            <PageHeader browserTitle="ISO22301 - Compliance | Smartcomply" />
            {/* {openAiTopBar && <ComplianceAITopBar handleCloseAiTopBar={handleCloseAiTopBar} />} */}
            <Box sx={{ p: { xs: 0, md: 0 } }}>
                <ComplianceLayout
                    onTabChange={handleTabChange}
                    tab={currentTab}
                    openTasksModal={openTasksModal}
                    standard="is022301"
                    ref1={ref1}
                    // ref2={ref2}
                    ref3={ref3}
                >
                    <AppTabPanel value={currentTab} index={0}>
                        <Documents
                            // view={view}
                            loading={loading?.content || loading?.metrics || loading?.exceptions}
                            // handleViewChange={handleViewChange}
                            // goBack={goBack}
                            // selectedContinuityGroup={selectedContinuityGroup}
                            openTaskAssignModal={openTaskAssignModal}
                            openExemptModal={openExemptModal}
                        />
                    </AppTabPanel>
                    {/* <AppTabPanel value={currentTab} index={1}> */}
                    {/* <AuditQuestions loading={loading.requirements} getRequirements={getAllRequirements} /> */}
                    {/* <AuditQuestion
                            view={view}
                            goBack={goBack}
                            loading={loading?.content}
                            handleViewChange={handleViewChange}
                            selectedContinuityGroup={selectedContinuityGroup}
                            openTaskAssignModal={openTaskAssignModal}
                            />
                            </AppTabPanel> */}
                    <AppTabPanel value={currentTab} index={2}>
                        <Documents
                            // view={view}
                            loading={loading?.content || loading?.metrics || loading?.exceptions}
                            // handleViewChange={handleViewChange}
                            // goBack={goBack}
                            // selectedContinuityGroup={selectedContinuityGroup}
                            isExceptionTab={true}
                            openRevertModal={openRevertModal}
                        />
                    </AppTabPanel>
                </ComplianceLayout>
            </Box>
            <TaskIntroductionModal
                open={introModalOpen}
                handleClose={closeIntroModal}
                updateModuleIntroModal={updateModuleIntroModal}
            />

            {/* <AssignTaskModal open={modalOpen} onClose={closeTaskAssignModal} type={modalType} taskInfo={taskInfo} /> */}
            <AssignToDialog isOpen={modalOpen} handleCallback={closeTaskAssignModal} requirementData={taskInfo} />
            <ExemptRequirementDialog
                title="Clause"
                subTitle="clauses"
                isOpen={exmeptModalOpen}
                handleCallback={closeExemptModal}
                requirementData={taskInfo}
            />
            <RevertRequirementDialog
                title="Clause"
                isOpen={revertModalOpen}
                handleCallback={closeRevertModal}
                requirementData={taskInfo}
            />
            <AllTaskModal open={tasksModalOpen} handleClose={closeTasksModal} standard="is022301" />
            {!loading.content && <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} />}
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Compliance"
                headerId={oneArticle?.id}
                icon={true}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        continuitygroup_fetched: state?.adminReducers?.all_continuity_clauses,
        guideModal: state?.generalReducers?.module,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.compliance,
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};
export default connect(mapStateToProps, {
    GetAllContinuityClauses,
    GetContinuityGroupMetrics,
    // GetAllMerchantDocuments,
    // GetAllMerchantAnswers,
    GetUserDetails,
    UpdateUserDetails,
    GetModuleGuides,
    UpdateModuleGuide,
    CreateModuleGuide,
    // GetAllDocumentsByTags,
    // GetAllFormsByTags,
})(index);
