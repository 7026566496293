import { Box } from '@mui/material';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';

// core components
import PageHeader from 'components/new_components/PageHeader';
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import CategorySelect from '../common/CategorySelect';
import ComplianceLayout from '../common/ComplianceLayout';
// import AuditQuestions from './AuditQuestions';
import Documents from './Documents';
// import Exceptions from './Exceptions';
// import ComplianceAITipsModal from '../common/ComplianceAITipsModal';

// redux
import { useDispatch, useSelector } from 'react-redux';
// import { GetAllMerchantAnswers, GetAllMerchantDocuments } from 'store/actions/complianceActions';
import { GetAllTechSecgroups, GetTechSecMetrics } from 'store/actions/iso27001+iso27035Actions';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';

//userguide
import { Tour } from 'antd';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideHeader } from 'components/new_components/AppUserGuide';
// import { GetAllDocumentsByTags } from 'store/actions/adminActions';
import { CreateModuleGuide, UpdateModuleGuide } from 'store/actions/generalActions';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import AllTaskModal from '../common/AllTaskModal';
// import AssignTaskModal from '../common/AssignTaskModal';
import { GetAllExemptedControls } from 'store/actions/complianceActions';
import AssignToDialog from '../common/document_review/components/AssignToDialog';
import ExemptRequirementDialog from '../common/document_review/components/ExemptRequirementDialog';
import RevertRequirementDialog from '../common/document_review/components/RevertRequirementDialog';
import TaskIntroductionModal from '../common/TaskIntroductionModal';

const ISO27001plusISO27035 = () => {
    // state
    const [currentTab, setCurrentTab] = useState(0);
    const [category, setCategory] = useState('clause');
    const [loading, setLoading] = useState({ content: false, exceptions: false, metrics: false });
    // const [view, setView] = useState(0);
    // const [selectedIsogroup, setSelectedIsogroup] = useState(null);
    // const [openAiModal, setOpenAiModal] = useState(false);
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);
    const [introModalOpen, setIntroModalOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [tasksModalOpen, setTaskModalOpen] = useState(false);
    const [exmeptModalOpen, setExemptModalOpen] = useState(false);
    const [revertModalOpen, setRevertModalOpen] = useState(false);
    // const [modalType, setModalType] = useState(null);
    const [taskInfo, setTaskInfo] = useState({});

    const { activeMerchant } = useContext(SelectedMerchantContext);
    const dispatch = useDispatch();
    const techsecgroup_fetched = useSelector(
        (state) => state?.iso27001plusiso27035Reducers?.all_tech_sec_groups?.length
    );
    const techsecgroup_metrics_fetched = useSelector(
        (state) => state?.iso27001plusiso27035Reducers?.all_tech_sec_metrics?.length
    );
    const userguide_info = useSelector((state) => state?.guideReducers?.userguide_info?.[0]?.compliance);
    const guideModal = useSelector((state) => state?.generalReducers?.module);
    const all_article_header = useSelector((state) => state?.articlesReducers?.all_article_header);

    //userguide
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);
    const ref1 = useRef(null);
    // const ref2 = useRef(null);
    const ref3 = useRef(null);

    const STEPS_LENGTH = 2;

    const steps = [
        {
            title: <UserGuideHeader title="Documents" id={1} length={STEPS_LENGTH} />,
            description: 'Here you can Upload compliance policies.',
            target: () => ref1.current,
            placement: 'rightTop',
        },
        // {
        //     title: <UserGuideHeader title="Audit Questions" id={2} length={STEPS_LENGTH} />,
        //     description: 'Respond to audit-related queries here.',
        //     target: () => ref2.current,
        //     placement: 'rightTop',
        // },
        {
            title: <UserGuideHeader title="Exceptions" id={3} length={STEPS_LENGTH} />,
            description: 'This tab displays requirements that are exempted',
            target: () => ref3.current,
            placement: 'rightTop',
        },
    ];

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Compliance'),
        [all_article_header]
    );

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ compliance: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, compliance: false });
        setOpenArticle(false);
    };

    // functions
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
        // goBack();
    };
    const handleCategoryChange = (category) => {
        setCategory(category);
    };
    // handle the view on the standard
    // const handleViewChange = (isogroupId) => {
    //     setView(1);
    //     setSelectedIsogroup(isogroupId);
    // };
    // const goBack = () => {
    //     setView(0);
    //     setSelectedIsogroup(null);
    // };

    // const handleOpenAiModal = () => {
    //     setOpenAiModal(true);
    // };
    // const handleCloseAiModal = () => {
    //     setOpenAiModal(false);
    // };
    // async functions
    const getAllExemptedControls = async () => {
        setLoading((current) => ({
            ...current,
            exceptions: true,
        }));
        const res = await dispatch(GetAllExemptedControls());
        setLoading((current) => ({ ...current, exceptions: false }));
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch exempted controls.");
        }
    };
    const getAllTechSecgroups = async (category, shouldLoad) => {
        setLoading((curr) => ({
            ...curr,
            content: shouldLoad || !techsecgroup_fetched,
        }));
        const res = await dispatch(GetAllTechSecgroups(category));
        setLoading((curr) => ({ ...curr, content: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log('Error:::', res?.message);
        }
    };
    const getAllIsogroupsMetric = async () => {
        setLoading((curr) => ({ ...curr, metrics: !techsecgroup_metrics_fetched }));
        const res = await dispatch(GetTechSecMetrics(activeMerchant));
        setLoading((curr) => ({ ...curr, metrics: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };
    // const getAllMerchantDocuments = async () => {
    //     const res = await dispatch(GetAllMerchantDocuments(activeMerchant));
    //     if (!res.success) {
    //         toast.error("Something went wrong! Couldn't fetch your documents.");
    //         console.log(res.message);
    //     }
    // };

    // const getAllDocumentsByTags = async () => {
    //     const res = await dispatch(GetAllDocumentsByTags('tech_sec'));
    //     if (!res?.success) {
    //         toast.error(res?.message);
    //     }
    // };
    // const getMerchantAnswers = async () => {
    //     const res = await dispatch(GetAllMerchantAnswers(activeMerchant));
    //     if (!res?.success) {
    //         toast.error("Something went wrong! Couldn't fetch previous answers.");
    //         console.log('Error: ', res);
    //     }
    // };

    const updateModuleIntroModal = async () => {
        const res =
            guideModal && guideModal[0]
                ? await UpdateModuleGuide({ compliance_task: true }, guideModal[0]?.id)
                : await CreateModuleGuide({ compliance_task: true });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const closeIntroModal = () => {
        setIntroModalOpen(false);
    };

    const openTaskAssignModal = (type, info) => {
        setModalOpen(true);
        // setModalType(type);
        setTaskInfo(info);
    };
    const closeTaskAssignModal = () => {
        setModalOpen(false);
        // setTimeout(() => setModalType(null), 500);
        setTaskInfo({});
    };

    const openExemptModal = (type, info) => {
        setExemptModalOpen(true);
        setTaskInfo(info);
    };
    const closeExemptModal = () => {
        setExemptModalOpen(false);
        setTaskInfo({});
    };

    const openRevertModal = (type, info) => {
        setRevertModalOpen(true);
        setTaskInfo(info);
    };
    const closeRevertModal = () => {
        setRevertModalOpen(false);
        setTaskInfo({});
    };

    const openTasksModal = () => {
        setTaskModalOpen(true);
    };
    const closeTasksModal = () => {
        setTaskModalOpen(false);
    };

    useEffect(() => {
        if (category) {
            getAllTechSecgroups(category, true);
        }
    }, [category]);

    useEffect(() => {
        getAllExemptedControls();
        getAllIsogroupsMetric();
        // getAllMerchantDocuments();
        // getMerchantAnswers();
        // getAllDocumentsByTags();
    }, [activeMerchant]);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.compliance ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.compliance]);
    // useEffect(() => {
    //     handleOpenAiModal();
    // }, []);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.compliance ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.compliance]);

    return (
        <>
            <PageHeader browserTitle="ISO27017 + ISO27032 - Compliance | Smartcomply" />
            <Box sx={{ py: { xs: 0, md: 0 }, px: { xs: 0, md: 0 } }}>
                <ComplianceLayout
                    onTabChange={handleTabChange}
                    tab={currentTab}
                    ref1={ref1}
                    // ref2={ref2}
                    ref3={ref3}
                    openTasksModal={openTasksModal}
                    standard="iso27017+iso27032"
                >
                    {/* {view === 0 && ( */}
                    <CategorySelect onCategoryChange={handleCategoryChange} category={category} includeCybersecurity />
                    {/* )} */}
                    <AppTabPanel value={currentTab} index={0}>
                        <Documents
                            loading={loading?.content || loading?.metrics || loading?.exceptions}
                            // getIsogroups={getAllTechSecgroups}
                            // category={category}
                            // view={view}
                            // handleViewChange={handleViewChange}
                            // goBack={goBack}
                            // selectedIsogroup={selectedIsogroup}
                            openTaskAssignModal={openTaskAssignModal}
                            openExemptModal={openExemptModal}
                        />
                    </AppTabPanel>
                    {/* <AppTabPanel value={currentTab} index={1}>
                        <AuditQuestions
                        loading={loading?.content}
                        getIsogroups={getAllTechSecgroups}
                        category={category}
                        view={view}
                        handleViewChange={handleViewChange}
                        goBack={goBack}
                        selectedIsogroup={selectedIsogroup}
                        openTaskAssignModal={openTaskAssignModal}
                        />
                        </AppTabPanel> */}
                    <AppTabPanel value={currentTab} index={2}>
                        {/* <Exceptions getIsogroups={getAllTechSecgroups} category={category} loading={loading?.content} /> */}
                        <Documents
                            loading={loading?.content || loading?.metrics || loading?.exceptions}
                            // getIsogroups={getAllTechSecgroups}
                            // category={category}
                            // view={view}
                            // handleViewChange={handleViewChange}
                            // goBack={goBack}
                            // selectedIsogroup={selectedIsogroup}
                            isExceptionTab={true}
                            openRevertModal={openRevertModal}
                        />
                    </AppTabPanel>
                </ComplianceLayout>

                <TaskIntroductionModal
                    open={introModalOpen}
                    handleClose={closeIntroModal}
                    updateModuleIntroModal={updateModuleIntroModal}
                />

                {/* <AssignTaskModal open={modalOpen} onClose={closeTaskAssignModal} type={modalType} taskInfo={taskInfo} /> */}
                <AssignToDialog isOpen={modalOpen} handleCallback={closeTaskAssignModal} requirementData={taskInfo} />
                <ExemptRequirementDialog
                    title="Control"
                    subTitle="controls"
                    isOpen={exmeptModalOpen}
                    handleCallback={closeExemptModal}
                    requirementData={taskInfo}
                />
                <RevertRequirementDialog
                    title="Control"
                    isOpen={revertModalOpen}
                    handleCallback={closeRevertModal}
                    requirementData={taskInfo}
                />
                <AllTaskModal open={tasksModalOpen} handleClose={closeTasksModal} standard="tech_sec" />
                {/* <ComplianceAITipsModal open={openAiModal} handleClose={handleCloseAiModal} width="700px" minWidth={900} /> */}

                {!loading.content && <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} />}
                <AppArticle
                    open={openArticle}
                    handleClose={CloseArticle}
                    title="Compliance"
                    headerId={oneArticle?.id}
                    icon={true}
                />
            </Box>
        </>
    );
};

export default ISO27001plusISO27035;
