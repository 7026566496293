import React from 'react';
import QRCode from 'qrcode.react';
import moment from 'moment';
import Barcode from 'react-barcode';
import { getPcidss4Version } from '../utils';
const CertImplementer = '/img/audit-certificate/cert implementer.svg';
const Certi = '/img/audit-certificate/compliance cert.svg';
function CertificateComponent({ certificate, targetRef, user_info, current_client, merchant_info }) {
    return (
        <div
            className="w-[1016px] h-[710.49px] relative text-[#132944] flex flex-col justify-center items-center certificate-container"
            ref={targetRef}
            id="certificate"
        >
            <img src={Certi} alt="cert" className="object-cover   absolute w-full h-full top-0 left-0" />

            <div className="w-1/2 my-3 z-1 relative text-center flex flex-col gap-3">
                <h2 className="font-semibold text-xl mt-0">PRESENTED TO</h2>

                <div className="flex justify-center items-center my-1">
                    {/* display name */}
                    {certificate && (
                        <h1 className="text-3xl font-bold mb-2">
                            {certificate?.company_name ||
                                (user_info?.user_type === 'implementer' ? current_client?.name : merchant_info?.name)}
                        </h1>
                    )}
                </div>
                <h4 className="text-xl">
                    This is to certify that
                    <strong className="font-semibold">
                        {' '}
                        {certificate &&
                            (certificate?.company_name ||
                                (user_info?.user_type === 'implementer'
                                    ? current_client?.name
                                    : merchant_info?.name))}{' '}
                    </strong>
                    has successfully been assessed against PCI DSS version{' '}
                    {getPcidss4Version(certificate?.date_created)} and found to be compliant.
                </h4>
            </div>
            <div className="w-[90%] border-2 rounded-lg border-dashed border-[#4BA978] flex relative z-1 text-[#132944] items-center">
                <div className="w-1/3 px-3 pb-2 border-r-2 border-dashed border-[#4ba978] h-full flex flex-col justify-center">
                    <h4 className="font-medium text-xl text-[#132944] leading-relaxed">Service Covered:</h4>
                    <p className="leading-tight">
                        {' '}
                        {user_info?.user_type === 'implementer'
                            ? current_client?.service_covered
                            : merchant_info?.service_covered}
                    </p>
                </div>
                <div className="w-1/3 px-3 py-2 border-r-2 border-dashed border-[#4ba978] relative h-full ">
                    <div className="flex justify-center items-center h-full -ml-3">
                        <p className="rotate-[-90deg] text-black text-[10px] whitespace-nowrap w-[25px] font-medium h-[fit] -mb-12 ">
                            Scan to verify
                        </p>
                        <QRCode
                            id="qr"
                            value={`${
                                window.location.hostname === 'app.smartcomplyapp.com'
                                    ? 'https://app.smartcomplyapp.com/verification'
                                    : 'https://development.smartcomplyapp.com/verification'
                            }/${user_info?.user_type === 'implementer' ? current_client?.id : merchant_info?.id}`}
                            renderAs="svg"
                            size={80}
                        />
                    </div>
                </div>
                <div className="w-1/3 px-3 pb-2 flex flex-col justify-center ">
                    <h4 className="font-medium text-xl text-[#132944] leading-relaxed">Issue Date:</h4>
                    <p className="leading-tight ">{moment(certificate?.date_created).format('DD MMMM, YYYY')}</p>
                </div>
            </div>

            <div className="z-1 absolute bottom-[4.5rem] left-[50%] translate-x-[-50%]">
                <Barcode
                    value={certificate?.code || ''}
                    width={2}
                    height={73}
                    fontSize={20}
                    rendererProps={{
                        style: { fill: '#132944', fontWeight: '500' },
                    }}
                    className="w-[127px] h-[73px] relative"
                />
            </div>

            <img
                src={CertImplementer}
                alt="implemented by Smartcomply"
                className="z-1 absolute bottom-[4.7rem] left-12 w-[180px]"
            />
        </div>
    );
}

export default CertificateComponent;
