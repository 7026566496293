import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { policyStatementsTabs, useUploadedFileTabs } from '../../db';

export default function TableTop({
    currentTab,
    setCurrentTab,
    formattedDocReviewStats,
    setIsReuseablePolicyViewOpen,
    setIsUsePolicyEditorDrawerOpen,
    setIsUseUploadedFileDrawerOpen,
    setDefaultTab,
    standard,
}) {
    const all_addons = useSelector((state) => state?.generalReducers?.all_addons);
    const all_merchant_documents = useSelector((state) => state?.complianceReducers?.all_merchant_documents);
    const user_id = useSelector((state) => state?.authReducers?.user_id);

    const isPaid = all_addons?.[0]?.policy_editor?.status === 'approved';

    const filteredDocumentsApprovalAndReviewerCount = useMemo(
        () =>
            all_merchant_documents
                ?.filter((doc) => doc?.tag === standard && doc?.current)
                ?.filter(
                    (doc) =>
                        (doc?.approved_by === user_id && doc?.policy_status === 'awaiting_approval') ||
                        (doc?.reviewer === user_id && doc?.policy_status === 'awaiting_review')
                )?.length,
        [all_merchant_documents]
    );

    const gapsCount = useMemo(
        () => formattedDocReviewStats.filter((data) => data.gaps).length,
        [formattedDocReviewStats]
    );

    return (
        <>
            <div className="flex items-center justify-between">
                <div className="flex items-center !gap-4 !border-b !border-[#F1F5F9]">
                    {policyStatementsTabs.map((tab) => (
                        <button
                            key={tab}
                            className={`relative transition-all overflow-hidden capitalize !py-3 text-sm before:absolute before:-bottom-1 before:left-1/2 before:h-2 before:-translate-x-1/2 before:rounded-full before:bg-[#1B295F] before:transition-all before:duration-500 hover:brightness-75 ${
                                currentTab === tab
                                    ? 'before:w-full font-semibold text-[#1B295F]'
                                    : 'before:w-0 font-medium text-[#64748B]'
                            }`}
                            onClick={() => setCurrentTab(tab)}
                        >
                            {tab}
                        </button>
                    ))}
                </div>

                <div className="flex items-center !gap-2.5">
                    {filteredDocumentsApprovalAndReviewerCount > 0 ? (
                        <button
                            className="transition-all hover:brightness-95 bg-white !rounded !py-1.5 !px-3 flex items-center !gap-2 text-[#475569] font-medium text-sm"
                            onClick={() => {
                                if (isPaid) {
                                    setIsUsePolicyEditorDrawerOpen(true);
                                } else {
                                    setDefaultTab(useUploadedFileTabs[1]);
                                    setIsUseUploadedFileDrawerOpen(true);
                                }
                            }}
                        >
                            <span className="relative !border !border-[#E2E8F0] !rounded !py-0.5 !px-1">
                                <>
                                    <span className="absolute !-right-1 !-top-1 !h-2 !w-2 rounded-full bg-[#FF5449]" />

                                    <span className="absolute !-right-1 !-top-1 !h-2 !w-2 animate-ping rounded-full bg-[#FF5449] opacity-75" />
                                </>

                                <span>{filteredDocumentsApprovalAndReviewerCount}</span>
                            </span>

                            <span>Pending Approval/Review</span>
                        </button>
                    ) : null}

                    {gapsCount > 0 ? (
                        <button
                            className="transition-all hover:brightness-95 bg-white !border !border-[#E2E8F0] !rounded !py-1.5 !px-3 flex items-center !gap-2 text-[#D2A500] font-medium text-sm"
                            onClick={() => setIsReuseablePolicyViewOpen(true)}
                        >
                            <span className="!border !border-[#E2E8F0] !rounded !py-0.5 !px-1">{gapsCount}</span>

                            <span>Gaps left</span>
                        </button>
                    ) : null}
                </div>
            </div>
        </>
    );
}
