import { Grid } from '@mui/material';
import EmptyState from 'components/new_components/EmptyState';
import LoadingState from 'components/new_components/LoadingState';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import CategoryCard from '../../common/CategoryCard';

//translations
import { useTranslation } from 'react-i18next';

const Isogroups = (props) => {
    const {
        // resource,
        loading,
        // onViewChange,
        openTaskAssignModal,
        openExemptModal,
        openRevertModal,
        all_compliance_assigned_tasks,
        isExceptionTab,
    } = props;

    //translation
    const { t } = useTranslation('compliance');

    const all_techsecgroups = useSelector((state) => state?.iso27001plusiso27035Reducers?.all_tech_sec_groups);
    const all_techsecgroup_metrics = useSelector((state) => state?.iso27001plusiso27035Reducers?.all_tech_sec_metrics);
    const all_exempted_controls = useSelector((state) => state?.complianceReducers?.all_exempted_controls);

    const exmeptedCategoriesId = useMemo(
        () => all_exempted_controls?.filter((item) => item?.standard === 'tech_sec')?.map((item) => item?.tech_sec),
        [all_exempted_controls]
    );

    const filteredCategories = useMemo(() => {
        if (isExceptionTab) {
            return all_techsecgroups?.filter((item) => exmeptedCategoriesId?.includes(item?.id));
        } else {
            return all_techsecgroups?.filter((item) => !exmeptedCategoriesId?.includes(item?.id));
        }
    }, [all_techsecgroups, exmeptedCategoriesId, isExceptionTab]);

    // const all_tech_sec_documents = useSelector((state) => state?.adminReducers?.all_documents_by_tags);
    // const all_tech_sec_forms = useSelector((state) => state?.adminReducers?.all_forms_by_tag);

    // const documentsByRequirement = useMemo(() => {
    //     return all_tech_sec_documents
    //         ?.filter((document) => {
    //             const tech_secDocuments = all_compliance_assigned_tasks?.documents?.forms?.tech_sec || [];

    //             const res = tech_secDocuments?.length ? tech_secDocuments.filter((item) => document?.id === item) : [];
    //             console.log({ res });
    //             return res?.length ? document : null;
    //         })
    //         .map((document) => Math.floor(document?.tech_sec));
    // }, [all_tech_sec_documents, all_compliance_assigned_tasks?.documents?.forms?.tech_sec]);

    // const formsByRequirement = useMemo(() => {
    //     return all_tech_sec_forms
    //         ?.filter((form) => {
    //             const tech_secForms = all_compliance_assigned_tasks?.questions?.forms?.tech_sec || [];

    //             const res = tech_secForms?.length ? tech_secForms.filter((item) => form?.id === item) : [];

    //             return res?.length ? form : null;
    //         })
    //         .map((form) => Math.floor(form?.tech_sec));
    // }, [all_tech_sec_forms, all_compliance_assigned_tasks?.documents?.forms?.tech_sec]);
    return !loading ? (
        <Grid container spacing={2.5} sx={{ p: 4 }}>
            {filteredCategories && filteredCategories?.length ? (
                filteredCategories
                    ?.sort((a, b) => a?.id - b?.id)
                    ?.map((techsecgroup) => {
                        {
                            /* get and descructure the categories metrics */
                        }
                        // const { total_form, total_doc, user_form, user_doc } =
                        //   all_isogroup_metrics?.[isogroup?.title];

                        {
                            /* format metric and label based on resource type */
                        }
                        const metric =
                            // resource === 'doc'
                            // ? {
                            //       totalValue: all_techsecgroup_metrics?.[techsecgroup?.title]?.total_doc,
                            //       totalAttendedTo: all_techsecgroup_metrics?.[techsecgroup?.title]?.user_doc,
                            //   }
                            {
                                totalValue:
                                    all_techsecgroup_metrics?.[techsecgroup?.title]?.doc_review_stats?.split('/')[1],
                                totalAttendedTo:
                                    all_techsecgroup_metrics?.[techsecgroup?.title]?.doc_review_stats?.split('/')[0],
                            };
                        // : {
                        //       totalValue: all_techsecgroup_metrics?.[techsecgroup?.title]?.total_form,
                        //       totalAttendedTo: all_techsecgroup_metrics?.[techsecgroup?.title]?.user_form,
                        //   };
                        // const resourceLabel = resource === 'doc' ? t('fileUploaded') : t('questionAnswered');

                        const isCategoryAssigned =
                            // resource === 'doc'
                            // ? all_compliance_assigned_tasks?.documents?.category?.tech_sec?.includes(
                            //       techsecgroup?.id
                            //   )
                            all_compliance_assigned_tasks?.documents?.category?.tech_sec?.includes(techsecgroup?.id);
                        // : all_compliance_assigned_tasks?.questions?.category?.tech_sec?.includes(
                        //       techsecgroup?.id
                        //   );
                        const exmeptedCategory = all_exempted_controls
                            ?.filter((item) => item?.standard === 'tech_sec')
                            ?.find((item) => item?.tech_sec === techsecgroup?.id);
                        return (
                            <CategoryCard
                                key={techsecgroup?.id}
                                id={techsecgroup?.id}
                                title={techsecgroup?.title}
                                metric={metric}
                                // onViewChange={onViewChange}
                                // resourceLabel={resourceLabel}
                                openTaskAssignModal={() =>
                                    openTaskAssignModal('requirement', {
                                        tech_sec: techsecgroup?.id,
                                        // is_document: resourceLabel !== 'Questions Answered',
                                        is_document: true,
                                        standard: 'tech_sec',
                                    })
                                }
                                openExemptModal={() =>
                                    openExemptModal({
                                        standard: 'tech_sec',
                                        tech_sec: techsecgroup?.id,
                                    })
                                }
                                openRevertModal={() =>
                                    openRevertModal({
                                        id: exmeptedCategory?.id,
                                    })
                                }
                                standard="tech_sec"
                                isCategoryAssigned={isCategoryAssigned}
                                // resourceAssigned={resource === 'doc' ? documentsByRequirement : formsByRequirement}
                                isExceptionTab={isExceptionTab}
                            />
                        );
                    })
            ) : (
                <EmptyState description={isExceptionTab ? t('noException') : t('noISO')} />
            )}
        </Grid>
    ) : (
        <LoadingState />
    );
};

export default Isogroups;
