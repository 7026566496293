/* eslint-disable no-unused-vars */

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import ReadOnlyEditor from '../../PolicyEditorDrawer/ReadOnlyEditor';

export default function MainContent({ selectedVersionId, currentPolicyDocument, standard }) {
    const all_merchant_documents = useSelector((state) => state?.complianceReducers?.all_merchant_documents);

    const allDocuments = useMemo(
        () =>
            all_merchant_documents
                ?.filter((doc) => doc?.tag === standard)
                ?.map((doc) => ({ ...doc, default: doc?.document })),
        [all_merchant_documents]
    );

    const currentDocument = useMemo(() => {
        const currentVersion = currentPolicyDocument?.policy_versions?.find(
            (version) => version?.id === selectedVersionId
        );

        return allDocuments?.find((doc) => doc?.id === currentVersion?.id);
    }, [selectedVersionId, currentPolicyDocument, allDocuments]);

    return (
        <div className="!my-6 !ml-4 !mr-6 !rounded bg-white !h-[calc(100vh-120px)] overflow-y-auto overflow-x-auto custom-scroll !w-full">
            <ReadOnlyEditor readOnlyDocument={currentDocument} delta={currentDocument?.operations} />
        </div>
    );
}
