import { Grid } from '@mui/material';
import EmptyState from 'components/new_components/EmptyState';
import LoadingState from 'components/new_components/LoadingState';
import { useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import CategoryCard from '../../common/CategoryCard';

//translations
import { useTranslation } from 'react-i18next';

const Categories = (props) => {
    const {
        // resource,
        loading,
        // onViewChange,
        all_categories,
        all_categories_metrics,
        all_compliance_assigned_tasks,
        openTaskAssignModal,
        openExemptModal,
        openRevertModal,
        // all_kdpa_documents,
        // all_kdpa_forms,
        isExceptionTab,
    } = props;

    //translation
    const { t } = useTranslation('compliance');

    const all_exempted_controls = useSelector((state) => state?.complianceReducers?.all_exempted_controls);

    const exmeptedCategoriesId = useMemo(
        () => all_exempted_controls?.filter((item) => item?.standard === 'kdpa')?.map((item) => item?.kdpa_category),
        [all_exempted_controls]
    );

    const filteredCategories = useMemo(() => {
        if (isExceptionTab) {
            return all_categories?.filter((item) => exmeptedCategoriesId?.includes(item?.id));
        } else {
            return all_categories?.filter((item) => !exmeptedCategoriesId?.includes(item?.id));
        }
    }, [all_categories, exmeptedCategoriesId, isExceptionTab]);

    // const documentsByCategory = useMemo(() => {
    //     return all_kdpa_documents
    //         ?.filter((document) => {
    //             const kdpaDocuments = all_compliance_assigned_tasks?.documents?.forms?.ndpr || [];

    //             const res = kdpaDocuments?.length ? kdpaDocuments.filter((item) => document?.id === item) : [];

    //             return res?.length ? document : null;
    //         })
    //         .map((document) => document?.kdpa_category);
    // }, [all_kdpa_documents, all_compliance_assigned_tasks?.documents?.forms?.ndpr]);

    // const formsByCategory = useMemo(() => {
    //     return all_kdpa_forms
    //         ?.filter((form) => {
    //             const kdpaForms = all_compliance_assigned_tasks?.questions?.forms?.ndpr || [];

    //             const res = kdpaForms?.length ? kdpaForms.filter((item) => form?.id === item) : [];

    //             return res?.length ? form : null;
    //         })
    //         .map((form) => form?.category);
    // }, [all_kdpa_forms, all_compliance_assigned_tasks?.documents?.forms?.ndpr]);
    return !loading ? (
        <Grid container spacing={2.5} sx={{ p: 4 }}>
            {filteredCategories && filteredCategories?.length ? (
                filteredCategories
                    ?.sort((a, b) => a?.id - b?.id)
                    ?.map((category) => {
                        {
                            /* get and descructure the categories metrics */
                        }
                        // const { total_form, total_doc, user_form, user_doc } =
                        //   all_categories_metrics?.[category?.title];

                        {
                            /* format metric and label based on resource type */
                        }
                        const metric =
                            // resource === 'doc'
                            // ? {
                            //       totalValue: all_categories_metrics?.[category?.title]?.total_doc,
                            //       totalAttendedTo: all_categories_metrics?.[category?.title]?.user_doc,
                            //   }
                            {
                                totalValue: all_categories_metrics?.[category?.title]?.doc_review_stats?.split('/')[1],
                                totalAttendedTo:
                                    all_categories_metrics?.[category?.title]?.doc_review_stats?.split('/')[0],
                            };
                        // : {
                        //       totalValue: all_categories_metrics?.[category?.title]?.total_form,
                        //       totalAttendedTo: all_categories_metrics?.[category?.title]?.user_form,
                        //   };
                        // const resourceLabel = resource === 'doc' ? t('fileUploaded') : t('questionAnswered');
                        const isCategoryAssigned =
                            // resource === 'doc'
                            // ? all_compliance_assigned_tasks?.documents?.category?.kdpa?.includes(category?.id)
                            all_compliance_assigned_tasks?.documents?.category?.kdpa?.includes(category?.id);
                        // : all_compliance_assigned_tasks?.questions?.category?.kdpa?.includes(category?.id);
                        const exmeptedCategory = all_exempted_controls
                            ?.filter((item) => item?.standard === 'kdpa')
                            ?.find((item) => item?.kdpa_category === category?.id);
                        return (
                            <CategoryCard
                                key={category?.id}
                                id={category?.id}
                                title={category?.title}
                                // resourceLabel={resourceLabel}
                                metric={metric}
                                // onViewChange={onViewChange}
                                standard="kdpa"
                                openTaskAssignModal={() =>
                                    openTaskAssignModal('category', {
                                        kdpa_category: category?.id,
                                        // is_document: resourceLabel !== 'Questions Answered',
                                        is_document: true,
                                        standard: 'kdpa',
                                    })
                                }
                                openExemptModal={() =>
                                    openExemptModal('category', {
                                        kdpa_category: category?.id,
                                        standard: 'kdpa',
                                    })
                                }
                                openRevertModal={() =>
                                    openRevertModal('category', {
                                        id: exmeptedCategory?.id,
                                    })
                                }
                                isCategoryAssigned={isCategoryAssigned}
                                // resourceAssigned={resource === 'doc' ? documentsByCategory : formsByCategory}
                                isExceptionTab={isExceptionTab}
                            />
                        );
                    })
            ) : (
                <EmptyState description={isExceptionTab ? t('noException') : t('noCategory')} />
            )}
        </Grid>
    ) : (
        <LoadingState />
    );
};
const mapStateToProps = (state) => {
    return {
        all_categories: state?.adminReducers?.all_kdpa_categories,
        all_categories_metrics: state?.complianceReducers?.all_kdpa_categories_metrics,
        // all_kdpa_forms: state?.adminReducers?.all_forms_by_tag,
        // all_kdpa_documents: state?.adminReducers?.all_documents_by_tags,
    };
};
export default connect(mapStateToProps, {})(Categories);
