import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { Box, Button, Modal, Typography, useTheme } from '@mui/material';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { DeleteOnboardingItem } from 'store/actions/onboardingActions';

const DeletePolicyModal = (props) => {
    const { open, handleClose, refetch, DeleteOnboardingItem, row } = props;
    const [deleting, setDeleting] = useState(false);
    const theme = useTheme();
    const { t } = useTranslation('onboardingPolicy');

    const handleDelete = async () => {
        setDeleting(true);
        const res = await DeleteOnboardingItem(row?.id);
        setDeleting(false);
        if (res?.success) {
            refetch();
            toast?.success('Item deleted successfully');
            handleClose();
        } else {
            toast?.error(res?.message);
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="vendor-delete-modal-title"
            aria-describedby="vendor-delete-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 450,
                    bgcolor: 'white',
                    boxShadow: 24,
                    borderRadius: 2,
                    outline: 'none',
                }}
            >
                {/* Modal Content */}
                <Box
                    sx={{
                        p: 3,
                        textAlign: 'center',
                        background: '#fff',
                    }}
                >
                    <Typography
                        id="vendor-delete-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                            fontWeight: 600,
                            fontSize: '18px',
                            color: theme.palette.gray[600],
                        }}
                    >
                        {row?.tag === 'document'
                            ? t('deleteOnboardingItem.policyTitle')
                            : t('deleteOnboardingItem.videoTitle')}
                    </Typography>

                    <Typography
                        id="vendor-delete-modal-description"
                        sx={{
                            mt: 2,
                            color: theme.palette.gray[500],
                            fontSize: '14px',
                        }}
                    >
                        {row?.tag === 'document'
                            ? t(`deleteOnboardingItem.policySubtitle`)
                            : t(`deleteOnboardingItem.videoSubtitle`)}
                    </Typography>
                </Box>

                {/* Footer with Buttons */}
                <Box
                    sx={{
                        bgcolor: '#F8FAFC',
                        py: 3,
                        px: 3,
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 2,
                        borderTop: '1px solid #E5E7EB',
                    }}
                >
                    <Button
                        variant="text"
                        onClick={handleClose}
                        sx={{
                            textTransform: 'none',
                            color: theme.palette.gray[900],
                            background: '#fff',
                            border: '1px solid #E2E8F0',
                        }}
                    >
                        {t('buttonText.cancel')}
                    </Button>

                    <LoadingButton
                        variant="contained"
                        color="error"
                        onClick={handleDelete}
                        loading={deleting}
                        sx={{
                            textTransform: 'none',
                            backgroundColor: '#FF5449',
                            boxShadow: 'none!important',
                            '&:hover': {
                                backgroundColor: '#E0443D',
                            },
                        }}
                    >
                        {t('buttonText.delete')}
                    </LoadingButton>
                </Box>
            </Box>
        </Modal>
    );
};

export default connect(null, {
    DeleteOnboardingItem,
})(DeletePolicyModal);
