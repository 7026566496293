import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { numExtraction } from 'utils';

// lib
import { ExclamationCircleOutlined, FileOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Table, Tabs } from 'antd';

// core components
import BreadCrumb from 'components/Breadcrumb';
import SubcontrolOperations from './ISO9001Actions/SubcontrolOperations';
import SubcontrolsModal from './ISO9001Actions/SubcontrolsModal';

// redux
import { FileCheck2 } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { GetDocumentsByQualityManagementId } from 'store/actions/adminActions';
import { CreateQualityManagement, DeleteQualityManagement, EditQualityManagement } from 'store/actions/iso9001';
import Templates from './Templates';

// subcomponents
const { confirm } = Modal;

const SubcontrolsView = (props) => {
    const { location } = props;
    const [modal, setModal] = useState(false);
    const [control, setControl] = useState({});
    const [subcontrol, setSubcontrol] = useState({});
    const dispatch = useDispatch();
    const all_quality_management = useSelector((state) => state?.ISO9001Reducers?.all_quality_management);

    const openModal = (mode, data) => {
        setModal(mode);
        setSubcontrol(mode === 'Edit' ? data : {});
    };
    const closeModal = () => {
        setModal(false);
        setSubcontrol(null);
    };
    const deleteSubcontrol = (id) => {
        confirm({
            title: 'Are you sure you want to delete this sub management? Note: Deleting this sub management means you will be deleting this sub management, the documents, audit questions and merchant data associated with this sub management',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await dispatch(DeleteQualityManagement(id, true));
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };

    // data
    const columns = [
        {
            title: 'Sub Management Number',
            render: (record) => `QM ${record.number}`,
            sorter: (a, b) => a.number - b.number,
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Title',
            render: (record) =>
                // <Link
                //     to={{
                //         pathname: '/admin/iso9001/submanagement/items',
                //         state: { subcontrol: record },
                //     }}
                // >
                // </Link>
                record.title,
            sorter: (a, b) => numExtraction(a.title) - numExtraction(b.title),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Description',
            render: (record) => record.description,
            // width: '70%',
        },
        {
            title: 'Policy Statements',
            render: (record) => (
                <ul className="list-disc flex flex-col !gap-1 !mx-4">
                    {record.policy_statements?.length ? (
                        record.policy_statements?.map((policy, index) => <li key={index}>{policy}</li>)
                    ) : (
                        <li>No policy statement</li>
                    )}
                </ul>
            ),
        },
        {
            title: 'Actions',
            align: 'right',
            render: (record) => (
                <SubcontrolOperations
                    openEditModal={openModal}
                    subcontrol={record}
                    deleteSubcontrol={deleteSubcontrol}
                    control={control}
                    // parent_man_num={location?.state?.man_num}
                />
            ),
        },
    ];

    const getDocumentsByControlObjective = async () => {
        const res = await dispatch(GetDocumentsByQualityManagementId(location?.state?.management_id));
        if (!res.success) {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        getDocumentsByControlObjective();
        const { management_id } = location.state || {};
        const one_control = all_quality_management?.find((control) => control?.id === management_id);
        setControl(one_control);
    }, [all_quality_management, location?.state]);
    return (
        <div>
            <Col>
                <Col>
                    <BreadCrumb views={[{ label: 'ISO9001', link: '/iso9001' }, { label: control?.title }]} />
                </Col>

                <Col>
                    <section className="my-3">
                        <Tabs
                            defaultActiveKey="1"
                            items={[
                                {
                                    key: '1',
                                    label: (
                                        <span className="flex items-center !gap-2">
                                            <FileCheck2 className="!h-3.5 !w-3.5" /> <p>Sub-Managements</p>
                                        </span>
                                    ),
                                    children: (
                                        <>
                                            <Row justify="end">
                                                <Button
                                                    type="primary"
                                                    size="large"
                                                    icon={<PlusOutlined />}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                    }}
                                                    onClick={() => openModal('Add')}
                                                >
                                                    Add Sub Qualtiy Management
                                                </Button>
                                            </Row>
                                            <section className="my-3">
                                                <Table
                                                    columns={columns}
                                                    dataSource={control?.sub_quality_mgt}
                                                    rowKey={(sub_control) => sub_control?.id}
                                                />
                                            </section>
                                        </>
                                    ),
                                },
                                {
                                    key: '2',
                                    label: (
                                        <span className="flex items-center !gap-2">
                                            <FileOutlined className="!h-3.5 !w-3.5 !m-0" /> <p>Templates</p>
                                        </span>
                                    ),
                                    children: (
                                        <Templates
                                            parent_control_id={control?.id}
                                            sub_quality_mgt={control?.sub_quality_mgt}
                                        />
                                    ),
                                },
                            ]}
                        />
                    </section>
                </Col>
            </Col>

            <SubcontrolsModal
                open={modal}
                handleCancel={closeModal}
                parent_control_id={control?.id}
                one_subcontrol={subcontrol}
                CreateControl={CreateQualityManagement}
                EditControl={EditQualityManagement}
            />
        </div>
    );
};
export default SubcontrolsView;
