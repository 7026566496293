import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// core components
import Categories from './components/Criterias';
import SubCategory from './components/Subcriteria';

// redux
import { connect } from 'react-redux';
import {
    GetAllSector,
    GetAllDocumentsBySubsector,
    GetAllCiiConditionalItems,
    GetAllFormsBySubSector,
    GetUserCiiTools,
    GetAllCiiToolsItems,
} from 'store/actions/adminActions';
import { AppForm, AppFormContext } from 'components/new_components/forms';
import {
    SubmitMerchantAnswers,
    GetAllMerchantAnswers,
    CiiConditionalFormAnswer,
    SubmitCiiConditionalForm,
    GetCiiSectorSummaries,
    GetAllMerchantDocuments,
    AllCiiExceptions,
    GetAllCiiExceptions,
} from 'store/actions/complianceActions';

// validation
import { validateForm } from './utils/validation';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';

const Criteria = (props) => {
    const {
        GetAllSector,
        all_sector,
        GetAllDocumentsBySubsector,
        GetAllCiiConditionalItems,
        SubmitMerchantAnswers,
        GetAllFormsBySubSector,
        merchant_answers,
        GetAllMerchantAnswers,
        CiiConditionalFormAnswer,
        SubmitCiiConditionalForm,
        company_id,
        GetCiiSectorSummaries,
        GetAllMerchantDocuments,
        GetAllCiiToolsItems,
        GetUserCiiTools,
        GetAllCiiExceptions,
        all_cii_exceptions,
        AllCiiExceptions,
        all_cii_exceptions_,
    } = props;
    // state
    const [view, setView] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [loading, setLoading] = useState({ content: false, criterias: false, submit: false });
    const [touchedFields, setTouchedFields] = useState({});
    const [formPayload, setFormPayload] = useState({});
    const [condtitionalPayload, setConditionalPayload] = useState({});
    const [index, setIndex] = useState();

    const { activeMerchant } = useContext(SelectedMerchantContext);
    // async functions
    const getAllConditionalFromAnswer = async () => {
        setLoading((current) => ({
            ...current,
            criterias: true,
        }));
        const res = await CiiConditionalFormAnswer();
        setLoading((current) => ({ ...current, criterias: false }));
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch requirements.");
            console.log(res.message);
        }
    };

    const getAllExemptedCii = async () => {
        const res = await GetAllCiiExceptions();
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch exceptions.");
            console.log('Error::::', res?.message);
        }
    };

    const getAllSector = async () => {
        setLoading((current) => ({
            ...current,
            criterias: true,
        }));
        const res = await GetAllSector();
        setLoading((current) => ({ ...current, criterias: false }));
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch Sectors.");
            console.log(res.message);
        }
    };

    const getAllMerchantDocuments = async () => {
        setLoading((current) => ({
            ...current,
            criterias: true,
        }));
        const res = await GetAllMerchantDocuments();
        setLoading((current) => ({ ...current, criterias: false }));
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch Documents.");
            console.log(res.message);
        }
    };

    // logic functions
    const handleViewChange = (categoryId, idx) => {
        setView(1);
        setSelectedCategory(categoryId);
        setIndex(idx);
    };

    const goBack = () => {
        setView(0);
        setSelectedCategory(null);
    };

    // async functions
    const getSubCriteriaDocumentsById = async (subReqNo) => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await GetAllDocumentsBySubsector(subReqNo);
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };
    const getConditionalForms = async () => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await GetAllCiiConditionalItems();
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };

    const getMerchantAnswers = async () => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await GetAllMerchantAnswers(activeMerchant);
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };

    const getUserBackground_checkTools = async () => {
        const res = await GetUserCiiTools();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getAllExceptions = async () => {
        const res = await AllCiiExceptions();
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch Exceptions.");
            console.log(res.message);
        }
    };

    const getCiiTools = async () => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await GetAllCiiToolsItems();
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };

    const getSubCriteriaQuestion = async (subReqNo) => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await GetAllFormsBySubSector(subReqNo);
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };

    const getCiiSectorSummaries = async () => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await GetCiiSectorSummaries();
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };
    const handleSubmitAnswers = async (values) => {
        if (formPayload?.resource === 'form') {
            setLoading((current) => ({ ...current, submit: true }));
            const res = await SubmitMerchantAnswers({
                answer: values,
                form_id: formPayload.form_id,
                cii_subsector: formPayload.cii_subsector,
                tag: 'cii',
            });

            setLoading((current) => ({ ...current, submit: false }));
            if (res?.success) {
                toast.success('You’ve successfully answered this question! Click ‘NEXT’ to proceed to the next one.');
            } else {
                toast.error('Something went wrong!!');
                console.log('Check Question, error:::::', res?.message);
            }
        } else {
            setLoading((current) => ({ ...current, submit: true }));
            const value = Object.values(values).map((value) => value);
            const res = await SubmitCiiConditionalForm({
                answer: value[0],
                form_answer: value[1],
                merchant: company_id,
                cii_conditional: formPayload.cii_conditional,
                document_id: formPayload?.document_id,
            });

            setLoading((current) => ({ ...current, submit: false }));
            if (res?.success) {
                toast.success('You’ve successfully answered this question! Click ‘NEXT’ to proceed to the next one.');
            } else {
                toast.error('Something went wrong!!');
            }
        }
    };

    const handleExempted = (subrequirementNo) => {
        getSubCriteriaQuestion(subrequirementNo);
        getSubCriteriaDocumentsById(subrequirementNo);
    };
    const getCurrentValues = (values) => setConditionalPayload(values);
    useEffect(() => {
        getAllSector();
        getMerchantAnswers();
        getAllConditionalFromAnswer();
        getCiiSectorSummaries();
        getAllMerchantDocuments();
        getConditionalForms();
        getCiiTools();
        getUserBackground_checkTools();
        getAllExemptedCii();
        getAllExceptions();
    }, [activeMerchant]);

    const module_exception = all_cii_exceptions?.find((exception) => exception?.id === 2);
    const module_exception_id = all_cii_exceptions_?.find((cii) => cii?.cii_sector === module_exception?.id);
    return view === 0 ? (
        <Categories
            loading={loading.criterias}
            onViewChange={handleViewChange}
            data={all_sector}
            module_exception={module_exception}
            module_exception_id={module_exception_id?.id}
        />
    ) : (
        <AppForm
            initialValues={formPayload?.values || {}}
            validate={(values) => validateForm(values, formPayload?.requiredValues)}
            onSubmit={handleSubmitAnswers}
        >
            <AppFormContext getTouched={setTouchedFields} getValues={getCurrentValues}>
                <SubCategory
                    criteria={selectedCategory}
                    goBack={goBack}
                    loading={loading}
                    setFormPayload={setFormPayload}
                    touchedFields={touchedFields}
                    getSubCriteriaDocumentsByTag={getSubCriteriaDocumentsById}
                    getSubCriteriaQuestion={getSubCriteriaQuestion}
                    handleResourceExempted={handleExempted}
                    merchantResources={merchant_answers}
                    formPayload={formPayload}
                    index={index}
                    condtitionalPayload={condtitionalPayload}
                />
            </AppFormContext>
        </AppForm>
    );
};
const mapStateToProps = (state) => {
    return {
        all_sector: state?.adminReducers?.all_sector,
        merchant_answers: state?.complianceReducers?.merchant_answers,
        company_id: state?.authReducers?.company_id,
        all_cii_exceptions: state?.complianceReducers?.all_cii_exceptions,
        all_cii_exceptions_: state?.complianceReducers?.all_cii_exceptions_,
    };
};
export default connect(mapStateToProps, {
    GetAllSector,
    GetAllDocumentsBySubsector,
    GetAllCiiConditionalItems,
    SubmitCiiConditionalForm,
    GetAllFormsBySubSector,
    SubmitMerchantAnswers,
    GetAllMerchantAnswers,
    CiiConditionalFormAnswer,
    GetCiiSectorSummaries,
    GetAllMerchantDocuments,
    GetAllCiiToolsItems,
    GetUserCiiTools,
    GetAllCiiExceptions,
    AllCiiExceptions,
})(Criteria);
