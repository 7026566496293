/* eslint-disable no-unused-vars */

import { Drawer, Slide } from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';
import { useUploadedFileTabs } from '../../db';
import MyFilesTab from './MyFilesTab';
import NewUploadTab from './NewUploadTab';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

export default function UseUploadedFileDrawer({
    isPaid,
    isOpen,
    setIsOpen,
    setIsUsePolicyEditorDrawerOpen,
    standard,
    templates,
    currentRequirement,
    title,
    fetchDocReviewStats,
    defaultTab,
    setDefaultTab,
}) {
    const [currentTab, setCurrentTab] = useState(useUploadedFileTabs[0]);

    function handleClose() {
        setIsOpen(false);
        setCurrentTab(useUploadedFileTabs[0]);
        setDefaultTab(null);
    }

    useEffect(() => {
        if (isOpen && defaultTab) {
            setCurrentTab(defaultTab);
        }
    }, [isOpen, defaultTab]);

    return (
        <Drawer
            open={isOpen}
            anchor="right"
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={handleClose}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    width: '620px',
                    flex: 1,
                    overflow: 'auto',
                    zIndex: 9999,
                },
            }}
            scroll="paper"
        >
            <div className="border-b border-[#F1F5F9] !p-4 flex items-center justify-between">
                <div className="flex items-center !gap-4">
                    <img
                        src="/img/automated-scan/arrow-left-icon.svg"
                        alt="arrow-left-icon"
                        onClick={handleClose}
                        className="cursor-pointer object-contain"
                    />

                    <h3 className="text-[#002C72] capitalize font-semibold text-lg">Use uploaded file</h3>
                </div>
            </div>

            <div className="flex items-center !gap-4 !mx-4 !w-fit !border-b !border-[#F1F5F9]">
                {useUploadedFileTabs.map((tab) => (
                    <button
                        key={tab}
                        className={`relative transition-all overflow-hidden capitalize !py-3 text-sm before:absolute before:-bottom-1 before:left-1/2 before:h-2 before:-translate-x-1/2 before:rounded-full before:bg-[#1B295F] before:transition-all before:duration-500 hover:brightness-75 ${
                            currentTab === tab
                                ? 'before:w-full font-semibold text-[#1B295F]'
                                : 'before:w-0 font-medium text-[#64748B]'
                        }`}
                        onClick={() => setCurrentTab(tab)}
                    >
                        {tab}
                    </button>
                ))}
            </div>

            {currentTab === useUploadedFileTabs[0] && (
                <NewUploadTab
                    isPaid={isPaid}
                    isOpen={isOpen}
                    handleCallback={handleClose}
                    setIsUsePolicyEditorDrawerOpen={setIsUsePolicyEditorDrawerOpen}
                    standard={standard}
                    templates={templates}
                    currentRequirement={currentRequirement}
                    title={title}
                    fetchDocReviewStats={fetchDocReviewStats}
                />
            )}

            {currentTab === useUploadedFileTabs[1] && (
                <MyFilesTab
                    isPaid={isPaid}
                    standard={standard}
                    templates={templates}
                    currentRequirement={currentRequirement}
                    title={title}
                    fetchDocReviewStats={fetchDocReviewStats}
                />
            )}
        </Drawer>
    );
}
