import {
    // useState,
    forwardRef,
} from 'react';
// import { toast } from 'react-toastify';

// core component
import Requirements from './components/Requirements';
// import Subrequirements from './components/Subrequirements';

// redux
// import { GetDocumentsBySubRequirement } from 'store/actions/adminActions';
import { connect } from 'react-redux';

const Documents = forwardRef((props, ref) => {
    const {
        loading: loadingRequirement,
        // all_merchant_documents,
        // all_documents_by_subreq,
        // GetDocumentsBySubRequirement,
        // getRequirements,
        openTaskAssignModal,
        openExemptModal,
        openRevertModal,
        all_compliance_assigned_tasks,
        isExceptionTab,
    } = props;
    // state
    // const [view, setView] = useState(0);
    // const [selectedRequirement, setSelectedRequirement] = useState(null);
    // const [loading, setLoading] = useState({ content: false });
    // const [refState, setRefState] = useState(null);

    // logic functions
    // const handleViewChange = (requirementId) => {
    //     setView(1);
    //     setSelectedRequirement(requirementId);
    // };
    // const goBack = () => {
    //     setView(0);
    //     setSelectedRequirement(null);
    // };

    // async functions
    // const getSubrequirementDocuments = async (subReqNo) => {
    //     setLoading((current) => ({ ...current, content: true }));
    //     const res = await GetDocumentsBySubRequirement(subReqNo);
    //     setLoading((current) => ({ ...current, content: false }));
    //     if (!res?.success) {
    //         toast.error('Something went wrong!');
    //     }
    // };
    // const handleDocumentExempted = (subrequirementNo) => {
    //     getRequirements();
    //     getSubrequirementDocuments(subrequirementNo);
    // };
    // useEffect
    // useEffect(() => {
    //     if (loadingRequirement) {
    //         setTimeout(() => setRefState(ref), 5000);
    //     }
    // }, [loadingRequirement]);

    // return view === 0 ? (
    return (
        <Requirements
            // onViewChange={handleViewChange}
            loadingContent={loadingRequirement}
            resource="doc"
            ref={ref}
            openTaskAssignModal={openTaskAssignModal}
            openExemptModal={openExemptModal}
            openRevertModal={openRevertModal}
            all_compliance_assigned_tasks={all_compliance_assigned_tasks}
            isExceptionTab={isExceptionTab}
        />
        // ) : (
        //     <Subrequirements
        //         requirement={selectedRequirement}
        //         goBack={goBack}
        //         resource="doc"
        //         loading={loading}
        //         onSubrequirementChange={getSubrequirementDocuments}
        //         merchantResources={all_merchant_documents}
        //         subrequirementResources={all_documents_by_subreq}
        //         handleResourceExempted={handleDocumentExempted}
        //         openTaskAssignModal={openTaskAssignModal}
        //         all_compliance_assigned_tasks={all_compliance_assigned_tasks}
        //     />
    );
});

const mapStateToProps = (state) => {
    return {
        // all_documents_by_subreq: state?.adminReducers?.all_documents_by_subreq,
        // all_merchant_documents: state?.complianceReducers?.all_merchant_documents,
        all_compliance_assigned_tasks: state?.generalReducers?.user_info?.compliance_assigned_tasks,
    };
};

export default connect(
    mapStateToProps,
    {
        // GetDocumentsBySubRequirement,
    },
    null,
    { forwardRef: true }
)(Documents);
