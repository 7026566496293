import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';

const SubcontrolOperations = (props) => {
    const {
        openEditModal,
        subcontrol,
        deleteSubcontrol,
        // control
    } = props;
    // const history = useHistory();
    const menu = (
        <Menu className="tableaction">
            {/* <Menu.Item key="0">
                <Link
                    to={{
                        pathname: '/admin/iso9001/submanagement/items',
                        state: { subcontrol, title: control?.title },
                    }}
                >
                    <EyeOutlined /> View
                </Link>
            </Menu.Item> */}
            <Menu.Item key="1" onClick={() => openEditModal('Edit', subcontrol)}>
                <EditOutlined /> Edit
            </Menu.Item>
            <Menu.Item key="2" style={{ color: 'red' }} onClick={() => deleteSubcontrol(subcontrol?.id)}>
                <DeleteOutlined /> Delete
            </Menu.Item>
        </Menu>
    );
    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <MoreOutlined />
        </Dropdown>
    );
};

export default SubcontrolOperations;
