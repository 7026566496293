import React from 'react';
//core components
import { Box, List, ListItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AppViewModal from 'components/new_components/AppViewModal';
import AppTag from 'components/new_components/AppTags';

//translations
import { useTranslation } from 'react-i18next';

const EmployeeViewModal = (props) => {
    //props
    const { open, handleClose, title, width, icon, payload } = props;
    //theme
    const theme = useTheme();

    //translation
    const { t } = useTranslation('inventory');

    return (
        <AppViewModal open={open} handleClose={handleClose} title={title} width={width} icon={icon}>
            <Box sx={{ marginTop: '1rem' }}>
                <List>
                    <ListItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${theme.palette.gray[300]}`,
                            py: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                            {t('viewEmployeeModal.fullName')}:
                        </Typography>
                        <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                            {`${payload?.first_name} ${payload?.last_name}`}
                        </Typography>
                    </ListItem>
                    <ListItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${theme.palette.gray[300]}`,
                            py: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                            {t('viewEmployeeModal.email')}:
                        </Typography>
                        <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                            {payload?.email}
                        </Typography>
                    </ListItem>
                    <ListItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${theme.palette.gray[300]}`,
                            py: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                            {t('viewEmployeeModal.phoneNumber')}:
                        </Typography>
                        <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                            {payload?.phone}
                        </Typography>
                    </ListItem>
                    <ListItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${theme.palette.gray[300]}`,
                            py: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                            {t('viewEmployeeModal.role')}:
                        </Typography>
                        <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                            {payload?.position}
                        </Typography>
                    </ListItem>
                    <ListItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${theme.palette.gray[300]}`,
                            py: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                            {t('viewEmployeeModal.jobDescription')}:
                        </Typography>
                        <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                            {payload?.job_description}
                        </Typography>
                    </ListItem>

                    <ListItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${theme.palette.gray[300]}`,
                            py: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                            {t('viewEmployeeModal.department')}:
                        </Typography>
                        <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                            {payload?.department}
                        </Typography>
                    </ListItem>
                    <ListItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${theme.palette.gray[300]}`,
                            py: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                            {t('viewEmployeeModal.location')}:
                        </Typography>
                        <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                            {payload?.address}
                        </Typography>
                    </ListItem>
                    <ListItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${theme.palette.gray[300]}`,
                            py: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                            {t('viewEmployeeModal.country')}:
                        </Typography>
                        <Typography
                            sx={{ fontWeight: '700', color: theme.palette.gray[900], textTransform: 'capitalize' }}
                        >
                            {payload?.country}
                        </Typography>
                    </ListItem>
                    <ListItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${theme.palette.gray[300]}`,
                            py: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                            {t('viewEmployeeModal.policy')}:
                        </Typography>
                        <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                            {payload?.policy ? (
                                <AppTag
                                    text={t('viewEmployeeModal.acknowledged')}
                                    type={payload?.policy}
                                    hasIcon={true}
                                />
                            ) : (
                                t('viewEmployeeModal.notAcknowledged')
                            )}
                        </Typography>
                    </ListItem>
                    <ListItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${theme.palette.gray[300]}`,
                            py: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                            {t('viewEmployeeModal.security')}:
                        </Typography>
                        <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                            {payload?.security_check ? (
                                <AppTag
                                    text={t('viewEmployeeModal.completed')}
                                    type={payload?.security_check}
                                    hasIcon={true}
                                />
                            ) : (
                                t('viewEmployeeModal.notAvailable')
                            )}
                        </Typography>
                    </ListItem>
                    <ListItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${theme.palette.gray[300]}`,
                            py: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                            {t('viewEmployeeModal.backgroundCheck')}:
                        </Typography>
                        <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                            {payload?.background_check ? (
                                <AppTag
                                    text={t('viewEmployeeModal.passed')}
                                    type={payload?.background_check}
                                    hasIcon={true}
                                />
                            ) : (
                                t('viewEmployeeModal.notAvailable')
                            )}
                        </Typography>
                    </ListItem>
                </List>
            </Box>
        </AppViewModal>
    );
};

export default EmployeeViewModal;
