// import HistoryTool from '../Utilities/HistoryTool';
import Profile from '../Utilities/Profile';
import AlignmentTool from './AlignmentTool';
import BackgroundTool from './BackgroundTool';
import BlockquoteTool from './BlockquoteTool';
import BoldTool from './BoldTool';
import ClearTool from './ClearTool';
import CodeBlockTool from './CodeBlockTool';
import CodeTool from './CodeTool';
import ColorTool from './ColorTool';
// import CommentTool from './CommentTool';
import DecreaseIndentTool from './DecreaseIndentTool';
import FontTool from './FontTool';
import ImageTool from './ImageTool';
import IncreaseIndentTool from './IncreaseIndentTool';
import ItalicTool from './ItalicTool';
import LinkToolbar from './LinkToolbar';
import OrderedListTool from './OrderedListTool';
import RedoTool from './RedoTool';
import SizeTool from './SizeTool';
import StrikeTool from './StrikeTool';
import TableTool from './TableTool';
// import TemplateTool from './TemplateTool';
import UnderlineTool from './UnderlineTool';
import UndoTool from './UndoTool';
import UnorderedListTool from './UnorderedListTool';

export default function CustomToolbar({
    editorRef,
    // templates,
    isEditable,
}) {
    return (
        <div
            className={`flex items-center justify-between !border-b !border-[#F1F5F9] bg-white !px-6 !py-1 fixed z-10 w-full !top-[69px] ${
                isEditable ? '' : 'pointer-events-none saturate-0 cursor-none'
            }`}
        >
            <div className="flex flex-1 items-center !gap-2 !py-2">
                {/* <TemplateTool editorRef={editorRef} templates={templates} />

                <div className="!w-px !h-7 bg-[#CBD5E1]" /> */}

                <FontTool editorRef={editorRef} />

                <div className="!w-px !h-7 bg-[#CBD5E1]" />

                <div className="flex items-center !gap-0.5">
                    <UndoTool editorRef={editorRef} />

                    <RedoTool editorRef={editorRef} />
                </div>

                <div className="!w-px !h-7 bg-[#CBD5E1]" />

                <SizeTool editorRef={editorRef} />

                <div className="!w-px !h-7 bg-[#CBD5E1]" />

                <AlignmentTool editorRef={editorRef} />

                <div className="!w-px !h-7 bg-[#CBD5E1]" />

                <ColorTool editorRef={editorRef} />

                <div className="!w-px !h-7 bg-[#CBD5E1]" />

                <div className="flex items-center !gap-0.5">
                    <BoldTool editorRef={editorRef} />

                    <ItalicTool editorRef={editorRef} />

                    <UnderlineTool editorRef={editorRef} />

                    <StrikeTool editorRef={editorRef} />

                    <CodeTool editorRef={editorRef} />

                    <BackgroundTool editorRef={editorRef} />
                </div>

                <div className="!w-px !h-7 bg-[#CBD5E1]" />

                <div className="flex items-center !gap-0.5">
                    <UnorderedListTool editorRef={editorRef} />

                    <OrderedListTool editorRef={editorRef} />

                    <DecreaseIndentTool editorRef={editorRef} />

                    <IncreaseIndentTool editorRef={editorRef} />
                </div>

                <div className="!w-px !h-7 bg-[#CBD5E1]" />

                <div className="flex items-center !gap-0.5">
                    <LinkToolbar editorRef={editorRef} />

                    <ImageTool editorRef={editorRef} />

                    <TableTool editorRef={editorRef} />

                    <CodeBlockTool editorRef={editorRef} />

                    <BlockquoteTool editorRef={editorRef} />

                    <ClearTool editorRef={editorRef} />
                </div>
            </div>

            <div className="flex items-center !gap-4 !py-1.5">
                <Profile />

                {/* <HistoryTool /> */}

                {/* <CommentTool editorRef={editorRef} /> */}
            </div>
        </div>
    );
}
