import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import BreadCrumb from 'components/Breadcrumb';
import AnnexModal from '../IsoActions/AnnexModal';

// antd components
import { Button, Col, Modal, Row, Table } from 'antd';

// icons
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';

// utils

//redux
import { connect } from 'react-redux';
import { CreateAnnexTwo, DeleteAnnexTwo, EditAnnexTwo, GetAllAnnexesTwo } from 'store/actions/adminActions';
import { numExtraction } from 'utils';
import AnnexOperations from './AnnexOperations';

// sub-components
// const { Title } = Typography;
const { confirm } = Modal;

const AnnexesView = (props) => {
    const { GetAllAnnexesTwo, CreateAnnexTwo, EditAnnexTwo, DeleteAnnexTwo, all_annexes } = props;
    // state
    const [loading, setLoading] = useState({
        content: false,
    });
    const [modal, setModal] = useState(null);
    const [oneReq, setOneReq] = useState({});

    // functions
    const openModal = (mode, data) => {
        setModal(mode);
        setOneReq(data);
    };
    const closeModal = () => {
        setModal(null);
    };

    const deleteAnnex = (id) => {
        confirm({
            title: 'Are you sure you want to delete this category? Note: Deleting this category means you will be deleting this category, the documents, audit questions and merchant data associated with this category',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteAnnexTwo(id);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    const extractNum = (title) => {
        const titleNum = numExtraction(title.split('.')[1]);
        return titleNum;
    };

    const columns = [
        {
            title: 'Title',
            render: (record) => record.title,
            sorter: (a, b) => extractNum(a.title) - extractNum(b.title),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Policy Statements',
            render: (record) => (
                <ul className="list-disc flex flex-col !gap-1 !mx-4">
                    {record.policy_statements?.length ? (
                        record.policy_statements?.map((policy, index) => <li key={index}>{policy}</li>)
                    ) : (
                        <li>No policy statement</li>
                    )}
                </ul>
            ),
        },
        {
            title: 'Actions',
            align: 'right',
            render: (record) => <AnnexOperations openEditModal={openModal} annex={record} deleteAnnex={deleteAnnex} />,
        },
    ];

    useEffect(() => {
        const getAllAnnex = async () => {
            setLoading(true);
            const res = await GetAllAnnexesTwo();
            if (res?.success) {
                setLoading(false);
            } else {
                setLoading(false);
                toast.error(res?.message);
            }
        };
        getAllAnnex();
    }, []);
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'ISO27001-2022', link: '/iso27001_2022' }, { label: 'Annexes' }]} />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openModal('Add')}
                    >
                        Add Annex
                    </Button>
                </Col>
            </Row>

            <section className="my-3">
                <Table
                    columns={columns}
                    dataSource={all_annexes}
                    loading={loading?.content}
                    rowKey={(annex) => annex.id}
                />
            </section>

            {/* <section className="my-3">
                {loading.content ? (
                    <Row justify="center">
                        <Spin size="large" />
                    </Row>
                ) : (
                    <>
                        {all_annexes && all_annexes?.length > 0 ? (
                            <Row wrap gutter={24}>
                                {all_annexes
                                    ?.sort((a, b) => extractNum(a.title) - extractNum(b.title))
                                    ?.map((annex, index) => (
                                        <Col
                                            xs={24}
                                            md={12}
                                            lg={8}
                                            xxl={6}
                                            key={annex.id}
                                            style={{ marginBottom: '1rem' }}
                                        >
                                            <Card
                                                title={`#${index}`}
                                                loading={false}
                                                actions={[
                                                    <Tooltip title="View Annex" color="blue">
                                                        <Link
                                                            key={index + 1}
                                                            to={{
                                                                pathname: '/admin/iso27001_2022/annex',
                                                                state: {
                                                                    annex,
                                                                },
                                                            }}
                                                        >
                                                            <EyeOutlined key="preview" />
                                                        </Link>
                                                    </Tooltip>,
                                                    <Tooltip title="Edit Annex" color="blue">
                                                        <EditOutlined
                                                            key="edit"
                                                            onClick={() => openModal('Edit', annex)}
                                                        />
                                                    </Tooltip>,
                                                    <Tooltip title="Delete Annex" color="blue">
                                                        <DeleteOutlined
                                                            key="delete"
                                                            onClick={() => deleteAnnex(annex.id, annex.category)}
                                                        />
                                                    </Tooltip>,
                                                ]}
                                            >
                                                <Title level={5} style={{ textAlign: 'center' }}>
                                                    {annex.title}
                                                </Title>
                                            </Card>
                                        </Col>
                                    ))}
                            </Row>
                        ) : (
                            <Row justify="center">
                                <Col>
                                    <Empty
                                        description={
                                            <div>
                                                <h3>No Annexes</h3>
                                                <span>Add a new Annex </span>
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </section> */}

            <AnnexModal
                open={modal}
                handleCancel={closeModal}
                one_req={oneReq}
                CreateAnnex={CreateAnnexTwo}
                EditAnnex={EditAnnexTwo}
            />
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        all_annexes: state?.adminReducers?.all_annexes_two,
    };
};
export default connect(mapStateToProps, {
    GetAllAnnexesTwo,
    CreateAnnexTwo,
    EditAnnexTwo,
    DeleteAnnexTwo,
})(AnnexesView);
