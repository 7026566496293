import { Image } from 'lucide-react';

export default function ImageTool({ editorRef }) {
    function handleImage() {
        if (editorRef.current) {
            const quill = editorRef.current;
            const selection = quill.getSelection();
            const blot = quill.getLeaf(selection?.index)?.[0];

            if (blot && blot?.parent?.domNode?.closest('table')) {
                return;
            }

            const input = document.createElement('input');

            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            input.click();

            input.onchange = async () => {
                const file = input.files?.[0];

                if (!file) return;

                const reader = new FileReader();

                reader.onload = () => {
                    const imageUrl = reader.result;
                    const range = quill.getSelection(true);
                    const scrollY = window.scrollY;

                    quill.insertEmbed(range.index, 'image', imageUrl);
                    quill.insertText(range.index + 1, '\n');
                    quill.setSelection(range.index + 2, 0);

                    window.scrollTo(0, scrollY);
                };

                reader.readAsDataURL(file);
            };
        }
    }

    return (
        <>
            <button
                className="!h-7 !w-7 !rounded bg-white text-[#1E293B] grid place-items-center transition-all hover:brightness-95"
                onClick={handleImage}
            >
                <Image className="!h-5 !w-5" />
            </button>
        </>
    );
}
