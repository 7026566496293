import { Box, Divider, Grid, IconButton, Stack, useTheme } from '@mui/material';

import { AddCircle, Clear, Info } from '@mui/icons-material';
import AppButton from 'components/new_components/AppButton';
import AppInput from 'components/new_components/AppInput';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import AppSelectInput from 'components/new_components/AppSelectInput';
import AppTooltip from 'components/new_components/AppTooltip';
import React, { useEffect, useMemo, useState } from 'react';

// redux
import { connect, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
    AnswerAuditCategory,
    CreateAuditRequest,
    GetAllAuditRequests,
    UpdateAuditAnswer,
    UpdateAuditRequest,
} from 'store/actions/auditActions';

const FormItem = (props) => {
    // hook
    const theme = useTheme();

    // props
    const {
        category,
        goPrev,
        AnswerAuditCategory,
        answers,
        UpdateAuditAnswer,
        navigateCategory,
        isLast,
        CreateAuditRequest,
        UpdateAuditRequest,
        GetAllAuditRequests,
        merchant_info,
        all_audit_requests,
        location,
    } = props;

    // state
    const [entries, setEntries] = useState({});
    const [loading, setLoading] = useState(false);
    const [formIsTouched, setFormIsTouched] = useState(false);

    //accessing values from the redux store
    const user_type = useSelector((state) => state?.authReducers?.user_type);

    // memos
    const questions = useMemo(() => {
        try {
            return JSON.parse(category.questions).sort((a, b) => a?.id - b?.id);
        } catch {
            return [];
        }
    }, [category]);
    const sections = useMemo(() => {
        try {
            return JSON.parse(category?.sections);
        } catch {
            return [''];
        }
    }, [category]);
    const answer = useMemo(() => {
        return answers.find((answer) => answer.category === category?.id);
    }, [answers, category?.id]);

    // functions
    const addNewEntry = (section) => {
        const sectionEntries = [...entries[section], {}];

        setEntries((current) => ({ ...current, [section]: sectionEntries }));
    };
    const removeEntry = (section, index) => {
        const sectionEntries = [...entries[section]];
        sectionEntries.splice(index, 1);
        setEntries((current) => ({ ...current, [section]: sectionEntries }));
    };
    const handleFieldChange = (section, index, name, value) => {
        const sectionEntries = [...entries[section]];
        const entryValue = { ...sectionEntries[index], [name]: value };
        sectionEntries[index] = entryValue;
        setEntries({ ...entries, [section]: sectionEntries });
        setFormIsTouched(true);
    };
    const handleTextChange = (e, section, index) => {
        const { name, value } = e.target;
        handleFieldChange(section, index, name, value);
    };

    const find_roc = all_audit_requests?.find((standard) => standard?.compliance === 'pcidss4.0');
    const handleAuditRequest = async () => {
        const create_payload = {
            compliance: 'pcidss4.0',
            status: 'draft',
            merchant: merchant_info?.id,
        };
        const update_payload = {
            compliance: 'pcidss4.0',
            status: 'inreview',
            merchant: merchant_info?.id,
        };
        const res = find_roc
            ? await UpdateAuditRequest(update_payload, find_roc?.id)
            : await CreateAuditRequest(create_payload);
        if (!res?.success) {
            setLoading(false);
            return toast.error("Something went wrong, couldn't save your asnwer");
        } else {
            setLoading(false);
            toast.success('Answers successfully saved!');
        }
    };

    const handleSubmitAndNext = async (e) => {
        e.preventDefault();
        setLoading(true);
        const entries_ = Object.values(entries)[0];
        if (formIsTouched) {
            const payload = {
                category: category?.id,
                answers:
                    category?.name === 'In-scope Networks'
                        ? entries
                        : category?.name === 'In-scope Business Functions'
                        ? entries
                        : entries_,
            };
            const res = answer ? await UpdateAuditAnswer(payload, answer?.id) : await AnswerAuditCategory(payload);
            if (!res?.success) {
                return toast.error("Something went wrong, couldn't save your asnwer");
            } else {
                if (!find_roc) {
                    handleAuditRequest();
                } else if (find_roc && isLast()) {
                    handleAuditRequest();
                } else {
                    toast.success('Answers successfully saved!');
                }
            }
        }
        await navigateCategory();
        setFormIsTouched(false);
        setLoading(false);
    };

    // useEffects
    useEffect(() => {
        if (answer) {
            const answers = answer?.answers;
            if (category?.name !== 'In-scope Networks' && category?.name !== 'In-scope Business Functions') {
                setEntries({ '': answers });
            } else {
                setEntries(answers);
            }
        } else {
            const entries = {};
            sections.forEach((section) => (entries[section] = [{}]));
            setEntries(entries);
        }
    }, [sections, answer]);

    useEffect(() => {
        const getAllAuditRequests = async () => {
            setLoading(true);
            const res = await GetAllAuditRequests();
            setLoading(false);
            if (!res?.success) {
                toast.error("Something went wrong, couldn't get audits.");
            }
        };

        getAllAuditRequests();
    }, []);

    return (
        <Stack component="form" onSubmit={handleSubmitAndNext} justifyContent="space-between" className="flex-1">
            <Box sx={{ pb: 3 }}>
                {category?.question_type === 'multiple' && (
                    <div className="flex items-center">
                        <Info color="primary" />
                        <span className="text-[#5E5E62] text-[14px] pl-2">
                            Use the <strong>Add field</strong> button to enter a new data.
                        </span>
                    </div>
                )}
                {/* sections loop */}
                {sections?.map((section, index) => {
                    const sectionEntries = entries[section];
                    return (
                        <React.Fragment key={index}>
                            <p className="text-[#46464A] font-medium">{section}</p>
                            {/* entries loop */}
                            <Box sx={{ px: 3, py: 1 }}>
                                {sectionEntries?.map((entry, entryIdx) => {
                                    return (
                                        <React.Fragment key={entryIdx}>
                                            <Grid container spacing={2}>
                                                {/* questions loop */}
                                                {questions.map((question, index) => {
                                                    return (
                                                        <FieldItem
                                                            onChange={handleTextChange}
                                                            key={index}
                                                            question={question}
                                                            entryIdx={entryIdx}
                                                            answers={entry}
                                                            section={section}
                                                        />
                                                    );
                                                })}
                                            </Grid>
                                            {/* add item and remove item buttons */}
                                            {category?.question_type === 'multiple' && (
                                                <Grid justifyContent="space-between" container className="my-3">
                                                    <Grid item>
                                                        {entryIdx + 1 === sectionEntries.length && (
                                                            <Box
                                                                component="button"
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    color: theme.palette.primary[900],
                                                                }}
                                                                onClick={() => addNewEntry(section)}
                                                            >
                                                                <AddCircle />
                                                                <span className="font-medium text-base">Add Field</span>
                                                            </Box>
                                                        )}
                                                    </Grid>
                                                    {sectionEntries.length > 1 && (
                                                        <Grid item>
                                                            <Box
                                                                component="button"
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    color: theme.palette.primary[900],
                                                                }}
                                                                onClick={() => removeEntry(section, entryIdx)}
                                                            >
                                                                <Clear />
                                                                <span className="font-medium text-base">
                                                                    Remove Field
                                                                </span>
                                                            </Box>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            )}
                                            {entryIdx + 1 < sectionEntries?.length && <Divider />}
                                        </React.Fragment>
                                    );
                                })}
                            </Box>
                        </React.Fragment>
                    );
                })}
            </Box>
            <Stack direction="row" spacing={4} sx={{ px: 3 }}>
                <AppButton name="Previous" variant="outlined" color="primary" onClick={goPrev} />
                {user_type !== 'auditor' && (
                    <AppLoadingButton
                        text={
                            isLast()
                                ? location?.pathname !== '/merchant/audits/edit/pcidss4.0.1'
                                    ? 'Update'
                                    : 'Submit'
                                : 'Next'
                        }
                        variant="contained"
                        color="primary"
                        type="submit"
                        loading={loading}
                    />
                )}
            </Stack>
        </Stack>
    );
};

const FieldItem = (props) => {
    // hook
    const theme = useTheme();
    // props
    const { question, onChange, entryIdx, section, answers } = props;

    return (
        <Grid item xs={12} md={question?.fullWidth ? 12 : 6}>
            {question.type === 'text' ? (
                <AppInput
                    label={
                        <>
                            <span>{question?.name}</span>
                            {!!question.tooltip && (
                                <AppTooltip title={question?.tooltip} placement="top">
                                    <IconButton sx={{ p: 0 }}>
                                        <Info
                                            sx={{
                                                width: 16,
                                                height: 16,
                                                ml: 0.5,
                                                color: theme.palette.gray[500],
                                            }}
                                        />
                                    </IconButton>
                                </AppTooltip>
                            )}
                        </>
                    }
                    multiline
                    required={question?.required}
                    onChange={(e) => onChange(e, section, entryIdx)}
                    value={answers?.[question?.name] || ''}
                    name={question?.name}
                />
            ) : question?.type === 'dropdown' ? (
                <AppSelectInput
                    name={question?.name}
                    label={question?.name}
                    defaultValue="Select an option"
                    value={answers?.[question?.name] || ''}
                    required={question?.required}
                    onChange={(e) => onChange(e, section, entryIdx)}
                    options={question?.options}
                />
            ) : null}
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    all_audit_requests: state?.auditReducers?.all_audit_requests,
    merchant_info: state?.merchantReducers?.merchant_info,
});
export default connect(mapStateToProps, {
    GetAllAuditRequests,
    AnswerAuditCategory,
    UpdateAuditAnswer,
    UpdateAuditRequest,
    CreateAuditRequest,
})(FormItem);
