import { useEffect, useMemo, useRef, useState } from 'react';

import { Box, Stack, Typography, useTheme } from '@mui/material';
import AppInput from 'components/new_components/AppInput';
import { complianceSubroutes, generateBreadcrumbs, RouteMap } from './utils';
import { useHistory } from 'react-router-dom';
import { Search } from 'lucide-react';
import { useCallback } from 'react';

const SmartSearch = () => {
    const theme = useTheme();
    const [query, setQuery] = useState('');
    const [open, setOpen] = useState(false);

    const history = useHistory();

    const handleChange = (e) => {
        setQuery(e.target.value);
    };

    const handleFocus = () => {
        setOpen(true);
    };

    const handleLeave = () => {
        // if (!open)
        setOpen(false);
    };

    const filterRoutes = (routes, query) => {
        const text = query.toLowerCase();
        let matchingRoutes = [];

        routes.forEach((route) => {
            // check if the current route's name matches the query
            const routeMatches = route.name.toLowerCase().includes(text) || route.path.toLowerCase().includes(text);

            // add matching route to list
            if (routeMatches) {
                matchingRoutes.push(route);
            }

            // recursively filter subroutes and add to the flat list
            if (route.subroutes && route.subroutes.length > 0) {
                matchingRoutes = matchingRoutes.concat(filterRoutes(route.subroutes, query));
            }

            // check for actions related to the route
            if (route.features && route.features?.length > 0) {
                route.features?.forEach((feature) => {
                    if (feature.name.toLowerCase().includes(text) || route.path.toLowerCase().includes(text)) {
                        matchingRoutes.push({ ...feature, path: route.path, type: 'action' }); // add feature as a separate item
                    }
                });
            }
        });

        return matchingRoutes;
    };

    const filteredItems = useMemo(() => {
        if (!query) return [];
        const text = query?.toLowerCase();

        return filterRoutes(RouteMap, text)?.filter((item) => !item?.notExists);
    }, [RouteMap, query]);

    const routeAction = (route, action) => {
        const parent = route?.split('/')?.pop()?.toLowerCase();
        if (route && action) {
            history.push((route.includes('risk-assessment/settings') ? '' : '/merchant') + route, {
                modalType: action,
                parent,
            });
        }
    };

    const searchRef = useRef(null);
    // const complianceList = ['pcidss', 'pcidss4.0', 'soc2', 'pcislc', 'ndpr', ];
    const complianceList = complianceSubroutes?.map((el) => el?.key);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                handleLeave(); // close the searchbox & queries when clicked outside
            }
        };

        // add event listener when component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchRef]);

    const HighlightedText = useCallback(({ text, query }) => {
        if (!query) return <span>{text}</span>;

        const regex = new RegExp(`(${query})`, 'gi');
        return text.split(regex).map((part, index) =>
            part.toLowerCase() === query.toLowerCase() ? (
                <span key={index} className="font-normal">
                    {part}
                </span>
            ) : (
                <span className="font-bold" key={index}>
                    {part}
                </span>
            )
        );
    }, []);

    return (
        <Box sx={{ mr: 2, position: 'relative' }} ref={searchRef}>
            <Box aria-haspopup="true" aria-expanded={open && query ? 'true' : undefined}>
                <AppInput
                    id="searchbox"
                    value={query}
                    onChange={handleChange}
                    style={{ width: '300px', paddingLeft: '10px' }}
                    controlStyle={{ mt: 0 }}
                    onFocus={() => handleFocus()}
                    // onBlur={() => handleLeave()}
                    placeholder={open && query ? 'What are you looking for?' : 'Search for a sub menu or anything'}
                    startAdornment={<Search className="text-[#94A3B8] w-5 h-5 mr-1" />}
                    sx={{
                        color: '#64748B',
                    }}
                />
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    width: '400px',
                    top: '50px',
                    height: '200px',
                    zIndex: 1000,
                    display: open && query && filteredItems?.length ? 'block' : 'none',
                    // display: 'block',
                    borderRadius: '10px',
                    border: '1px solid #f1f5f9',
                    background: 'white',
                    boxShadow: '0px 4px 6px 0px #0B1A441A',
                    overflowY: 'auto',
                    py: 1.2,
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary[900] + '30',
                        borderRadius: '10px',
                    },
                }}
                className="custom-scroll"
            >
                <Stack>
                    {filteredItems?.length ? (
                        filteredItems?.map((item, key) => {
                            const breadcrumbs = generateBreadcrumbs(item?.path);

                            return (
                                <Box
                                    key={key}
                                    px={3}
                                    py={1}
                                    component={item?.type !== 'action' && !item?.notExists ? 'a' : 'div'}
                                    href={
                                        item?.type !== 'action' && item.path && !item?.notExists
                                            ? (item.path.includes('risk-assessment/settings') ? '' : '/merchant') +
                                              item?.path
                                            : undefined
                                    }
                                    sx={{
                                        // '&:hover': { background: '#E2E8F0' },
                                        cursor: item?.type === 'action' ? 'pointer' : '',
                                        textDecoration: 'none !important',
                                    }}
                                    onClick={() => {
                                        handleLeave();

                                        if (item?.type === 'action') {
                                            // e.stopPropagation();
                                            routeAction(item?.path, item?.key);
                                        }
                                    }}
                                >
                                    <Typography
                                        variant="h3"
                                        sx={{
                                            color: '#475569',
                                            mb: 0.5,
                                            fontSize: 15,
                                        }}
                                    >
                                        <HighlightedText text={item.name} query={query} />
                                    </Typography>
                                    <Stack
                                        flexDirection="row"
                                        gap={0.8}
                                        alignItems="center"
                                        justifyContent="start"
                                        flexWrap="wrap"
                                    >
                                        {breadcrumbs?.length
                                            ? breadcrumbs?.map((crumb, id) => {
                                                  return (
                                                      <>
                                                          <p
                                                              key={crumb.name}
                                                              style={{
                                                                  color: '#475569',
                                                                  textDecoration: 'none',
                                                                  textTransform: complianceList.includes(
                                                                      crumb?.name?.toLowerCase()
                                                                  )
                                                                      ? 'uppercase'
                                                                      : 'capitalize',
                                                                  fontSize: 13.5,
                                                              }}
                                                          >
                                                              {crumb?.name?.replaceAll('_', ' ').replaceAll('-', ' ')}
                                                          </p>

                                                          {id !== breadcrumbs?.length - 1 ? (
                                                              <span className="text-[#475569]">{'->'}</span>
                                                          ) : null}
                                                      </>
                                                  );
                                              })
                                            : null}
                                        {item?.type === 'action' ? (
                                            <>
                                                <span className="text-[#475569]">{'->'}</span>
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        color: '#475569',
                                                        textTransform: 'capitalize',
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    {item?.name}
                                                </Typography>
                                            </>
                                        ) : null}
                                    </Stack>
                                </Box>
                            );
                        })
                    ) : (
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography sx={{ color: theme.palette.neutral[500], mt: 4 }}>
                                Uhh. Nothing to see here
                            </Typography>
                        </Box>
                    )}
                </Stack>
            </Box>
        </Box>
    );
};

export default SmartSearch;
