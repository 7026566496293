// core components
import Controls from './components/Controls';
// import SubControls from './components/SubControls';

// redux
import { connect } from 'react-redux';
// import { GetDocumentsBySubComplianceManagement } from 'store/actions/adminActions';

const Documents = (props) => {
    const {
        loading: loadingControl,
        // getControls,
        openTaskAssignModal,
        openExemptModal,
        openRevertModal,
        all_compliance_assigned_tasks,
        // view,
        // setView,
        isExceptionTab,
    } = props;
    // state
    // const [selectedControl, setSelectedControl] = useState(null);
    // const [loading, setLoading] = useState({ content: false });
    // const dispatch = useDispatch();
    // const documents_by_management = useSelector(
    //     (state) => state?.adminReducers?.all_admin_documents_by_compliance_management
    // );
    // const merchant_documents = useSelector((state) => state?.complianceReducers?.all_merchant_documents);

    // functions
    // logic functions
    // const handleViewChange = (controlID) => {
    //     setView(1);
    //     setSelectedControl(controlID);
    // };
    // const goBack = () => {
    //     setView(0);
    //     setSelectedControl(null);
    // };
    // async functions
    // const getSubcontrolDocuments = async (subcontrolId) => {
    //     setLoading((current) => ({ ...current, content: true }));
    //     const res = await dispatch(GetDocumentsBySubComplianceManagement(subcontrolId));
    //     setLoading((current) => ({ ...current, content: false }));
    //     if (!res?.success) {
    //         toast.error('Something went wrong!');
    //     }
    // };

    // const handleDocumentExempted = (subcontrol) => {
    //     getControls();
    //     getSubcontrolDocuments(subcontrol);
    // };

    // return view === 0 ? (
    return (
        <Controls
            // onViewChange={handleViewChange}
            loading={loadingControl}
            // resource="doc"
            openTaskAssignModal={openTaskAssignModal}
            openExemptModal={openExemptModal}
            openRevertModal={openRevertModal}
            all_compliance_assigned_tasks={all_compliance_assigned_tasks}
            isExceptionTab={isExceptionTab}
        />
        // ) : (
        //     <SubControls
        //         controlId={selectedControl}
        //         goBack={goBack}
        //         resource="doc"
        //         loading={loading.content}
        //         onSubcontrolChange={getSubcontrolDocuments}
        //         merchantResources={merchant_documents}
        //         subcontrolResources={documents_by_management}
        //         handleResourceExempted={handleDocumentExempted}
        //         openTaskAssignModal={openTaskAssignModal}
        //         all_compliance_assigned_tasks={all_compliance_assigned_tasks}
        //     />
    );
};
const mapStateToProps = (state) => {
    return {
        all_compliance_assigned_tasks: state?.generalReducers?.user_info?.compliance_assigned_tasks,
    };
};
export default connect(mapStateToProps, {})(Documents);
