export function extractDocName(url) {
    return url?.split('/')?.pop()?.split('?')?.shift();
}

export function shortenDocumentTitle(text) {
    const ext = text?.split('.')?.pop();

    if (text?.length > 50) {
        return `${text?.substring(0, 47)}...${ext}`;
    }

    return text;
}
