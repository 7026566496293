import React, { useEffect, useState } from 'react';
import { Typography, IconButton, Stack, Box } from '@mui/material';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { toast } from 'react-toastify';

import CloseIcon from '@mui/icons-material/Close';

import StepOne from './StepOne';
import StepTwo from './StepTwo';

//redux
import { useDispatch } from 'react-redux';
import { OverwriteMerchantOnboardingVideo } from 'store/actions/onboardingActions';
import { OverwriteMerchantOnboardingPolicy } from 'store/actions/onboardingActions';

const PolicyOverwriteModal = (props) => {
    const { open, handleClose, data, refetch, type, existingFiles } = props;
    const [img, setImg] = useState('');
    const [newDocument, setNewDocument] = useState('');
    const [notify, setNotify] = useState(null);
    const [notifyList, setNotifyList] = useState([]);
    const [error, setError] = useState(false);
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    const [isNotifyIconClicked, setIsNotifyIconClicked] = useState(false);
    const dispatch = useDispatch();
    const [existing, setExisting] = useState(null);

    const closeModal = () => {
        setTimeout(() => {
            setNewDocument('');
            setStep(1);
            setNotify(null);
            setNotifyList([]);
            setError(false);
            document.getElementById('policy_name').value = '';
        }, 100);
        handleClose();
    };
    const toNextStep = () => setStep((current) => current + 1);
    const toPreviousStep = () => setStep((current) => current - 1);
    const moveToStep = (activeStep) => setStep(activeStep);

    //step one
    // UPLOAD IMAGE ONCHANGE
    const uploadImage = async (event) => {
        setImageLoading(true);
        const input = event.target;
        const file = input.files?.item(0);
        const base64 = await convertedBase64(file);
        setNewDocument(file);
        setError(false);
        setImg(base64);
        setImageLoading(false);
    };
    // CONVERT IMAGE TO BASE 64
    const convertedBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const filReader = new FileReader();
            filReader.readAsDataURL(file);
            filReader.onload = () => {
                resolve(filReader.result);
            };
            filReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const dragOver = (e) => {
        e.preventDefault();
    };

    const dragDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && file.type.includes('image')) {
            const reader = new FileReader();
            reader.onload = () => {
                setImg(reader.result);
            };
            reader.readAsDataURL(file);
            convertedBase64(file).then(() => {});
        } else {
            // enqueueSnackbar('Invalid Data Dropped', { variant: 'error' });
        }
    };

    //step two
    const notifyRadioChange = (e) => {
        setNotify(e.target.value);
        setError(false);
    };
    const handleNotifyListSelect = (_, option) => {
        setNotifyList((prevList) => [...prevList, option]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (notify === null) {
            setError(true);
            return;
        }

        if (notify === 'true' && !notifyList?.length) {
            toast.info('Please click on change to select departments');
            return;
        }
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('tag', data?.tag);
            formData.append('name', data?.name);
            if (notify === true || notify === 'true') {
                formData.append('notify', 'True');
                formData.append('notify_list', JSON.stringify(notifyList));
            }
            if (existing != null) {
                formData.append('existing_policy_id', existing?.value);
            } else {
                formData.append('file', newDocument);
            }
            const res =
                type === 'video'
                    ? await dispatch(OverwriteMerchantOnboardingVideo(formData, data?.id))
                    : await dispatch(OverwriteMerchantOnboardingPolicy(formData, data?.id));

            if (res?.success) {
                toast.success('Onboarding policy updated successfully');
                refetch();
                closeModal();
                setNewDocument(null);
            } else {
                toast.error(res?.message || 'An error occurred');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Something went wrong. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (open && isNotifyIconClicked) {
            setStep(2);
        }
    }, [isNotifyIconClicked, open]);

    return (
        <AppCenteredModal
            open={open}
            handleClose={closeModal}
            titleStyle={{ mx: 3, px: 0 }}
            sx={{ maxHeight: '95dvh', height: 'fit-content!important', p: 0, borderRadius: '4px' }}
            width={step == 1 ? 511 : 572}
        >
            <Box sx={{ mt: 1, mb: 2, height: 'fit-content', p: 0 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography sx={{ fontSize: 18, fontWeight: 600, color: '#202D66' }}>
                        {step === 1
                            ? type === 'video'
                                ? 'Overwrite Video'
                                : 'Overwrite Policy'
                            : step === 2
                            ? 'Notify employees about these changes?'
                            : step === 2 && isNotifyIconClicked
                            ? 'Notify employees about these changes?'
                            : null}
                    </Typography>
                    <IconButton
                        color="disabled"
                        onClick={closeModal}
                        sx={{
                            border: '1px solid #E2E8F0',
                            borderRadius: 30,
                            width: 30,
                            height: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        <CloseIcon sx={{ width: 20, height: 20 }} />
                    </IconButton>
                </Stack>
                {step === 1 ? (
                    <StepOne
                        dragDrop={dragDrop}
                        dragOver={dragOver}
                        uploadImage={uploadImage}
                        imageLoading={imageLoading}
                        img={img}
                        newDocument={newDocument}
                        toNextStep={toNextStep}
                        moveToStep={moveToStep}
                        step={step}
                        data={data}
                        type={type}
                        existing={existing}
                        setExisting={setExisting}
                        existingFiles={existingFiles}
                    />
                ) : (
                    <StepTwo
                        handleRadioChange={notifyRadioChange}
                        value={notify}
                        error={error}
                        handleSubmit={handleSubmit}
                        toNextStep={toNextStep}
                        moveToStep={moveToStep}
                        toPreviousStep={toPreviousStep}
                        step={step}
                        handleNotifyListSelect={handleNotifyListSelect}
                        notifyList={notifyList}
                        setNotifyList={setNotifyList}
                        loading={loading}
                        isNotifyIconClicked={isNotifyIconClicked}
                        setIsNotifyIconClicked={setIsNotifyIconClicked}
                    />
                )}
            </Box>
        </AppCenteredModal>
    );
};
export default PolicyOverwriteModal;
