/* eslint-disable no-unused-vars */

import { Dialog, Drawer, Menu, MenuItem, Slide } from '@mui/material';
import { Loader2 } from 'lucide-react';
import moment from 'moment';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AddDocumentReminder, AnalyzeDocument, UploadMerchantDocument } from 'store/actions/complianceActions';
import { GetAllCompanyEmployees } from 'store/actions/generalActions';
import LoadingOverlay from '../LoadingOverlay';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

export default function PolicyReviewDrawer({
    isOpen,
    setIsOpen,
    handleCallback,
    document,
    documentNotes,
    standard,
    existingPolicyId,
    useExistingPolicy,
    currentRequirement,
    filename,
    delta,
}) {
    const [revisionHistory, setRevisionHistory] = useState({
        currentVersion: 'V1.0',
        dateVersion: new Date().toISOString().split('T')[0],
        author: null,
        comment: '',
    });
    const [documentReview, setDocumentReview] = useState({
        count: 30,
        type: 'days',
    });
    const [distribution, setDistribution] = useState({
        employees: [],
        sendToAll: false,
    });
    const [reviewer, setReviewer] = useState(null);
    const [error, setError] = useState({
        currentVersion: '',
        author: '',
        comment: '',
        employees: '',
        reviewer: '',
    });
    const [authorAnchorEl, setAuthorAnchorEl] = useState(null);
    const [typeAnchorEl, setTypeAnchorEl] = useState(null);
    const [employeesAnchorEl, setEmployeesAnchorEl] = useState(null);
    const [reviewerAnchorEl, setReviewerAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [isPolicyReviewDialogOpen, setIsPolicyReviewDialogOpen] = useState(false);
    const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);
    const [isAnalysing, setIsAnalysing] = useState(false);

    const dispatch = useDispatch();

    const raw_all_employees = useSelector((state) => state?.generalReducers?.all_employees);
    const user_id = useSelector((state) => state?.authReducers?.user_id);
    const user_info = useSelector((state) => state?.authReducers?.user_info);
    const employee_id = useSelector((state) => state?.authReducers?.user_info?.employee?.id);
    const all_merchant_documents = useSelector((state) => state?.complianceReducers?.all_merchant_documents);

    const userDetails = useMemo(
        () => ({
            id: user_info?.organization?.owner_detail?.id,
            user_detail: {
                id: user_info?.organization?.owner_detail?.id,
                first_name: user_info?.organization?.owner_detail?.first_name,
                last_name: user_info?.organization?.owner_detail?.last_name,
                email: user_info?.organization?.owner_detail?.email,
            },
        }),
        [user_info]
    );

    const allDocuments = useMemo(
        () => all_merchant_documents?.map((doc) => ({ ...doc, default: doc?.document })),
        [all_merchant_documents]
    );

    const all_employees = useMemo(
        () =>
            raw_all_employees
                ?.filter((employee) => employee?.id !== employee_id)
                ?.map((employee) => ({
                    ...employee,
                    name: employee?.user_detail?.first_name
                        ? `${employee?.user_detail?.first_name} ${employee?.user_detail?.last_name}`
                        : employee?.user_detail?.email,
                }))
                ?.filter((employee) => employee?.name?.toLowerCase().includes(search.toLowerCase())),
        [raw_all_employees, search]
    );

    const all_authors = useMemo(
        () =>
            [...raw_all_employees, userDetails]
                ?.map((employee) => ({
                    ...employee,
                    name: employee?.user_detail?.first_name
                        ? `${employee?.user_detail?.first_name} ${employee?.user_detail?.last_name}`
                        : employee?.user_detail?.email,
                }))
                ?.filter((employee) => employee?.name?.toLowerCase().includes(search.toLowerCase())),
        [raw_all_employees, userDetails, search]
    );

    const currentDocument = useMemo(
        () => (existingPolicyId ? allDocuments?.find((doc) => doc?.id === existingPolicyId) : null),
        [allDocuments, existingPolicyId]
    );

    function calculateReviewDate() {
        return moment(revisionHistory.dateVersion).add(documentReview.count, documentReview.type).format('DD/MM/YYYY');
    }

    function validateForm() {
        const errors = {
            currentVersion: '',
            author: '',
            comment: '',
            employees: '',
            reviewer: '',
        };

        let isValid = true;

        if (!revisionHistory.currentVersion) {
            errors.currentVersion = 'Current version is required';
            isValid = false;
        } else if (
            existingPolicyId &&
            currentDocument &&
            revisionHistory.currentVersion === currentDocument?.version_no
        ) {
            errors.currentVersion = 'Current version must be different from the existing version';
            isValid = false;
        }

        if (!revisionHistory.author) {
            errors.author = 'Author is required';
            isValid = false;
        }

        if (!revisionHistory.comment) {
            errors.comment = 'Comment is required';
            isValid = false;
        }

        if (!distribution.employees.length && !distribution.sendToAll) {
            errors.employees = 'Employees is required';
            isValid = false;
        }

        if (!reviewer) {
            errors.reviewer = 'Reviewer is required';
            isValid = false;
        }

        setError(errors);

        return isValid;
    }

    async function runNewDocumentAnalysis() {
        try {
            setIsAnalysing(true);
            setShowLoadingOverlay(true);

            const nativeFormData = new FormData();
            nativeFormData.append('file', document);
            nativeFormData.append('standard', standard);
            nativeFormData.append('id', currentRequirement?.id);

            const response = await dispatch(AnalyzeDocument(nativeFormData));
            if (!response.success) throw new Error(response?.message);

            return {
                success: true,
                data: response.data,
            };
        } catch (error) {
            return {
                success: false,
                message: error?.message || 'Failed to analyze document',
            };
        } finally {
            setIsAnalysing(false);
        }
    }

    async function handleSubmit() {
        if (!validateForm()) return;

        try {
            setIsLoading(true);

            let newPolicyInformaton = {};

            const analysisResponse = await runNewDocumentAnalysis();
            if (!analysisResponse.success) throw new Error(analysisResponse?.message);

            newPolicyInformaton = analysisResponse?.data;

            const reminderNativeFormData = new FormData();
            reminderNativeFormData.append('tags', 'compliance');
            reminderNativeFormData.append(
                'every_scheduled_date',
                JSON.stringify({
                    frequency: documentReview.type,
                    interval: documentReview.count,
                })
            );

            const reminderResponse = await dispatch(AddDocumentReminder(reminderNativeFormData));
            if (!reminderResponse.success) throw new Error(reminderResponse?.message);

            const nativeFormData = new FormData();
            existingPolicyId && nativeFormData.append('existing_policy', existingPolicyId);
            nativeFormData.append('reminder_ids', reminderResponse?.data?.id);
            useExistingPolicy && nativeFormData.append('use_existing_policy', 'true');
            nativeFormData.append('document', document);
            nativeFormData.append('filename', filename);
            nativeFormData.append('notify_all_employees', `${distribution.sendToAll}`);
            !distribution.sendToAll &&
                nativeFormData.append('employees_id', JSON.stringify(distribution.employees.map((emp) => emp.id)));
            nativeFormData.append('tag', standard);
            nativeFormData.append('policy_status', 'awaiting_review');
            nativeFormData.append('is_policy_editor', 'true');
            nativeFormData.append('change_summary', revisionHistory.comment);
            nativeFormData.append('version_no', revisionHistory.currentVersion);
            nativeFormData.append('notes', documentNotes);
            nativeFormData.append('policy_info', JSON.stringify(newPolicyInformaton));
            delta && nativeFormData.append('operations', JSON.stringify(delta));
            nativeFormData.append('reviewer', reviewer?.user_detail?.id);
            nativeFormData.append('requested_by', user_id);
            nativeFormData.append('author', revisionHistory.author?.user_detail?.id);

            const response = await dispatch(UploadMerchantDocument(nativeFormData));
            if (!response.success) throw new Error(response?.message);

            setIsPolicyReviewDialogOpen(true);
        } catch (error) {
            toast.error(error?.message || 'Failed to submit document for review');
        } finally {
            setIsLoading(false);
        }
    }

    function handleClose() {
        setIsOpen(false);

        setRevisionHistory({
            currentVersion: 'V1.0',
            dateVersion: new Date(),
            author: null,
            comment: '',
        });

        setDocumentReview({
            count: 30,
            type: 'days',
        });

        setDistribution({
            employees: [],
            sendToAll: false,
        });

        setReviewer(null);

        setError({
            currentVersion: '',
            author: '',
            comment: '',
            employees: '',
            reviewer: '',
        });
    }

    useEffect(() => {
        if (isOpen && existingPolicyId) {
            if (!currentDocument) return;

            setRevisionHistory((prev) => ({
                ...prev,
                currentVersion: currentDocument?.version_no ?? 'V1.0',
                author: all_authors?.find((author) => author?.user_detail?.id === currentDocument?.author),
                comment: currentDocument?.change_summary,
            }));

            setDocumentReview((prev) => ({
                ...prev,
                count: currentDocument?.reminder_date?.[0]?.interval ?? 30,
                type: currentDocument?.reminder_date?.[0]?.frequency ?? 'days',
            }));

            setDistribution((prev) => ({
                ...prev,
                employees: currentDocument?.employees_id?.map((emp) =>
                    all_employees?.find((employee) => employee?.id === emp)
                ),
                sendToAll: currentDocument?.notify_all_employees,
            }));

            setReviewer(all_employees?.find((employee) => employee?.user_detail?.id === currentDocument?.reviewer));
        }
    }, [isOpen, existingPolicyId, currentDocument, all_authors, all_employees]);

    useEffect(() => {
        const getAllCompanyEmployees = async () => {
            setIsLoading(true);
            const res = await dispatch(GetAllCompanyEmployees());
            if (res?.success) {
                setIsLoading(false);
            } else {
                setIsLoading(false);
                toast.error(res?.message);
            }
        };
        getAllCompanyEmployees();
    }, []);

    return (
        <>
            <Drawer
                open={isOpen}
                anchor="right"
                TransitionComponent={Transition}
                transitionDuration={1000}
                keepMounted
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                        minWidth: 370,
                        width: 525,
                        flex: 1,
                        overflow: 'auto',
                        zIndex: 9999,
                    },
                }}
                scroll="paper"
            >
                <div className="flex flex-col h-full">
                    <div className="border-b border-[#F1F5F9] !px-2 !py-4 flex items-center gap-2">
                        <img
                            src="/img/automated-scan/arrow-left-icon.svg"
                            alt="arrow-left-icon"
                            onClick={handleClose}
                            className="cursor-pointer !pl-2 object-contain"
                        />

                        <h3 className="!pl-2 text-[#202D66] capitalize font-semibold text-base">Control page</h3>
                    </div>

                    <div className="flex-1 overflow-y-auto custom-scroll !py-4 !px-6 flex flex-col !gap-4">
                        <div className="bg-[#F8FAFC] !border !border-[#F0F0FA] !rounded flex !gap-1 !px-4 !py-2 items-start">
                            <img src="/img/frameworks/lightbulb.svg" alt="Bulb Icon" className="!mt-0.5 !w-6 !h-6" />

                            <p className="text-[#64748B] font-normal text-[13px] leading-5">
                                Control page helps keeps track of policy versioning, distributions, review date,
                                reviewer and approver.
                            </p>
                        </div>

                        <div className="flex flex-col !gap-4 !border-t !border-[#CBD5E1] !py-2">
                            <p className="text-[#395BA9] font-semibold text-[13px]">Revision History</p>

                            <div className="flex flex-col !gap-1">
                                <div className="flex !gap-4">
                                    <div className="flex-1 flex flex-col !gap-2">
                                        <p className="font-medium text-[13px] text-[#64748B]">Current version</p>

                                        <input
                                            type="text"
                                            value={revisionHistory.currentVersion}
                                            onChange={(event) => {
                                                setRevisionHistory((prev) => ({
                                                    ...prev,
                                                    currentVersion: event.target.value,
                                                }));

                                                if (error.currentVersion) {
                                                    setError((prev) => ({ ...prev, currentVersion: '' }));
                                                }
                                            }}
                                            placeholder="Enter current version"
                                            className="!w-full !h-9 !p-2 !border !border-[#E2E8F0] text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                        />

                                        {error.currentVersion && (
                                            <span className="text-[10px] !-mt-1 text-[#FF0000] font-normal">
                                                {error.currentVersion}
                                            </span>
                                        )}
                                    </div>

                                    <div className="flex-1 flex flex-col !gap-2">
                                        <p className="font-medium text-[13px] text-[#64748B]">Date version</p>

                                        <input
                                            type="date"
                                            value={revisionHistory.dateVersion}
                                            onChange={(event) => {
                                                setRevisionHistory((prev) => ({
                                                    ...prev,
                                                    dateVersion: event.target.value,
                                                }));
                                            }}
                                            placeholder="Enter date version"
                                            className="!w-full !h-9 !p-2 !border !border-[#E2E8F0] text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75 disabled:pointer-events-none disabled:cursor-not-allowed"
                                            disabled
                                        />
                                    </div>
                                </div>

                                {existingPolicyId && currentDocument?.version_no && (
                                    <p className="text-[#395BA9] text-xs">
                                        Previous version was{' '}
                                        <span className="font-semibold">{currentDocument?.version_no}</span> & created
                                        on{' '}
                                        <span className="font-semibold">
                                            {moment(currentDocument?.date_created).format('DD/MM/YYYY')}
                                        </span>
                                    </p>
                                )}
                            </div>

                            <div className="flex flex-col !gap-2">
                                <p className="font-medium text-[13px] text-[#64748B]">Name of author</p>

                                <div>
                                    <button
                                        aria-controls={authorAnchorEl ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={authorAnchorEl ? 'true' : undefined}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setAuthorAnchorEl(event.currentTarget);
                                        }}
                                        className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors !h-9"
                                    >
                                        <p
                                            className={`font-normal text-sm ${
                                                revisionHistory.author
                                                    ? 'capitalize text-[hsl(215,20%,40%)]'
                                                    : 'text-[#94A3B8]'
                                            }`}
                                        >
                                            {revisionHistory.author ? revisionHistory.author?.name : 'Select an author'}
                                        </p>

                                        <img
                                            src="/img/frameworks/chevron-down.svg"
                                            alt="Arrow Down Icon"
                                            className="object-contain"
                                        />
                                    </button>

                                    <Menu
                                        anchorEl={authorAnchorEl}
                                        open={Boolean(authorAnchorEl)}
                                        onClose={() => setAuthorAnchorEl(null)}
                                        sx={{
                                            '& .MuiPaper-root': {
                                                borderRadius: '4px',
                                                boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                            },
                                            '& .MuiList-root': {
                                                padding: 0,
                                                width: '477px',
                                            },
                                        }}
                                    >
                                        <div className="min-h-[200px] max-h-[300px] overflow-auto custom-scroll">
                                            <div className="mx-2 mt-4">
                                                <div
                                                    className="relative"
                                                    onKeyDown={(event) => {
                                                        event.stopPropagation();
                                                    }}
                                                >
                                                    <input
                                                        type="text"
                                                        placeholder="Search"
                                                        className="w-full !py-2 !pr-9 !pl-2 !border !border-[#E2E8F0] h-9 text-[13px] font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                                        value={search}
                                                        onChange={(event) => {
                                                            event.stopPropagation();
                                                            setSearch(event.target.value);
                                                        }}
                                                    />

                                                    <img
                                                        src="/img/frameworks/search.svg"
                                                        alt="Search Icon"
                                                        className="absolute object-contain top-1/2 -translate-y-1/2 !right-2"
                                                    />
                                                </div>
                                            </div>

                                            <div className="!p-2 flex flex-col !w-full">
                                                {all_authors?.length ? (
                                                    all_authors?.map((employee, index) => (
                                                        <MenuItem
                                                            key={index}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                setRevisionHistory((prev) => ({
                                                                    ...prev,
                                                                    author: employee,
                                                                }));
                                                                setError((prev) => ({ ...prev, author: '' }));
                                                                setAuthorAnchorEl(null);
                                                                setSearch('');
                                                            }}
                                                            sx={{
                                                                color: '#64748B',
                                                                fontWeight: 500,
                                                                fontSize: '13px',
                                                                textTransform: 'capitalize',
                                                                padding: '8px 8px',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            {employee?.name}
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    <p className="flex items-center justify-center !my-4 text-sm text-[#64748B] font-medium">
                                                        User not found
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </Menu>
                                </div>

                                {error.author && (
                                    <span className="text-[10px] !-mt-1 text-[#FF0000] font-normal">
                                        {error.author}
                                    </span>
                                )}
                            </div>

                            <div className="flex flex-col !gap-2">
                                <p className="font-medium text-[13px] text-[#64748B]">Summary of change</p>

                                <textarea
                                    className="!w-full !p-2 !border !border-[#E2E8F0] !min-h-[76px] text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                    placeholder="Comment"
                                    value={revisionHistory.comment}
                                    onChange={(event) => {
                                        setRevisionHistory((prev) => ({
                                            ...prev,
                                            comment: event.target.value,
                                        }));

                                        if (error.comment) {
                                            setError((prev) => ({ ...prev, comment: '' }));
                                        }
                                    }}
                                />

                                {error.comment && (
                                    <span className="text-[10px] !-mt-1 text-[#FF0000] font-normal">
                                        {error.comment}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="flex flex-col !gap-4 !border-t !border-[#CBD5E1] !py-2">
                            <p className="text-[#395BA9] font-semibold text-[13px]">Document review</p>

                            <div className="flex flex-col !gap-2">
                                <p className="font-medium text-[13px] text-[#64748B]">
                                    Choose date of next schedule review
                                </p>

                                <div className="flex !gap-2">
                                    <button className="flex-1 !border !border-[#E2E8F0] flex items-center justify-start !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors !h-9">
                                        <p className="font-normal text-sm capitalize text-[hsl(215,20%,40%)]">Every</p>
                                    </button>

                                    <input
                                        type="number"
                                        min={1}
                                        max={999}
                                        value={documentReview.count}
                                        onChange={(event) => {
                                            if (+event.target.value < 1) return;
                                            setDocumentReview((prev) => ({
                                                ...prev,
                                                count: event.target.value,
                                            }));
                                        }}
                                        className="flex-1 !w-full !h-9 !p-2 !border !border-[#E2E8F0] text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                    />

                                    <div className="flex-1">
                                        <button
                                            aria-controls={typeAnchorEl ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={typeAnchorEl ? 'true' : undefined}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setTypeAnchorEl(event.currentTarget);
                                            }}
                                            className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors !h-9"
                                        >
                                            <p className="font-normal text-sm capitalize text-[hsl(215,20%,40%)]">
                                                {documentReview.type}
                                            </p>

                                            <img
                                                src="/img/frameworks/chevron-down.svg"
                                                alt="Arrow Down Icon"
                                                className="object-contain"
                                            />
                                        </button>

                                        <Menu
                                            anchorEl={typeAnchorEl}
                                            open={Boolean(typeAnchorEl)}
                                            onClose={(event) => {
                                                event.stopPropagation();
                                                setTypeAnchorEl(null);
                                            }}
                                            sx={{
                                                '& .MuiPaper-root': {
                                                    borderRadius: '4px',
                                                    boxShadow:
                                                        '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                                                },
                                                '& .MuiList-root': {
                                                    padding: '0 0',
                                                },
                                            }}
                                        >
                                            {['hours', 'days', 'weeks', 'months', 'years'].map((type, index) => (
                                                <MenuItem
                                                    key={index}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setTypeAnchorEl(null);
                                                        setDocumentReview((prev) => ({
                                                            ...prev,
                                                            type,
                                                        }));
                                                    }}
                                                    sx={{
                                                        color: '#64748B',
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                        textTransform: 'capitalize',
                                                        padding: '8px 16px',
                                                        border: '1px solid #F1F5F9',
                                                    }}
                                                >
                                                    {type}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </div>
                                </div>

                                <p className="text-[#395BA9] text-xs">
                                    Next review on <span className="font-semibold">{calculateReviewDate()}</span>
                                </p>
                            </div>
                        </div>

                        <div className="flex flex-col !gap-4 !border-t !border-[#CBD5E1] !pt-2">
                            <p className="text-[#395BA9] font-semibold text-[13px]">
                                Distribution (communication for acceptance)
                            </p>

                            <div className="flex flex-col !gap-2">
                                <p className="text-[13px] text-[#64748B]">
                                    Auto-notify employee for policy acceptance once approved
                                </p>

                                <p className="font-medium text-[13px] text-[#64748B]">Select employees</p>

                                <div>
                                    <div
                                        className="!w-full flex flex-wrap !gap-2 !border !border-[#E2E8F0] !rounded-sm !px-2 !py-[5px] focus-within:ring-2 focus-within:ring-[#395BA9] focus-within:ring-opacity-75 cursor-pointer"
                                        aria-controls={employeesAnchorEl ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={employeesAnchorEl ? 'true' : undefined}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setEmployeesAnchorEl(event.currentTarget);
                                        }}
                                    >
                                        {distribution.employees?.length ? (
                                            <>
                                                {distribution.employees?.map((employee, index) => (
                                                    <button
                                                        key={index}
                                                        className="flex items-center gap-2 !border !border-[#E2E8F0] bg-[#F1F5F9] !rounded !py-0 !px-2 hover:brightness-90 transition-all h-6"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            setDistribution((prev) => ({
                                                                ...prev,
                                                                employees: prev.employees.filter(
                                                                    (item) => item?.name !== employee?.name
                                                                ),
                                                            }));
                                                        }}
                                                    >
                                                        <p className="font-normal capitalize text-sm text-[#64748B]">
                                                            {employee?.name}
                                                        </p>

                                                        <img
                                                            src="/img/risk-assessment/x-close-small.svg"
                                                            alt="Delete Icon"
                                                            className="object-contain"
                                                        />
                                                    </button>
                                                ))}
                                            </>
                                        ) : (
                                            <span className="!w-auto focus-visible:outline-none !rounded-none !p-0 h-6 !border-none text-sm font-normal cursor-pointer self-center flex items-center text-[#94A3B8]">
                                                {distribution.employees?.length === 0 && 'Select employee(s)'}
                                            </span>
                                        )}
                                    </div>

                                    <Menu
                                        anchorEl={employeesAnchorEl}
                                        open={Boolean(employeesAnchorEl)}
                                        onClose={() => {
                                            setEmployeesAnchorEl(null);
                                        }}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        sx={{
                                            '& .MuiPaper-root': {
                                                borderRadius: '4px',
                                                boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                            },
                                            '& .MuiList-root': {
                                                padding: 0,
                                                width: '477px',
                                            },
                                        }}
                                    >
                                        <div className="min-h-[200px] max-h-[300px] overflow-auto custom-scroll">
                                            <div className="!mx-2 !mt-4">
                                                <div
                                                    className="relative"
                                                    onKeyDown={(event) => {
                                                        event.stopPropagation();
                                                    }}
                                                >
                                                    <input
                                                        type="text"
                                                        placeholder="Search"
                                                        className="w-full !py-2.5 !pr-2.5 !pl-8 !border !border-[#E2E8F0] !h-7 text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                                        value={search}
                                                        onChange={(event) => {
                                                            event.stopPropagation();
                                                            setSearch(event.target.value);
                                                        }}
                                                    />

                                                    <img
                                                        src="/img/risk-assessment/ri_search-line-small.svg"
                                                        alt="Search Icon"
                                                        className="absolute object-contain top-1/2 -translate-y-1/2 left-[10px]"
                                                    />
                                                </div>
                                            </div>

                                            {all_employees?.length ? (
                                                <div className="!p-2 flex flex-col !w-full">
                                                    {all_employees
                                                        ?.filter(
                                                            (employee) => !distribution.employees?.includes(employee)
                                                        )
                                                        ?.map((employee, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    setDistribution((prev) => ({
                                                                        ...prev,
                                                                        employees: [...prev.employees, employee],
                                                                    }));
                                                                    error?.users &&
                                                                        setError((prev) => ({
                                                                            ...prev,
                                                                            employees: '',
                                                                        }));
                                                                }}
                                                                sx={{
                                                                    color: '#64748B',
                                                                    fontWeight: 500,
                                                                    fontSize: '13px',
                                                                    textTransform: 'capitalize',
                                                                    padding: '8px 8px',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                {employee?.name}
                                                            </MenuItem>
                                                        ))}
                                                </div>
                                            ) : (
                                                <p className="flex items-center justify-center !my-4 text-sm text-[#64748B] font-medium">
                                                    User not found
                                                </p>
                                            )}
                                        </div>
                                    </Menu>
                                </div>

                                {error.employees && (
                                    <span className="text-[10px] !-mt-1 text-[#FF0000] font-normal">
                                        {error.employees}
                                    </span>
                                )}

                                <button
                                    className="!w-fit !ml-auto flex items-center !gap-1 transition-all hover:brightness-90"
                                    onClick={() => {
                                        setDistribution((prev) => ({
                                            ...prev,
                                            sendToAll: !prev.sendToAll,
                                        }));

                                        if (error.employees) {
                                            setError((prev) => ({ ...prev, employees: '' }));
                                        }
                                    }}
                                >
                                    {distribution.sendToAll ? (
                                        <img
                                            src="/img/frameworks/checkbox-active.svg"
                                            alt="Checkbox icon"
                                            className="object-contain"
                                        />
                                    ) : (
                                        <img
                                            src="/img/frameworks/checkbox.svg"
                                            alt="Checkbox icon"
                                            className="object-contain"
                                        />
                                    )}

                                    <p className="font-medium text-[13px] leading-5 text-[#64748B]">
                                        Send to all departments
                                    </p>
                                </button>
                            </div>
                        </div>

                        <div className="flex flex-col !gap-4 !border-t !border-[#CBD5E1] !py-2">
                            <p className="text-[#395BA9] font-semibold text-[13px]">Reviewer</p>

                            <div className="flex flex-col !gap-2">
                                <div>
                                    <button
                                        aria-controls={reviewerAnchorEl ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={reviewerAnchorEl ? 'true' : undefined}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setReviewerAnchorEl(event.currentTarget);
                                        }}
                                        className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors !h-9"
                                    >
                                        <p
                                            className={`font-normal text-sm ${
                                                reviewer ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                                            }`}
                                        >
                                            {reviewer ? reviewer?.name : 'Select a reviewer'}
                                        </p>

                                        <img
                                            src="/img/frameworks/chevron-down.svg"
                                            alt="Arrow Down Icon"
                                            className="object-contain"
                                        />
                                    </button>

                                    <Menu
                                        anchorEl={reviewerAnchorEl}
                                        open={Boolean(reviewerAnchorEl)}
                                        onClose={() => setReviewerAnchorEl(null)}
                                        sx={{
                                            '& .MuiPaper-root': {
                                                borderRadius: '4px',
                                                boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                            },
                                            '& .MuiList-root': {
                                                padding: 0,
                                                width: '477px',
                                            },
                                        }}
                                    >
                                        <div className="min-h-[200px] max-h-[300px] overflow-auto custom-scroll">
                                            <div className="mx-2 mt-4">
                                                <div
                                                    className="relative"
                                                    onKeyDown={(event) => {
                                                        event.stopPropagation();
                                                    }}
                                                >
                                                    <input
                                                        type="text"
                                                        placeholder="Search"
                                                        className="w-full !py-2 !pr-9 !pl-2 !border !border-[#E2E8F0] h-9 text-[13px] font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                                        value={search}
                                                        onChange={(event) => {
                                                            event.stopPropagation();
                                                            setSearch(event.target.value);
                                                        }}
                                                    />

                                                    <img
                                                        src="/img/frameworks/search.svg"
                                                        alt="Search Icon"
                                                        className="absolute object-contain top-1/2 -translate-y-1/2 !right-2"
                                                    />
                                                </div>
                                            </div>

                                            <div className="!p-2 flex flex-col !w-full">
                                                {all_employees?.length ? (
                                                    all_employees?.map((employee, index) => (
                                                        <MenuItem
                                                            key={index}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                setReviewer(employee);
                                                                setError((prev) => ({ ...prev, reviewer: '' }));
                                                                setReviewerAnchorEl(null);
                                                                setSearch('');
                                                            }}
                                                            sx={{
                                                                color: '#64748B',
                                                                fontWeight: 500,
                                                                fontSize: '13px',
                                                                textTransform: 'capitalize',
                                                                padding: '8px 8px',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            {employee?.name}
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    <p className="flex items-center justify-center !my-4 text-sm text-[#64748B] font-medium">
                                                        User not found
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </Menu>
                                </div>

                                {error.reviewer && (
                                    <span className="text-[10px] !-mt-1 text-[#FF0000] font-normal">
                                        {error.reviewer}
                                    </span>
                                )}

                                <p className="text-xs text-[#64748B]">
                                    Policy may need to be reviewed for adjustment before approval
                                </p>
                            </div>

                            <button className="!w-fit flex items-center !gap-1 pointer-events-none">
                                <img
                                    src="/img/frameworks/checkbox-disabled.svg"
                                    alt="Checkbox icon"
                                    className="object-contain"
                                />

                                <p className="font-medium text-[13px] leading-5 text-[#64748B]">
                                    Send to approver once reviewed and approved
                                </p>
                            </button>

                            <div className="bg-[#F8FAFC] !border !border-[#F0F0FA] !rounded flex !gap-2 !px-3 !py-2 items-start">
                                <img
                                    src="/img/frameworks/lightbulb.svg"
                                    alt="Bulb Icon"
                                    className="!mt-0.5 !w-6 !h-6"
                                />

                                <p className="text-[#64748B] font-normal text-sm leading-5">
                                    The reviewer will receive an email notification about this request and will be able
                                    to review it once logged in.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center justify-end !p-6 bg-[#F8FAFC] !gap-2">
                        <button
                            className="bg-[#fff] !border !border-[#E2E8F0] !rounded-sm !px-4 !py-2 hover:bg-red-500 hover:text-white transition-colors text-[#334155] text-sm font-medium"
                            onClick={handleClose}
                        >
                            Cancel
                        </button>

                        <button
                            className="bg-[#202D66] !rounded-sm !px-4 !py-2 hover:bg-[#151e44] transition-all text-white text-sm font-semibold disabled:opacity-50 disabled:pointer-events-none flex items-center !gap-1"
                            disabled={isLoading}
                            onClick={handleSubmit}
                        >
                            {isLoading && <Loader2 className="!w-5 !h-5 animate-spin" />}
                            Finish
                        </button>
                    </div>
                </div>
            </Drawer>

            <PolicyReviewDialog
                isOpen={isPolicyReviewDialogOpen}
                handleCallback={() => {
                    handleClose();
                    setIsPolicyReviewDialogOpen(false);
                    handleCallback();
                }}
            />

            <LoadingOverlay
                isOpen={showLoadingOverlay}
                handleCallback={() => setShowLoadingOverlay(false)}
                isLoading={isAnalysing}
                isPaid={true}
            />
        </>
    );
}

function PolicyReviewDialog({ isOpen, handleCallback }) {
    return (
        <Dialog
            open={isOpen}
            onClose={handleCallback}
            PaperProps={{
                sx: {
                    borderRadius: '16px !important',
                    padding: 0,
                    boxShadow: '0px 16px 24px 0px #00000026',
                    width: '440px',
                },
            }}
        >
            <div className="!p-6 flex flex-col !gap-2 justify-center items-center">
                <h5 className="text-lg font-semibold text-[#37A372]">Policy sent for review</h5>

                <p className="text-center text-[#64748B] text-[13px]">
                    Policy has been successfully sent to the reviewer for review
                </p>
            </div>

            <div className="flex items-center justify-center !p-6 bg-[#F8FAFC] !gap-2">
                <button
                    className="bg-[#fff] !border !border-[#E2E8F0] !rounded-sm !px-4 !py-2 hover:brightness-90 transition-all text-[#334155] text-sm font-medium"
                    onClick={handleCallback}
                >
                    Continue
                </button>
            </div>
        </Dialog>
    );
}
