import { Route, Switch } from 'react-router-dom';
import CategoryPage from './CategoryPage';
import NdprDashboard from './NdprDashboard';
// import FormsAndDocumentsView from './FormsAndDocumentsView';
// import NdprFormCreation from '../Operations/FormCreation';

const Ndpr = () => {
    return (
        <Switch>
            <Route path="/admin/ndpr" exact component={NdprDashboard} />
            <Route path="/admin/ndpr/category" exact component={CategoryPage} />
            {/* <Route path="/admin/ndpr/form" component={(props) => <NdprFormCreation tag="ndpr" {...props} />} /> */}
        </Switch>
    );
};

export default Ndpr;
