//components
import ContinuityGroups from './components/ContinuityGroups';
// import ResourceView from './components/ResourceView';
// import { all_continuitygroup_metrics } from './data';
//redux
import { connect } from 'react-redux';
// import { GetAllDocumentsByContinuityIso } from 'store/actions/adminActions';

const Documents = (props) => {
    const {
        loading,
        // handleViewChange,
        // view,
        // goBack,
        // merchant_documents,
        // GetAllDocumentsByContinuityIso,
        // selectedContinuityGroup,
        // continuityGroup_documents,
        openTaskAssignModal,
        openExemptModal,
        openRevertModal,
        all_compliance_assigned_tasks,
        isExceptionTab,
    } = props;

    // state
    // const [loading, setLoading] = useState({ content: false });

    // async functions
    // const getContinuityGroupDocuments = async (continuityGroupId) => {
    //     setLoading((curr) => ({ ...curr, content: true }));
    //     const res = await GetAllDocumentsByContinuityIso(continuityGroupId);
    //     setLoading((curr) => ({ ...curr, content: false }));
    //     if (!res?.success) {
    //         toast.error('Something went wrong!');
    //         console.log('Error:::', res?.message);
    //     }
    // };

    //useEffect
    // useEffect(() => {
    //     if (selectedContinuityGroup) {
    //         getContinuityGroupDocuments(selectedContinuityGroup);
    //     }
    // }, [selectedContinuityGroup]);
    // selectedContinuityGroup;
    // return view === 0 ? (
    return (
        <>
            <ContinuityGroups
                // resource="doc"
                // onViewChange={handleViewChange}
                loading={loading}
                openTaskAssignModal={openTaskAssignModal}
                openExemptModal={openExemptModal}
                openRevertModal={openRevertModal}
                all_compliance_assigned_tasks={all_compliance_assigned_tasks}
                isExceptionTab={isExceptionTab}
            />
        </>
        // ) : (
        //     <>
        //         <ResourceView
        //             goBack={goBack}
        //             continuityGroupID={selectedContinuityGroup}
        //             loading={loading?.content}
        //             resource="doc"
        //             merchantResources={merchant_documents}
        //             continuityGroupResources={continuityGroup_documents}
        //             openTaskAssignModal={openTaskAssignModal}
        //             all_compliance_assigned_tasks={all_compliance_assigned_tasks}
        //         />
        //     </>
    );
};
const mapStateToProps = (state) => {
    return {
        // merchant_documents: state?.complianceReducers?.all_merchant_documents,
        // continuityGroup_documents: state?.adminReducers?.all_continuity_iso_documents,
        all_compliance_assigned_tasks: state?.generalReducers?.user_info?.compliance_assigned_tasks,
    };
};
export default connect(mapStateToProps, {
    // GetAllDocumentsByContinuityIso
})(Documents);
