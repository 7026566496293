import BreadCrumb from 'components/Breadcrumb';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

//antd
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Table } from 'antd';
import CloudSecurityModal from '../IsoActions/CloudSecurityModal';

//redux
import { connect } from 'react-redux';
import { CreateAnnexNew, DeleteAnnexNew, EditAnnexNew, GetAllCloudSecurities } from 'store/actions/adminActions';
import CloudSecurityOperations from './CloudSecurityOperations';

// sub-components
// const { Title } = Typography;
const { confirm } = Modal;

const CloudSecurityView = (props) => {
    //state
    const [loading, setLoading] = useState({
        content: false,
    });
    const [modalOpen, setModalOpen] = useState(null);
    const [oneReq, setOneReq] = useState({});

    //props
    const { GetAllCloudSecurities, CreateAnnexNew, EditAnnexNew, DeleteAnnexNew, all_cloud_securities } = props;
    //functions
    const openAddModal = (mode, data) => {
        setModalOpen(mode);
        setOneReq(data);
    };
    const closeAddModal = () => {
        setModalOpen(false);
    };

    const deleteCloudSecurity = (id, category) => {
        confirm({
            title: 'Are you sure you want to delete this cloud security? Note: Deleting this cloud security means you will be deleting this cloud security, the documents, audit questions and merchant data associated with this cloud security',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteAnnexNew(id, category);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    // data
    const columns = [
        {
            title: 'Title',
            render: (record) => record.title,
            sorter: (a, b) => a.title.localeCompare(b.title),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Policy Statements',
            render: (record) => (
                <ul className="list-disc flex flex-col !gap-1 !mx-4">
                    {record.policy_statements?.length ? (
                        record.policy_statements?.map((policy, index) => <li key={index}>{policy}</li>)
                    ) : (
                        <li>No policy statement</li>
                    )}
                </ul>
            ),
        },
        {
            title: 'Actions',
            align: 'right',
            render: (record) => (
                <CloudSecurityOperations
                    openEditModal={openAddModal}
                    cloud_security={record}
                    deleteCloudSecurity={deleteCloudSecurity}
                />
            ),
        },
    ];

    useEffect(() => {
        const getAllCloudsecurities = async () => {
            setLoading(true);
            const res = await GetAllCloudSecurities();
            setLoading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllCloudsecurities();
    }, []);
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb
                        views={[{ label: 'ISO27017 + 27032', link: '/iso27017+27032' }, { label: 'Cloud Securities' }]}
                    />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openAddModal('Add')}
                    >
                        Add Cloud Security
                    </Button>
                </Col>
            </Row>

            <section className="my-3">
                <Table
                    columns={columns}
                    dataSource={all_cloud_securities}
                    loading={loading?.content}
                    rowKey={(cloud_security) => cloud_security.id}
                />
            </section>

            {/* <section className="my-3">
                {all_cloud_securities && all_cloud_securities?.length > 0 ? (
                    <Row wrap gutter={24}>
                        {all_cloud_securities
                            ?.sort((a, b) => a.id - b.id)
                            ?.map((cloud_security, index) => (
                                <Col
                                    xs={24}
                                    md={12}
                                    lg={8}
                                    xxl={6}
                                    key={cloud_security.id}
                                    style={{ marginBottom: '1rem' }}
                                >
                                    <Card
                                        title={`#${index}`}
                                        loading={false}
                                        actions={[
                                            <Tooltip title="View this cloud security" color="blue">
                                                <Link
                                                    key="preview"
                                                    to={{
                                                        pathname: '/admin/iso27017+27032/cloud_security',
                                                        state: {
                                                            cloud_security,
                                                        },
                                                    }}
                                                >
                                                    <EyeOutlined key="preview" />
                                                </Link>
                                            </Tooltip>,
                                            <Tooltip title="Edit this cloud security" color="blue">
                                                <EditOutlined
                                                    key="edit"
                                                    onClick={() => openAddModal('Edit', cloud_security)}
                                                />
                                            </Tooltip>,
                                            <Tooltip title="Delete this cloud security" color="red">
                                                <DeleteOutlined
                                                    key="delete"
                                                    onClick={() =>
                                                        deleteAnnex(cloud_security.id, cloud_security.category)
                                                    }
                                                />
                                            </Tooltip>,
                                        ]}
                                    >
                                        <Title level={5} style={{ textAlign: 'center' }}>
                                            {cloud_security.title}
                                        </Title>
                                    </Card>
                                </Col>
                            ))}
                    </Row>
                ) : (
                    <Row justify="center">
                        <Col>
                            <Empty
                                description={
                                    <div>
                                        <h3>No Cybersecurity</h3>
                                        <span>Add a new Cybersecurity </span>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                )}
            </section> */}

            <CloudSecurityModal
                open={modalOpen}
                handleCancel={closeAddModal}
                CreateClause={CreateAnnexNew}
                EditClause={EditAnnexNew}
                one_req={oneReq}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_cloud_securities: state?.adminReducers?.all_cloud_securities,
    };
};

export default connect(mapStateToProps, {
    GetAllCloudSecurities,
    CreateAnnexNew,
    EditAnnexNew,
    DeleteAnnexNew,
})(CloudSecurityView);
