import axiosInstance from '../../utils/https';
import * as types from '../constants';

// PCIDSS
export const GetAllRequirements = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/requirements/?parent_requirement=false`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.ALL_REQUIREMENTS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetOneRequirement = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/requirements/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.ONE_REQUIREMENT, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateRequirement = (credentials, is_sub) => async (dispatch, getState) => {
    const parent_id = getState()?.adminReducers?.one_requirement?.id;
    try {
        const res = await axiosInstance().post(`/compliance/requirements/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllRequirements());
        if (is_sub) {
            dispatch(GetOneRequirement(parent_id));
        }
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditRequirement = (id, credentials, is_sub) => async (dispatch, getState) => {
    const parent_id = getState()?.adminReducers?.one_requirement?.id;
    try {
        const res = await axiosInstance().patch(`/compliance/requirements/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllRequirements());
        if (is_sub) {
            dispatch(GetOneRequirement(parent_id));
        }
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteRequirement = (id, is_sub) => async (dispatch, getState) => {
    const parent_id = getState()?.adminReducers?.one_requirement?.id;
    try {
        const res = await axiosInstance().delete(`/compliance/requirements/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllRequirements());
        if (is_sub) {
            dispatch(GetOneRequirement(parent_id));
        }
        return {
            success: true,
            message: 'Deleted Successful.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetDocumentsRequirementID = (requirement_id) => async (dispatch, getState) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?requirement_id=${requirement_id}&tags=pcidss`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        const previousPayload = getState().adminReducers.all_documents_by_requirement_id;
        const newPayload = {
            ...previousPayload,
            [requirement_id]: res?.data,
        };
        dispatch({
            type: types.ALL_DOCUMENTS_BY_REQUIREMENT_ID,
            payload: newPayload,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetDocumentsByRequirement = (requirement_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(
            `/compliance/documents/?requirement_id=${requirement_id}&new_template=true`
        );
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: types.ALL_ADMIN_DOCUMENTS_BY_REQ,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            documents: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetDocumentsBySubRequirement = (sub_requirement_no) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(
            `/compliance/documents/?requirement_sub_no=${sub_requirement_no}&tags=pcidss`
        );
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: types.ALL_ADMIN_DOCUMENTS_BY_SUBREQ,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            documents: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllFormsBySubRequirement = (sub_requirement_no) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(
            `/compliance/forms/?requirement_sub_no=${sub_requirement_no}&tags=pcidss`
        );
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: types.ALL_ADMIN_PCIDSS_FORMS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// PCIDSS4.0.1
export const GetAllPcidss4Point0Requirements = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/card4/?parent_requirement=false`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.ALL_PCIDSS4POINT0_REQUIREMENTS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetOnePcidss4Point0Requirement = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/card4/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.ONE_PCIDSS4POINT0_REQUIREMENT, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreatePcidss4Point0Requirement = (credentials, is_sub) => async (dispatch, getState) => {
    const parent_id = getState()?.adminReducers?.one_pcidss4point0_requirement?.id;
    try {
        const res = await axiosInstance().post(`/compliance/card4/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllPcidss4Point0Requirements());
        if (is_sub) {
            dispatch(GetOnePcidss4Point0Requirement(parent_id));
        }
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditPcidss4Point0Requirement = (id, credentials, is_sub) => async (dispatch, getState) => {
    const parent_id = getState()?.adminReducers?.one_pcidss4point0_requirement?.id;
    try {
        const res = await axiosInstance().patch(`/compliance/card4/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllPcidss4Point0Requirements());
        if (is_sub) {
            dispatch(GetOnePcidss4Point0Requirement(parent_id));
        }
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeletePcidss4Point0Requirement = (id, is_sub) => async (dispatch, getState) => {
    const parent_id = getState()?.adminReducers?.one_pcidss4point0_requirement?.id;
    try {
        const res = await axiosInstance().delete(`/compliance/card4/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllPcidss4Point0Requirements());
        if (is_sub) {
            dispatch(GetOnePcidss4Point0Requirement(parent_id));
        }
        return {
            success: true,
            message: 'Deleted Successful.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllPcidss4Point0RequirementMetrics = (merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers?.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/card4_summary/?merchant_id=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.ALL_PCIDSS4POINT0_REQUIREMENTS_METRICS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetAllPcidss4Point0DocumentsByAltTag = (tag) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?not_tag=${tag}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_ALTTAGS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetPcidss4Point0DocumentsByRequirement = (requirement_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?card4_id=${requirement_id}&new_template=true`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: types.ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_REQ,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            documents: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetPcidss4Point0DocumentsBySubRequirement = (sub_requirement_no) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?card4_sub_no=${sub_requirement_no}&tags=card4`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: types.ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_SUBREQ,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            documents: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllPcidss4Point0DocumentsByTags = (tag) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?tags=${tag}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_TAGS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllPcidss4Point0FormsBySubRequirement = (sub_requirement_no) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/forms/?card4_sub_no=${sub_requirement_no}&tags=card4`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: types.ALL_ADMIN_PCIDSS4POINT0_FORMS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetEvidencesByCard4 = (control) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/evidence/?card4=${control}&tags=card4`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: types.ALL_ADMIN_EVIDENCE_BY_CARD4,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
