/* eslint-disable no-unused-vars */

import AppTable from 'components/new_components/app-table2/CustomIndex';
import { useEffect, useMemo, useState } from 'react';
import CustomTooltip from '../CustomTooltip';
import ReuseablePolicyViewDrawer from '../ReuseablePolicyViewDrawer';
import ViewPolicyStatementDrawer from '../ViewPolicyStatementDrawer';
import TableAction from './TableAction';
import TableFilter from './TableFilter';
import TableTop from './TableTop';

const NO_PER_PAGE = 5;

export default function PolicyStatementsTable({
    currentRequirement,
    title,
    formattedDocReviewStats,
    setIsUsePolicyEditorDrawerOpen,
    setIsUseUploadedFileDrawerOpen,
    setDefaultTab,
    standard,
}) {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [activeRow, setActiveRow] = useState({
        row: null,
        viewMode: 'idle',
        isOpen: false,
    });
    const [currentTab, setCurrentTab] = useState('all');
    const [searchText, setSearchText] = useState('');
    const [sourceFilter, setSourceFilter] = useState('all');
    const [isReuseablePolicyViewOpen, setIsReuseablePolicyViewOpen] = useState(false);

    const columns = useMemo(
        () => [
            {
                title: <p className="!pl-4">Policy statements</p>,
                key: 'name',
                render: (row) => (
                    <div className="!pl-4 relative flex items-start !gap-1 !w-fit">
                        {row?.gaps ? (
                            <div
                                className="absolute top-0 !-left-1 cursor-pointer"
                                role="button"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setIsReuseablePolicyViewOpen(true);
                                }}
                            >
                                <CustomTooltip title="Gaps identified. Click to view" placement="bottom" width="105px">
                                    <img src="/img/frameworks/warning_amber.svg" alt="warning" className="!w-4 !h-4" />
                                </CustomTooltip>
                            </div>
                        ) : null}

                        <p
                            className={`leading-4 max-w-[320px] 2xl:max-w-[480px] ${
                                row?.gaps ? 'text-[#D2A500]' : 'text-[#395BA9]'
                            }`}
                        >
                            {shortenText(row?.name)}
                        </p>

                        {row?.isNew && (
                            <p className="!border !border-[#395BA9] leading-none !rounded !py-0.5 !px-1 text-[#395BA9] text-[11px]">
                                New
                            </p>
                        )}
                    </div>
                ),
            },
            {
                title: 'Policy found in',
                key: 'source',
                render: (row) =>
                    row?.source ? (
                        <div className="flex items-center !gap-2">
                            <img src="/img/frameworks/notification-status.svg" alt="notify" className="!h-4 !w-4" />

                            <p className="text-[#64748B]">{shortenDocumentTitle(row?.source?.replaceAll('_', ' '))}</p>
                        </div>
                    ) : (
                        <p className="text-[#64748B] capitalize">-</p>
                    ),
            },
            {
                title: 'Status',
                key: 'status',
                render: (row) => (
                    <div className="!w-fit !border !border-[#E2E8F0] !rounded flex items-center !gap-1 !py-1 !px-2">
                        <div
                            className={`!rounded-full !w-2 !h-2 ${
                                row?.status === 'implemented' ? 'bg-[#37A372]' : 'bg-[#94A3B8]'
                            }`}
                        />

                        <p
                            className={`text-[#64748B] font-medium text-sm whitespace-nowrap capitalize ${
                                row?.status === 'implemented' ? 'text-[#37A372]' : 'text-[#64748B]'
                            }`}
                        >
                            {row?.status}
                        </p>
                    </div>
                ),
            },
            {
                title: null,
                key: 'action',
                render: (row) => <TableAction row={row} setActiveRow={setActiveRow} />,
            },
        ],
        []
    );

    function shortenText(text) {
        if (text?.length > 130) {
            return `${text?.substring(0, 127)}...`;
        }

        return text;
    }

    function shortenDocumentTitle(text) {
        const ext = text?.split('.')?.pop();

        if (text?.length > 20) {
            return `${text?.substring(0, 17)}...${ext}`;
        }

        return text;
    }

    function handlePageChange(page) {
        setPage(page);
    }

    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;

        let filteredData = formattedDocReviewStats?.length ? [...formattedDocReviewStats] : [];

        if (currentTab !== 'all') {
            if (currentTab === 'gaps') {
                filteredData = filteredData.filter((data) => data.gaps);
            } else {
                filteredData = filteredData.filter((data) => data.status === currentTab);
            }
        }

        if (sourceFilter !== 'all') {
            filteredData = filteredData.filter((data) => data.source === sourceFilter);
        }

        if (searchText) {
            filteredData = filteredData.filter((data) => data.name.toLowerCase().includes(searchText.toLowerCase()));
        }

        setPageData(filteredData.slice(start, stop));
    }, [formattedDocReviewStats, page, currentTab, searchText, sourceFilter]);

    return (
        <>
            <div className="flex flex-col !gap-4 !py-6 !px-4">
                <TableTop
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    formattedDocReviewStats={formattedDocReviewStats}
                    setIsReuseablePolicyViewOpen={setIsReuseablePolicyViewOpen}
                    setIsUsePolicyEditorDrawerOpen={setIsUsePolicyEditorDrawerOpen}
                    setIsUseUploadedFileDrawerOpen={setIsUseUploadedFileDrawerOpen}
                    setDefaultTab={setDefaultTab}
                    standard={standard}
                />

                <TableFilter
                    searchText={searchText}
                    setSearchText={setSearchText}
                    sourceFilter={sourceFilter}
                    setSourceFilter={setSourceFilter}
                    formattedDocReviewStats={formattedDocReviewStats}
                />

                <AppTable
                    columns={columns}
                    data={pageData}
                    sorter={(a, b) => a?.id - b?.id}
                    loading={loading}
                    showTitleBar={false}
                    dataLength={formattedDocReviewStats?.length}
                    noPerPage={NO_PER_PAGE}
                    page={page}
                    onPageChange={handlePageChange}
                    clickableRow={() => true}
                    onRowClick={(row) =>
                        setActiveRow({
                            row,
                            viewMode: 'view',
                            isOpen: true,
                        })
                    }
                    applyClickableRowStyles={true}
                />
            </div>

            <ViewPolicyStatementDrawer activeRow={activeRow} setActiveRow={setActiveRow} />

            <ReuseablePolicyViewDrawer
                title="Gaps left"
                moduleTitle={title}
                mode="view-gaps"
                isOpen={isReuseablePolicyViewOpen}
                setIsOpen={setIsReuseablePolicyViewOpen}
                currentRequirement={currentRequirement}
                formattedDocReviewStats={formattedDocReviewStats}
            />
        </>
    );
}
