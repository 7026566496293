/* eslint-disable no-unused-vars */

import { Drawer, Fade } from '@mui/material';
import { forwardRef, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearFormatting } from 'store/actions/editorActions';
import DocumentLoadingOverlay from './DocumentLoadingOverlay';
import Editor from './Editor';
import NavBar from './NavBar';

const Transition = forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

export default function PolicyEditorDrawer({
    isOpen,
    setIsOpen,
    handleCallback,
    mode,
    standard,
    templates,
    currentRequirement,
    document,
    isNativeFile,
    role,
    existingPolicyId,
    useExistingPolicy,
    filename,
}) {
    const [isEditable, setIsEditable] = useState(false);
    const [exportedDoc, setExportedDoc] = useState({});

    const isLoadingDocument = useSelector((state) => state.editorReducers.isLoadingDocument);

    const dispatch = useDispatch();

    const editorRef = useRef(null);

    function handleClose() {
        setIsOpen(false);
        setIsEditable(false);
        setExportedDoc({});
        dispatch(clearFormatting());
    }

    return (
        <>
            <Drawer
                open={isOpen}
                anchor="right"
                TransitionComponent={Transition}
                transitionDuration={0}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                        width: '100%',
                        flex: 1,
                        overflow: 'auto',
                        zIndex: 9999,
                        backgroundColor: '#F8FAFC',
                    },
                }}
                scroll="paper"
            >
                <NavBar
                    handleClose={handleClose}
                    handleCallback={handleCallback}
                    mode={mode}
                    standard={standard}
                    currentRequirement={currentRequirement}
                    document={document}
                    isNativeFile={isNativeFile}
                    role={role}
                    existingPolicyId={existingPolicyId}
                    useExistingPolicy={useExistingPolicy}
                    editorRef={editorRef}
                    isEditable={isEditable}
                    setIsEditable={setIsEditable}
                    exportedDoc={exportedDoc}
                    setExportedDoc={setExportedDoc}
                    filename={filename}
                />

                <Editor editorRef={editorRef} templates={templates} isEditable={isEditable} />
            </Drawer>

            <DocumentLoadingOverlay isOpen={isLoadingDocument} />
        </>
    );
}
