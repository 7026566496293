/* eslint-disable no-unused-vars */

import { useState } from 'react';

export default function StatementCard({ currentStatement, setCurrentStatement, statement, statementIndex }) {
    const [isCopied, setIsCopied] = useState(false);

    const isActive = statementIndex === currentStatement;

    function copyToClipboard(event) {
        event.stopPropagation();
        navigator.clipboard.writeText(statement?.policy).then(() => {
            setIsCopied(true);

            setTimeout(() => {
                setIsCopied(false);
            }, 3000);
        });
    }

    return (
        <div
            className={`bg-white !max-w-[954px] !w-full !p-4 !rounded-lg flex flex-col !gap-4 cursor-pointer transition-all hover:brightness-[.98] ${
                isActive ? '!border-2 !border-[#395BA9]' : '!border !border-[#F1F5F9] opacity-75'
            }`}
            role="button"
            onClick={() => setCurrentStatement(statementIndex)}
        >
            <div className="flex items-center justify-between !gap-4">
                <p className={`font-bold text-[15px] leading-5 ${isActive ? 'text-[#1E293B]' : 'text-[#64748B]'}`}>
                    Policy statement {statementIndex + 1}
                </p>

                <div className="flex items-center !gap-2">
                    <button
                        className={`!border !border-[#E2E8F0] bg-white !rounded-full !py-1 !px-2.5 font-medium text-[13px] text-[#475569] transition-all hover:brightness-90`}
                        onClick={copyToClipboard}
                    >
                        {isCopied ? 'Copied' : 'Copy'}
                    </button>

                    <p
                        className={`capitalize !py-1 !px-2.5 font-medium text-[13px] ${
                            statement?.status === 'implemented' ? 'text-[#37A372]' : 'text-[#FF5449]'
                        }`}
                    >
                        {statement?.status}
                    </p>
                </div>
            </div>

            <p className="text-[#334155] text-sm">{statement?.policy}</p>
        </div>
    );
}
