import { Button, Col, Drawer, Input, Row, Space, Tree, Upload } from 'antd';
import { useMemo, useRef, useState } from 'react';
import { AiOutlineInbox } from 'react-icons/ai';
import { toast } from 'react-toastify';

// import { getOptionLabel } from 'utils';

// redux
// import { useMemo } from 'react';
import { connect } from 'react-redux';
import { CreateDocument, EditDocument } from 'store/actions/adminActions';
import { accept } from 'validate';

// const { Option } = Select;

const getParentKey = (key, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
            if (node.children.some((item) => item.key === key)) {
                parentKey = node.key;
            } else if (getParentKey(key, node.children)) {
                parentKey = getParentKey(key, node.children);
            }
        }
    }
    return parentKey;
};

const AddDocumentModal = (props) => {
    const {
        open,
        handleClose,
        // all_documents_by_alttags,
        // all_pcidss4point0_documents_by_alttags,
        tag,
        CreateDocument,
        // EditDocument,
        payload,
        titleLabel,
        descLabel,
        // groupIdLabel,
        one_document,
        parent_requirement,
        sub_requirements,
        treeSubTitle,
        treeSubKey,
    } = props;

    // state
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    // const [mode, setMode] = useState('new');
    // const [extendingDocID, setExtendingDocID] = useState();
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [searchValue, setSearchValue] = useState('');

    const { pdf, docx } = accept;

    // drgagger
    const { Dragger } = Upload;
    const draggers = {
        name: 'default',
        accept: `${pdf},${docx}`,
        action: null,
        multiple: false,
        maxCount: 1,
        onChange(info) {
            setData({ ...data, default: info.fileList[0]?.originFileObj });
        },
    };

    const formRef = useRef(null);

    const treePolicyStatements = useMemo(
        () =>
            sub_requirements?.reduce((acc, subreq) => {
                return [
                    ...acc,
                    ...subreq.policy_statements.map((policy) => ({
                        title: policy,
                        key: policy,
                    })),
                ];
            }, []),
        [sub_requirements]
    );

    const treeData = useMemo(
        () =>
            sub_requirements?.map((subreq) => ({
                title: `${treeSubTitle} - ${subreq?.[treeSubKey]}`,
                key: subreq?.id,
                children: subreq?.policy_statements?.map((policy) => ({
                    title: policy,
                    key: policy,
                })),
            })),
        [sub_requirements]
    );

    const searchedTreeData = useMemo(() => {
        const loop = (data) =>
            data.map((item) => {
                const strTitle = item.title;
                const index = strTitle.toLowerCase().indexOf(searchValue.toLocaleLowerCase());
                const beforeStr = strTitle.substring(0, index);
                const afterStr = strTitle.slice(index + searchValue.length);
                const mainStr = strTitle.slice(index, index + searchValue.length);
                const title =
                    index > -1 ? (
                        <span key={item.key}>
                            {beforeStr}
                            <span className="site-tree-search-value text-blue-500">{mainStr}</span>
                            {afterStr}
                        </span>
                    ) : (
                        <span key={item.key}>{strTitle}</span>
                    );
                if (item.children) {
                    return {
                        title,
                        key: item.key,
                        children: loop(item.children),
                    };
                }
                return {
                    title,
                    key: item.key,
                };
            });
        return loop(treeData);
    }, [searchValue, treeData]);

    const onExpand = (expandedKeysValue) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };

    const onCheck = (checkedKeysValue) => {
        const newPolicies = {};
        checkedKeysValue
            ?.filter((key) => treePolicyStatements.map((item) => item.key).includes(key))
            ?.forEach((key) => {
                const parentKey = getParentKey(key, treeData);
                if (newPolicies[parentKey]) {
                    newPolicies[parentKey].push(key);
                } else {
                    newPolicies[parentKey] = [key];
                }
            });

        setCheckedKeys(checkedKeysValue);
        setData((prev) => ({
            ...prev,
            policies: {
                ...prev?.policies,
                [tag]: {
                    ...prev?.policies?.[tag],
                    ...newPolicies,
                },
            },
        }));
    };

    const onSearchTextChange = (e) => {
        const { value } = e.target;
        const newExpandedKeys = treePolicyStatements
            .map((item) => {
                if (item.title.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                    return getParentKey(item.key, treeData);
                }
                return null;
            })
            .filter((item, i, self) => !!(item && self.indexOf(item) === i));
        setExpandedKeys(newExpandedKeys);
        setSearchValue(value);
        setAutoExpandParent(true);
    };

    // const availableDocuments = useMemo(() => {
    //     return tag === 'card4' ? all_pcidss4point0_documents_by_alttags : all_documents_by_alttags;
    // });
    // functions
    const closeAll = () => {
        setData({});
        setExpandedKeys([]);
        setCheckedKeys([]);
        setAutoExpandParent(true);
        setSearchValue('');
        // setExtendingDocID(null);
        handleClose();
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        // if (mode === 'new') {
        handleCreateDocument();
        // } else {
        //     // if the record is to be extended to another document
        //     handleDocumentExtension();
        // }
    };
    const createFormData = () => {
        const formData = new FormData();
        const newPayload = { ...payload, ...data, ...one_document };
        for (let field in newPayload) {
            if (field === 'policies') {
                formData.append(field, JSON.stringify(newPayload[field]));
            } else if (field !== 'default') {
                formData.append(field, newPayload[field]);
            }
        }
        return formData;
    };
    const handleCreateDocument = async () => {
        //collating the form data
        const formData = createFormData();
        formData.append('tags', tag);

        // add the template identifier
        formData.append('new_template', true);

        // for codereview and pentest documents, don't upload a document
        if (!one_document?.type) {
            formData.append('default', data?.default);
        }

        // get the group ID (requirement no, category id or annex id) based on the tag
        // const groupId = payload?.[groupIdLabel];
        setLoading(true);
        const res = await CreateDocument(formData, null, tag, parent_requirement);
        setLoading(false);
        if (res.success) {
            toast.success('Template Added Successfully');
            closeAll();
        } else {
            toast.error(res?.message);
        }
    };
    // const handleDocumentExtension = async () => {
    //     // collating the form data
    //     const formData = createFormData();
    //     // adding the new tag.
    //     // first get the document from the alttag documents
    //     const extendingDoc = availableDocuments?.find((doc) => doc.id === extendingDocID);
    //     // then split the csv into array
    //     let newTags = extendingDoc?.tags?.split(',');
    //     // push the new standard's tag to the existing one (linking the document)
    //     newTags.push(tag);
    //     // append the new tags list to the exist form data
    //     formData.append('tags', newTags.join(','));

    //     // get the group ID (requirement no, category id, control id or annex id) based on the tag
    //     const groupID = payload?.[groupIdLabel];
    //     setLoading(true);
    //     // send request to edit the form data.
    //     // extendingDocID => the existing document that hosts the extension
    //     const res = await EditDocument(formData, extendingDocID, tag, groupID);
    //     setLoading(false);
    //     if (res.success) {
    //         toast.success('Template Added Successfully');
    //         closeAll();
    //     } else {
    //         toast.error(res?.message);
    //     }
    // };
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    // const onSearch = (value) => {
    //     console.log('search:', value);
    // };

    // Filter `option.label` match the user type `input`
    // const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    // useEffect(() => {
    //     createFormData();
    // }, [one_document]);

    return (
        <Drawer
            title={`Add ${one_document?.type || ''} template`}
            open={open}
            onClose={closeAll}
            width={600}
            extra={
                <Space>
                    <Button
                        className="ant-btn"
                        htmlType="submit"
                        type="primary"
                        loading={loading}
                        onClick={() => formRef?.current?.requestSubmit()}
                    >
                        Submit
                    </Button>
                </Space>
            }
        >
            <form encType="multipart/form-data" onSubmit={handleSubmit} ref={formRef}>
                <Row justify="end">
                    <Col span={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor={titleLabel}>Template title</label>
                            <Input
                                type="text"
                                size="large"
                                name={titleLabel}
                                id={titleLabel}
                                onChange={handleTextChange}
                                value={data?.[titleLabel] || ''}
                                required
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor={descLabel}>Template description</label>
                            <Input.TextArea
                                type="text"
                                size="large"
                                name={descLabel}
                                id={descLabel}
                                onChange={handleTextChange}
                                value={data?.[descLabel] || ''}
                                required
                            />
                        </div>
                    </Col>
                    {!one_document?.type && (
                        <>
                            {/* <Col span={24}>
                                <div className="form-group" style={{ marginBottom: '1rem' }}>
                                    <label htmlFor="creation_mode">Select creation mode</label>
                                    <Select
                                        defaultValue="new"
                                        size="large"
                                        id="creation_mode"
                                        onChange={(value) => setMode(value)}
                                        value={mode}
                                        required
                                        style={{ width: '100%' }}
                                    >
                                        <Option value="new">Upload a new template</Option>
                                        <Option value="extend">Extend an existing template</Option>
                                    </Select>
                                </div>
                            </Col> */}

                            {/* {mode === 'new' ? ( */}
                            <Col span={24} style={{ marginBottom: data?.default ? '4rem' : '3rem' }}>
                                <label htmlFor="template_file">Upload Template</label>
                                <Dragger {...draggers}>
                                    <p className="ant-upload-drag-icon">
                                        <AiOutlineInbox />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                </Dragger>
                            </Col>
                            {/* ) : (
                                <Col span={24}>
                                    <div className="form-group" style={{ marginBottom: '1rem' }}>
                                        <label htmlFor="extending_doc">Select the template to extend</label>
                                        <Select
                                            size="large"
                                            id="extending_doc"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={filterOption}
                                            required
                                            onSearch={onSearch}
                                            style={{ width: '100%' }}
                                            onChange={(value) => setExtendingDocID(value)}
                                            options={
                                                availableDocuments?.filter((doc) => !doc.tags.split(',').includes(tag))
                                                    ?.length
                                                    ? availableDocuments
                                                          ?.filter((doc) => !doc.tags.split(',').includes(tag))
                                                          ?.filter((doc) => !doc.tags.split(',').includes('blockchain'))
                                                          ?.sort((a, b) => a.id - b.id)
                                                          ?.map((doc) => {
                                                              return {
                                                                  value: doc.id,
                                                                  label: getOptionLabel(doc, tag),
                                                              };
                                                          })
                                                    : [
                                                          {
                                                              value: '',
                                                              label: 'Select Template',
                                                          },
                                                      ]
                                            }
                                            value={extendingDocID}
                                        />
                                    </div>
                                </Col>
                            )} */}
                        </>
                    )}
                    <Col span={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="policyStatements">
                                Select all policy statements that applies to the template
                            </label>
                            {/* <Select
                                id="policyStatements"
                                size="large"
                                mode="multiple"
                                showSearch
                                placeholder="Select policy statements"
                                optionFilterProp="label"
                                style={{ width: '100%' }}
                                options={sub_requirements
                                    ?.filter((subreq) => subreq?.policy_statements?.length)
                                    ?.map((subreq) => ({
                                        label: <span>Sub-Requirement {subreq?.requirement_number}</span>,
                                        title: subreq?.requirement_number,
                                        options: subreq?.policy_statements?.map((policy) => ({
                                            value: policy,
                                            label: policy,
                                        })),
                                    }))}
                                onChange={(value) => {
                                    setData((prev) => ({
                                        ...prev,
                                        policies: {
                                            ...prev?.policies,
                                            [tag]: {
                                                ...prev?.policies?.[tag],
                                                [parent_requirement]: value,
                                            },
                                        },
                                    }));
                                }}
                                value={data?.policies?.[tag]?.[parent_requirement]}
                                required
                            /> */}
                            <div>
                                <Input.Search
                                    className="!mb-4"
                                    placeholder="Search statements"
                                    onChange={onSearchTextChange}
                                />

                                <Tree
                                    checkable
                                    onExpand={onExpand}
                                    expandedKeys={expandedKeys}
                                    autoExpandParent={autoExpandParent}
                                    onCheck={onCheck}
                                    checkedKeys={checkedKeys}
                                    treeData={searchedTreeData}
                                    selectable={false}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </Drawer>
    );
};
// const mapStateToProps = (state) => {
const mapStateToProps = () => {
    return {
        // all_documents_by_alttags: state?.adminReducers?.all_documents_by_alttags,
        // all_pcidss4point0_documents_by_alttags: state?.adminReducers?.all_pcidss4point0_documents_by_alttags,
    };
};
export default connect(mapStateToProps, { CreateDocument, EditDocument })(AddDocumentModal);
