import * as docx from 'docx';
import { customNumberedLevels, defaultStyles } from './docStyles';
import { base64ToArrayBuffer, getFileExtension, ptToHalfPt, pxToHalfPt } from './fns';

// let linkTracker = 0;
let numberedTracker = -1;
let styles = defaultStyles;
let levels = customNumberedLevels;
let customBullets = false;

// main public function to generate docx document
export async function generateWord(delta, config) {
    // linkTracker = 0; // reset link tracker
    numberedTracker = -1; // reset numered list tracker
    customBullets = false; // reset custom bullets
    if (!delta.paragraphs) {
        // if input is not recognized
        throw new Error('Please provide a parsed Quill delta.');
    }
    // set up the docx document based on configuration
    const doc = setupDoc(delta, config);

    // return the appropriate export object based on configuration
    return exportDoc(doc, config);
}

// set a style's paragraph and run properties
function setStyle(style, styleId, index) {
    if (style.paragraph) {
        styles[index].paragraph = style.paragraph;
    }
    if (style.run) {
        styles[index].run = style.run;
    }
}

// apply custom paragraph styles from the user
function setParagraphsStyles(paragraphStyles) {
    if (paragraphStyles.normal) {
        const index = styles.findIndex((style) => style.id === 'normal');
        setStyle(paragraphStyles.normal, 'normal', index);
    }
    if (paragraphStyles.header_1) {
        const index = styles.findIndex((style) => style.id === 'header_1');
        setStyle(paragraphStyles.header_1, 'header_1', index);
    }
    if (paragraphStyles.header_2) {
        const index = styles.findIndex((style) => style.id === 'header_2');
        setStyle(paragraphStyles.header_2, 'header_2', index);
    }
    if (paragraphStyles.list_paragraph) {
        const index = styles.findIndex((style) => style.id === 'list_paragraph');
        setStyle(paragraphStyles.list_paragraph, 'list_paragraph', index);
    }
    if (paragraphStyles.code_block) {
        const index = styles.findIndex((style) => style.id === 'code_block');
        setStyle(paragraphStyles.code_block, 'code_block', index);
    }
    if (paragraphStyles.block_quote) {
        const index = styles.findIndex((style) => style.id === 'block_quote');
        setStyle(paragraphStyles.block_quote, 'block_quote', index);
    }
    if (paragraphStyles.citation) {
        const index = styles.findIndex((style) => style.id === 'citation');
        setStyle(paragraphStyles.citation, 'citation', index);
    }
}

// apply custom configuration from the user
function setupConfig(config) {
    if (config.paragraphStyles) {
        setParagraphsStyles(config.paragraphStyles);
    }
    if (config.customLevels) {
        levels = config.customLevels;
    }
}

// sets up the docx document
function setupDoc(parsedDelta, config) {
    styles = defaultStyles; // reset back to original
    levels = customNumberedLevels; // reset back to original
    if (config) {
        setupConfig(config);
    }
    let numbering = undefined;
    // build the numbering property
    if (parsedDelta.setup.numberedLists > 0) {
        numbering = buildNumbering(parsedDelta.setup.numberedLists);
    }
    if (config?.customBulletLevels) {
        numbering = addCustomBullets(numbering, config.customBulletLevels);
        customBullets = true;
    }
    const paragraphs = buildSection(parsedDelta.paragraphs);
    const doc = new docx.Document({
        styles: {
            paragraphStyles: styles,
        },
        numbering: numbering,
        sections: [
            {
                children: paragraphs,
            },
        ],
    });

    return doc;
}

// export the appropriate object based on configuration
async function exportDoc(doc, config) {
    if (!config || !config.exportAs || config.exportAs === 'doc') {
        return doc;
    }
    if (config.exportAs === 'blob') {
        return docx.Packer.toBlob(doc);
    }
    if (config.exportAs === 'buffer') {
        console.log('returning buffer');
        return docx.Packer.toBuffer(doc);
    }
    if (config.exportAs === 'base64') {
        return docx.Packer.toBase64String(doc);
    }
    throw new Error('Please set exportAs configuration to blob, buffer, doc, or base64.');
}

// build docx numbering object from quill numbered lists
function buildNumbering(numberOfLists) {
    const config = [];
    let numberTracker = 0;
    // create a new docx numbering object for each quill numbered list
    while (numberTracker < numberOfLists) {
        const newList = {
            reference: `numbered_${numberTracker}`,
            levels: levels,
        };
        config.push(newList);
        numberTracker++;
    }
    const numberConfig = {
        config: config,
    };
    return numberConfig;
}

// adds a custom bullet styled list to the numbering configuration
function addCustomBullets(numberConfig, bulletLevels) {
    const customBullets = {
        reference: 'customBullets',
        levels: bulletLevels,
    };
    if (numberConfig) {
        numberConfig.config.push(customBullets);
        return numberConfig;
    } else {
        return {
            config: [customBullets],
        };
    }
}

// generate a section as an array of paragraphs
function buildSection(quillParagraphs) {
    let quillParagraphTracker = 0;
    // create a container to hold the docx paragraphs
    const paragraphs = [];
    // build a docx paragraph from each delta paragraph
    for (const paragraph of quillParagraphs) {
        // if embed video or image
        if (paragraph.embed?.image) {
            const image = buildImage(paragraph.embed.image);
            paragraphs.push(new docx.Paragraph({ children: [image] }));
        } else if (paragraph.embed?.video) {
            const run = buildVideo(paragraph.embed.video);
            paragraphs.push(new docx.Paragraph({ children: [run] }));
            // if text runs
        } else if (paragraph.attributes?.table) {
            const table = buildTable(paragraph?.rows);
            paragraphs.push(table);
        } else if (paragraph.textRuns) {
            // handle ordered list tracking
            if (quillParagraphTracker > 0 && paragraph.attributes?.list === 'ordered') {
                if (quillParagraphs[quillParagraphTracker - 1].attributes?.list === 'ordered') {
                    // eslint-disable-next-line no-self-assign
                    numberedTracker = numberedTracker;
                } else {
                    numberedTracker++;
                }
            }
            paragraphs.push(buildParagraph(paragraph));
        }
        quillParagraphTracker++;
    }
    return paragraphs;
}

// generate a paragraph as an array of text runs
function buildParagraph(paragraph) {
    // container to hold docx text runs
    const textRuns = [];
    // build a docx run from each delta run
    for (const run of paragraph.textRuns) {
        // if formula
        if (run.formula) {
            textRuns.push(buildFormula(run.formula));
            // if text
        } else if (run.text) {
            textRuns.push(buildTextRun(run));
        }
    }
    const docxParagraph = new docx.Paragraph({
        children: textRuns,

        heading:
            paragraph.attributes?.header === 1
                ? docx.HeadingLevel.HEADING_1
                : paragraph.attributes?.header === 2
                ? docx.HeadingLevel.HEADING_2
                : undefined,

        bullet:
            paragraph.attributes?.list === 'bullet' && !customBullets
                ? {
                      level: paragraph.attributes.indent ? paragraph.attributes.indent : 0,
                  }
                : undefined,

        numbering:
            paragraph.attributes?.list === 'ordered'
                ? {
                      reference: `numbered_${numberedTracker}`,
                      level: paragraph.attributes.indent ? paragraph.attributes.indent : 0,
                  }
                : paragraph.attributes?.list === 'bullet' && customBullets
                ? {
                      reference: 'customBullets',
                      level: paragraph.attributes.indent ? paragraph.attributes.indent : 0,
                  }
                : undefined,

        alignment:
            paragraph.attributes?.align === 'left'
                ? docx.AlignmentType.LEFT
                : paragraph.attributes?.align === 'center'
                ? docx.AlignmentType.CENTER
                : paragraph.attributes?.align === 'right'
                ? docx.AlignmentType.RIGHT
                : paragraph.attributes?.align === 'justify'
                ? docx.AlignmentType.JUSTIFIED
                : undefined,

        style: paragraph.attributes?.['code-block']
            ? 'code_block'
            : paragraph.attributes?.blockquote
            ? 'block_quote'
            : paragraph.attributes?.citation
            ? 'citation'
            : undefined,
        // bidirectional: paragraph.attributes?.direction === 'rtl' ? true : undefined,
        // indent
    });
    return docxParagraph;
}

// generate a docx text run from quill text run
function buildTextRun(run) {
    let textRun;
    if (run.attributes?.link) {
        textRun = new docx.ExternalHyperlink({
            children: [
                new docx.TextRun({
                    text: run.text,
                    style: 'Hyperlink',
                }),
            ],
            link: run.attributes.link,
        });
        // linkTracker++;
    } else {
        textRun = new docx.TextRun({
            text: run.text,
            bold: run.attributes?.bold ? true : false,
            italics: run.attributes?.italic ? true : false,
            subScript: run.attributes?.script === 'sub' ? true : false,
            superScript: run.attributes?.script === 'super' ? true : false,
            strike: run.attributes?.strike ? true : false,
            underline: run.attributes?.underline ? { type: docx.UnderlineType.SINGLE, color: 'auto' } : undefined,
            color: run.attributes?.color ? run.attributes?.color.slice(1) : undefined,
            size: run.attributes?.size
                ? run.attributes?.size?.includes('px')
                    ? pxToHalfPt(parseInt(run.attributes.size?.replace('px', '')))
                    : run?.attributes?.size?.includes('pt')
                    ? ptToHalfPt(parseInt(run.attributes.size?.replace('pt', '')))
                    : pxToHalfPt(15)
                : pxToHalfPt(15),
            // rightToLeft: paragraph.attributes?.direction === 'rtl' ? true : undefined
            // font
            highlight: run.attributes?.background
                ? run.attributes?.background === 'transparent'
                    ? undefined
                    : 'yellow'
                : undefined,
        });
    }
    return textRun;
}

// build a formula
function buildFormula(formula) {
    return new docx.TextRun({
        text: formula,
    });
}

// build a video
function buildVideo(video) {
    return new docx.TextRun({
        text: video,
    });
}

// build an image
function buildImage(image) {
    return new docx.ImageRun({
        type: getFileExtension(image),
        data: base64ToArrayBuffer(image?.split(',')[1]),
        transformation: {
            width: 400,
            height: 400,
        },
    });
}

// build a table
function buildTable(rows) {
    const table = new docx.Table({
        width: {
            size: 100,
            type: docx.WidthType.PERCENTAGE,
        },
        rows: rows
            ? rows?.map(
                  (row) =>
                      new docx.TableRow({
                          children: row.cells
                              ? row.cells?.map((cell) => {
                                    const paragraphs = [];
                                    if (cell?.embed?.image) {
                                        const image = buildImage(cell.embed.image);
                                        paragraphs.push(new docx.Paragraph({ children: [image] }));
                                    } else if (cell?.embed?.video) {
                                        const run = buildVideo(cell.embed.video);
                                        paragraphs.push(new docx.Paragraph({ children: [run] }));
                                    } else if (cell?.textRuns) {
                                        paragraphs.push(buildParagraph(cell));
                                    }
                                    return new docx.TableCell({ children: paragraphs });
                                })
                              : [],
                      })
              )
            : [],
    });
    return table;
}
