/* eslint-disable no-unused-vars */

import { Dialog, Menu, MenuItem } from '@mui/material';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AnalyzeDocument, GetDocumentLink } from 'store/actions/complianceActions';
import { extractDocName } from '../utils';
import ReuseablePolicyViewDrawer from './ReuseablePolicyViewDrawer';
import LoadingOverlay from './ReuseablePolicyViewDrawer/LoadingOverlay';

export default function UseExistingPolicyDialog({
    isOpen,
    setIsOpen,
    isPaid,
    standard,
    currentRequirement,
    title,
    fetchDocReviewStats,
}) {
    const [existingPolicy, setExistingPolicy] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);
    const [isReuseablePolicyViewDrawerOpen, setIsReuseablePolicyViewDrawerOpen] = useState(false);
    const [policyInformation, setPolicyInformation] = useState({});

    const all_merchant_documents = useSelector((state) => state?.complianceReducers?.all_merchant_documents);

    const allDocuments = useMemo(
        () =>
            all_merchant_documents
                ?.filter((doc) => doc?.policy_status !== 'awaiting_review')
                ?.map((doc) => ({ ...doc, default: doc?.document })),
        [all_merchant_documents]
    );

    const dispatch = useDispatch();

    function shortenDocumentTitle(text) {
        const ext = text?.split('.')?.pop();

        if (text?.length > 20) {
            return `${text?.substring(0, 17)}...${ext}`;
        }

        return text;
    }

    async function handleSubmit() {
        try {
            setIsLoading(true);
            setShowLoadingOverlay(true);
            setPolicyInformation({});

            if (isPaid) {
                const authorizedUrlResponse = await dispatch(GetDocumentLink('userdoc', existingPolicy?.id));
                if (!authorizedUrlResponse?.success) {
                    throw new Error('Failed to get authorized document link');
                }

                const nativeFormData = new FormData();
                nativeFormData.append('file_url', authorizedUrlResponse?.data);
                nativeFormData.append('standard', standard);
                nativeFormData.append('id', currentRequirement?.id);

                const response = await dispatch(AnalyzeDocument(nativeFormData));
                if (!response.success) throw new Error(response?.message);
                setPolicyInformation(response.data);
            } else {
                await new Promise((resolve) => setTimeout(resolve, 4000));
            }

            setIsOpen(false);
            setIsReuseablePolicyViewDrawerOpen(true);
        } catch (error) {
            toast.error(error?.message || 'Failed to upload document');
        } finally {
            setIsLoading(false);
        }
    }

    function handleClose() {
        setIsOpen(false);
        setExistingPolicy(null);
    }

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        borderRadius: '4px !important',
                        padding: 0,
                        boxShadow: '0px 16px 24px 0px #00000026',
                        width: '511px',
                    },
                }}
            >
                <div className="flex items-center justify-between !px-6 !pt-6">
                    <h5 className="text-lg font-semibold text-[#202D66]">Use existing policy</h5>

                    <button
                        className="border-[#E2E8F0] border rounded-full !p-1 !h-6 !w-6 grid place-items-center hover:bg-[#E2E8F0] transition-colors"
                        onClick={handleClose}
                    >
                        <img src="/img/automated-scan/close.svg" alt="close icon" className="object-contain" />
                    </button>
                </div>

                <div className="!p-6 flex flex-col !gap-6">
                    <div className="bg-[#F8FAFC] !border !border-[#F0F0FA] !rounded flex !gap-1 !px-4 !py-2 items-start">
                        <img src="/img/frameworks/lightbulb.svg" alt="Bulb Icon" className="!mt-0.5 !w-6 !h-6" />

                        <p className="text-[#395BA9] font-normal text-[13px] leading-5">
                            Choose any policy file from any compliance standard to use to implement policy statements in
                            this requirement.
                        </p>
                    </div>

                    <div className="flex flex-col !gap-2">
                        <p className="font-medium text-[13px] text-[#64748B]">Choose an existing policy file</p>

                        <div>
                            <button
                                aria-controls={anchorEl ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={anchorEl ? 'true' : undefined}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setAnchorEl(event.currentTarget);
                                }}
                                className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors !h-9"
                            >
                                <p
                                    className={`font-normal text-sm ${
                                        existingPolicy ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                                    }`}
                                >
                                    {existingPolicy
                                        ? shortenDocumentTitle(
                                              extractDocName(existingPolicy?.filename || existingPolicy?.document)
                                          )?.replaceAll('_', ' ')
                                        : 'Select policy file'}
                                </p>

                                <img
                                    src="/img/frameworks/chevron-down.svg"
                                    alt="Arrow Down Icon"
                                    className="object-contain"
                                />
                            </button>

                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={() => setAnchorEl(null)}
                                sx={{
                                    '& .MuiPaper-root': {
                                        borderRadius: '4px',
                                        boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                    },
                                    '& .MuiList-root': {
                                        padding: 0,
                                        width: '463px',
                                    },
                                }}
                            >
                                <div className="max-h-[300px] overflow-auto custom-scroll">
                                    <div className="mx-2 mt-2">
                                        <div
                                            className="relative"
                                            onKeyDown={(event) => {
                                                event.stopPropagation();
                                            }}
                                        >
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                className="w-full !py-2 !pr-9 !pl-2 !border !border-[#E2E8F0] !h-9 text-[13px] font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                                value={searchValue}
                                                onChange={(event) => {
                                                    event.stopPropagation();
                                                    setSearchValue(event.target.value);
                                                }}
                                            />

                                            <img
                                                src="/img/frameworks/search.svg"
                                                alt="Search Icon"
                                                className="absolute object-contain top-1/2 -translate-y-1/2 !right-2"
                                            />
                                        </div>
                                    </div>

                                    <div className="!p-2 flex flex-col">
                                        {allDocuments
                                            ?.filter((doc) =>
                                                extractDocName((doc?.filename || doc?.document)?.replaceAll('_', ' '))
                                                    ?.toLowerCase()
                                                    .includes(searchValue.toLowerCase())
                                            )
                                            ?.map((doc) => (
                                                <MenuItem
                                                    key={doc?.id}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setExistingPolicy(doc);
                                                        setAnchorEl(null);
                                                        setSearchValue('');
                                                    }}
                                                    sx={{
                                                        color: '#64748B',
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                        textTransform: 'capitalize',
                                                        padding: '8px 8px',
                                                        whiteSpace: 'pre-wrap',
                                                    }}
                                                >
                                                    {extractDocName(
                                                        (doc?.filename || doc?.document)?.replaceAll('_', ' ')
                                                    )}{' '}
                                                    {doc?.version_no}
                                                </MenuItem>
                                            ))}
                                    </div>
                                </div>
                            </Menu>
                        </div>

                        {/* {existingPolicy && (
                            <p className="text-[13px] text-[#64748B]">
                                This policy file exist in {existingPolicy?.tag}.
                            </p>
                        )} */}
                    </div>
                </div>

                <div className="flex items-center justify-end !p-6 bg-[#F8FAFC] !gap-2">
                    <button
                        className="bg-[#fff] !border !border-[#E2E8F0] !rounded-sm !px-4 !py-2 hover:bg-red-500 hover:text-white transition-colors text-[#334155] text-sm font-medium"
                        onClick={handleClose}
                    >
                        Cancel
                    </button>

                    <button
                        className="bg-[#202D66] !rounded-sm !px-4 !py-2 hover:bg-[#151e44] transition-all text-white text-sm font-semibold disabled:opacity-50 disabled:pointer-events-none"
                        disabled={isLoading || !existingPolicy}
                        onClick={handleSubmit}
                    >
                        Continue
                    </button>
                </div>
            </Dialog>

            <LoadingOverlay
                isOpen={showLoadingOverlay}
                handleCallback={() => setShowLoadingOverlay(false)}
                isLoading={isLoading}
                isPaid={isPaid}
            />

            <ReuseablePolicyViewDrawer
                title={extractDocName(existingPolicy?.filename || existingPolicy?.document)?.replaceAll('_', ' ')}
                moduleTitle={title}
                mode="upload"
                document={existingPolicy}
                policyInformation={policyInformation}
                existingPolicyId={existingPolicy?.id}
                standard={standard}
                isOpen={isReuseablePolicyViewDrawerOpen}
                setIsOpen={setIsReuseablePolicyViewDrawerOpen}
                handleCallback={handleClose}
                currentRequirement={currentRequirement}
                fetchDocReviewStats={fetchDocReviewStats}
                useExistingPolicy={true}
                filename={extractDocName(existingPolicy?.filename || existingPolicy?.document)?.replaceAll('_', ' ')}
            />
        </>
    );
}
