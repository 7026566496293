import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { numExtraction } from 'utils';

// lib
import { ExclamationCircleOutlined, FileOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Table, Tabs } from 'antd';

// core components
import BreadCrumb from 'components/Breadcrumb';
import SubcontrolOperations from './Soc2Actions/SubcontrolOperations';
import SubcontrolsModal from './Soc2Actions/SubcontrolsModal';

// redux
import { FileCheck2 } from 'lucide-react';
import { connect, useDispatch } from 'react-redux';
import { CreateControl, DeleteControl, EditControl, GetDocumentsByCommonControlId } from 'store/actions/adminActions';
import Templates from './Templates';

// subcomponents
const { confirm } = Modal;

const SubcontrolsView = (props) => {
    const { location, all_controls, CreateControl, EditControl, DeleteControl } = props;
    const [modal, setModal] = useState(false);
    const [control, setControl] = useState({});
    const [subcontrol, setSubcontrol] = useState({});

    const dispatch = useDispatch();

    const openModal = (mode, data) => {
        setModal(mode);
        setSubcontrol(mode === 'Edit' ? data : {});
    };
    const closeModal = () => {
        setModal(false);
        setSubcontrol(null);
    };
    const deleteSubcontrol = (id) => {
        confirm({
            title: 'Are you sure you want to delete this subcontrol? Note: Deleting this subcontrol means you will be deleting this subcontrol, the documents, audit questions and merchant data associated with this subcontrol',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteControl(id, true);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };

    // data
    const columns = [
        {
            title: 'Title',
            render: (record) =>
                // <Link
                //     to={{
                //         pathname: '/admin/soc2/subcontrols/items',
                //         state: { subcontrol: record },
                //     }}
                // >
                // </Link>
                record.title,
            sorter: (a, b) => numExtraction(a.title) - numExtraction(b.title),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Description',
            render: (record) => record.description,
            // width: '70%',
        },
        {
            title: 'Policy Statements',
            render: (record) => (
                <ul className="list-disc flex flex-col !gap-1 !mx-4">
                    {record.policy_statements?.length ? (
                        record.policy_statements?.map((policy, index) => <li key={index}>{policy}</li>)
                    ) : (
                        <li>No policy statement</li>
                    )}
                </ul>
            ),
        },
        {
            title: 'Actions',
            align: 'right',
            render: (record) => (
                <SubcontrolOperations
                    openEditModal={openModal}
                    subcontrol={record}
                    deleteSubcontrol={deleteSubcontrol}
                    control={control}
                    // parent_req_num={location?.state?.req_num}
                />
            ),
        },
    ];

    const getDocumentsByCommonControlId = async () => {
        const res = await dispatch(GetDocumentsByCommonControlId(location?.state?.objective_id));
        if (!res.success) {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        getDocumentsByCommonControlId;
        const { control_id } = location.state || {};
        const one_control = all_controls?.find((control) => control?.id === control_id);
        setControl(one_control);
    }, [all_controls, location?.state]);
    return (
        <div>
            <Col>
                <Col>
                    <BreadCrumb views={[{ label: 'SOC2', link: '/soc2' }, { label: control?.title }]} />
                </Col>

                <Col>
                    <section className="my-3">
                        <Tabs
                            defaultActiveKey="1"
                            items={[
                                {
                                    key: '1',
                                    label: (
                                        <span className="flex items-center !gap-2">
                                            <FileCheck2 className="!h-3.5 !w-3.5" /> <p>Sub-Controls</p>
                                        </span>
                                    ),
                                    children: (
                                        <>
                                            <Row justify="end">
                                                <Button
                                                    type="primary"
                                                    size="large"
                                                    icon={<PlusOutlined />}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                    }}
                                                    onClick={() => openModal('Add')}
                                                >
                                                    Add Subcontrol
                                                </Button>
                                            </Row>
                                            <section className="my-3">
                                                <Table
                                                    columns={columns}
                                                    dataSource={control?.sub_controls}
                                                    rowKey={(sub_control) => sub_control?.id}
                                                />
                                            </section>
                                        </>
                                    ),
                                },
                                {
                                    key: '2',
                                    label: (
                                        <span className="flex items-center !gap-2">
                                            <FileOutlined className="!h-3.5 !w-3.5 !m-0" /> <p>Templates</p>
                                        </span>
                                    ),
                                    children: (
                                        <Templates
                                            parent_control_id={control?.id}
                                            sub_controls={control?.sub_controls}
                                        />
                                    ),
                                },
                            ]}
                        />
                    </section>
                </Col>
            </Col>

            <SubcontrolsModal
                open={modal}
                handleCancel={closeModal}
                parent_control_id={control?.id}
                one_subcontrol={subcontrol}
                CreateControl={CreateControl}
                EditControl={EditControl}
            />
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        all_controls: state?.adminReducers?.all_controls,
    };
};
export default connect(mapStateToProps, {
    CreateControl,
    EditControl,
    DeleteControl,
})(SubcontrolsView);
