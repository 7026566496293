/* eslint-disable no-unused-vars */

import { Dialog } from '@mui/material';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RequestEditorAddOn } from 'store/actions/complianceActions';

export default function AwarenessDialog({ isOpen, setIsOpen }) {
    const [isLoading, setIsLoading] = useState(false);

    const all_addons = useSelector((state) => state?.generalReducers?.all_addons);

    const dispatch = useDispatch();

    const isEditorApprovalPending = all_addons?.[0]?.policy_editor?.status === 'pending';

    async function requestEditorAccess() {
        setIsLoading(true);
        const response = await dispatch(RequestEditorAddOn());
        setIsLoading(false);
        if (response?.success) {
            handleClose();
            toast.success('Request sent successfully');
        } else {
            toast.error(response?.message || 'Failed to send request');
        }
    }

    function handleClose() {
        setIsOpen(false);
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: '0px !important',
                    padding: 0,
                    boxShadow: 'none',
                    width: '100%',
                    maxWidth: '1046px',
                    backgroundColor: 'transparent !important',
                },
            }}
        >
            <button
                className="bg-white !rounded-full !ml-auto z-10 !h-[38px] !w-[38px] grid place-items-center transition-all hover:brightness-95 !shadow-[0px,16px,24px,0px,#00000026]"
                onClick={handleClose}
            >
                <img src="/img/frameworks/close-circle.svg" alt="times" className="!w-7 !h-7" />
            </button>

            <div className="bg-white !rounded-3xl overflow-hidden flex items-center !gap-8 !mx-[19px] !shadow-[0px,16px,24px,0px,#00000026] !-mt-6 !pr-9">
                <img
                    src="/img/frameworks/awareness-side.svg"
                    alt="editor preview"
                    className="!w-[400px] !h-[670px] object-cover"
                />

                <div className="flex flex-col !gap-[88px]">
                    <div className="flex flex-col !gap-7 relative">
                        {/* <div className="flex absolute items-center !gap-2 !left-[5px] !-top-12">
                            <img src="/img/frameworks/clarity_warning-solid.svg" alt="warning" className="!h-7 !w-7" />

                            <p className="text-[#B28C00] text-base font-medium">
                                Sorry. This feature is not part of your current subscription.
                            </p>
                        </div> */}

                        <h4 className="text-[#334155] font-bold text-4xl">
                            Create & edit your policies easily with policy editor.
                        </h4>

                        <p className="text-[#64748B] font-medium text-base">
                            Are you fed up with the hassle of editing your policy on your device and having to re-upload
                            it repeatedly? Our Policy Editor streamlines the process by allowing you to create and
                            modify policies effortlessly with pre-designed templates. It also ensures precise version
                            control and lets you download your policy anytime.
                        </p>
                    </div>

                    <div className="flex flex-col !gap-1">
                        {isEditorApprovalPending ? (
                            <p className="text-[#B28C00] text-[13px] leading-5">
                                Your request is pending approval, please wait for the admin to approve it.
                            </p>
                        ) : (
                            <p className="text-[#64748B] text-[13px] leading-5">
                                Want this feature? Give us a beep by clicking the button below
                            </p>
                        )}

                        <button
                            className="flex items-center !gap-1 bg-[#202D66] !rounded-lg !p-3 !border-2 !border-[#6E8EDF] transition-all hover:brightness-95 !w-fit disabled:opacity-50 disabled:pointer-events-none"
                            disabled={isLoading || isEditorApprovalPending}
                            onClick={requestEditorAccess}
                        >
                            {isLoading && <Loader2 className="!h-5 !w-5 animate-spin text-white !mr-1" />}

                            <p className="text-white font-semibold text-base">Send Request</p>

                            <img src="/img/frameworks/arrow-narrow-right.svg" alt="arrow-right" className="!h-5 !w-5" />
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
