import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { UploadBulkVendors, EditVendor } from 'store/actions/vendorActions';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import { accept } from 'validate';
import { toast } from 'react-toastify';
import FolderIcon from 'assets/img/folder.svg';
import AppCenteredModalNew from 'components/new_components/AppCenteredModalNew';
import XslIcon from 'assets/img/xsl.svg';
import { LoadingButton } from '@mui/lab';

const UploadVendorModal = (props) => {
    const [loading, setLoading] = useState(false);
    const { excel } = accept;
    const { open, handleClose, UploadBulkVendors } = props;
    const [document, setDocument] = useState(null);
    const [error, setError] = useState(false);

    //translation
    const { t } = useTranslation('vendorManagement');

    const dragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && excel.includes(file?.type)) {
            setError('');
            setDocument(file);
        } else {
            setError('Invalid file type');
            // enqueueSnackbar('Invalid Data Dropped', { variant: 'error' });
        }
    };

    const uploadImage = async (event) => {
        const input = event.target;
        const file = input.files?.item(0);

        if (!file) return;
        const fileSize = file.size / 1000 / 1000;

        if (fileSize > 2) {
            toast.info('File too large, minimum of 5MB');
            setDocument('');
        } else {
            setDocument(file);
        }
    };

    const handleSubmit = async () => {
        if (!document) {
            setError('Please upload file');
        } else {
            setError('');
            const formData = new FormData();
            setLoading(true);
            formData.append('file_upload', document);
            const res = await UploadBulkVendors(formData);
            setLoading(false);
            if (res?.success) {
                toast.success('Vendors Added Successfully');
                onClose(); // Reset document and form input on success
            } else {
                toast.error(res?.message);
            }
        }
    };

    const onClose = () => {
        setDocument(null);
        setError('');
        handleClose();
    };

    return (
        <AppCenteredModalNew
            open={open}
            handleClose={onClose}
            title={
                <Typography sx={{ color: '#202D66', fontWeight: 600, fontSize: '18px' }}>
                    {t('vendorModal.addVendorUpload')}
                </Typography>
            }
            icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
            noShadow
            noClose
            headerAction={
                <Box
                    sx={{
                        border: '1px solid #E2E8F0',
                        borderRadius: 30,
                        width: 30,
                        height: 30,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={onClose}
                >
                    <CloseIcon sx={{ width: 20, height: 20 }} />
                </Box>
            }
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                        mt: 3,
                        backgroundColor: '#F8FAFC',
                        height: '80px',
                        mx: '-2rem',
                        mb: '-2rem',
                        px: '2rem',
                        pt: '1rem',
                        pb: '4.5rem',
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit', color: '#334155' }}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        sx={{ textTransform: 'inherit', fontWeight: 600 }}
                        onClick={handleSubmit}
                        loading={loading}
                    >
                        RUN
                    </LoadingButton>
                </Box>
            }
            width="550px"
        >
            <Box sx={{ mt: 2 }}>
                <Typography
                    sx={{ backgroundColor: '#F8FAFC', color: '#64748B', fontSize: '14px', fontWeight: '400', p: 2 }}
                >
                    Having multiple vendors to add?.{' '}
                    <Typography
                        component="a"
                        href={'https://secure-gen.s3.amazonaws.com/templates/Vendors Upload Template.xlsx'}
                        target="_blank"
                        rel="noreferrer"
                        sx={{ color: '#395BA9', textDecoration: 'underline', fontSize: '14px', fontWeight: '400' }}
                    >
                        Download our XLSX template
                    </Typography>{' '}
                    and fill it with the required information
                </Typography>

                <Box
                    sx={{
                        border: '1px dashed #CBD5E1',
                        borderRadius: 2,
                        padding: 1,
                        minHeight: 150,
                        position: 'relative',
                        mt: 2,
                        cursor: 'pointer',
                    }}
                >
                    <label htmlFor="csv-input" className="mb-0 w-full cursor-pointer">
                        <Box id="drop-zone" onDrop={handleDrop} onDragOver={dragOver} sx={{ height: '100%', p: 2 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                }}
                            >
                                <img src={FolderIcon} alt={FolderIcon} />
                                <Typography sx={{ color: '#64748B', fontSize: '12px', fontWeight: 500 }}>
                                    Drop your files or click to upload
                                </Typography>
                                <Typography sx={{ color: '#94A3B8', fontSize: '12px', fontWeight: 400 }}>
                                    Supported file types: CSV:
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                                <Box
                                    sx={{
                                        border: '1px solid #E2E8F0',
                                        cursor: 'pointer',
                                        padding: 0.5,
                                    }}
                                >
                                    <Typography sx={{ color: '#475569', fontWeight: 400, fontSize: 12 }}>
                                        Browse
                                    </Typography>
                                </Box>

                                <input
                                    type="file"
                                    accept={`${excel}`}
                                    onChange={(e) => uploadImage(e)}
                                    hidden
                                    id="csv-input"
                                />
                            </Box>
                            {/* {errors[name] && <FormHelperText error>{formatSentence(errors[name])}</FormHelperText>} */}
                        </Box>
                    </label>
                </Box>
                {error ? (
                    <Typography sx={{ fontSize: '10px', fontWeight: 400, color: '#D91B1B', mt: 0.5 }}>
                        {error}
                    </Typography>
                ) : null}
                {document && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mt: 3,
                        }}
                    >
                        <img src={XslIcon} alt="icon" />
                        <Box sx={{ ml: 1 }}>
                            <Typography
                                sx={{ color: '#475569', fontWeight: 400, fontSize: '14px', wordBreak: 'break-word' }}
                            >
                                {document.name}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
        </AppCenteredModalNew>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { UploadBulkVendors, EditVendor })(UploadVendorModal);
