import React, { useState, useEffect, useContext, useRef, useMemo, useCallback } from 'react';

// core components
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import TabLayout from './tabLayout';
// import PreviewLink from './components/PreviewLink';
import OnboardTable from './components/OnboardTable';
import PageHeader from 'components/new_components/PageHeader';

// redux
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';

//userguide
import { Tour } from 'antd';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import AppArticle from 'components/new_components/AppArticle';

//translations
import { useTranslation } from 'react-i18next';
import { UserGuideHeader } from 'components/new_components/AppUserGuide';
import { GetAllMerchantOnboardingPolicies } from 'store/actions/onboardingActions';
import { GetAllMerchantOnboardingVideos } from 'store/actions/onboardingActions';

const OnboardingPolicy = () => {
    // redux stores
    const { onboarding_policies = [], onboarding_videos = [] } = useSelector(
        (state) => state?.onboardingReducers || {}
    );

    const { userguide_info = [] } = useSelector((state) => state?.guideReducers || {});
    const { all_article_header = [] } = useSelector((state) => state?.articlesReducers || {});
    // state
    const [currentTab, setCurrentTab] = useState(0);
    // const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    //userguide
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const dispatch = useDispatch();

    const STEPS_LENGTH = 2;

    const steps = [
        {
            title: <UserGuideHeader title="Policy Documents" id={1} length={STEPS_LENGTH} />,
            description: 'You can upload the policies that govern your organization here.',
            target: () => ref1.current,
            placement: 'bottomLeft',
        },
        {
            title: <UserGuideHeader title="Awareness Videos" id={2} length={STEPS_LENGTH} />,
            description: 'Upload videos that create awareness to your employees.',
            target: () => ref2.current,
            placement: 'bottomLeft',
        },
    ];

    //translation
    const { t } = useTranslation('onboardingPolicy');

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Onboarding Policy'),
        [all_article_header]
    );

    // logic functions
    const handleTabChange = (tab) => {
        console.log(tab);
        setCurrentTab(tab);
    };

    const getAllMerchantOnboardingPolicies = async (filters) => {
        setLoading(true);
        const res = await dispatch(GetAllMerchantOnboardingPolicies(filters));
        if (!res?.success) {
            toast.error(res?.message);
        }
        setLoading(false);
    };
    const getAllmerchantOnboardingVideos = async (filters) => {
        setLoading(true);
        const res = await dispatch(GetAllMerchantOnboardingVideos(filters));
        if (!res?.success) {
            toast.error(res?.message);
        }
        setLoading(false);
    };

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ onboarding: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, onboarding: false });
        setOpenArticle(false);
    };

    const refetch = useCallback(
        (filters) => {
            if (currentTab == 0) {
                getAllMerchantOnboardingPolicies(filters);
            } else {
                getAllmerchantOnboardingVideos(filters);
            }
        },
        [currentTab]
    );

    // useEffect
    useEffect(() => {
        getAllMerchantOnboardingPolicies();
        getAllmerchantOnboardingVideos();
    }, []);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.onboarding ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.onboarding]);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.onboarding ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.onboarding]);
    // smartsearch actions
    useEffect(() => {
        if (location.state?.parent && location.state?.parent === 'policies') {
            if (location.state.modalType) {
                switch (location.state.modalType) {
                    case 'documents':
                        setCurrentTab(0);
                        return;
                    case 'awarenessVideos':
                        setCurrentTab(1);
                        return;
                    default:
                        return;
                }
            }
        }
    }, [location]);

    return (
        <>
            <PageHeader browserTitle="Onboarding Process | Smartcomply" />
            <TabLayout
                onTabChange={handleTabChange}
                tab={currentTab}
                tabs={t('tabs', { returnObjects: true })}
                ref1={ref1}
                ref2={ref2}
            >
                <AppTabPanel value={currentTab} index={0}>
                    <OnboardTable refetch={refetch} data={onboarding_policies} loading={loading} type="document" />
                </AppTabPanel>
                <AppTabPanel value={currentTab} index={1}>
                    <OnboardTable data={onboarding_videos} refetch={refetch} loading={loading} type="video" />
                </AppTabPanel>
            </TabLayout>
            <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} />
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Onboarding Policy"
                headerId={oneArticle?.id}
                icon={true}
            />
        </>
    );
};
export default OnboardingPolicy;
