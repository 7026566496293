import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { Box, Button, Modal, Typography, useTheme } from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { ChangeMerchantOnboardingPolicyStatus } from 'store/actions/onboardingActions';

const EnablePolicyModal = (props) => {
    const { open, handleClose, refetch, type, row } = props;
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation('onboardingPolicy');
    const dispatch = useDispatch();
    const theme = useTheme();

    const handleClick = async () => {
        const payload = { status: 'enabled', id: row?.id };

        setLoading(true);
        const res = await dispatch(ChangeMerchantOnboardingPolicyStatus(payload));

        if (res?.success) {
            toast.success('Item enabled successfully');
            refetch();
            handleClose();
        } else {
            toast.error(res?.message);
        }
        setLoading(false);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="enable-policy-modal"
            aria-describedby="enable-policy-modal"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 450,
                    bgcolor: 'white',
                    boxShadow: 24,
                    borderRadius: 2,
                    outline: 'none',
                }}
            >
                {/* Modal Content */}
                <Box
                    sx={{
                        p: 3,
                        textAlign: 'center',
                        background: '#fff',
                    }}
                >
                    <Typography
                        id="vendor-delete-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                            fontWeight: 600,
                            fontSize: '18px',
                            color: theme.palette.gray[600],
                        }}
                    >
                        {type === 'document'
                            ? t('enableOnboardingItem.policyTitle')
                            : t('enableOnboardingItem.videoTitle')}
                    </Typography>

                    <Typography
                        id="vendor-delete-modal-description"
                        sx={{
                            mt: 2,
                            color: theme.palette.gray[500],
                            fontSize: '14px',
                        }}
                    >
                        {type === 'document'
                            ? t(`enableOnboardingItem.policySubtitle`)
                            : t(`enableOnboardingItem.videoSubtitle`)}
                    </Typography>
                </Box>

                {/* Footer with Buttons */}
                <Box
                    sx={{
                        bgcolor: '#F8FAFC',
                        py: 3,
                        px: 3,
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 2,
                        borderTop: '1px solid #E5E7EB',
                    }}
                >
                    <Button
                        variant="text"
                        onClick={handleClose}
                        sx={{
                            textTransform: 'none',
                            color: theme.palette.gray[900],
                            background: '#fff',
                            border: '1px solid #E2E8F0',
                        }}
                    >
                        {t('buttonText.cancel')}
                    </Button>

                    <LoadingButton
                        variant="contained"
                        onClick={handleClick}
                        loading={loading}
                        sx={{
                            textTransform: 'none',
                        }}
                    >
                        {t('buttonText.enable')}
                    </LoadingButton>
                </Box>
            </Box>
        </Modal>
    );
};

const DisablePolicyModal = (props) => {
    const { open, handleClose, refetch, type, row } = props;
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation('onboardingPolicy');
    const dispatch = useDispatch();
    const theme = useTheme();

    const handleClick = async () => {
        const payload = { status: 'disabled', id: row?.id };
        setLoading(true);
        const res = await dispatch(ChangeMerchantOnboardingPolicyStatus(payload));

        if (res?.success) {
            toast.success('Item enabled successfully');
            refetch();
            handleClose();
        } else {
            toast.error(res?.message);
        }
        setLoading(false);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="enable-policy-modal"
            aria-describedby="enable-policy-modal"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 450,
                    bgcolor: 'white',
                    boxShadow: 24,
                    borderRadius: 2,
                    outline: 'none',
                }}
            >
                {/* Modal Content */}
                <Box
                    sx={{
                        p: 3,
                        textAlign: 'center',
                        background: '#fff',
                    }}
                >
                    <Typography
                        id="vendor-delete-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                            fontWeight: 600,
                            fontSize: '18px',
                            color: theme.palette.gray[600],
                        }}
                    >
                        {type === 'document'
                            ? t('disableOnboardingItem.policyTitle')
                            : t('disableOnboardingItem.videoTitle')}
                    </Typography>

                    <Typography
                        id="vendor-delete-modal-description"
                        sx={{
                            mt: 2,
                            color: theme.palette.gray[500],
                            fontSize: '14px',
                        }}
                    >
                        {type === 'document'
                            ? t(`disableOnboardingItem.policySubtitle`)
                            : t(`disableOnboardingItem.videoSubtitle`)}
                    </Typography>
                </Box>

                {/* Footer with Buttons */}
                <Box
                    sx={{
                        bgcolor: '#F8FAFC',
                        py: 3,
                        px: 3,
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 2,
                        borderTop: '1px solid #E5E7EB',
                    }}
                >
                    <Button
                        variant="text"
                        onClick={handleClose}
                        sx={{
                            textTransform: 'none',
                            color: theme.palette.gray[900],
                            background: '#fff',
                            border: '1px solid #E2E8F0',
                        }}
                    >
                        {t('buttonText.cancel')}
                    </Button>

                    <LoadingButton
                        variant="contained"
                        color="error"
                        onClick={handleClick}
                        loading={loading}
                        sx={{
                            textTransform: 'none',
                            backgroundColor: '#FF5449',
                            boxShadow: 'none!important',
                            '&:hover': {
                                backgroundColor: '#E0443D',
                            },
                        }}
                    >
                        {t('buttonText.disable')}
                    </LoadingButton>
                </Box>
            </Box>
        </Modal>
    );
};

export { EnablePolicyModal, DisablePolicyModal };
