import { Grid } from '@mui/material';
import { useMemo } from 'react';
// core components
import LoadingState from 'components/new_components/LoadingState';
import ControlCard from './ControlCard';
// redux
import { connect, useSelector } from 'react-redux';

//translations
import EmptyState from 'components/new_components/EmptyState';
import { useTranslation } from 'react-i18next';

const Controls = (props) => {
    const {
        // resource,
        // onViewChange,
        loading,
        all_exempted_controls,
        openTaskAssignModal,
        openExemptModal,
        openRevertModal,
        all_compliance_assigned_tasks,
        // all_soc2_documents,
        // all_soc2_forms,
        // all_assigned_task,
        isExceptionTab,
    } = props;

    //translation
    const { t } = useTranslation('compliance');
    const all_compliance_management = useSelector((state) => state?.ISO37301Reducers?.all_compliance_management);
    const all_compliance_management_summary = useSelector(
        (state) => state?.ISO37301Reducers?.all_compliance_management_summary
    );

    const exmeptedControlsId = useMemo(
        () =>
            all_exempted_controls
                ?.filter((item) => item?.standard === 'iso37301')
                ?.map((item) => item?.complianc_mgt_id),
        [all_exempted_controls]
    );

    const filteredControls = useMemo(() => {
        if (isExceptionTab) {
            return all_compliance_management?.filter((item) => exmeptedControlsId?.includes(item?.id));
        } else {
            return all_compliance_management?.filter((item) => !exmeptedControlsId?.includes(item?.id));
        }
    }, [all_compliance_management, exmeptedControlsId, isExceptionTab]);

    // const sortedControls = useMemo(() => {
    //     if (!all_compliance_management || !all_exempted_controls) {
    //         return [];
    //     }

    //     //sort to get controls not exempted
    //     const notExemptedControls = all_compliance_management.filter((control) => {
    //         return !all_exempted_controls.some(
    //             (exempted_control) => exempted_control?.compliance_mgt_detail?.title === control?.title
    //         );
    //     });
    //     //sort to get controls exempted
    //     const exemptedControls = all_compliance_management
    //         .filter((control) => {
    //             return all_exempted_controls.some(
    //                 (exempted_control) => exempted_control?.compliance_mgt_detail?.title === control?.title
    //             );
    //         })
    //         .map((ec) => ({
    //             ...ec,
    //             exempted: true,
    //         }));

    //     return [...notExemptedControls, ...exemptedControls];
    // }, [all_compliance_management, all_exempted_controls]);

    // const subControlAssignedChecker = (assignedSubControl, sub_controls) => {
    //     let matchedRequirements;
    //     const subControls = (sub_controls?.length ? sub_controls : [])?.map((subCon) => subCon?.title);
    //     matchedRequirements = assignedSubControl?.filter((item) => {
    //         return subControls?.includes(item);
    //     });
    //     return matchedRequirements?.length;
    // };

    // const documentsByRequirement = useMemo(() => {
    //     return all_soc2_documents?.filter((document) => {
    //         const soc2Documents = all_compliance_assigned_tasks?.documents?.forms?.iso37301 || [];

    //         const res = soc2Documents?.length ? soc2Documents.filter((item) => document?.id === item) : [];

    //         return res?.length ? document?.sub_control : null;
    //     });
    //     // .map((document) => Math.floor(document?.requirement_sub_no));
    // }, [all_soc2_documents, all_compliance_assigned_tasks?.documents?.forms?.iso37301]);

    // const formsByRequirement = useMemo(() => {
    //     return all_soc2_forms?.filter((form) => {
    //         const soc2Forms = all_compliance_assigned_tasks?.questions?.forms?.iso37301 || [];

    //         const res = soc2Forms?.length ? soc2Forms.filter((item) => form?.id === item) : [];

    //         return res?.length ? form?.sub_control : null;
    //     });
    //     // .map((form) => Math.floor(form?.requirement_sub_no));
    // }, [all_soc2_forms, all_compliance_assigned_tasks?.documents?.forms?.iso37301]);
    return !loading ? (
        <Grid container spacing={2.5} sx={{ p: 4 }}>
            {filteredControls?.length ? (
                filteredControls
                    ?.sort((a, b) => a?.title?.localeCompare(b?.title))
                    ?.map((control, index) => {
                        const {
                            // total_form,
                            // total_doc,
                            // user_form,
                            // user_doc,
                            doc_review_stats,
                        } = all_compliance_management_summary?.[control?.title] || {};
                        const metric =
                            // resource === 'doc'
                            // ? { totalValue: total_doc, totalAttendedTo: user_doc }
                            {
                                totalValue: doc_review_stats?.split('/')[1],
                                totalAttendedTo: doc_review_stats?.split('/')[0],
                            };
                        // : { totalValue: total_form, totalAttendedTo: user_form };
                        // const resourceLabel = resource === 'doc' ? t('fileUploaded') : t('questionAnswered');
                        const isControlAssigned =
                            // resource === 'doc'
                            // ? all_compliance_assigned_tasks?.documents?.category?.iso37301?.includes(control?.id)
                            all_compliance_assigned_tasks?.documents?.category?.iso37301?.includes(control?.id);
                        // : all_compliance_assigned_tasks?.questions?.category?.iso37301?.includes(control?.id);
                        // const isSubControlAssigned =
                        //     resource === 'doc'
                        //         ? subControlAssignedChecker(
                        //               all_compliance_assigned_tasks?.documents?.sub_category?.iso37301,
                        //               control?.sub_controls
                        //           )
                        //         : subControlAssignedChecker(
                        //               all_compliance_assigned_tasks?.questions?.sub_category?.iso37301,
                        //               control?.sub_controls
                        //           );

                        // const assigned_document_category = all_assigned_task?.filter(
                        //     (document) =>
                        //         document?.compliance_mgt === control?.id && document?.completion_status === 'missed'
                        // );
                        // const missed_documents = assigned_document_category?.find(
                        //     (item) => item?.compliance_task_info?.type === 'document(s)'
                        // );
                        // const missed_questions = assigned_document_category?.find(
                        //     (item) => item?.compliance_task_info?.type === 'forms(s)'
                        // );
                        // const task_missed = resource === 'doc' ? missed_documents : missed_questions;
                        return (
                            <ControlCard
                                key={control?.id}
                                index={index}
                                id={control?.id}
                                title={control?.title}
                                description={control?.description}
                                // service={control?.service_category}
                                // exempted={control?.exempted}
                                metric={metric}
                                // resourceLabel={resourceLabel}
                                // onViewChange={onViewChange}
                                isControlAssigned={isControlAssigned}
                                // isSubControlAssigned={isSubControlAssigned}
                                openTaskAssignModal={openTaskAssignModal}
                                openExemptModal={openExemptModal}
                                openRevertModal={openRevertModal}
                                // isTaskMissed={task_missed}
                                // resourceAssigned={resource === 'doc' ? documentsByRequirement : formsByRequirement}
                                isExceptionTab={isExceptionTab}
                            />
                        );
                    })
            ) : (
                <EmptyState description={isExceptionTab ? t('noException') : t('noControl')} />
            )}
        </Grid>
    ) : (
        <LoadingState />
    );
};
const mapStateToProps = (state) => {
    return {
        all_exempted_controls: state?.complianceReducers?.all_exempted_controls,
        // all_soc2_documents: state?.adminReducers?.all_documents_by_tags,
        // all_soc2_forms: state?.adminReducers?.all_forms_by_tag,
        // all_assigned_task: state?.tasksReducers?.all_assigned_tasks_by_standard,
    };
};
export default connect(mapStateToProps, {})(Controls);
