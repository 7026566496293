/* eslint-disable no-unused-vars */

import { Dialog } from '@mui/material';

export default function DocumentLoadingOverlay({ isOpen }) {
    return (
        <Dialog
            open={isOpen}
            PaperProps={{
                sx: {
                    borderRadius: '0px !important',
                    padding: 0,
                    boxShadow: '0px 16px 24px 0px #00000026',
                    width: '100%',
                    height: '100%',
                    margin: 0,
                    maxWidth: '100%',
                    maxHeight: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                },
            }}
        >
            <div className="flex flex-col items-center justify-center !gap-1.5 !h-full !w-full">
                <div className="relative">
                    <CountDownSpinner />

                    <img
                        src="/img/frameworks/stars-loading.svg"
                        alt="star"
                        className="object-contain absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
                    />
                </div>

                <p className="text-white font-semibold">Extracting document Information...</p>
            </div>
        </Dialog>
    );
}

function CountDownSpinner() {
    const progress = 30;
    const circleLength = 2 * Math.PI * 40;

    return (
        <svg viewBox="0 0 100 100" className="h-[87.5px] w-[87.5px] animate-spin">
            <circle cx="50" cy="50" r="40" stroke="#ffffff1a" strokeWidth="12" fill="none" />
            <circle
                cx="50"
                cy="50"
                r="40"
                stroke="white"
                strokeWidth="12"
                fill="none"
                strokeDasharray={circleLength}
                strokeDashoffset={circleLength * (1 - progress / 100)}
                strokeLinecap="round"
                transform="rotate(-90 50 50)"
                style={{
                    transition: 'stroke-dashoffset 1s linear',
                }}
            />
        </svg>
    );
}
