import { Box } from '@mui/material';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';

// core components
import PageHeader from 'components/new_components/PageHeader';
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import ComplianceLayoutNew from '../common/NewComplianceLayout';
// import AuditQuestions from './AuditQuestions';
import Documents from './Documents';
// import Exceptions from './Exceptions';

// redux
import { useDispatch, useSelector } from 'react-redux';
// import { GetAllDocumentsByTags, GetAllFormsByTags } from 'store/actions/adminActions';
import { GetAllExemptedControls } from 'store/actions/complianceActions';
import { CreateModuleGuide, GetModuleGuides, UpdateModuleGuide } from 'store/actions/generalActions';
import { GetCompanyDetails } from 'store/actions/merchantActions';
import AllTaskModal from '../common/AllTaskModal';
// import AssignTaskModal from '../common/AssignTaskModalNew';
// import DataRoomModal from '../common/DataRoomModal';
// import PolicyApprovalModal from '../common/PolicyApprovalModal';
import TaskIntroductionModal from '../common/TaskIntroductionModal';
// import { GetAllMerchantEvidences, GetEvidenceMetrics } from 'store/actions/complianceActions';
// import ComplianceAITipsModal from '../common/ComplianceAITipsModal';

//Userguide
import { Tour } from 'antd';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideHeader } from 'components/new_components/AppUserGuide';
// import { GetAllMerchantEvidences, GetEvidenceMetrics } from 'store/actions/complianceActions';
import { GetUserDetails } from 'store/actions/generalActions';
import { GetAllLifecycles, GetControlObjectiveMetrics } from 'store/actions/pci_slcActions';
import { GetAllTasksByStandard } from 'store/actions/taskActions';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import AssignToDialog from '../common/document_review/components/AssignToDialog';
import ExemptRequirementDialog from '../common/document_review/components/ExemptRequirementDialog';
import RevertRequirementDialog from '../common/document_review/components/RevertRequirementDialog';
import { useLocation } from 'react-router-dom';

const PCISLC = () => {
    // state
    const [currentTab, setCurrentTab] = useState(0);
    const [loading, setLoading] = useState({ controls: false, exceptions: false, metrics: false });
    const [introModalOpen, setIntroModalOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [tasksModalOpen, setTaskModalOpen] = useState(false);
    const [exmeptModalOpen, setExemptModalOpen] = useState(false);
    const [revertModalOpen, setRevertModalOpen] = useState(false);
    // const [policyModalOpen, setPolicyModalOpen] = useState(false);
    // const [dataRoomModalOpen, setDataRoomModalOpen] = useState(false);
    // const [modalType, setModalType] = useState(null);
    const [taskInfo, setTaskInfo] = useState({});
    // const [view, setView] = useState(0);
    // const [openAiModal, setOpenAiModal] = useState(false);
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    const location = useLocation();

    // redux
    const dispatch = useDispatch();
    const all_Lifecycle = useSelector((state) => state?.pcislcReducers?.all_Lifecycle);
    const all_Lifecycle_summary = useSelector((state) => state?.pcislcReducers?.all_Lifecycle_summary);
    // const documents_by_control = useSelector((state) => state?.pcislcReducers?.documents_by_control);
    // const evidences_metrics = useSelector((state) => state?.complianceReducers?.all_evidences_metrics);
    const guideModal = useSelector((state) => state?.generalReducers?.module);
    // const company_details = useSelector((state) => state?.authReducers?.company_details);
    const userguide_info = useSelector((state) => state?.guideReducers?.userguide_info?.[0]?.compliance);
    const all_article_header = useSelector((state) => state?.articlesReducers?.all_article_header);
    // const all_merchant_documents = useSelector((state) => state?.complianceReducers?.all_merchant_documents);
    const user_id = useSelector((state) => state?.generalReducers?.user_info?.employee?.id);
    const all_assigned_task = useSelector((state) => state?.tasksReducers?.all_assigned_tasks_by_standard);

    // const standards = company_details?.standards?.split(',');
    //userguide
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);
    const ref1 = useRef(null);
    // const ref2 = useRef(null);
    const ref3 = useRef(null);

    const STEPS_LENGTH = 3;

    const steps = [
        {
            title: <UserGuideHeader title="Documents" id={1} length={STEPS_LENGTH} />,
            description: 'Here you can Upload compliance policies.',
            target: () => ref1.current,
            placement: 'rightTop',
        },
        // {
        //     title: <UserGuideHeader title="Audit Questions" id={2} length={STEPS_LENGTH} />,
        //     description: 'Respond to audit-related queries here.',
        //     target: () => ref2.current,
        //     placement: 'rightTop',
        // },
        {
            title: <UserGuideHeader title="Exceptions" id={3} length={STEPS_LENGTH} />,
            description: 'This tab displays requirements that are exempted',
            target: () => ref3.current,
            placement: 'rightTop',
        },
    ];

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Compliance'),
        [all_article_header]
    );

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ compliance: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, compliance: false });
        setOpenArticle(false);
    };

    // functions
    // logic functions
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
    };
    // const handleOpenAiModal = () => {
    //     setOpenAiModal(true);
    // };
    // const handleCloseAiModal = () => {
    //     setOpenAiModal(false);
    // };

    //task introduction/explanation modal
    const closeIntroModal = () => {
        setIntroModalOpen(false);
    };
    // async functions
    const getAllTasksByStandard = async () => {
        const res = await dispatch(GetAllTasksByStandard('sslc', user_id));
        if (!res.message) {
            toast.error(res.message);
        }
    };

    const openTaskAssignModal = (type, info) => {
        setModalOpen(true);
        // setModalType(type);
        setTaskInfo(info);
    };
    const closeTaskAssignModal = () => {
        setModalOpen(false);
        // setTimeout(() => setModalType(null), 500);
        setTaskInfo({});
    };

    const openExemptModal = (type, info) => {
        setExemptModalOpen(true);
        setTaskInfo(info);
    };
    const closeExemptModal = () => {
        setExemptModalOpen(false);
        setTaskInfo({});
    };

    const openRevertModal = (type, info) => {
        setRevertModalOpen(true);
        setTaskInfo(info);
    };
    const closeRevertModal = () => {
        setRevertModalOpen(false);
        setTaskInfo({});
    };

    const openTasksModal = () => {
        setTaskModalOpen(true);
    };
    const closeTasksModal = () => {
        setTaskModalOpen(false);
    };

    // const openPolicyModal = () => {
    //     setPolicyModalOpen(true);
    // };
    // const closePolicyModal = () => {
    //     setPolicyModalOpen(false);
    // };

    // const openDataRoomModal = () => {
    //     setDataRoomModalOpen(true);
    // };
    // const closeDataRoomModal = () => {
    //     setDataRoomModalOpen(false);
    // };
    // async functions
    // get all the controls
    const getAllLifecycle = async () => {
        setLoading((current) => ({
            ...current,
            controls: !all_Lifecycle?.length,
        }));
        const res = await dispatch(GetAllLifecycles());
        setLoading((current) => ({ ...current, controls: false }));
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch requirements.");
        }
    };
    // get all requirement metric for this merchant
    const getControlMetrics = async () => {
        setLoading((current) => ({
            ...current,
            metrics: !all_Lifecycle_summary?.length,
        }));
        const res = await dispatch(GetControlObjectiveMetrics());
        setLoading((current) => ({ ...current, metrics: false }));
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch requirements.");
        }
    };

    // const getEvidenceMetrics = async () => {
    //     setLoading((current) => ({
    //         ...current,
    //         controls: !all_Lifecycle_summary?.length,
    //     }));
    //     const res = await dispatch(GetEvidenceMetrics('sslc'));
    //     setLoading((current) => ({ ...current, controls: false }));
    //     if (!res.success) {
    //         toast.error("Something went wrong! Couldn't fetch requirements.");
    //     }
    // };
    // const getAllMerchantDocuments = async () => {
    //     const res = await dispatch(GetAllMerchantDocuments());
    //     if (!res.success) {
    //         toast.error("Something went wrong! Couldn't fetch your documents.");
    //     }
    // };

    // const getAllMerchantEvidences = async () => {
    //     const res = await dispatch(GetAllMerchantEvidences());
    //     if (!res.success) {
    //         toast.error("Something went wrong! Couldn't fetch your documents.");
    //     }
    // };
    // const getMerchantAnswers = async () => {
    //     const res = await dispatch(GetAllMerchantAnswers());
    //     if (!res?.success) {
    //         toast.error("Something went wrong! Couldn't fetch previous answers.");
    //     }
    // };
    //get all exempted controls
    const getAllExemptedControls = async () => {
        setLoading((current) => ({
            ...current,
            exceptions: true,
        }));
        const res = await dispatch(GetAllExemptedControls());
        setLoading((current) => ({ ...current, exceptions: false }));
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch exempted controls.");
        }
    };
    const getUserDetails = async () => {
        const res = await dispatch(GetUserDetails());
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch user details");
        }
    };

    const getCompanyDetails = async () => {
        const res = await dispatch(GetCompanyDetails());
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch company details");
        }
    };

    const getModuleGuides = async () => {
        const res = await dispatch(GetModuleGuides());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    // const getAllFormsByTags = async () => {
    //     const res = await dispatch(GetAllFormsByTags('sslc'));
    //     if (!res?.success) {
    //         toast.error(res?.message);
    //     }
    // };
    // const getAllDocumentByTags = async () => {
    //     const res = await dispatch(GetAllDocumentsByTags('sslc'));
    //     if (!res?.success) {
    //         toast.error(res?.message);
    //     }
    // };

    const updateModuleIntroModal = async () => {
        const res =
            guideModal && guideModal[0]
                ? await UpdateModuleGuide({ compliance_task: true }, guideModal[0]?.id)
                : await CreateModuleGuide({ compliance_task: true });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        getAllLifecycle();
        getControlMetrics();
        // getMerchantAnswers();
        // getAllMerchantDocuments();
        getAllExemptedControls();
        getCompanyDetails();
        getUserDetails();
        getModuleGuides();
        // getAllFormsByTags();
        // getAllDocumentByTags();
        // getAllMerchantEvidences();
        // getEvidenceMetrics();
        getAllTasksByStandard();
    }, [currentTab]);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.compliance ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.compliance]);

    // const filter_documents_by_approved = all_merchant_documents?.filter(
    //     (doc) => doc?.tag === 'sslc' && doc?.approved_status === 'pending' && doc?.approved_by === user_id
    // );

    // smartsearch
    useEffect(() => {
        if (location.state?.parent && location.state?.parent === 'pcislc') {
            if (location.state.modalType) {
                switch (location.state.modalType) {
                    case 'documents':
                        setCurrentTab(0);
                        return;
                    case 'auditQuestions':
                        setCurrentTab(1);
                        return;
                    case 'exceptions':
                        setCurrentTab(2);
                        return;
                    case 'dataroom':
                        // openDataRoomModal();
                        return;
                    case 'policyApproval':
                        // openPolicyModal();
                        return;
                    case 'tasks':
                        openTasksModal();
                        return;
                    default:
                        return;
                }
            }
        }
    }, [location]);

    return (
        <>
            <PageHeader browserTitle="PCI SLC - Compliance | Smartcomply" />
            <Box sx={{ px: { xs: 0, md: 0 }, py: { xs: 0, md: 0 } }}>
                <ComplianceLayoutNew
                    onTabChange={handleTabChange}
                    tab={currentTab}
                    openTasksModal={openTasksModal}
                    // openPolicyModal={openPolicyModal}
                    // openDataRoomModal={openDataRoomModal}
                    standard="pcislc"
                    // standards={standards}
                    // view={view}
                    ref1={ref1}
                    // ref2={ref2}
                    ref3={ref3}
                    // newPolicy={filter_documents_by_approved?.length}
                    newTask={all_assigned_task?.length}
                >
                    <AppTabPanel value={currentTab} index={0}>
                        <Documents
                            loading={loading.controls || loading.metrics || loading.exceptions}
                            // getControls={getAllLifecycle}
                            openTaskAssignModal={openTaskAssignModal}
                            openExemptModal={openExemptModal}
                            // all_metrics={all_Lifecycle_summary}
                            // view={view}
                            // setView={setView}
                        />
                    </AppTabPanel>
                    {/* <AppTabPanel value={currentTab} index={1}>
                        <AuditQuestions
                        loading={loading.controls}
                        getControls={getAllLifecycle}
                        openTaskAssignModal={openTaskAssignModal}
                        view={view}
                        setView={setView}
                        />
                        </AppTabPanel> */}
                    <AppTabPanel value={currentTab} index={2}>
                        {/* <Exceptions getControls={getAllLifecycle} /> */}
                        <Documents
                            loading={loading.controls || loading.metrics || loading.exceptions}
                            // getControls={getAllLifecycle}
                            // all_metrics={all_Lifecycle_summary}
                            isExceptionTab={true}
                            openRevertModal={openRevertModal}
                            // view={view}
                            // setView={setView}
                        />
                    </AppTabPanel>
                </ComplianceLayoutNew>
                {/* <ComplianceAITipsModal open={openAiModal} handleClose={handleCloseAiModal} width="700px" minWidth={900} /> */}
            </Box>
            <TaskIntroductionModal
                open={introModalOpen}
                handleClose={closeIntroModal}
                updateModuleIntroModal={updateModuleIntroModal}
            />

            {/* <AssignTaskModal open={modalOpen} onClose={closeTaskAssignModal} type={modalType} taskInfo={taskInfo} /> */}
            <AssignToDialog isOpen={modalOpen} handleCallback={closeTaskAssignModal} requirementData={taskInfo} />
            <ExemptRequirementDialog
                title="Control"
                subTitle="controls"
                isOpen={exmeptModalOpen}
                handleCallback={closeExemptModal}
                requirementData={taskInfo}
            />
            <RevertRequirementDialog
                title="Control"
                isOpen={revertModalOpen}
                handleCallback={closeRevertModal}
                requirementData={taskInfo}
            />
            <AllTaskModal open={tasksModalOpen} handleClose={closeTasksModal} standard="sslc" />
            {/* <PolicyApprovalModal open={policyModalOpen} handleClose={closePolicyModal} standard="sslc" />
            <DataRoomModal
                open={dataRoomModalOpen}
                handleClose={closeDataRoomModal}
                standard="sslc"
                all_requirements={all_Lifecycle}
                all_metrics={all_Lifecycle_summary}
                all_evidences_metrics={evidences_metrics}
                get_all_evidences_metrics={getEvidenceMetrics}
                documents_by_control={documents_by_control}
            /> */}

            {!loading.controls && <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} />}
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Compliance"
                headerId={oneArticle?.id}
                icon={true}
            />
        </>
    );
};

export default PCISLC;
