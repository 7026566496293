import React, { useEffect, useMemo, useState } from 'react';
import { ButtonGroup, Menu, MenuItem, Typography, useTheme, Button } from '@mui/material';
import AppTable from 'components/new_components/app-table2';
import useSearch from 'hooks/useSearch';
import KycHeading from './KycHeading';
import KycCheckModal from './components/KycCheckModal';
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import { GetKycResults } from 'store/actions/FraudGauardianActions';
import { Add } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KycTableAction from './components/KycTableAction';
import DeleteModal from './DeleteModal';
import AddVendorModal from './components/AddVendorModal';
import { vendorsInitialValues } from './utils/constants';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import KycDetailsModal from './components/KycDetailsModal';
import UploadCsv from './components/UploadCsvModal';
import ViewSourceModal from './components/ViewSourceModal';
import { useLocation } from 'react-router-dom';

const NO_PER_PAGE = 10;

const KycChecks = (props) => {
    // Props
    const { GetKycResults, kyc_results, reff } = props;
    const location = useLocation();

    // Hook States
    const [pageData, setPageData] = useState([]);
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false);
    const [modalDetailsOpen, setModalDetailsOpen] = useState(false);
    const [addVendorModal, setAddVendorModal] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [modalPayload, setModalPayload] = useState({});
    const [vendorPayload, setVendorPayload] = useState(vendorsInitialValues);
    const [modalUploadOpen, setModalUploadOpen] = useState(false);
    const [viewSource, setViewSource] = useState(false);
    const [allSources, setAllSource] = useState([]);
    const [risk_level, setRisk_level] = useState({
        name: 'All',
        value: 'all',
    });
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();

    const openMenu = (e) => setAnchorEl(e.target);
    const closeMenu = () => setAnchorEl(null);

    const openDetailsModal = (payload = {}) => {
        setModalDetailsOpen(true);
        setModalPayload(payload);
        closeMenu();
    };
    const handleViewSource = (sources) => {
        setAllSource(sources);
        setViewSource(true);
    };

    const closeDetailsModal = () => {
        setModalDetailsOpen(false);
    };

    const openDeleteModal = (payload = {}) => {
        setModalPayload(payload);
        setDeleteModalOpen(true);
    };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setModalPayload({});
    };

    const openVendorModal = () => {
        setAddVendorModal(true);
    };
    const closeVendorModal = () => {
        setAddVendorModal(false);
    };

    const openModalUpload = () => {
        setModalUploadOpen(true);
        closeMenu();
    };
    const closeModalUpload = () => {
        setModalUploadOpen(false);
    };

    const query_params = useMemo(() => {
        const params = {
            ...(risk_level?.value?.length && { risk_level: risk_level?.value === '' ? '' : risk_level?.value }),
        };
        return params;
    }, [risk_level]);

    const openModal = () => {
        setOpen(true);
    };
    const CloseModal = () => {
        setOpen(false);
    };

    const { data, handleSearch } = useSearch(kyc_results, ['name', 'risk_level', 'entity_type']);

    const columns = [
        {
            title: 'Name',
            key: 'name',
            render: (row) => (
                <Typography
                    sx={{
                        color: row?.result?.total_blacklist_hits ? '#FF5449' : '#5E5E62',
                        fontSize: '14px',
                        cursor: 'pointer',
                    }}
                >
                    {row?.name}
                </Typography>
            ),
        },
        {
            title: 'Date created',
            key: 'date_created',
            render: (row) => (
                <Typography sx={{ color: '#5E5E62', fontSize: '14px' }}>
                    {moment(row?.date_created).format('MMMM Do, YYYY')}
                </Typography>
            ),
        },
        {
            title: 'Risk Level',
            key: 'risk_level',
            render: (row) => (
                <div
                    className={`${
                        row?.result?.risk_level === 'low'
                            ? ' w-[50px]'
                            : row?.result?.risk_level === 'medium'
                            ? ' w-[70px]'
                            : row?.result?.risk_level === 'high'
                            ? ' w-[60px]'
                            : 'w-[32px]'
                    } flex items-center gap-1.5 border border-[#E1E2EC] rounded p-1`}
                >
                    {row?.result?.risk_level && (
                        <FiberManualRecordIcon
                            className={`${
                                row?.result?.risk_level === 'low'
                                    ? 'text-[#55BE8B]'
                                    : row?.result?.risk_level === 'medium'
                                    ? 'text-[#F2C021]'
                                    : 'text-[#FF5449]'
                            }`}
                            sx={{ width: '10px', height: '10px' }}
                        />
                    )}
                    <p className="text-gray-500 font-medium text-xs">{row?.result?.risk_level || 'N/A'}</p>
                </div>
                // <Typography sx={{ color: '#5E5E62', fontSize: '14px' }}>
                //     {row?.risk_level ? row?.risk_level?.replace(/_/g, ' ') : 'N/A'}
                // </Typography>
            ),
        },
        {
            title: 'Status',
            key: 'status',
            render: (row) => (
                <Typography
                    sx={{
                        color: '#5E5E62',
                        fontSize: '14px',
                        fontStyle: row?.status === 'running' ? 'italic' : '',
                        textTransform: 'capitalize',
                    }}
                >
                    {row?.status}
                </Typography>
            ),
        },
        {
            title: 'Search hit',
            key: 'total_hits',
            render: (row) => (
                <p className="text-gray-500 font-medium text-xs border border-[#E1E2EC] w-[30px] p-1 rounded flex items-center justify-center">
                    {row?.result?.total_hits ? row?.result?.total_hits : 'N/A'}
                </p>
            ),
        },
        {
            title: 'Blacklist hit',
            key: 'blacklist_hits',
            render: (row) => (
                <p
                    className={`${
                        row?.result?.total_blacklist_hits > 0 ? 'text-[#FF5449]' : 'text-gray-500'
                    } font-medium text-xs border border-[#E1E2EC] w-[30px] p-1 rounded flex items-center justify-center`}
                >
                    {row?.result?.total_blacklist_hits ? row?.result?.total_blacklist_hits : 'N/A'}
                </p>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (row) => (
                <>
                    <KycTableAction row={row} openDeleteModal={openDeleteModal} openDetailsModal={openDetailsModal} />
                </>
            ),
        },
    ];

    useEffect(() => {
        const getKycResult = async () => {
            setLoading(true);
            const res = await GetKycResults(query_params);
            setLoading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getKycResult();
    }, [query_params]);

    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;
        const new_data = data?.length ? data?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : [];
        let filteredData = [...new_data];

        if (risk_level?.value !== 'all') {
            filteredData = filteredData.filter(
                (item) => item?.result?.risk_level?.toLowerCase() === risk_level?.value.toLowerCase()
            );
        }

        setPageData(filteredData);

        return filteredData;
    }, [page, data, risk_level]);

    const handlePageChange = (page) => {
        setPage(page);
    };

    useEffect(() => {
        if (location.state?.parent && location.state?.parent === 'kyc') {
            if (location.state.modalType) {
                switch (location.state.modalType) {
                    case 'kycCheck':
                        openModal('add');
                        return;
                    case 'uploadExcel':
                        openModalUpload();
                        return;
                    default:
                        return;
                }
            }
        }
    }, [location]);

    return (
        <div className="mt-2 bg-white p-3">
            <AppTable
                columns={columns}
                data={pageData}
                sorter={(a, b) => b?.id - a?.id}
                loading={loading}
                title={<KycHeading onSearch={handleSearch} risk_level={risk_level} setRisk_level={setRisk_level} />}
                dataLength={kyc_results?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                onPageChange={handlePageChange}
                actions={[
                    <ButtonGroup variant="contained" aria-label="Basic button group">
                        <Button
                            startIcon={<Add />}
                            ref={reff}
                            color="primary"
                            variant="contained"
                            // disabled={reminderCount === 0}
                            disableElevation
                            onClick={() => openModal('add')}
                            sx={{
                                display: 'flex-inline',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontWeight: '600',
                                // borderRadius: '4px',
                                py: 1.2,
                                px: 2.4,
                                textTransform: 'unset',
                                '&.Mui-disabled': {
                                    color: theme.palette.white.main,
                                    backgroundColor: theme.palette.gray[600],
                                },
                            }}
                        >
                            Run Check
                        </Button>
                        <Button
                            onClick={openMenu}
                            color="primary"
                            variant="contained"
                            sx={{ borderLeft: '1px solid #5474C4', py: 1.2 }}
                        >
                            <KeyboardArrowDownIcon />
                        </Button>
                    </ButtonGroup>,
                ]}
            />
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={closeMenu}
                sx={{ mt: 2 }}
                PaperProps={{
                    style: {
                        width: 190,
                    },
                }}
            >
                <MenuItem sx={{ color: '#64748B', fontSize: '14px', fontWeight: 500 }} onClick={openModalUpload}>
                    Upload From EXCEL
                </MenuItem>
            </Menu>

            <KycCheckModal
                open={open}
                handleClose={CloseModal}
                openVendorModal={openVendorModal}
                values={vendorPayload}
            />
            <DeleteModal open={deleteModalOpen} handleClose={closeDeleteModal} id={modalPayload?.id} />
            <AddVendorModal
                open={addVendorModal}
                handleClose={closeVendorModal}
                payload={vendorPayload}
                setVendorPayload={setVendorPayload}
            />
            <KycDetailsModal
                open={modalDetailsOpen}
                handleClose={closeDetailsModal}
                details={modalPayload}
                handleViewSource={handleViewSource}
            />
            <UploadCsv open={modalUploadOpen} handleClose={closeModalUpload} />
            <ViewSourceModal open={viewSource} handleClose={() => setViewSource(false)} allSources={allSources} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        kyc_results: state?.fraudGuardianReducers?.kyc_results,
    };
};

export default connect(mapStateToProps, { GetKycResults })(KycChecks);
