/* eslint-disable no-unused-vars */

import { Menu, MenuItem } from '@mui/material';
import SearchIcon from 'assets/img/Searchicon.svg';
import { useMemo, useState } from 'react';

export default function TableFilter({
    searchText,
    setSearchText,
    sourceFilter,
    setSourceFilter,
    formattedDocReviewStats,
}) {
    const [anchorEl, setAnchorEl] = useState(null);

    const policyDocuments = useMemo(
        () =>
            formattedDocReviewStats?.reduce((acc, curr) => {
                if (!acc.includes(curr.source)) {
                    acc.push(curr.source);
                }

                return acc;
            }, []),
        [formattedDocReviewStats]
    );

    return (
        <div className="flex items-center !gap-2">
            <div className="relative">
                <input
                    placeholder="Search..."
                    value={searchText}
                    onChange={(event) => setSearchText(event.target.value)}
                    className="!border !border-[#E2E8F0] !py-2.5 !pr-2.5 !h-9 !w-[224px] pl-[31px] text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                />

                <img src={SearchIcon} alt="SearchIcon" className="absolute !left-2.5 top-1/2 -translate-y-1/2 !w-4" />
            </div>

            <div>
                <button
                    aria-controls={anchorEl ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={anchorEl ? 'true' : undefined}
                    onClick={(event) => {
                        event.stopPropagation();
                        setAnchorEl(event.currentTarget);
                    }}
                    className="transition-all hover:brightness-95 bg-white !border !border-[#E2E8F0] !rounded !py-1.5 !px-2 !h-9 flex items-center !gap-1.5 text-[#64748B] font-medium text-[13px] capitalize"
                >
                    <span>{sourceFilter === 'all' ? 'All policy documents' : sourceFilter?.replaceAll('_', ' ')}</span>

                    <img
                        src="/img/frameworks/material-symbols_keyboard-arrow-down-rounded.svg"
                        alt="chevron down"
                        className="!w-4 !h-4"
                    />
                </button>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={(event) => {
                        event.stopPropagation();
                        setAnchorEl(null);
                    }}
                    sx={{
                        '& .MuiPaper-root': {
                            borderRadius: '4px',
                            boxShadow: '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                        },
                        '& .MuiList-root': {
                            padding: '0 0',
                        },
                    }}
                >
                    <div className="max-h-[132px] max-w-[260px] overflow-auto custom-scroll">
                        <MenuItem
                            onClick={(event) => {
                                event.stopPropagation();
                                setAnchorEl(null);
                                setSourceFilter('all');
                            }}
                            sx={{
                                color: '#64748B',
                                fontWeight: 500,
                                fontSize: '14px',
                                textTransform: 'none',
                                padding: '6px 16px',
                                border: '1px solid #F1F5F9',
                            }}
                        >
                            All policy documents
                        </MenuItem>

                        {policyDocuments?.map((document) => (
                            <MenuItem
                                key={document}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setAnchorEl(null);
                                    setSourceFilter(document);
                                }}
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '13px',
                                    padding: '6px 16px',
                                    borderBottom: '1px solid #F1F5F9',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {document?.replaceAll('_', ' ')}
                            </MenuItem>
                        ))}
                    </div>
                </Menu>
            </div>
        </div>
    );
}
