import Quill from 'quill';
import QuillBetterTable from 'quill-better-table';
import { ImageDrop } from 'quill-image-drop-module';
import QuillResizeImage from 'quill-resize-image';
import { useCallback } from 'react';
import CustomToolbar from './CustomToolbar';
// import Comments from './Utilities/Comments';
// import PageBreak from './Utilities/PageBreak';
import {
    // CommentBlot,
    fonts,
    lineBreakMatcher,
} from './utils/db';

const Font = Quill.import('formats/font');
Font.whitelist = fonts;
Quill.register(Font, true);

const Size = Quill.import('attributors/style/size');
Size.whitelist = null;
Quill.register(Size, true);

const History = Quill.import('modules/history');
Quill.register('modules/history', History);

Quill.register('modules/resize', QuillResizeImage);

Quill.register('modules/imageDrop', ImageDrop);

Quill.register('modules/better-table', QuillBetterTable);

// Quill.register(PageBreakBlot);

// Quill.register(CommentBlot);

export default function Editor({ editorRef, templates, isEditable }) {
    const containerRef = useCallback((node) => {
        if (node === null) {
            editorRef.current = null;
            return;
        }

        node.innerHTML = '';

        const editor = document.createElement('div');

        node.appendChild(editor);

        const quill = new Quill(editor, {
            theme: 'snow',
            modules: {
                toolbar: false,
                history: {
                    delay: 2000,
                    maxStack: 500,
                    userOnly: true,
                },
                resize: {
                    locale: {},
                },
                imageDrop: true,
                table: false,
                'better-table': {
                    operationMenu: {
                        items: {
                            unmergeCells: {
                                text: 'Unmerge Cells',
                            },
                        },
                    },
                },
                clipboard: {
                    matchers: [['BR', lineBreakMatcher]],
                },
                keyboard: {
                    bindings: QuillBetterTable.keyboardBindings,
                },
                syntax: true,
            },
            // formats: [
            //     'font',
            //     'size',
            //     'align',
            //     'color',
            //     'bold',
            //     'italic',
            //     'underline',
            //     'strike',
            //     'code',
            //     'background',
            //     'list',
            //     'indent',
            //     'link',
            //     'image',
            //     'code-block',
            //     'blockquote',
            //     // 'pageBreak',
            // ],
            readOnly: true,
            bounds: document.body,
        });

        editorRef.current = quill;
    }, []);

    return (
        <>
            <CustomToolbar editorRef={editorRef} templates={templates} isEditable={isEditable} />

            <div className="!mb-[100px] !mt-[126px]" ref={containerRef} />

            {/* <PageBreak editorRef={editorRef} /> */}

            {/* <Comments editorRef={editorRef} /> */}
        </>
    );
}
