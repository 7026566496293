import { MoreVert } from '@mui/icons-material';
import { IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMemo, useState } from 'react';

// core component
// import { Tag } from 'antd';
import AppTableActionMenu from 'components/new_components/app-table/AppTableActionMenu';
import GroupCard from '../../common/GroupCard';
// import ControlExemptModal from './ControlExemptModal';

//redux
import { connect, useSelector } from 'react-redux';
// import { getType } from 'utils';
import { useHistory } from 'react-router-dom';

const ControlCard = (props) => {
    const theme = useTheme();
    const {
        id,
        title,
        description,
        metric,
        // resourceLabel,
        // onViewChange,
        // exempted,
        // all_exempted_controls,
        openTaskAssignModal,
        openExemptModal,
        openRevertModal,
        isControlAssigned,
        // isSubControlAssigned,
        // resourceAssigned,
        // isTaskMissed,
        isExceptionTab,
    } = props;
    // const [activeHoverCard, setActiveHoverCard] = useState(null);
    // const [modalOpen, setModalOpen] = useState(false);
    // const [activeTitle, setActiveTitle] = useState('');
    // const [type, setType] = useState('');
    const [anchor, setAnchor] = useState(null);
    // const role = getType('role');

    const history = useHistory();

    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const organization = useSelector((state) => state?.generalReducers?.user_info?.organization);
    const all_exempted_controls = useSelector((state) => state?.complianceReducers?.all_exempted_controls);

    const exmeptedRequirement = useMemo(
        () =>
            all_exempted_controls
                ?.filter((item) => item?.standard === 'sslc')
                ?.find((item) => item?.lifecycle_id === id),
        [all_exempted_controls, id]
    );

    const openMenu = (e) => {
        e.stopPropagation();
        setAnchor(e.target);
    };
    const closeMenu = () => setAnchor(null);

    const handleOpenTaskModal = (e) => {
        e.stopPropagation();
        openTaskAssignModal('requirement', {
            lifecycle: id,
            // is_document: resourceLabel !== 'Questions Answered',
            is_document: true,
            standard: 'sslc',
        });
        closeMenu();
    };

    const handleOpenExemptModal = (e) => {
        e.stopPropagation();
        openExemptModal('requirement', {
            lifecycle_id: id,
            standard: 'sslc',
        });
        closeMenu();
    };

    const handleOpenRevertModal = (e) => {
        e.stopPropagation();
        openRevertModal('requirement', {
            id: exmeptedRequirement?.id,
        });
        closeMenu();
    };

    const handleCardClick = () => {
        if (metric?.totalValue && !anchor) {
            // onViewChange(id);
            history.push(`/merchant/compliance/frameworks/sslc/${id}`);
        }
    };
    // const onHover = (id) => {
    //     setActiveHoverCard(id);
    // };
    // const onHoverOut = () => {
    //     setActiveHoverCard(null);
    // };

    // const closeModal = () => {
    //     setModalOpen(false);
    //     setType(null);
    //     setActiveTitle(null);
    // };

    // const exemptedControlId = useMemo(() => {
    //     return exempted
    //         ? all_exempted_controls?.find((control) => control?.lifecycle_detail?.title === activeTitle)?.id
    //         : id;
    // }, [all_exempted_controls, activeTitle, exempted]);
    return (
        <>
            <GroupCard
                percentCompleted={
                    (metric?.totalValue === 0 ? 100 : (metric?.totalAttendedTo / metric?.totalValue) * 100) || 0
                }
                totalValue={metric?.totalValue}
                totalAttendedTo={metric?.totalAttendedTo}
                // resourceLabel={resourceLabel}
                onCardClick={handleCardClick}
                empty={metric?.totalValue == 0}
                // onHover={onHover}
                // onHoverOut={onHoverOut}
                id={id}
                // exempted={exempted}
                // activeHoverCard={activeHoverCard}
                isCategoryAssigned={isControlAssigned}
                // isTaskMissed={isTaskMissed}
                // isSubCatgoryAssigned={isSubControlAssigned}
                // isResourceAssigned={resourceAssigned?.includes(title)}
                // standard={'sslc'}
                isExceptionTab={isExceptionTab}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    className="relative"
                    onClick={(event) => event.stopPropagation()}
                >
                    <Typography
                        sx={{ fontWeight: 600, mb: 0, color: '#475569', fontSize: 15, textTransform: 'capitalize' }}
                    >
                        {/* Control Objective {index + 1} */}
                        {title}
                    </Typography>
                    {metric?.totalValue > 0 &&
                        (!isExceptionTab ? (
                            !isControlAssigned && user_type !== 'auditor' ? (
                                <>
                                    {organization?.merchant_plan?.name !== 'free_version' &&
                                        organization?.merchant_plan?.name !== '3_days_trial' && (
                                            <IconButton
                                                sx={{ color: theme.palette.gray[900], minWidth: 'unset', p: 0 }}
                                                onClick={openMenu}
                                            >
                                                <MoreVert
                                                    sx={{
                                                        color: '#475569',
                                                    }}
                                                />
                                            </IconButton>
                                        )}
                                    <AppTableActionMenu
                                        anchor={anchor}
                                        closeMenu={closeMenu}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <MenuItem
                                            sx={{ fontSize: 14, fontWeight: 500, color: '#64748B' }}
                                            onClick={handleOpenTaskModal}
                                        >
                                            Assign to
                                        </MenuItem>

                                        <MenuItem
                                            sx={{ fontSize: 14, fontWeight: 500, color: '#64748B' }}
                                            onClick={handleOpenExemptModal}
                                        >
                                            Exempt
                                        </MenuItem>
                                    </AppTableActionMenu>
                                </>
                            ) : null
                        ) : (
                            <>
                                {organization?.merchant_plan?.name !== 'free_version' &&
                                    organization?.merchant_plan?.name !== '3_days_trial' && (
                                        <IconButton
                                            sx={{ color: theme.palette.gray[900], minWidth: 'unset', p: 0 }}
                                            onClick={openMenu}
                                        >
                                            <MoreVert
                                                sx={{
                                                    color: '#475569',
                                                }}
                                            />
                                        </IconButton>
                                    )}
                                <AppTableActionMenu
                                    anchor={anchor}
                                    closeMenu={closeMenu}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <MenuItem
                                        sx={{ fontSize: 14, fontWeight: 500, color: '#64748B' }}
                                        onClick={handleOpenRevertModal}
                                    >
                                        Revert
                                    </MenuItem>
                                </AppTableActionMenu>
                            </>
                        ))}
                    {/* {activeHoverCard === id && ( */}

                    {/* {metric?.totalValue > 0 && !isControlAssigned && !exempted && role !== 'auditor' && (
                        <>
                            <IconButton
                                sx={{
                                    color: theme.palette.gray[900],
                                    minWidth: 'unset',
                                    height: '25px',
                                    p: 0,
                                    border: '1px solid #E1E2EC',
                                    borderRadius: 1,
                                }}
                                onClick={openMenu}
                            >
                                <MoreVert />
                            </IconButton>
                            <Menu
                                open={Boolean(anchor)}
                                anchorEl={anchor}
                                onClose={closeMenu}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem
                                    sx={{ fontSize: 14, color: '#64748B', fontWeight: 500, py: -2 }}
                                    onClick={handleOpenTaskModal}
                                >
                                    Assign to:
                                </MenuItem>
                            </Menu>
                        </>
                    )} */}
                    {/* )} */}
                </Stack>
                {/* <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: 500,
                        color: '#64748B',
                        textTransform: 'capitalize',
                        mt: 1,
                    }}
                >
                    {description}
                </Typography> */}
                <Typography component="p" sx={{ fontSize: 12, fontWeight: 500, color: '#64748B' }}>
                    {description}
                </Typography>
            </GroupCard>
            {/* <ControlExemptModal open={modalOpen} handleClose={closeModal} type={type} ControlId={exemptedControlId} /> */}
        </>
    );
};

// const mapStateToProps = (state) => ({
const mapStateToProps = () => ({
    // all_exempted_controls: state?.complianceReducers?.all_exempted_controls,
});
export default connect(mapStateToProps, {})(ControlCard);
