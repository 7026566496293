import { MessageSquareQuote } from 'lucide-react';
import { useState } from 'react';

export default function BlockquoteTool({ editorRef }) {
    const [isBlockquote, setIsBlockquote] = useState(false);

    function handleBlockquote() {
        if (editorRef.current) {
            const quill = editorRef.current;
            const format = quill.getFormat();
            const selection = quill.getSelection();
            const blot = quill.getLeaf(selection?.index)?.[0];

            if (blot && blot?.parent?.domNode?.closest('table')) {
                return;
            }

            if (format['blockquote']) {
                quill.format('blockquote', false);
                setIsBlockquote(false);
            } else {
                quill.format('blockquote', true);
                setIsBlockquote(true);
            }

            quill.focus();
        }
    }

    return (
        <button
            className={`!h-7 !w-7 !rounded grid place-items-center transition-all hover:brightness-95 ${
                isBlockquote ? 'text-[#395BA9] bg-[#395BA910]' : 'text-[#1E293B] bg-white'
            }`}
            onClick={handleBlockquote}
        >
            <MessageSquareQuote className="!h-5 !w-5" />
        </button>
    );
}
