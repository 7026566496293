import React, { useMemo, useState } from 'react';
import { Typography, IconButton, Stack, Box, Button } from '@mui/material';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { toast } from 'react-toastify';
import FolderIcon from 'assets/img/folder.svg';
import Pdf from 'assets/img/pngfile.png';
import Vid from 'assets/img/movFile.svg';
import CloseIcon from '@mui/icons-material/Close';
import { TrashIcon } from 'lucide-react';
import Light from 'assets/img/Light.svg';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { AppForm } from 'components/new_components/forms';
import { accept } from 'validate';
import SubTab from 'components/new_components/tabs/SubTab';
import { AppFormInput } from 'components/new_components/forms';
import AppSelectSearchPinned from 'components/new_components/AppSelectSearchPinned';
import { AddMerchantOnboardingPolicy } from 'store/actions/onboardingActions';
import { AppSubmitButton } from 'components/new_components/forms';
import { AddMerchantOnboardingVideo } from 'store/actions/onboardingActions';
import { AppFormFileInput } from 'components/new_components/forms';

const tabs = [
    {
        index: 0,
        label: 'New',
    },
    {
        index: 1,
        label: 'Existing Policy',
    },
];

const getFileName = (url) => {
    const fileName = url?.split('/')?.[4];
    return fileName;
};

const UploadPolicyModal = (props) => {
    const { open, handleClose, refetch, type } = props;
    const { onboarding_policies = [] } = useSelector((state) => state?.onboardingReducers);
    // const merchant = useSelector((state) => state?.generalReducers?.user_info?.organization?.id);
    const { pdf, video } = accept;
    const [doc, setDoc] = useState(null);
    const [existing, setExisting] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
    const dispatch = useDispatch();

    const closeModal = () => {
        setDoc(null);
        setExisting(null);
        setError('');
        handleClose();
    };

    const dragOver = (e) => {
        e.preventDefault();
    };

    const dragDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        handleUpload(file);
    };

    const handleUpload = (file) => {
        if (type === 'video') {
            if (file) {
                setDoc(file);
                setError('');
            }
        } else {
            if (file && file.type === 'application/pdf') {
                setDoc(file);
                setError('');
            } else {
                setError('Only PDF files are supported');
            }
        }
    };

    const validateForm = (values) => {
        const errors = {};

        if (currentTab === 1 && !existing?.value) {
            errors.file = 'Please Select from an existing file';
        }

        if (currentTab == 0 && !doc) {
            errors.file = 'Please upload a PDF file';
        }

        if (!values.policy_name || !values.policy_name.trim()) {
            errors.policy_name = type == 'video' ? 'Please enter a video name' : 'Please enter a policy name';
        }

        return errors;
    };

    const handleSubmit = async (values) => {
        if (currentTab == 0 && !doc) {
            setError('Please upload a file');
            return;
        }
        if (currentTab == 1 && !existing.value) {
            setError('Please choose and existing file');
            return;
        }

        setLoading(true);
        const formData = new FormData();

        formData.append('name', values.policy_name);
        formData.append('description', values.policy_name);
        if (currentTab == 1) {
            formData.append('existing_policy_id', existing?.value);
        } else {
            formData.append('file', doc);
        }

        const res =
            type == 'video'
                ? await dispatch(AddMerchantOnboardingVideo(formData))
                : await dispatch(AddMerchantOnboardingPolicy(formData));

        /*  if (!data?.admin) {
            formData.append('merchant', merchant);
        } */

        if (res?.success) {
            refetch();
            toast.success('Onboarding policy updated successfully');
            setDoc(null);
            setExisting(null);
            closeModal();
        } else {
            toast.error(res?.message || 'An error occurred');
        }
        setLoading(false);
    };

    const existingFiles = useMemo(() => {
        return onboarding_policies?.map((policy) => {
            return {
                label: getFileName(policy?.file) || '',
                value: policy?.id,
                tag: policy?.name,
            };
        });
    }, [onboarding_policies]);

    const handleDelete = () => {
        setDoc(null);
        setError('');
    };

    const handleTabChange = (val) => {
        setExisting(null);
        setDoc(null);
        setError('');
        setCurrentTab(val);
    };

    return (
        <AppCenteredModal
            open={open}
            handleClose={closeModal}
            titleStyle={{ mx: 3, px: 0 }}
            width={511}
            sx={{
                minHeight: type == 'video' ? '396px' : '462px',
                height: 'fit-content!important',
                p: 0,
                borderRadius: '4px',
            }}
        >
            <Box sx={{ mt: 1, mb: 2, height: 'fit-content', p: 0 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography sx={{ fontSize: 18, fontWeight: 600, color: '#202D66' }}>
                        {type === 'video' ? 'Video' : 'Policy'} Upload
                    </Typography>
                    <IconButton
                        color="disabled"
                        onClick={closeModal}
                        sx={{
                            border: '1px solid #E2E8F0',
                            borderRadius: 30,
                            width: 30,
                            height: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        <CloseIcon sx={{ width: 20, height: 20 }} />
                    </IconButton>
                </Stack>
                {type != 'video' && (
                    <SubTab
                        tabs={tabs}
                        currentTab={currentTab}
                        setCurrentTab={handleTabChange}
                        labelProps={{
                            activeColor: '#1B295F',
                            defaultColor: '#64748B',
                        }}
                        sx={{
                            wrapper: {
                                gap: 2,
                                overflow: 'hidden',
                                fontFamily: 'poppins',
                                width: '10rem',
                                justifyContent: 'flex-start',
                                borderBottom: 'none',
                            },
                            label: {
                                mt: 0,
                                width: 'fit-content',
                                whiteSpace: 'nowrap',
                                fontWeight: '500',
                            },
                            divider: {
                                backgroundColor: 'transparent',
                                width: '8.9rem',
                                ml: 0,
                                border: 'none',
                                borderBottom: '1px solid #F1F5F9',
                            },
                        }}
                    />
                )}

                <AppForm initialValues={{ policy_name: '' }} validate={validateForm} onSubmit={handleSubmit}>
                    {currentTab === 0 ? (
                        <>
                            <Box
                                sx={{
                                    border: `1px dashed ${error ? '#e14b4b' : '#CBD5E1'}`,
                                    borderRadius: '8px',
                                    padding: '8px',
                                    minHeight: '150px',
                                    position: 'relative',
                                    marginTop: '16px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <label htmlFor="file-input" className="m-0 w-full cursor-pointer">
                                    <Box
                                        id="drop-zone"
                                        onDrop={dragDrop}
                                        onDragOver={dragOver}
                                        sx={{ height: '100%', p: 2 }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <img src={FolderIcon} alt="Folder Icon" />
                                            <Typography sx={{ color: '#64748B', fontSize: '12px', fontWeight: 500 }}>
                                                Drop your file or click to upload
                                            </Typography>
                                            <Typography sx={{ color: '#94A3B8', fontSize: '12px', fontWeight: 400 }}>
                                                Supported file types: {type == 'video' ? 'Video' : 'PDF'}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                mt: 1,
                                                flexDirection: 'column',
                                                height: 'fit-content',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    border: '1px solid #E2E8F0',
                                                    cursor: 'pointer',
                                                    padding: 0.5,
                                                }}
                                            >
                                                <Typography sx={{ color: '#475569', fontWeight: 400, fontSize: 12 }}>
                                                    Browse
                                                </Typography>
                                            </Box>

                                            <AppFormFileInput
                                                type="file"
                                                inputProps={{ accept: `${type == 'video' ? video : pdf}` }}
                                                onChange={(e) => handleUpload(e.target.files[0])}
                                                hidden
                                                id="file-input"
                                            />
                                        </Box>
                                    </Box>
                                </label>
                            </Box>
                            {error && (
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#e14b4b', mt: 1 }}>
                                    {error}
                                </Typography>
                            )}

                            {doc && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mt: 1,
                                    }}
                                >
                                    <img src={type == 'video' ? Vid : Pdf} alt="file icon" />
                                    <Box
                                        sx={{
                                            ml: 1,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: 'stretch',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: '#475569',
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                wordBreak: 'break-word',
                                            }}
                                        >
                                            {doc.name}
                                        </Typography>
                                        <IconButton
                                            sx={{ ml: 0, fontSize: '16px', color: '#CBD5E1' }}
                                            onClick={handleDelete}
                                        >
                                            <TrashIcon size={16} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            )}
                        </>
                    ) : (
                        <>
                            <Stack
                                flexDirection="row"
                                sx={{
                                    border: `1px solid #F0F0FA`,
                                    backgroundColor: '#F8FAFC',
                                    borderRadius: '4px',
                                    p: '12px',
                                    pt: '8px',
                                    mt: 5,
                                }}
                            >
                                <img src={Light} alt="file question" className="h-fit w-fit" />
                                <Typography
                                    sx={{
                                        color: '#64748B',
                                        fontWeight: 400,
                                        fontSize: '13px',
                                        ml: 1,
                                    }}
                                >
                                    Select existing policies on the platform to use as onboarding policies for new
                                    employees.
                                </Typography>
                            </Stack>

                            <AppSelectSearchPinned
                                options={existingFiles}
                                onChange={(e, val) => {
                                    if (val) {
                                        setExisting({
                                            value: val?.value,
                                            label: e.target.textContent,
                                            tag: val?.tag,
                                        });

                                        setError(null);
                                    }
                                }}
                                noDataLabel="No File available"
                                labelStyle={{
                                    color: '#64748B',
                                    fontSize: '13px',
                                    fontWeight: 500,
                                }}
                                sx={{
                                    cursor: 'pointer',
                                }}
                                label="Choose an existing policy file"
                                value={existing?.value ?? 'Select from existing policies'}
                                placeholder={existing?.label ?? 'Select from existing policies'}
                            />
                            {existing && (
                                <Typography sx={{ fontSize: '13px', fontWeight: 400, color: '#64748B', mt: 1 }}>
                                    This policy file exist in {`{${existing?.tag}}`}. Changes made there will reflect
                                    here.
                                </Typography>
                            )}
                            {error && (
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#e14b4b', mt: 1 }}>
                                    {error}
                                </Typography>
                            )}
                        </>
                    )}

                    {/* Policy name */}
                    <Box>
                        <AppFormInput
                            name="policy_name"
                            id="policy_name"
                            label={type === 'video' ? 'Video name' : 'Policy name'}
                            sx={{
                                color: '#64748B',
                            }}
                            placeholder="Enter policy name"
                        />
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'center',
                            mt: 3,
                            backgroundColor: '#F8FAFC',
                            height: '80px',
                            mx: '-1.65rem',
                            mb: '-1rem',
                            px: '1.9rem',
                            pt: '3rem',
                            pb: '3rem',
                        }}
                    >
                        <Button
                            variant="outlined"
                            type="reset"
                            sx={{
                                mr: 2,
                                border: '1px solid #E2E8F0',
                                textTransform: 'inherit',
                                color: '#334155',
                            }}
                            onClick={closeModal}
                        >
                            Cancel
                        </Button>
                        <AppSubmitButton
                            text={'Upload'}
                            variant="contained"
                            color="primary"
                            loadingPosition="center"
                            sx={{ textTransform: 'inherit', fontWeight: 600 }}
                            loading={loading}
                        />
                    </Box>
                </AppForm>
            </Box>
        </AppCenteredModal>
    );
};

export default UploadPolicyModal;
