import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
// core components
import BreadCrumb from 'components/Breadcrumb';
import CategoryModal from './KDPAActions/CategoryModal';

// antd components
import { Button, Col, Modal, Row, Table } from 'antd';

// icons
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';

//redux
import { connect } from 'react-redux';
import {
    CreateKdpaCategory,
    DeleteKdpaCategory,
    EditKdpaCategory,
    GetAllKdpaCategories,
} from 'store/actions/adminActions';
import CatOperations from './KDPAActions/CatOperations';
// import { truncateText } from 'utils';

// sub-components
// const { Title } = Typography;
const { confirm } = Modal;

const KdpaDashboard = (props) => {
    const { GetAllKdpaCategories, CreateKdpaCategory, EditKdpaCategory, DeleteKdpaCategory, all_kdpa_categories } =
        props;
    // state
    const [loading, setLoading] = useState({
        content: false,
    });
    const [modal, setModal] = useState(null);
    const [oneReq, setOneReq] = useState({});

    // functions
    const openModal = (mode, data) => {
        setModal(mode);
        setOneReq(data);
    };
    const closeModal = () => {
        setModal(null);
    };
    const deleteCategory = (id) => {
        confirm({
            title: 'Are you sure you want to delete this category? Note: Deleting this category means you will be deleting this category, the documents, audit questions and merchant data associated with this category',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteKdpaCategory(id);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    // data
    const columns = [
        {
            title: 'Title',
            render: (record) => record.title,
            sorter: (a, b) => a.title.localeCompare(b.title),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Description',
            render: (record) => record.description,
        },
        {
            title: 'Policy Statements',
            render: (record) => (
                <ul className="list-disc flex flex-col !gap-1 !mx-4">
                    {record.policy_statements?.length ? (
                        record.policy_statements?.map((policy, index) => <li key={index}>{policy}</li>)
                    ) : (
                        <li>No policy statement</li>
                    )}
                </ul>
            ),
        },
        {
            title: 'Actions',
            align: 'right',
            render: (record) => (
                <CatOperations openEditModal={openModal} category={record} deleteCategory={deleteCategory} />
            ),
        },
    ];

    useEffect(() => {
        const getAllCatgeories = async () => {
            setLoading(true);
            const res = await GetAllKdpaCategories();
            setLoading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllCatgeories();
    }, []);
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'KDPA' }]} />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openModal('Add')}
                    >
                        Add Category
                    </Button>
                </Col>
            </Row>

            <section className="my-3">
                <Table
                    columns={columns}
                    dataSource={all_kdpa_categories}
                    loading={loading?.content}
                    rowKey={(category) => category.id}
                />
            </section>

            {/* <section className="my-3">
                {loading.content ? (
                    <Row justify="center">
                        <Spin size="large" />
                    </Row>
                ) : (
                    <>
                        {all_kdpa_categories && all_kdpa_categories?.length > 0 ? (
                            <Row wrap gutter={24}>
                                {all_kdpa_categories?.map((cat) => (
                                    <Col xs={24} md={12} lg={8} xxl={6} key={cat.id} style={{ marginBottom: '1rem' }}>
                                        <Card
                                            title={cat?.title}
                                            loading={false}
                                            actions={[
                                                <Tooltip title="View this Category" color="blue">
                                                    <Link
                                                        key="preview"
                                                        to={{
                                                            pathname: '/admin/kdpa/category',
                                                            state: {
                                                                category: cat,
                                                            },
                                                        }}
                                                    >
                                                        <EyeOutlined key="preview" />
                                                    </Link>
                                                </Tooltip>,
                                                <Tooltip title="Edit this Category" color="blue">
                                                    <EditOutlined key="edit" onClick={() => openModal('Edit', cat)} />
                                                </Tooltip>,
                                                <Tooltip title="Delete this Category" color="red">
                                                    <DeleteOutlined
                                                        key="delete"
                                                        onClick={() => deleteCategory(cat.id)}
                                                    />
                                                </Tooltip>,
                                            ]}
                                        >
                                            <Tooltip title={cat.description}>
                                                <Title level={5} style={{ textAlign: 'center' }}>
                                                    {truncateText(cat.description)}
                                                </Title>
                                            </Tooltip>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        ) : (
                            <Row justify="center">
                                <Col>
                                    <Empty
                                        description={
                                            <div>
                                                <h3>No Categories</h3>
                                                <span>Add a new Category </span>
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </section> */}
            <CategoryModal
                open={modal}
                handleCancel={closeModal}
                one_req={oneReq}
                CreateCategory={CreateKdpaCategory}
                EditCategory={EditKdpaCategory}
            />
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        all_kdpa_categories: state?.adminReducers?.all_kdpa_categories,
    };
};
export default connect(mapStateToProps, {
    GetAllKdpaCategories,
    CreateKdpaCategory,
    EditKdpaCategory,
    DeleteKdpaCategory,
})(KdpaDashboard);
