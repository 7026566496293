import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// core components
import BreadCrumb from 'components/Breadcrumb';

import { FileOutlined } from '@ant-design/icons';
import { Col, Tabs } from 'antd';
import { useDispatch } from 'react-redux';
import { GetAllDocumentsByAnnexNew } from 'store/actions/adminActions';
import Templates from './Templates';

const ClausePage = (props) => {
    const { location } = props;

    const [loading, setLoading] = useState(false);

    // redux
    const dispatch = useDispatch();

    //useEffect
    useEffect(() => {
        getDocumentsByClause();
    }, []);

    // functions
    const getDocumentsByClause = async () => {
        setLoading(true);
        const res = await dispatch(GetAllDocumentsByAnnexNew(location?.state?.clause?.id));
        setLoading(false);
        if (!res.success) {
            toast.error(res?.message);
        }
    };

    return (
        <Col>
            <Col>
                <BreadCrumb
                    views={[
                        { label: 'ISO27017 + 27032', link: '/iso27017+27032' },
                        { label: 'Clauses', link: '/iso27017+27032/clauses' },
                        { label: `Clause - ${location?.state?.clause?.title}` },
                    ]}
                />
            </Col>

            <Col>
                <section className="my-3">
                    <Tabs
                        defaultActiveKey="1"
                        items={[
                            {
                                key: '1',
                                label: (
                                    <span className="flex items-center !gap-2">
                                        <FileOutlined className="!h-3.5 !w-3.5 !m-0" /> <p>Templates</p>
                                    </span>
                                ),
                                children: (
                                    <Templates
                                        loading={loading}
                                        parent_clause_id={location?.state?.clause?.id}
                                        sub_clauses={[location?.state?.clause]}
                                    />
                                ),
                            },
                        ]}
                    />
                </section>
            </Col>
        </Col>
    );
};

export default ClausePage;
