// Functions
let activeNumberedList = false;

export function parseQuillDelta(quill) {
    activeNumberedList = false;
    const parsed = {
        paragraphs: [],
        setup: {
            numberedLists: 0,
            hyperlinks: [],
        },
    };
    for (const op of quill.ops) {
        parseOp(op, parsed);
    }
    return parsed;
}

function parseOp(op, parsed) {
    // handle videos and images
    if (op.insert.video || op.insert.image) {
        insertEmbedParagraph(op, parsed);
        // handle formulas
    } else if (op.insert.formula) {
        insertFormula(op, parsed);
        // handle exclusive newlines
    } else if (op.insert.pageBreak) {
        return;
    } else if (op.insert === '\n') {
        insertNewline(op, parsed);
        // handle text and text with newlines intermixed
    } else {
        insertText(op, parsed);
    }
}

// insert a blank paragraph
function startNewParagraph(parsed) {
    parsed.paragraphs.push({
        textRuns: [],
    });
}

// inserts a video or image embed
function insertEmbedParagraph(op, parsed) {
    parsed.paragraphs.push({
        embed: op.insert,
    });
    activeNumberedList = false;
    startNewParagraph(parsed);
}

// inserts a formula embed
function insertFormula(op, parsed) {
    if (parsed.paragraphs.length === 0) {
        startNewParagraph(parsed);
    }
    parsed.paragraphs[parsed.paragraphs.length - 1].textRuns?.push({
        formula: op.insert.formula,
        attributes: op.attributes,
    });
}

// inserts a new paragraph and applies line formatting
function insertNewline(op, parsed) {
    // if line attributes, apply those to the previous paragraph
    if (op.attributes) {
        parsed.paragraphs[parsed.paragraphs.length - 1].attributes = op.attributes;
        if (op.attributes.list === 'ordered') {
            // if already an active numbered list
            if (activeNumberedList) {
                // do not increment
                // leave active list true
            } else {
                // incrememnt
                // set active to true
                parsed.setup.numberedLists++;
                activeNumberedList = true;
            }
        } else {
            activeNumberedList = false;
        }
    }
    startNewParagraph(parsed);
}

// inserts text with intermixed newlines and run attributes
function insertText(op, parsed) {
    if (parsed.paragraphs.length === 0) {
        startNewParagraph(parsed);
    }
    //if it contains newline characters
    if (op.insert.match(/\n/)) {
        const strings = splitStrings(op.insert);
        for (const text of strings) {
            if (text === '\n') {
                startNewParagraph(parsed);
                activeNumberedList = false;
            } else {
                insertSimpleString(text, parsed);
            }
        }
    } else {
        insertSimpleString(op.insert, parsed, op.attributes);
    }
}

// inserts simple string with attributes
function insertSimpleString(text, parsed, attributes) {
    if (attributes) {
        parsed.paragraphs[parsed.paragraphs.length - 1].textRuns?.push({
            text: text,
            attributes: attributes,
        });
        if (attributes.link) {
            parsed.setup.hyperlinks.push({ text: text, link: attributes.link });
        }
    } else {
        parsed.paragraphs[parsed.paragraphs.length - 1].textRuns?.push({
            text: text,
        });
    }
}

// splits strings on every newline character, keeping the newline characters; returns array
function splitStrings(string) {
    return string.split(/(\n)/);
}
