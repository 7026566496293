/* eslint-disable no-unused-vars */

import { Menu, MenuItem } from '@mui/material';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { GetDocumentLink } from 'store/actions/complianceActions';
import { extractDocName, shortenDocumentTitle } from '../../../utils';
import UseCurrentVersionDialog from './UseCurrentVersionDialog';

export default function NavBar({
    currentPolicyDocument,
    setCurrentPolicyDocument,
    selectedVersionId,
    setSelectedVersionId,
    allDocuments,
    handleClose,
}) {
    const [changePolicyDocumentAnchorEl, setChangePolicyDocumentAnchorEl] = useState(null);
    // const [downloadPolicyAnchorEl, setDownloadPolicyAnchorEl] = useState(null);
    const [isUseCurrentVersionDialogOpen, setIsUseCurrentVersionDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    async function getDocumentLink(id) {
        setIsLoading(true);
        const res = await dispatch(GetDocumentLink('userdoc', id));
        setIsLoading(false);
        if (res?.success) {
            window.open(res?.data, '_blank');
        } else {
            toast.error('Something went wrong!');
        }
    }

    return (
        <>
            <div className="border-b border-[#F1F5F9] !px-6 !py-4 flex items-center justify-between">
                <div className="flex items-center !gap-6">
                    <img
                        src="/img/automated-scan/arrow-left-icon.svg"
                        alt="arrow-left-icon"
                        onClick={handleClose}
                        className="cursor-pointer object-contain"
                    />

                    <h3 className="text-[#002C72] capitalize font-bold text-lg">
                        {extractDocName(currentPolicyDocument?.filename || currentPolicyDocument?.default)?.replaceAll(
                            '_',
                            ' '
                        )}
                    </h3>
                </div>

                <div className="flex items-center !gap-1">
                    <div>
                        <button
                            aria-controls={changePolicyDocumentAnchorEl ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={changePolicyDocumentAnchorEl ? 'true' : undefined}
                            onClick={(event) => {
                                event.stopPropagation();
                                setChangePolicyDocumentAnchorEl(event.currentTarget);
                            }}
                            className="transition-all hover:brightness-95 bg-white !rounded !py-1.5 !px-3 flex items-center !gap-1.5 text-[#64748B] font-medium text-sm capitalize"
                        >
                            <span>
                                {shortenDocumentTitle(
                                    extractDocName(currentPolicyDocument?.filename || currentPolicyDocument?.default)
                                )?.replaceAll('_', ' ')}
                            </span>

                            <img
                                src="/img/frameworks/material-symbols_keyboard-arrow-down-rounded.svg"
                                alt="chevron down"
                                className="!w-4 !h-4"
                            />
                        </button>

                        <Menu
                            anchorEl={changePolicyDocumentAnchorEl}
                            open={Boolean(changePolicyDocumentAnchorEl)}
                            onClose={(event) => {
                                event.stopPropagation();
                                setChangePolicyDocumentAnchorEl(null);
                            }}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow:
                                        '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                                },
                                '& .MuiList-root': {
                                    padding: '0 0',
                                },
                            }}
                        >
                            <div className="max-h-[132px] max-w-[281px] overflow-auto custom-scroll">
                                {allDocuments.map((document) => (
                                    <MenuItem
                                        key={document?.id}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setChangePolicyDocumentAnchorEl(null);
                                            setCurrentPolicyDocument(document);
                                            setSelectedVersionId(document?.policy_versions?.[0]?.id);
                                        }}
                                        sx={{
                                            color: '#64748B',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            textTransform: 'capitalize',
                                            padding: '6px 16px',
                                            border: '1px solid #F1F5F9',
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    >
                                        {extractDocName(document?.filename || document?.default)?.replaceAll('_', ' ')}
                                    </MenuItem>
                                ))}
                            </div>
                        </Menu>
                    </div>

                    {!currentPolicyDocument?.policy_versions?.find((version) => version?.id === selectedVersionId)
                        ?.is_current && (
                        <button
                            className="transition-all hover:brightness-95 bg-[#F1F5F9] !border !border-[#E2E8F0] !rounded !py-1.5 !px-2 text-[#334155] font-medium text-sm"
                            onClick={() => setIsUseCurrentVersionDialogOpen(true)}
                        >
                            Use as current version
                        </button>
                    )}

                    <div>
                        <button
                            // aria-controls={downloadPolicyAnchorEl ? 'basic-menu' : undefined}
                            // aria-haspopup="true"
                            // aria-expanded={downloadPolicyAnchorEl ? 'true' : undefined}
                            onClick={(event) => {
                                event.stopPropagation();
                                // setDownloadPolicyAnchorEl(event.currentTarget);
                                getDocumentLink(selectedVersionId);
                            }}
                            className="transition-all hover:brightness-95 bg-white !rounded-lg !border !border-[#E2E8F0] !py-1 !px-1.5 flex items-center disabled:opacity-50 disabled:pointer-events-none"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <Loader2 className="!h-5 !w-5 animate-spin" />
                            ) : (
                                <img src="/img/frameworks/download.svg" alt="download" className="!w-5 !h-5" />
                            )}
                        </button>

                        {/* <Menu
                            anchorEl={downloadPolicyAnchorEl}
                            open={Boolean(downloadPolicyAnchorEl)}
                            onClose={(event) => {
                                event.stopPropagation();
                                setDownloadPolicyAnchorEl(null);
                            }}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow:
                                        '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                                },
                                '& .MuiList-root': {
                                    padding: '0 0',
                                },
                            }}
                        >
                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setDownloadPolicyAnchorEl(null);
                                    window.open('#', '_blank');
                                }}
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    textTransform: 'none',
                                    padding: '8px 16px',
                                    border: '1px solid #F1F5F9',
                                }}
                            >
                                <div className="flex items-center !gap-1">
                                    <img src="/img/frameworks/pdf.svg" alt="pdf" className="!h-6 !w-6" />

                                    <span>Download as PDF</span>
                                </div>
                            </MenuItem>

                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setDownloadPolicyAnchorEl(null);
                                    window.open('#', '_blank');
                                }}
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    textTransform: 'none',
                                    padding: '8px 16px',
                                }}
                            >
                                <div className="flex items-center !gap-1">
                                    <img src="/img/frameworks/docx.svg" alt="pdf" className="!h-6 !w-6" />

                                    <span>Download as MS DOC</span>
                                </div>
                            </MenuItem>
                        </Menu> */}
                    </div>
                </div>
            </div>

            <UseCurrentVersionDialog
                isOpen={isUseCurrentVersionDialogOpen}
                setIsOpen={setIsUseCurrentVersionDialogOpen}
                currentPolicyDocument={currentPolicyDocument}
                selectedVersionId={selectedVersionId}
            />
        </>
    );
}
