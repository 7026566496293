/* eslint-disable no-unused-vars */

import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Row, Table } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { DeleteDocument } from 'store/actions/adminActions';
import { GetDocumentLink } from 'store/actions/complianceActions';
import AddDocumentModal from '../../Operations/AddDocumentModal';
import EditDocumentModal from '../../Operations/EditDocumentModal';
import Operations from './Operations';

const { confirm } = Modal;

export default function Templates({ loading, parent_category_id, sub_categories }) {
    const [modal, setModal] = useState({
        add: false,
        edit: false,
    });
    const [one_doc, setOneDoc] = useState({});
    // const [loading, setLoading] = useState(false);

    const all_kdpa_documents = useSelector((state) => state?.adminReducers?.all_kdpa_documents);

    const dispatch = useDispatch();

    const columns = [
        {
            title: 'Title',
            render: (record) => record.kdpa_title,
            sorter: (a, b) => a.kdpa_title.localeCompare(b.kdpa_title),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Description',
            render: (record) => record.kdpa_document_description,
        },
        {
            title: 'Policy Statements',
            render: (record) => (
                <div className="flex flex-col !gap-2">
                    {Object.entries(record.policies?.['kdpa'] || {})?.length ? (
                        Object.entries(record.policies?.['kdpa'] || {})?.map(([key, value]) => (
                            <div key={key} className="flex flex-col !gap-1">
                                <p className="font-bold">
                                    Category - {sub_categories?.find((sub) => sub?.id === +key)?.title}
                                </p>

                                <ul className="list-disc flex flex-col !gap-1 !mx-4">
                                    {value?.length ? (
                                        value?.map((policy, index) => <li key={index}>{policy}</li>)
                                    ) : (
                                        <li>No policy statement</li>
                                    )}
                                </ul>
                            </div>
                        ))
                    ) : (
                        <ul className="list-disc flex flex-col !gap-1 !mx-4">
                            <li>No policy statement</li>
                        </ul>
                    )}
                </div>
            ),
        },
        {
            title: 'Actions',
            align: 'right',
            render: (record) => (
                <Operations
                    deleteModal={deleteModal}
                    getDocumentLink={getDocumentLink}
                    openEditDocumentModal={openEditDocumentModal}
                    template={record}
                />
            ),
        },
    ];

    function openAddDocumentModal() {
        setModal({ ...modal, add: true });
    }

    function closeAddDocumentModal() {
        setModal({ ...modal, add: false });
    }

    function openEditDocumentModal(doc) {
        setModal({ ...modal, edit: true });
        setOneDoc(doc);
    }

    function closeEditDocumentModal() {
        setModal({ ...modal, edit: false });
    }

    function deleteModal(document) {
        confirm({
            title: 'Do you want to delete this template?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const res = await dispatch(DeleteDocument(document?.id, null, 'kdpa', parent_category_id));
                if (res.success) {
                    toast.success('Template Deleted Successfully');
                } else {
                    toast.error(res.message);
                }
            },
        });
    }

    // const getAllDocumentsByTags = async () => {
    //     setLoading(true);
    //     const res = await dispatch(GetAllPcidss4Point0DocumentsByTags('card4'));
    //     setLoading(false);
    //     if (!res?.success) {
    //         toast.error(res?.message);
    //     }
    // };

    async function getDocumentLink(id) {
        const res = await dispatch(GetDocumentLink('document', id));
        if (res?.success) {
            window.open(res?.data, '_blank');
        } else {
            toast.error('Something went wrong!');
        }
    }

    // useEffect(() => {
    //     getAllDocumentsByTags();
    // }, []);

    return (
        <>
            <Row justify="end">
                <Button type="primary" size="large" icon={<PlusOutlined />} onClick={() => openAddDocumentModal()}>
                    Add Template
                </Button>
            </Row>

            <section className="my-3">
                <Table
                    columns={columns}
                    dataSource={all_kdpa_documents}
                    loading={loading}
                    rowKey={(template) => template?.id}
                />
            </section>

            {modal.add && (
                <AddDocumentModal
                    open={modal.add}
                    handleClose={closeAddDocumentModal}
                    tag="kdpa"
                    payload={{
                        kdpa_category: parent_category_id,
                    }}
                    titleLabel="kdpa_title"
                    descLabel="kdpa_document_description"
                    parent_requirement={parent_category_id}
                    sub_requirements={sub_categories}
                    treeSubTitle="Category"
                    treeSubKey="title"
                />
            )}

            {modal.edit && (
                <EditDocumentModal
                    open={modal.edit}
                    handleClose={closeEditDocumentModal}
                    one_document={one_doc}
                    tag="kdpa"
                    payload={{
                        kdpa_category: parent_category_id,
                    }}
                    titleLabel="kdpa_title"
                    descLabel="kdpa_document_description"
                    parent_requirement={parent_category_id}
                    sub_requirements={sub_categories}
                    treeSubTitle="Category"
                    treeSubKey="title"
                />
            )}
        </>
    );
}
