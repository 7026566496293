export default function TableAction({ row, setActiveRow }) {
    return (
        <button
            className="transition-all hover:brightness-95 !w-fit !border !border-[#E2E8F0] bg-white !rounded-full !py-[5px] !px-2.5 text-[#64748B] font-medium text-[13px] whitespace-nowrap"
            onClick={() =>
                setActiveRow({
                    row,
                    viewMode: 'view',
                    isOpen: true,
                })
            }
        >
            View more info
        </button>
    );
}
