import { Code } from 'lucide-react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsCode } from 'store/actions/editorActions';

export default function CodeTool({ editorRef }) {
    const isCode = useSelector((state) => state.editorReducers.isCode);

    const dispatch = useDispatch();

    function handleCode() {
        if (editorRef.current) {
            const quill = editorRef.current;
            const selection = quill.getSelection();
            const format = quill.getFormat();

            if (!selection) return;

            if (format['code']) {
                quill.format('code', false);
                dispatch(setIsCode(false));
            } else {
                quill.format('code', true);
                dispatch(setIsCode(true));
            }

            quill.focus();
        }
    }

    useEffect(() => {
        if (editorRef.current) {
            const quill = editorRef.current;

            // eslint-disable-next-line no-inner-declarations
            function handleSelectionChange() {
                if (!quill.hasFocus()) return;
                const format = quill.getFormat();

                dispatch(setIsCode(!!format['code']));
            }

            quill.on('selection-change', handleSelectionChange);

            return () => {
                quill.off('selection-change', handleSelectionChange);
            };
        }
    }, [editorRef]);

    return (
        <button
            className={`!h-7 !w-7 !rounded grid place-items-center transition-all hover:brightness-95 ${
                isCode ? 'text-[#395BA9] bg-[#395BA910]' : 'text-[#1E293B] bg-white'
            }`}
            onClick={handleCode}
        >
            <Code className="!h-5 !w-5" />
        </button>
    );
}
