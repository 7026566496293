import Quill from 'quill';
import Delta from 'quill-delta';

const BlockEmbed = Quill.import('blots/block/embed');
const Inline = Quill.import('blots/inline');

export class PageBreakBlot extends BlockEmbed {}
PageBreakBlot.blotName = 'pageBreak';
PageBreakBlot.tagName = 'div';
PageBreakBlot.className = 'ql-page-break';

export class CommentBlot extends Inline {
    static create(commentId) {
        const node = super.create();
        node.setAttribute('data-comment-id', commentId);
        return node;
    }

    static formats(node) {
        return node.getAttribute('data-comment-id') || true;
    }

    format(name, value) {
        if (name === 'comment' && value) {
            this.domNode.setAttribute('data-comment-id', value);
        } else {
            super.format(name, value);
        }
    }
}
CommentBlot.blotName = 'comment';
CommentBlot.tagName = 'span';
CommentBlot.className = 'ql-comment';

export const lineBreakMatcher = () => {
    const newDelta = new Delta();
    newDelta.insert({ break: '' });
    return newDelta;
};

export const templates = ['template 1', 'template 2'];

export const fonts = ['sans-serif', 'serif', 'monospace', 'times-new-roman', 'comic-sans'];

export const alignments = ['left', 'center', 'right', 'justify'];

export const people = [
    {
        id: '1',
        firstName: 'John',
        lastName: 'Doe',
    },
    {
        id: '2',
        firstName: 'Jane',
        lastName: 'Doe',
    },
    {
        id: '3',
        firstName: 'Alice',
        lastName: 'Smith',
    },
    {
        id: '4',
        firstName: 'Bob',
        lastName: 'Smith',
    },
];
