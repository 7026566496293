import { Box } from '@mui/material';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';

// core components
import PageHeader from 'components/new_components/PageHeader';
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import ComplianceLayout from '../common/ComplianceLayout';
// import AuditQuestions from './AuditQuestions';
import Documents from './Documents';

// redux
import { connect, useDispatch } from 'react-redux';
import {
    // GetAllFormsByTags,
    GetAllPcidss4Point0Requirements,
} from 'store/actions/adminActions';
import {
    // GetAllMerchantAnswers,
    // GetAllMerchantDocuments,
    GetAllPcidss4Point0RequirementMetrics,
} from 'store/actions/complianceActions';
import {
    CreateModuleGuide,
    GetModuleGuides,
    GetUserDetails,
    UpdateModuleGuide,
    UpdateUserDetails,
} from 'store/actions/generalActions';
import { GetCompanyDetails } from 'store/actions/merchantActions';
import AllTaskModal from '../common/AllTaskModal';
// import AssignTaskModal from '../common/AssignTaskModal';
import TaskIntroductionModal from '../common/TaskIntroductionModal';
// import ComplianceAITipsModal from '../common/ComplianceAITipsModal';
// import ComplianceAITopBar from '../common/ComplianceAITopBar';
// import AdditionalEvidenceModal from '../common/AdditionalEvidence';

//userguide
import { Tour } from 'antd';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideHeader } from 'components/new_components/AppUserGuide';
import { GetAllExemptedControls } from 'store/actions/complianceActions';
// import { GetAllPcidss4Point0DocumentsByTags } from 'store/actions/pcidssActions';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import AssignToDialog from '../common/document_review/components/AssignToDialog';
import ExemptRequirementDialog from '../common/document_review/components/ExemptRequirementDialog';
import RevertRequirementDialog from '../common/document_review/components/RevertRequirementDialog';
import { useLocation } from 'react-router-dom';

const PCIDSS4POINT0 = (props) => {
    const {
        GetAllPcidss4Point0Requirements,
        // GetAllFormsByTags,
        GetAllPcidss4Point0RequirementMetrics,
        // GetAllMerchantDocuments,
        // GetAllMerchantAnswers,
        GetCompanyDetails,
        GetUserDetails,
        GetModuleGuides,
        CreateModuleGuide,
        UpdateModuleGuide,
        // UpdateUserDetails,
        // compliance_ai_modal,
        // merchant_info,
        guideModal,
        userguide_info,
        all_article_header,
    } = props;
    const location = useLocation();

    // state
    const [currentTab, setCurrentTab] = useState(0);
    const [loading, setLoading] = useState({ requirements: false, exceptions: false, metrics: false });
    // const [openAiModal, setOpenAiModal] = useState(false);
    // const [openAiTopBar, setOpenAiTopBar] = useState(false);
    const [introModalOpen, setIntroModalOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [tasksModalOpen, setTaskModalOpen] = useState(false);
    const [exmeptModalOpen, setExemptModalOpen] = useState(false);
    const [revertModalOpen, setRevertModalOpen] = useState(false);
    // const [modalType, setModalType] = useState(null);
    const [taskInfo, setTaskInfo] = useState({});
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);
    // const [additionalEvidenceModalOpen, setAdditionalEvidenceModalOpen] = useState(false);

    //userguide
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);
    // const { all_pcidss4point0_requirements_metrics, all_evidences_metrics } = useSelector(
    //     (state) => state?.complianceReducers
    // );
    // const all_pcidss4point0_requirements = useSelector((state) => state.pcidssReducers.all_pcidss4point0_requirements);
    const ref1 = useRef(null);
    // const ref2 = useRef(null);
    const ref3 = useRef(null);

    const dispatch = useDispatch();
    const STEPS_LENGTH = 2;

    const steps = [
        {
            title: <UserGuideHeader title="Documents" id={1} length={STEPS_LENGTH} />,
            description: 'Here you can Upload compliance policies.',
            target: () => ref1.current,
            placement: 'rightTop',
        },
        // {
        //     title: <UserGuideHeader title="Audit Questions" id={2} length={STEPS_LENGTH} />,
        //     description: 'Respond to audit-related queries here.',
        //     target: () => ref2.current,
        //     placement: 'rightTop',
        // },
        {
            title: <UserGuideHeader title="Exceptions" id={3} length={STEPS_LENGTH} />,
            description: 'This tab displays requirements that are exempted',
            target: () => ref3.current,
            placement: 'rightTop',
        },
    ];

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Compliance'),
        [all_article_header]
    );

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ compliance: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, compliance: false });
        setOpenArticle(false);
    };

    // const openAdditonalEvidenceModal = () => {
    //     setAdditionalEvidenceModalOpen(true);
    // };
    // const closeAdditionalEvidenceModal = () => {
    //     setAdditionalEvidenceModalOpen(false);
    // };

    //task introduction/explanation modal
    const closeIntroModal = () => {
        setIntroModalOpen(false);
    };

    const openTaskAssignModal = (type, info) => {
        setModalOpen(true);
        // setModalType(type);
        setTaskInfo(info);
    };
    const closeTaskAssignModal = () => {
        setModalOpen(false);
        // setTimeout(() => setModalType(null), 500);
        setTaskInfo({});
    };

    const openExemptModal = (type, info) => {
        setExemptModalOpen(true);
        setTaskInfo(info);
    };
    const closeExemptModal = () => {
        setExemptModalOpen(false);
        setTaskInfo({});
    };

    const openRevertModal = (type, info) => {
        setRevertModalOpen(true);
        setTaskInfo(info);
    };
    const closeRevertModal = () => {
        setRevertModalOpen(false);
        setTaskInfo({});
    };

    const openTasksModal = () => {
        setTaskModalOpen(true);
    };
    const closeTasksModal = () => {
        setTaskModalOpen(false);
    };
    //compliance AI toggles
    // const handleOpenAiModal = () => {
    //     setOpenAiModal(true);
    // };
    // const handleCloseAiModal = () => {
    //     setOpenAiModal(false);
    // };
    // const handleOpenAiTopBar = () => {
    //     setOpenAiTopBar(true);
    // };
    // const handleCloseAiTopBar = () => {
    //     setOpenAiTopBar(false);
    // };
    // functions
    // logic functions
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
    };
    // async functions
    const getAllExemptedControls = async () => {
        setLoading((current) => ({
            ...current,
            exceptions: true,
        }));
        const res = await dispatch(GetAllExemptedControls());
        setLoading((current) => ({ ...current, exceptions: false }));
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch exempted controls.");
        }
    };
    const getAllRequirements = async () => {
        setLoading((current) => ({
            ...current,
            requirements: true,
        }));
        const res = await GetAllPcidss4Point0Requirements();
        setLoading((current) => ({ ...current, requirements: false }));
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch requirements.");
        }
    };
    // get all requirement metric for this merchant
    const getAllRequirementMetrics = async () => {
        setLoading((current) => ({
            ...current,
            metrics: true,
        }));
        const res = await GetAllPcidss4Point0RequirementMetrics();
        setLoading((current) => ({ ...current, metrics: false }));
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch requirements.");
        }
    };
    // const getAllMerchantDocuments = async () => {
    //     const res = await GetAllMerchantDocuments();
    //     if (!res.success) {
    //         toast.error("Something went wrong! Couldn't fetch your documents.");
    //     }
    // };

    // const getEvidenceMetrics = async () => {
    //     setLoading((current) => ({
    //         ...current,
    //         requirements: true,
    //     }));
    //     const res = await dispatch(GetEvidenceMetrics('card4'));
    //     setLoading((current) => ({ ...current, requirements: false }));
    //     if (!res.success) {
    //         toast.error("Something went wrong! Couldn't fetch requirements.");
    //     }
    // };

    // const getAllMerchantEvidences = async () => {
    //     const res = await dispatch(GetAllMerchantEvidences());
    //     if (!res.success) {
    //         toast.error("Something went wrong! Couldn't fetch your documents.");
    //     }
    // };

    // const getMerchantAnswers = async () => {
    //     const res = await GetAllMerchantAnswers();
    //     if (!res?.success) {
    //         toast.error("Something went wrong! Couldn't fetch previous answers.");
    //     }
    // };

    const getUserDetails = async () => {
        const res = await GetUserDetails();
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch user details");
        }
    };

    const getCompanyDetails = async () => {
        const res = await GetCompanyDetails();
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch company details");
        }
    };
    const getModuleGuides = async () => {
        const res = await GetModuleGuides();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const updateModuleIntroModal = async () => {
        const res =
            guideModal && guideModal[0]
                ? await UpdateModuleGuide({ compliance_task: true }, guideModal[0]?.id)
                : await CreateModuleGuide({ compliance_task: true });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    // const getAllFormsByTags = async () => {
    //     const res = await GetAllFormsByTags('card4');
    //     if (!res?.success) {
    //         toast.error(res?.message);
    //     }
    // };
    // const getAllDocumentByTags = async () => {
    //     const res = await dispatch(GetAllPcidss4Point0DocumentsByTags('card4'));
    //     if (!res?.success) {
    //         toast.error(res?.message);
    //     }
    // };

    // const neverShowComplianceAiTipsModal = async () => {
    // const res = await UpdateUserDetails({ compliance_ai_modal: true });
    //     if (!res?.success) {
    //         toast.success("Something went wrong! Couldn't fetch company details");
    //     }
    // };

    // useEffect
    useEffect(() => {
        getAllExemptedControls();
        getAllRequirements();
        getAllRequirementMetrics();
        // getAllMerchantDocuments();
        // getMerchantAnswers();
        getCompanyDetails();
        getUserDetails();
        // getAllFormsByTags();
        // getAllDocumentByTags();
        getModuleGuides();
        // getEvidenceMetrics();
        // getAllMerchantEvidences();
    }, []);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.compliance ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.compliance]);

    // useEffect(() => {
    //     if (merchant_info?.compliance_ai && !compliance_ai_modal) {
    //         handleOpenAiModal();
    //     } else {
    //         setOpenAiModal(false);
    //     }
    // }, [compliance_ai_modal, merchant_info?.compliance_ai]);
    // useEffect(() => {
    //     if (!merchant_info?.compliance_ai) {
    //         handleOpenAiTopBar();
    //     }
    // }, [merchant_info?.compliance_ai]);

    // smartsearch
    useEffect(() => {
        if (location.state?.parent && location.state?.parent === 'pcidss4.0') {
            if (location.state.modalType) {
                switch (location.state.modalType) {
                    case 'documents':
                        setCurrentTab(0);
                        return;
                    case 'auditQuestions':
                        setCurrentTab(1);
                        return;
                    case 'exceptions':
                        setCurrentTab(2);
                        return;
                    case 'additionalEvidences':
                        // openAdditonalEvidenceModal();
                        return;
                    default:
                        return;
                }
            }
        }
    }, [location]);

    return (
        <>
            <PageHeader browserTitle="PCIDSS 4.0.1 - Compliance | Smartcomply" />
            {/* {openAiTopBar && currentTab === 1 ? <ComplianceAITopBar handleCloseAiTopBar={handleCloseAiTopBar} /> : null} */}
            <Box>
                <ComplianceLayout
                    onTabChange={handleTabChange}
                    tab={currentTab}
                    openTasksModal={openTasksModal}
                    // openDataRoomModal={openAdditonalEvidenceModal}
                    ref1={ref1}
                    // ref2={ref2}
                    ref3={ref3}
                    standard="card4"
                >
                    <AppTabPanel value={currentTab} index={0}>
                        <Documents
                            loading={loading.requirements || loading.metrics || loading.exceptions}
                            // getRequirements={getAllRequirements}
                            openTaskAssignModal={openTaskAssignModal}
                            openExemptModal={openExemptModal}
                        />
                    </AppTabPanel>
                    {/* <AppTabPanel value={currentTab} index={1}>
                        <AuditQuestions
                            loading={loading.requirements}
                            getRequirements={getAllRequirements}
                            isComplianceOn={merchant_info?.compliance_ai}
                            openTaskAssignModal={openTaskAssignModal}
                            />
                            </AppTabPanel> */}
                    <AppTabPanel value={currentTab} index={2}>
                        {/* <Exceptions getRequirements={getAllRequirements} /> */}
                        <Documents
                            loading={loading.requirements || loading.metrics || loading.exceptions}
                            // getRequirements={getAllRequirements}
                            isExceptionTab={true}
                            openRevertModal={openRevertModal}
                        />
                    </AppTabPanel>
                </ComplianceLayout>
            </Box>

            <TaskIntroductionModal
                open={introModalOpen}
                handleClose={closeIntroModal}
                updateModuleIntroModal={updateModuleIntroModal}
            />

            {/* <AssignTaskModal open={modalOpen} onClose={closeTaskAssignModal} type={modalType} taskInfo={taskInfo} /> */}
            <AssignToDialog isOpen={modalOpen} handleCallback={closeTaskAssignModal} requirementData={taskInfo} />
            <ExemptRequirementDialog
                title="Requirement"
                subTitle="requirements"
                isOpen={exmeptModalOpen}
                handleCallback={closeExemptModal}
                requirementData={taskInfo}
            />
            <RevertRequirementDialog
                title="Requirement"
                isOpen={revertModalOpen}
                handleCallback={closeRevertModal}
                requirementData={taskInfo}
            />
            <AllTaskModal open={tasksModalOpen} handleClose={closeTasksModal} standard="card4" />
            {/* {currentTab === 1 && (
                <ComplianceAITipsModal
                    open={openAiModal}
                    handleClose={handleCloseAiModal}
                    width="700px"
                    minWidth={900}
                    neverShowComplianceAiTipsModal={neverShowComplianceAiTipsModal}
                />
            )} */}
            {/* <AdditionalEvidenceModal
                open={additionalEvidenceModalOpen}
                handleClose={closeAdditionalEvidenceModal}
                standard="card4"
                all_requirements={all_pcidss4point0_requirements}
                all_metrics={all_pcidss4point0_requirements_metrics}
                all_evidences_metrics={all_evidences_metrics}
            /> */}
            {!loading.requirements && <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} />}
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Compliance"
                headerId={oneArticle?.id}
                icon={true}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        // merchant_info: state?.merchantReducers?.merchant_info,
        // compliance_ai_modal: state?.generalReducers?.user_info?.compliance_ai_modal,
        guideModal: state?.generalReducers?.module,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.compliance,
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};
export default connect(mapStateToProps, {
    GetAllPcidss4Point0Requirements,
    GetAllPcidss4Point0RequirementMetrics,
    // GetAllMerchantDocuments,
    // GetAllMerchantAnswers,
    GetCompanyDetails,
    GetUserDetails,
    UpdateUserDetails,
    // GetAllFormsByTags,
    GetModuleGuides,
    CreateModuleGuide,
    UpdateModuleGuide,
})(PCIDSS4POINT0);
