import { Route, Switch } from 'react-router-dom';
import GdpaDashboard from './GdpaDashboard';
// import FormsAndDocumentsView from './FormsAndDocumentsView';
import CategoryPage from './CategoryPage';
// import GdpaFormCreation from '../Operations/FormCreation';

const Gdpa = () => {
    return (
        <Switch>
            <Route path="/admin/gdpa" exact component={GdpaDashboard} />
            <Route path="/admin/gdpa/category" exact component={CategoryPage} />
            {/* <Route path="/admin/gdpa/form" component={(props) => <GdpaFormCreation tag="gdpa" {...props} />} /> */}
        </Switch>
    );
};

export default Gdpa;
