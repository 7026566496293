import BreadCrumb from 'components/Breadcrumb';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

//antd
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Table } from 'antd';
import CybersecurityModal from '../IsoActions/CybersecurityModal';

//redux
import { connect } from 'react-redux';
import { CreateAnnexNew, DeleteAnnexNew, EditAnnexNew, GetAllCybersecurities } from 'store/actions/adminActions';
import CyberSecurityOperations from './CyberSecurityOperations';

// sub-components
// const { Title } = Typography;
const { confirm } = Modal;

const CybersecurityView = (props) => {
    //state
    const [loading, setLoading] = useState({
        content: false,
    });
    const [modalOpen, setModalOpen] = useState(null);
    const [oneReq, setOneReq] = useState({});

    //props
    const { GetAllCybersecurities, CreateAnnexNew, EditAnnexNew, DeleteAnnexNew, all_cybersecurities } = props;
    //functions
    const openAddModal = (mode, data) => {
        setModalOpen(mode);
        setOneReq(data);
    };
    const closeAddModal = () => {
        setModalOpen(false);
    };

    const deleteCyberSecurity = (id, category) => {
        confirm({
            title: 'Are you sure you want to delete this cyber security? Note: Deleting this cyber security means you will be deleting this cyber security, the documents, audit questions and merchant data associated with this cyber security',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteAnnexNew(id, category);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    // data
    const columns = [
        {
            title: 'Title',
            render: (record) => record.title,
            sorter: (a, b) => a.title.localeCompare(b.title),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Policy Statements',
            render: (record) => (
                <ul className="list-disc flex flex-col !gap-1 !mx-4">
                    {record.policy_statements?.length ? (
                        record.policy_statements?.map((policy, index) => <li key={index}>{policy}</li>)
                    ) : (
                        <li>No policy statement</li>
                    )}
                </ul>
            ),
        },
        {
            title: 'Actions',
            align: 'right',
            render: (record) => (
                <CyberSecurityOperations
                    openEditModal={openAddModal}
                    cyber_security={record}
                    deleteCyberSecurity={deleteCyberSecurity}
                />
            ),
        },
    ];

    useEffect(() => {
        const getAllCybersecurities = async () => {
            setLoading(true);
            const res = await GetAllCybersecurities();
            setLoading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllCybersecurities();
    }, []);
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb
                        views={[{ label: 'ISO27017 + 27032', link: '/iso27017+27032' }, { label: 'Cyber Securities' }]}
                    />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openAddModal('Add')}
                    >
                        Add Cyber Security
                    </Button>
                </Col>
            </Row>

            <section className="my-3">
                <Table
                    columns={columns}
                    dataSource={all_cybersecurities}
                    loading={loading?.content}
                    rowKey={(cyber_security) => cyber_security.id}
                />
            </section>

            {/* <section className="my-3">
                {all_cybersecurities && all_cybersecurities?.length > 0 ? (
                    <Row wrap gutter={24}>
                        {all_cybersecurities
                            ?.sort((a, b) => a.id - b.id)
                            ?.map((cybersecurity, index) => (
                                <Col
                                    xs={24}
                                    md={12}
                                    lg={8}
                                    xxl={6}
                                    key={cybersecurity.id}
                                    style={{ marginBottom: '1rem' }}
                                >
                                    <Card
                                        title={`#${index}`}
                                        loading={false}
                                        actions={[
                                            <Tooltip title="View this cybersecurity" color="blue">
                                                <Link
                                                    key="preview"
                                                    to={{
                                                        pathname: '/admin/iso27017+27032/cybersecurity',
                                                        state: {
                                                            cybersecurity,
                                                        },
                                                    }}
                                                >
                                                    <EyeOutlined key="preview" />
                                                </Link>
                                            </Tooltip>,
                                            <Tooltip title="Edit this cybersecurity" color="blue">
                                                <EditOutlined
                                                    key="edit"
                                                    onClick={() => openAddModal('Edit', cybersecurity)}
                                                />
                                            </Tooltip>,
                                            <Tooltip title="Delete this cybersecurity" color="red">
                                                <DeleteOutlined
                                                    key="delete"
                                                    onClick={() =>
                                                        deleteAnnex(cybersecurity.id, cybersecurity.category)
                                                    }
                                                />
                                            </Tooltip>,
                                        ]}
                                    >
                                        <Title level={5} style={{ textAlign: 'center' }}>
                                            {cybersecurity.title}
                                        </Title>
                                    </Card>
                                </Col>
                            ))}
                    </Row>
                ) : (
                    <Row justify="center">
                        <Col>
                            <Empty
                                description={
                                    <div>
                                        <h3>No Cybersecurity</h3>
                                        <span>Add a new Cybersecurity </span>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                )}
            </section> */}

            <CybersecurityModal
                open={modalOpen}
                handleCancel={closeAddModal}
                CreateClause={CreateAnnexNew}
                EditClause={EditAnnexNew}
                one_req={oneReq}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_cybersecurities: state?.adminReducers?.all_cybersecurities,
    };
};

export default connect(mapStateToProps, {
    GetAllCybersecurities,
    CreateAnnexNew,
    EditAnnexNew,
    DeleteAnnexNew,
})(CybersecurityView);
