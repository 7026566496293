import { MoreVert } from '@mui/icons-material';
import { IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AppTableActionMenu from 'components/new_components/app-table/AppTableActionMenu';
import { useMemo, useState } from 'react';

// core component
// import { Tag } from 'antd';
import GroupCard from '../../common/GroupCard';
// import ControlExemptModal from './ControlExemptModal';

//redux
import { connect, useSelector } from 'react-redux';
// import { getType } from 'utils';
import { useHistory } from 'react-router-dom';

const ControlCard = (props) => {
    const theme = useTheme();
    const {
        id,
        title,
        service,
        metric,
        // resourceLabel,
        // onViewChange,
        // exempted,
        // all_exempted_controls,
        openTaskAssignModal,
        openExemptModal,
        openRevertModal,
        isControlAssigned,
        // isSubControlAssigned,
        // resourceAssigned,
        // isTaskMissed,
        isExceptionTab,
    } = props;
    // const [activeHoverCard, setActiveHoverCard] = useState(null);
    // const [modalOpen, setModalOpen] = useState(false);
    // const [activeTitle, setActiveTitle] = useState('');
    // const [type, setType] = useState('');
    const [anchor, setAnchor] = useState(null);
    // const role = getType('role');

    const history = useHistory();

    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const organization = useSelector((state) => state?.generalReducers?.user_info?.organization);
    const all_exempted_controls = useSelector((state) => state?.complianceReducers?.all_exempted_controls);

    const exmeptedRequirement = useMemo(
        () => all_exempted_controls?.filter((item) => item?.standard === 'soc2')?.find((item) => item?.soc_id === id),
        [all_exempted_controls, id]
    );

    const openMenu = (e) => {
        e.stopPropagation();
        setAnchor(e.target);
    };
    const closeMenu = () => setAnchor(null);

    const handleOpenTaskModal = (e) => {
        e.stopPropagation();
        openTaskAssignModal('requirement', {
            common_control: id,
            // is_document: resourceLabel !== 'Questions Answered',
            is_document: true,
            standard: 'soc2',
        });
        closeMenu();
    };

    const handleOpenExemptModal = (e) => {
        e.stopPropagation();
        openExemptModal('requirement', {
            soc_id: id,
            standard: 'soc2',
        });
        closeMenu();
    };

    const handleOpenRevertModal = (e) => {
        e.stopPropagation();
        openRevertModal('requirement', {
            id: exmeptedRequirement?.id,
        });
        closeMenu();
    };

    const handleCardClick = () => {
        if (metric?.totalValue && !anchor) {
            // onViewChange(id);
            history.push(`/merchant/compliance/frameworks/soc2/${id}`);
        }
    };
    // const onHover = (id) => {
    //     setActiveHoverCard(id);
    // };
    // const onHoverOut = () => {
    //     setActiveHoverCard(null);
    // };

    // const openModal = (exempted, title) => {
    //     setModalOpen(true);
    //     setType(!exempted ? 'exempt' : 'revert');
    //     setActiveTitle(title);
    //     closeMenu();
    // };
    // const closeModal = () => {
    //     setModalOpen(false);
    //     setType(null);
    //     setActiveTitle(null);
    // };

    // const exemptedControlId = useMemo(() => {
    //     return exempted ? all_exempted_controls?.find((control) => control?.soc_detail?.title === activeTitle)?.id : id;
    // }, [all_exempted_controls, activeTitle, exempted]);
    return (
        <>
            <GroupCard
                percentCompleted={
                    (metric?.totalValue === 0 ? 100 : (metric?.totalAttendedTo / metric?.totalValue) * 100) || 0
                }
                totalValue={metric?.totalValue}
                totalAttendedTo={metric?.totalAttendedTo}
                // resourceLabel={resourceLabel}
                onCardClick={handleCardClick}
                empty={metric?.totalValue == 0}
                // onHover={onHover}
                // onHoverOut={onHoverOut}
                id={id}
                // exempted={exempted}
                // activeHoverCard={activeHoverCard}
                isCategoryAssigned={isControlAssigned}
                // isTaskMissed={isTaskMissed}
                // isSubCatgoryAssigned={isSubControlAssigned}
                // isResourceAssigned={resourceAssigned?.includes(title)}
                // standard={'soc2'}
                isExceptionTab={isExceptionTab}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    className="relative"
                    onClick={(event) => event.stopPropagation()}
                >
                    <Typography
                        sx={{ fontWeight: 600, mb: 0, color: '#475569', fontSize: 15, textTransform: 'capitalize' }}
                    >
                        {title}
                    </Typography>
                    {metric?.totalValue > 0 &&
                        (!isExceptionTab ? (
                            !isControlAssigned && user_type !== 'auditor' ? (
                                <>
                                    {organization?.merchant_plan?.name !== 'free_version' &&
                                        organization?.merchant_plan?.name !== '3_days_trial' && (
                                            <IconButton
                                                sx={{ color: theme.palette.gray[900], minWidth: 'unset', p: 0 }}
                                                onClick={openMenu}
                                            >
                                                <MoreVert
                                                    sx={{
                                                        color: '#475569',
                                                    }}
                                                />
                                            </IconButton>
                                        )}
                                    <AppTableActionMenu
                                        anchor={anchor}
                                        closeMenu={closeMenu}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <MenuItem
                                            sx={{ fontSize: 14, fontWeight: 500, color: '#64748B' }}
                                            onClick={handleOpenTaskModal}
                                        >
                                            Assign to
                                        </MenuItem>

                                        <MenuItem
                                            sx={{ fontSize: 14, fontWeight: 500, color: '#64748B' }}
                                            onClick={handleOpenExemptModal}
                                        >
                                            Exempt
                                        </MenuItem>
                                    </AppTableActionMenu>
                                </>
                            ) : null
                        ) : (
                            <>
                                {organization?.merchant_plan?.name !== 'free_version' &&
                                    organization?.merchant_plan?.name !== '3_days_trial' && (
                                        <IconButton
                                            sx={{ color: theme.palette.gray[900], minWidth: 'unset', p: 0 }}
                                            onClick={openMenu}
                                        >
                                            <MoreVert
                                                sx={{
                                                    color: '#475569',
                                                }}
                                            />
                                        </IconButton>
                                    )}
                                <AppTableActionMenu
                                    anchor={anchor}
                                    closeMenu={closeMenu}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <MenuItem
                                        sx={{ fontSize: 14, fontWeight: 500, color: '#64748B' }}
                                        onClick={handleOpenRevertModal}
                                    >
                                        Revert
                                    </MenuItem>
                                </AppTableActionMenu>
                            </>
                        ))}
                    {/* {activeHoverCard === id && ( */}
                    {/* {role !== 'auditor' && (
                        <>
                            {exempted ? (
                                <Tag
                                    // color="#f50"
                                    className="w-fit h-fit absolute -right-4 text-[11px] z-20 cursor-pointer"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openModal(exempted, title);
                                    }}
                                >
                                    Revert
                                </Tag>
                            ) : (
                                <>
                                    <IconButton
                                        sx={{ color: theme.palette.gray[900], minWidth: 'unset', p: 0 }}
                                        onClick={openMenu}
                                    >
                                        <MoreVert />
                                    </IconButton>
                                    <AppTableActionMenu
                                        anchor={anchor}
                                        closeMenu={closeMenu}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        {!isControlAssigned ? (
                                            <MenuItem
                                                sx={{ fontSize: 14, color: '#64748B', fontWeight: 500 }}
                                                onClick={handleOpenTaskModal}
                                            >
                                                Assign to:
                                            </MenuItem>
                                        ) : null}
                                        <Divider />
                                        <MenuItem
                                            sx={{ fontSize: 14, color: '#64748B', fontWeight: 500 }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                openModal(exempted, title);
                                            }}
                                        >
                                            Not applicable
                                        </MenuItem>
                                    </AppTableActionMenu>
                                </>
                            )}
                        </>
                    )} */}
                    {/* )} */}
                </Stack>
                {/* <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: 500,
                        color: '#64748B',
                        textTransform: 'capitalize',
                        mt: 1,
                    }}
                >
                    {description}
                </Typography> */}
                <Typography
                    component="p"
                    sx={{ fontSize: 12, fontWeight: 500, color: '#64748B', textTransform: 'capitalize' }}
                >
                    {service}
                </Typography>
            </GroupCard>
            {/* <ControlExemptModal open={modalOpen} handleClose={closeModal} type={type} ControlId={exemptedControlId} /> */}
        </>
    );
};

// const mapStateToProps = (state) => ({
const mapStateToProps = () => ({
    // all_exempted_controls: state?.complianceReducers?.all_exempted_controls,
});
export default connect(mapStateToProps, {})(ControlCard);
