/* eslint-disable no-unused-vars */

import { Menu, MenuItem } from '@mui/material';
import { Loader2 } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { UploadMerchantDocument } from 'store/actions/complianceActions';
import { convertQuillContentsToDOCX, importDocumentIntoEditor } from 'store/actions/editorActions';
import { extractDocName } from '../../../utils';
import AcceptanceHistoryDrawer from '../../AcceptanceHistoryDrawer';
import PolicyHistoryDrawer from '../../PolicyHistoryDrawer';
import ChangesDialog from '../../ReuseablePolicyViewDrawer/NavBar/ChangesDialog';
import PolicyApprovalDrawer from '../../ReuseablePolicyViewDrawer/NavBar/PolicyApprovalDrawer';
import PolicyReviewDrawer from '../../ReuseablePolicyViewDrawer/NavBar/PolicyReviewDrawer';
import ApprovePolicyDialog from './ApprovePolicyDialog';
import RejectPolicyDialog from './RejectPolicyDialog';
import ReviewPolicyDialog from './ReviewPolicyDailog';

export default function NavBar({
    handleClose,
    handleCallback,
    mode,
    standard,
    currentRequirement,
    document,
    isNativeFile,
    role,
    existingPolicyId,
    useExistingPolicy,
    editorRef,
    isEditable,
    setIsEditable,
    exportedDoc,
    setExportedDoc,
    filename,
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isChangesDialogOpen, setIsChangesDialogOpen] = useState(false);
    const [isPolicyApprovalDrawerOpen, setIsPolicyApprovalDrawerOpen] = useState(false);
    const [isPolicyReviewDrawerOpen, setIsPolicyReviewDrawerOpen] = useState(false);
    const [isAcceptanceHistoryDrawerOpen, setIsAcceptanceHistoryDrawerOpen] = useState(false);
    const [isPolicyHistoryDrawerOpen, setIsPolicyHistoryDrawerOpen] = useState(false);
    const [isApprovePolicyDialogOpen, setIsApprovePolicyDialogOpen] = useState(false);
    const [isRejectPolicyDialogOpen, setIsRejectPolicyDialogOpen] = useState(false);
    const [rejectPolicyMode, setRejectPolicyMode] = useState('approve');
    const [isReviewPolicyDialogOpen, setIsReviewPolicyDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const all_merchant_documents = useSelector((state) => state?.complianceReducers?.all_merchant_documents);
    const user_id = useSelector((state) => state?.authReducers?.user_id);

    const dispatch = useDispatch();

    const allDocuments = useMemo(
        () =>
            all_merchant_documents
                ?.filter((doc) => doc?.tag === standard)
                ?.map((doc) => ({ ...doc, default: doc?.document })),
        [all_merchant_documents]
    );

    async function handleConvert() {
        setExportedDoc({});

        const response = await dispatch(convertQuillContentsToDOCX(editorRef.current));
        if (!response?.success) {
            return {
                success: false,
                message: response?.message,
            };
        }

        setExportedDoc({
            doc: response?.doc,
            delta: response?.delta,
        });

        return {
            success: true,
            doc: response?.doc,
            delta: response?.delta,
        };
    }

    async function handleCloseAsDraft() {
        const convertResponse = await handleConvert();
        if (!convertResponse?.success) {
            toast.error(convertResponse?.message);
            return;
        }

        try {
            setIsLoading(true);

            const nativeFormData = new FormData();
            existingPolicyId && nativeFormData.append('existing_policy', existingPolicyId);
            useExistingPolicy && nativeFormData.append('use_existing_policy', 'true');
            nativeFormData.append('document', convertResponse?.doc);
            nativeFormData.append('tag', standard);
            nativeFormData.append('policy_status', 'draft');
            nativeFormData.append('is_policy_editor', 'true');
            nativeFormData.append('filename', filename);
            nativeFormData.append('notes', document?.notes || '');
            nativeFormData.append('policy_info', JSON.stringify(document?.policy_info || {}));
            convertResponse?.delta && nativeFormData.append('operations', JSON.stringify(convertResponse?.delta));
            nativeFormData.append('requested_by', user_id);
            nativeFormData.append('author', user_id);

            const response = await dispatch(UploadMerchantDocument(nativeFormData));
            if (!response.success) throw new Error(response?.message);

            toast.success('Document saved successfully');

            handleClose();
            handleCallback();
        } catch (error) {
            toast.error(error?.message || 'Failed to save document');
        } finally {
            setIsLoading(false);
        }
    }

    async function loadDocument() {
        const response = await dispatch(
            importDocumentIntoEditor({
                document,
                isNativeFile,
                quill: editorRef.current,
                delta: isNativeFile ? null : document?.operations,
            })
        );
        if (!response?.success) {
            toast.error(response?.message);
            handleClose();
        }
    }

    useEffect(() => {
        if (!editorRef.current) return;
        loadDocument();
    }, [document, isNativeFile, editorRef]);

    return (
        <>
            <div
                className={`border-b border-[#F1F5F9] bg-white !px-6 !py-4 flex items-center justify-between fixed z-10 w-full top-0 ${
                    isLoading ? 'pointer-events-none cursor-wait' : ''
                }`}
                onClick={() => {
                    // dispatch(setCommentDialogAnchorEl(null));
                    // dispatch(setCommentPopUpAnchorEl(null));
                }}
            >
                <div className="flex items-center !gap-6">
                    <img
                        src="/img/automated-scan/arrow-left-icon.svg"
                        alt="arrow-left-icon"
                        onClick={handleClose}
                        className="cursor-pointer object-contain"
                    />

                    <div className="flex items-center !gap-2">
                        <h3 className="text-[#002C72] capitalize font-bold text-lg">
                            {isNativeFile
                                ? document?.name?.replaceAll('_', ' ')
                                : extractDocName((document?.filename || document?.default)?.replaceAll('_', ' '))}
                        </h3>

                        {!useExistingPolicy && document?.policy_status && (
                            <p
                                className={`!rounded-sm !py-0.5 !px-1.5 text-sm capitalize ${
                                    document?.policy_status === 'awaiting_approval'
                                        ? 'bg-[#F1F5F9] !border !border-[#CBD5E1] text-[#395BA9]'
                                        : document?.policy_status === 'awaiting_review'
                                        ? 'bg-[#F1F5F9] !border !border-[#CBD5E1] text-[#B28C00]'
                                        : document?.policy_status === 'approved'
                                        ? 'bg-[#F1F5F9] !border !border-[#CBD5E1] text-[#395BA9]'
                                        : document?.policy_status?.includes('rejected')
                                        ? 'bg-[#F1F5F9] !border !border-[#CBD5E1] text-[#FF5449]'
                                        : 'bg-[#F1F5F9] !border !border-[#CBD5E1] text-[#475569]'
                                }`}
                            >
                                {document?.policy_status?.replaceAll('_', ' ')}
                            </p>
                        )}
                    </div>
                </div>

                <div className="flex items-center !gap-3">
                    {isEditable && <img src="/img/frameworks/cloud-yes.svg" alt="cloud active" className="!w-6 !h-6" />}

                    {role === 'approver' ? (
                        document?.policy_status === 'awaiting_approval' &&
                        (isEditable ? (
                            <>
                                <button
                                    className="transition-all hover:brightness-95 bg-[#202D66] !rounded !h-9 !p-2 text-white font-medium text-sm"
                                    onClick={async () => {
                                        const response = await handleConvert();
                                        if (!response?.success) {
                                            toast.error(response?.message);
                                            return;
                                        }
                                        setIsPolicyApprovalDrawerOpen(true);
                                    }}
                                >
                                    Approve & Save
                                </button>

                                <button
                                    className="transition-all hover:brightness-95 bg-white !rounded !h-9 !p-2 !border !border-[#E2E8F0] text-[#334155] font-medium text-sm"
                                    onClick={async () => {
                                        const response = await handleConvert();
                                        if (!response?.success) {
                                            toast.error(response?.message);
                                            return;
                                        }
                                        setIsPolicyReviewDrawerOpen(true);
                                    }}
                                >
                                    Submit for review
                                </button>

                                <button
                                    className="transition-all hover:brightness-95 bg-[#F8FAFC] !rounded !h-9 !p-2 !border !border-[#E2E8F0] text-[#334155] font-medium text-sm disabled:opacity-50 disabled:pointer-events-none flex items-center !gap-1"
                                    disabled={isLoading}
                                    onClick={handleCloseAsDraft}
                                >
                                    {isLoading && <Loader2 className="!h-4 !w-4 animate-spin" />}
                                    Close as draft
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    className="transition-all hover:brightness-95 bg-[#202D66] !rounded !h-9 !p-2 text-white font-medium text-sm"
                                    onClick={() => setIsApprovePolicyDialogOpen(true)}
                                >
                                    Approve
                                </button>

                                <button
                                    className="transition-all hover:brightness-95 bg-white !rounded !h-9 !p-2 !border !border-[#E2E8F0] text-[#FF5449] font-medium text-sm"
                                    onClick={() => {
                                        setRejectPolicyMode('approve');
                                        setIsRejectPolicyDialogOpen(true);
                                    }}
                                >
                                    Reject
                                </button>

                                <button
                                    className="transition-all hover:brightness-95 bg-[#F8FAFC] !rounded !h-9 !p-2 !border !border-[#E2E8F0] text-[#334155] font-medium text-sm"
                                    onClick={() => {
                                        if (!editorRef.current) {
                                            toast.error('Editor not found');
                                            return;
                                        }
                                        editorRef.current.enable();
                                        setIsEditable(true);
                                    }}
                                >
                                    Edit
                                </button>
                            </>
                        ))
                    ) : role !== 'reviewer' &&
                      (mode === 'create' || document?.policy_status === 'draft' || useExistingPolicy) ? (
                        <>
                            <button
                                className="transition-all hover:brightness-95 bg-[#202D66] !rounded !h-9 !p-2 text-white font-medium text-sm"
                                onClick={async () => {
                                    const response = await handleConvert();
                                    if (!response?.success) {
                                        toast.error(response?.message);
                                        return;
                                    }
                                    setIsPolicyApprovalDrawerOpen(true);
                                }}
                            >
                                Submit for approval
                            </button>

                            <button
                                className="transition-all hover:brightness-95 bg-white !rounded !h-9 !p-2 !border !border-[#E2E8F0] text-[#334155] font-medium text-sm"
                                onClick={async () => {
                                    const response = await handleConvert();
                                    if (!response?.success) {
                                        toast.error(response?.message);
                                        return;
                                    }
                                    setIsPolicyReviewDrawerOpen(true);
                                }}
                            >
                                Submit for review
                            </button>

                            {isEditable ? (
                                <button
                                    className="transition-all hover:brightness-95 bg-[#F8FAFC] !rounded !h-9 !p-2 !border !border-[#E2E8F0] text-[#334155] font-medium text-sm disabled:opacity-50 disabled:pointer-events-none flex items-center !gap-1"
                                    disabled={isLoading}
                                    onClick={handleCloseAsDraft}
                                >
                                    {isLoading && <Loader2 className="!h-4 !w-4 animate-spin" />}
                                    Close as draft
                                </button>
                            ) : (
                                <button
                                    className="transition-all hover:brightness-95 bg-[#F8FAFC] !rounded !h-9 !p-2 !border !border-[#E2E8F0] text-[#334155] font-medium text-sm"
                                    onClick={() => {
                                        if (!editorRef.current) {
                                            toast.error('Editor not found');
                                            return;
                                        }
                                        editorRef.current.enable();
                                        setIsEditable(true);
                                    }}
                                >
                                    Edit
                                </button>
                            )}
                        </>
                    ) : (
                        role !== 'reviewer' &&
                        mode === 'update' && (
                            <>
                                <button
                                    className="transition-all hover:brightness-95 bg-[#202D66] !rounded !h-9 !p-2 text-white font-medium text-sm"
                                    onClick={async () => {
                                        if (document?.policy_status === 'awaiting_review') {
                                            toast.error(
                                                'You cannot makes changes to a document while it is under review'
                                            );
                                            return;
                                        }

                                        const response = await handleConvert();
                                        if (!response?.success) {
                                            toast.error(response?.message);
                                            return;
                                        }
                                        if (existingPolicyId && !useExistingPolicy) {
                                            setIsChangesDialogOpen(true);
                                        } else {
                                            setIsPolicyApprovalDrawerOpen(true);
                                        }
                                    }}
                                >
                                    Update
                                </button>

                                {!isEditable && (
                                    <button
                                        className="transition-all hover:brightness-95 bg-[#F8FAFC] !rounded !h-9 !p-2 !border !border-[#E2E8F0] text-[#334155] font-medium text-sm"
                                        onClick={() => {
                                            if (document?.policy_status === 'awaiting_review') {
                                                toast.error(
                                                    'You cannot makes changes to a document while it is under review'
                                                );
                                                return;
                                            }

                                            if (!editorRef.current) {
                                                toast.error('Editor not found');
                                                return;
                                            }
                                            editorRef.current.enable();
                                            setIsEditable(true);
                                        }}
                                    >
                                        Edit
                                    </button>
                                )}
                            </>
                        )
                    )}

                    {role === 'reviewer' && document?.policy_status === 'awaiting_review' && (
                        <>
                            <button
                                className="transition-all hover:brightness-95 bg-[#202D66] !rounded !h-9 !p-2 text-white font-medium text-sm"
                                onClick={() => setIsReviewPolicyDialogOpen(true)}
                            >
                                Mark as reviewed
                            </button>

                            <button
                                className="transition-all hover:brightness-95 bg-white !rounded !h-9 !p-2 !border !border-[#E2E8F0] text-[#FF5449] font-medium text-sm"
                                onClick={() => {
                                    setRejectPolicyMode('review');
                                    setIsRejectPolicyDialogOpen(true);
                                }}
                            >
                                Reject
                            </button>
                        </>
                    )}

                    <div>
                        <button
                            aria-controls={anchorEl ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={anchorEl ? 'true' : undefined}
                            onClick={(event) => {
                                event.stopPropagation();
                                setAnchorEl(event.currentTarget);
                                // dispatch(setCommentDialogAnchorEl(null));
                                // dispatch(setCommentPopUpAnchorEl(null));
                            }}
                            className="transition-all hover:brightness-95 bg-white !rounded !h-9 !px-2 !border !border-[#E2E8F0] grid place-items-center"
                        >
                            <img
                                src="/img/frameworks/more_horiz.svg"
                                alt="view more"
                                className="cursor-pointer object-contain"
                            />
                        </button>

                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={(event) => {
                                event.stopPropagation();
                                setAnchorEl(null);
                            }}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow:
                                        '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                                },
                                '& .MuiList-root': {
                                    padding: '0 0',
                                },
                            }}
                        >
                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setAnchorEl(null);

                                    if (document?.policy_status === 'awaiting_review') {
                                        toast.error('You cannot makes changes to a document while it is under review');
                                        return;
                                    }

                                    loadDocument();
                                }}
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    textTransform: 'capitalize',
                                    padding: '6px 16px',
                                    border: '1px solid #F1F5F9',
                                }}
                            >
                                Reset
                            </MenuItem>

                            {allDocuments?.length > 0 && !allDocuments?.every((doc) => !doc?.policy_versions) ? (
                                <MenuItem
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setAnchorEl(null);
                                        setIsPolicyHistoryDrawerOpen(true);
                                    }}
                                    sx={{
                                        color: '#64748B',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        textTransform: 'capitalize',
                                        padding: '6px 16px',
                                        border: '1px solid #F1F5F9',
                                    }}
                                >
                                    Policy history
                                </MenuItem>
                            ) : null}

                            {allDocuments?.length > 0 && !allDocuments?.every((doc) => !doc?.policy_versions) ? (
                                <MenuItem
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setAnchorEl(null);
                                        setIsAcceptanceHistoryDrawerOpen(true);
                                    }}
                                    sx={{
                                        color: '#64748B',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        textTransform: 'capitalize',
                                        padding: '6px 16px',
                                        border: '1px solid #F1F5F9',
                                    }}
                                >
                                    Acceptance history
                                </MenuItem>
                            ) : null}
                        </Menu>
                    </div>
                </div>
            </div>

            <ChangesDialog
                isOpen={isChangesDialogOpen}
                setIsOpen={setIsChangesDialogOpen}
                handleCallback={() => {
                    handleClose();
                    handleCallback();
                }}
                document={exportedDoc?.doc}
                documentNotes={document?.notes || ''}
                standard={standard}
                policyInformation={document?.policy_info || {}}
                existingPolicyId={existingPolicyId}
                useExistingPolicy={useExistingPolicy}
                setIsPolicyApprovalDrawerOpen={setIsPolicyApprovalDrawerOpen}
                filename={filename}
                delta={exportedDoc?.delta}
            />

            <PolicyApprovalDrawer
                role={role}
                mode={mode}
                isOpen={isPolicyApprovalDrawerOpen}
                setIsOpen={setIsPolicyApprovalDrawerOpen}
                handleCallback={() => {
                    handleClose();
                    handleCallback();
                }}
                document={exportedDoc?.doc}
                documentNotes={document?.notes || ''}
                standard={standard}
                policyInformation={document?.policy_info || {}}
                existingPolicyId={existingPolicyId}
                useExistingPolicy={useExistingPolicy}
                isEditorDocument={true}
                isPaid={true}
                currentRequirement={currentRequirement}
                filename={filename}
                delta={exportedDoc?.delta}
            />

            <PolicyReviewDrawer
                isOpen={isPolicyReviewDrawerOpen}
                setIsOpen={setIsPolicyReviewDrawerOpen}
                handleCallback={() => {
                    handleClose();
                    handleCallback();
                }}
                document={exportedDoc?.doc}
                documentNotes={document?.notes || ''}
                standard={standard}
                existingPolicyId={existingPolicyId}
                useExistingPolicy={useExistingPolicy}
                currentRequirement={currentRequirement}
                filename={filename}
                delta={exportedDoc?.delta}
            />

            <ApprovePolicyDialog
                document={document}
                isOpen={isApprovePolicyDialogOpen}
                setIsOpen={setIsApprovePolicyDialogOpen}
                handleCallback={() => {
                    handleClose();
                    handleCallback();
                }}
            />

            <ReviewPolicyDialog
                document={document}
                isOpen={isReviewPolicyDialogOpen}
                setIsOpen={setIsReviewPolicyDialogOpen}
                handleCallback={() => {
                    handleClose();
                    handleCallback();
                }}
            />

            <RejectPolicyDialog
                document={document}
                mode={rejectPolicyMode}
                isOpen={isRejectPolicyDialogOpen}
                setIsOpen={setIsRejectPolicyDialogOpen}
                handleCallback={() => {
                    handleClose();
                    handleCallback();
                }}
            />

            <PolicyHistoryDrawer
                isOpen={isPolicyHistoryDrawerOpen}
                setIsOpen={setIsPolicyHistoryDrawerOpen}
                standard={standard}
                document={document}
            />

            <AcceptanceHistoryDrawer
                isOpen={isAcceptanceHistoryDrawerOpen}
                setIsOpen={setIsAcceptanceHistoryDrawerOpen}
                standard={standard}
                document={document}
            />
        </>
    );
}
