/* eslint-disable no-unused-vars */

import PageHeader from 'components/new_components/PageHeader';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GetAllDocReviewStatsByRequirement, GetAllMerchantDocuments } from 'store/actions/complianceActions';
import { GetAddOns } from 'store/actions/generalActions';
import { GetPcidss4Point0DocumentsByRequirement } from 'store/actions/pcidssActions';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';
import TableLoading from 'views/merchant/Compliance/common/document_review/components/PolicyStatementsTable/TableLoading';
import DescriptionContent from '../../../common/document_review/components/DescriptionContent';
import PolicyStatementsTable from '../../../common/document_review/components/PolicyStatementsTable';
import TitleContent from '../../../common/document_review/components/TitleContent';

export default function PCIDSS4Point0RequirementID({ match }) {
    const [isLoading, setIsLoading] = useState(false);
    const [isUsePolicyEditorDrawerOpen, setIsUsePolicyEditorDrawerOpen] = useState(false);
    const [isUseUploadedFileDrawerOpen, setIsUseUploadedFileDrawerOpen] = useState(false);
    const [defaultTab, setDefaultTab] = useState(null);

    const all_requirements = useSelector((state) => state?.adminReducers?.all_pcidss4point0_requirements);
    const docReviewStats = useSelector((state) => state?.complianceReducers?.all_pcidss4point0_doc_review_stats_by_req);
    const all_documents_by_req = useSelector((state) => state?.pcidssReducers?.all_pcidss4point0_documents_by_req);

    const { activeMerchant } = useContext(SelectedMerchantContext);

    const history = useHistory();

    const dispatch = useDispatch();

    const currentRequirement = all_requirements?.find((req) => req?.id === +match?.params?.requirement_id);

    const formattedDocReviewStats = useMemo(
        () =>
            docReviewStats
                ? Object.entries(docReviewStats).map(([key, value], index) => ({
                      id: index + 1,
                      name: key,
                      status: value?.status?.replace('_', ' '),
                      source: value?.document || value?.default,
                      document: value?.document,
                      default: value?.default,
                      gaps: value?.status !== 'implemented',
                      isNew: value?.isNew,
                  }))
                : [],
        [docReviewStats]
    );

    async function fetchDocReviewStats() {
        setIsLoading(true);
        const response = await dispatch(GetAllDocReviewStatsByRequirement('card4', currentRequirement?.id));
        setIsLoading(false);
        if (!response.success) {
            toast.error("Something went wrong! Couldn't fetch requirement policy statements.");
        }
    }

    async function getAllMerchantDocuments() {
        const res = await dispatch(GetAllMerchantDocuments(activeMerchant));
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch your documents.");
        }
    }

    async function getDocumentsByRequirement() {
        const res = await dispatch(GetPcidss4Point0DocumentsByRequirement(currentRequirement?.id));
        if (!res.success) {
            toast.error(res?.message);
        }
    }

    useEffect(() => {
        fetchDocReviewStats();
        getAllMerchantDocuments();
        getDocumentsByRequirement();
        dispatch(GetAddOns());
    }, []);

    return (
        <>
            <PageHeader browserTitle="PCIDSS 4.0.1 Requirements - Compliance | SmartComplyApp" />

            <div className="flex items-center !gap-6 !border-b !border-[#F1F5F9] bg-white !py-4 !px-6 fixed top-0 !w-full">
                <button className="transition-all hover:scale-90" onClick={() => history.goBack()}>
                    <img src="/img/frameworks/arrow-narrow-left.svg" alt="arrow left" className="!w-6 !h-6" />
                </button>

                <h3 className="text-[#002C72] font-bold text-lg">PCIDSS 4.0.1: Requirement {currentRequirement?.id}</h3>
            </div>

            <div className="!mt-4 !mx-6 bg-white !rounded-lg !border !border-[#E2E8F0] flex flex-col">
                <TitleContent
                    currentRequirement={currentRequirement}
                    title="Requirement"
                    subTitle="requirements"
                    standard="card4"
                    templates={all_documents_by_req}
                    formattedDocReviewStats={formattedDocReviewStats}
                    assignToRequirementData={{
                        card4_requirement: currentRequirement?.id,
                        is_document: true,
                        standard: 'card4',
                    }}
                    exemptRequirementData={{
                        card4: currentRequirement?.id,
                        standard: 'card4',
                    }}
                    isLoading={isLoading}
                    fetchDocReviewStats={fetchDocReviewStats}
                    isUsePolicyEditorDrawerOpen={isUsePolicyEditorDrawerOpen}
                    setIsUsePolicyEditorDrawerOpen={setIsUsePolicyEditorDrawerOpen}
                    isUseUploadedFileDrawerOpen={isUseUploadedFileDrawerOpen}
                    setIsUseUploadedFileDrawerOpen={setIsUseUploadedFileDrawerOpen}
                    defaultTab={defaultTab}
                    setDefaultTab={setDefaultTab}
                />

                {currentRequirement?.requirement_description && (
                    <DescriptionContent description={currentRequirement?.requirement_description} />
                )}

                {isLoading ? (
                    <TableLoading />
                ) : (
                    <PolicyStatementsTable
                        currentRequirement={currentRequirement}
                        title="Requirement"
                        formattedDocReviewStats={formattedDocReviewStats}
                        setIsUsePolicyEditorDrawerOpen={setIsUsePolicyEditorDrawerOpen}
                        setIsUseUploadedFileDrawerOpen={setIsUseUploadedFileDrawerOpen}
                        setDefaultTab={setDefaultTab}
                        standard="card4"
                    />
                )}
            </div>
        </>
    );
}
