import { jsPDF } from 'jspdf';
import 'jspdf/dist/polyfills.es.js';
import Logo from '../../../../assets/img/brand/logo-blue.png';

import LiberationSerifRegular from './fonts/LiberationSerif-Regular.ttf';
import LiberationSerifBold from './fonts/LiberationSerif-Bold.ttf';
import { GetDownloadLink } from 'store/actions/generalActions';

const getImageFormat = (base64) => {
    const match = base64.match(/^data:image\/(png|jpeg|jpg);base64,/i);
    return match ? match[1].toUpperCase() : 'PNG';
};

export const generateVendorRiskReport = async (vendor, isAml = true, company_img, user_type, orgId) => {
    const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
        margin: 50,
    });

    const loadImage = async () => {
        try {
            const res = await GetDownloadLink('user_mgt', user_type, orgId)();
            if (!res?.success) throw new Error(res?.message);

            const response = await fetch(res.data);
            const blob = await response.blob();
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error('Failed to load image:', error);
            return '';
        }
    };

    let imageData = Logo;
    let failed = false;

    if (company_img) {
        const fetchedData = await loadImage();
        if (fetchedData) {
            imageData = fetchedData;
        } else {
            failed = true;
        }
    }

    doc.addFont(LiberationSerifRegular, 'Liberation Serif', 'normal');
    doc.addFont(LiberationSerifBold, 'Liberation Serif', 'bold');

    doc.setFont('Liberation Serif', 'normal');
    doc.setFontSize(12);

    const formatDate = (dateString) => {
        const date = dateString ? new Date(dateString) : new Date();
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    };

    const currentDate = formatDate(vendor.date_signed);
    const format = company_img ? getImageFormat(imageData) : 'PNG';

    // Add Cover Page
    const logoWidth = 123;
    const logoHeight = (60 / 246) * logoWidth + (company_img && !failed ? 40 : 0);
    doc.addImage(imageData, format, 10, 10, logoWidth / 3.78, logoHeight / 3.78);

    doc.setFontSize(28);
    doc.text(vendor.merchant_name, 105, 150, { align: 'center' });

    doc.setFontSize(12);
    const headerInfo = [
        `FROM: ${vendor?.department || 'IT and Operational Risk'} Department`,
        `TO: ${vendor?.to || 'Chief Risk Officer'}`,
        `DATE: ${formatDate(vendor.date)}`,
        `SUBJECT: Third Party Risk Assessment Report for ${vendor.vendor_name}`,
    ];
    headerInfo.forEach((line, index) => {
        doc.text(line, 10, 260 + index * 5);
    });

    doc.addPage();

    let yPos = 20;

    const assignMarker = (score) => {
        const numScore = parseInt(score);

        if (numScore <= parseInt(33)) {
            return `${score}-h`; // h high rish for 0-33
        } else if (numScore >= parseInt(34) && numScore < 66) {
            return `${score}-m`; //m for medium risk yellow for 34-66
        }
        return `${score}-l`; // l for low risk
    };
    const getScoreColor = (scoreWithMarker) => {
        // Check if the score has a risk marker
        const markerMatch = String(scoreWithMarker).match(/(\d+)-(h|m|l)/);
        if (markerMatch) {
            const marker = markerMatch[2];
            switch (marker) {
                case 'h':
                    return '#FF5449'; // high risk - red
                case 'm':
                    return '#F2C021'; // medium risk - yellow
                case 'l':
                    return '#55BE8B'; // low risk - green
                default:
                    return '#000000'; // default black
            }
        }

        // Fallback to numeric score logic if no marker is present
        const numScore = parseInt(scoreWithMarker);
        if (isNaN(numScore)) return '#000000';

        if (numScore <= 33) return '#FF5449';
        if (numScore >= 34 && numScore < 66) return '#F2C021';
        return '#55BE8B';
    };

    const addSection = (title, content) => {
        if (yPos > 200) {
            doc.addPage();
            yPos = 20;
        }
        if (!title.match(/introduction/gi)) {
            yPos += 10;
        }

        // Title
        doc.setFont('Liberation Serif', 'bold');
        doc.setFontSize(14);
        doc.text(title, 10, yPos);
        yPos += 8;

        doc.setFont('Liberation Serif', 'normal');
        doc.setFontSize(12);

        if (Array.isArray(content)) {
            content.forEach((line) => {
                if (yPos > 240) {
                    doc.addPage();
                    yPos = 20;
                }

                if (line.startsWith('•')) {
                    const bulletText = line.substring(1).trim();
                    const parts = bulletText.split(':');
                    if (parts.length > 1) {
                        // Check for score with risk marker (e.g., "88-h", "45-m", "23-l")
                        const scoreMatch = parts[1].match(/(\d+)-(h|m|l)/);
                        if (scoreMatch) {
                            const scoreWithMarker = scoreMatch[0];
                            const scoreText = parts[1].replace(/(\d+)-(h|m|l)/, '$1').trim();

                            doc.text(`• ${parts[0]}:`, 15, yPos, { maxWidth: 175 });
                            const textWidth = doc.getTextWidth(`• ${parts[0]}:`);
                            const scoreStartX = 15 + textWidth + 2;

                            doc.setFont('Liberation Serif', 'bold');
                            doc.setTextColor(getScoreColor(scoreWithMarker));
                            doc.text(scoreText, scoreStartX, yPos, { maxWidth: 175 });

                            doc.setFont('Liberation Serif', 'normal');
                            doc.setTextColor('#000000');
                        } else {
                            doc.text(line, 15, yPos, { maxWidth: 175 });
                        }
                    } else {
                        doc.text(line, 15, yPos, { maxWidth: 175 });
                    }
                } else {
                    // Check for score with risk marker in regular text
                    const scoreMatch = line.match(/(\d+)-(h|m|l)/);
                    if (scoreMatch) {
                        const scoreWithMarker = scoreMatch[0];
                        const parts = line.split(scoreMatch[0]);
                        const scoreText = scoreWithMarker.replace(/-(h|m|l)$/, '');

                        let textBeforeScore = parts[0];
                        let remainingText = parts.length > 1 ? parts[1] : '';

                        let textStartX = 10;

                        if (textBeforeScore === '') {
                            textBeforeScore = '';
                            textStartX = 10;
                        } else {
                            doc.text(textBeforeScore, textStartX, yPos, { maxWidth: 180 });
                            textStartX += doc.getTextWidth(textBeforeScore) + 2;
                        }

                        doc.setFont('Liberation Serif', 'bold');
                        doc.setTextColor(getScoreColor(scoreWithMarker));
                        doc.text(scoreText, textStartX, yPos, { maxWidth: 180 });

                        doc.setFont('Liberation Serif', 'normal');
                        doc.setTextColor('#000000');

                        const remainingStartX = textStartX + doc.getTextWidth(scoreText) + 2;
                        doc.text(remainingText, remainingStartX, yPos, { maxWidth: 180 });
                    } else {
                        doc.text(line, 10, yPos, { maxWidth: 180 });
                    }
                }

                const textHeight = doc.getTextDimensions(line, { maxWidth: 180 }).h;
                yPos += textHeight + 4;
            });
        } else {
            if (yPos > 260) {
                doc.addPage();
                yPos = 20;
            }
            doc.text(content, 10, yPos, { maxWidth: 180 });
            const textHeight = doc.getTextDimensions(content, { maxWidth: 180 }).h;
            yPos += textHeight + 4;
        }
        yPos += 5;
    };

    const addSpace = (lines = 3) => {
        yPos += lines * 3;
    };

    // Modify the areas_reviewed map to add -s suffix to scores
    const modifiedAreasReviewed = vendor.areas_reviewed.map((area) => ({
        ...area,
        // score: `${area.score}-s`,
        score: assignMarker(area?.score),
    }));

    // All sections
    const allSections = [
        {
            title: 'Introduction',
            content: [
                `This report provides an in-depth risk assessment of ${vendor.vendor_name}, ${vendor.description}. This assessment, conducted through a vendor questionnaire completed by ${vendor.vendor_name}, examines key areas of risk within the company's operations, including its service delivery, regulatory compliance, data management, and security practices. The evaluation specifically focuses on the company's services provided to ${vendor.merchant_name}, identifying potential risks associated with ${vendor.vendor_name}'s processes and controls that could impact service reliability, regulatory adherence, and data protection standards.`,
            ],
        },
        {
            title: 'Objectives',
            content: [
                `The primary objectives of this risk assessment are to:`,
                `• Evaluate ${vendor.vendor_name}'s operational and security practices to identify potential vulnerabilities.`,
                `• Assess the organization's compliance with regulatory and industry standards.`,
                `• Determine the adequacy of ${vendor.vendor_name}'s risk management framework.`,
                `• Provide actionable recommendations to mitigate identified risks.`,
            ],
        },
        {
            title: 'Assessment Methodology',
            content: [
                `The assessment process involved:`,
                `• Reviewing ${vendor.vendor_name}'s responses to a detailed vendor questionnaire.`,
                `• Analyzing documentation related to security policies, certifications, and procedures.`,
                `• Identifying potential gaps and vulnerabilities based on the organization's practices.`,
                `• Benchmarking against industry standards and best practices.`,
                ``,
                `Specific areas reviewed included:`,
                // ...modifiedAreasReviewed.map((area) => `• ${area.title}`),
                ...modifiedAreasReviewed.map((area) => `• ${area.title}: ${area.score}`),
            ],
        },
        {
            title: 'Summary of Findings',
            content: [
                `1. Organizational Overview:`,
                `• Name: ${vendor.vendor_name}`,
                `• Description: ${vendor.description}`,
                `• Products by Vendor: ${vendor.products}`,
                ``,
                `2. Organizational Information Security:`,
                `• Policies and practices to safeguard an organization's data and digital assets. The client overall score in this section is ${
                    vendor.areas_reviewed.find((area) => area.title === 'Organizational Information Security')?.score ||
                    '0' /* + '-s' */
                }.`,
                ``,
                `3. General Security:`,
                `• Broad security principles covering physical, digital, and operational risks. The client's evaluated score in this section is ${
                    vendor.areas_reviewed.find((area) => area.title === 'General Security')?.score || '0'
                    /* + '-s' */
                }.`,
                ``,
                `4. Network Security:`,
                `• Protection of network infrastructure from unauthorized access, attacks, and data breaches. For this section, the client has an overall score of ${
                    vendor.areas_reviewed.find((area) => area.title === 'Network Security')?.score || '0'
                    /* + '-s' */
                }.`,
                ``,
                `5. Security Monitoring:`,
                `• Continuous surveillance and analysis to detect and respond to security threats. The client received a score of ${
                    vendor.areas_reviewed.find((area) => area.title === 'Security Monitoring')?.score || '0' /* +
                    '-s' */
                } in this section.`,
                ``,
                `6. Business Continuity / Disaster Recovery:`,
                `• Strategies to ensure operational resilience and recovery in case of disruptions. The client overall score in this section is ${
                    vendor.areas_reviewed.find((area) => area.title === 'Business Continuity / Disaster Recovery')
                        ?.score || '0' /* + '-s' */
                }.`,
                ``,
                `7. Incident Response:`,
                `• Structured approach to identifying, managing, and mitigating security incidents. The client's cumulative score in this section is ${
                    vendor.areas_reviewed.find((area) => area.title === 'Incident Response')?.score || '0' /* +
                    '-s' */
                }.`,
                ``,
                `8. Risk Management / Auditing / Regulatory Compliance:`,
                `• Processes to identify risks, enforce security controls, and comply with industry regulations. In this section, the client's overall score stands at ${
                    vendor.areas_reviewed.find(
                        (area) => area.title === 'Risk Management/Auditing /Regulatory Compliance'
                    )?.score || '0' /* + '-s' */
                }.`,
                ``,
                `9. Availability:`,
                `• Ensuring systems, services, and data are accessible and functional when needed. The total score for the client in this section is ${
                    vendor.areas_reviewed.find((area) => area.title === 'Availability')?.score || '0' /* + '-s' */
                }.`,
                ...(isAml
                    ? [
                          ``,
                          `10. Anti-Money Laundering (AML):`,
                          `• Measures to detect and prevent financial crimes and illicit transactions. For this section, the client has an overall score of ${
                              vendor.areas_reviewed.find((area) => area.title === 'Anti-Money Laundering')?.score ||
                              '0' /* + '-s' */
                          }.`,
                          ``,
                          `11. Environmental and Social Governance (ESG):`,
                          `• Ethical and sustainability practices for corporate responsibility and compliance. The score attained by the client in this section is ${
                              vendor.areas_reviewed.find((area) => area.title === 'Environmental and Social Governance')
                                  ?.score || '0' /* + '-s' */
                          }.`,
                      ]
                    : [
                          ``,
                          `10. Environmental and Social Governance (ESG):`,
                          `• Ethical and sustainability practices for corporate responsibility and compliance. The score attained by the client in this section is ${
                              vendor.areas_reviewed.find((area) => area.title === 'Environmental and Social Governance')
                                  ?.score || '0' /* + '-s' */
                          }.`,
                      ]),
            ],
        },
        {
            title: 'Key Risks Identified',
            content: [
                ...vendor.key_risk_identified.flatMap((risk) => [
                    `• ${Object.keys(risk)[0]}: ${Object.values(risk)[0]}`,
                    ` `,
                ]),
            ],
        },
        {
            title: 'Recommendations',
            content: [
                ...vendor.recommendations.flatMap((rec) => [`• ${Object.keys(rec)[0]}: ${Object.values(rec)[0]}`, ` `]),
            ],
        },
        {
            title: 'Overall Rating',
            content: [
                `• Final Score: ${assignMarker(vendor.overall_score) || 'N/A'}`,
                `• Final Risk Level: ${vendor.overall_risk_level || 'N/A'}`,
            ],
        },
    ];

    allSections.forEach((section) => addSection(section.title, section.content));
    addSpace();

    let conclusionText = `${vendor.vendor_name} demonstrates a solid commitment to managing third-party risks associated with its services to ${vendor.merchant_name}. The organization's adherence to industry standards and its strong technical team significantly mitigate risk levels. However, the lack of physical security measures and the need for enhanced vendor oversight present areas for improvement. By implementing the recommendations provided, ${vendor.vendor_name} can strengthen its risk management framework and better safeguard its operations.`;

    // Conclusion Section
    doc.setFont('Liberation Serif', 'bold');
    doc.text('Conclusion', 10, yPos);
    yPos += 10;

    doc.setFont('Liberation Serif', 'normal');
    doc.text(conclusionText, 10, yPos, { maxWidth: 180 });
    addSpace();

    // Attestation Section
    yPos += 30;
    doc.setFont('Liberation Serif', 'bold');
    doc.text('Attestation', 10, yPos);
    yPos += 10;

    doc.setFont('Liberation Serif', 'normal');
    const attestationText = `I, ${vendor.recipient_name || '{Name}'}, on behalf of the ${
        vendor?.department || 'IT and Operational Risk'
    } Department, attest that this assessment report accurately reflects the findings and recommendations based on the review of ${
        vendor.vendor_name
    }.`;
    doc.text(attestationText, 10, yPos, { maxWidth: 180 });

    yPos += 25;
    doc.text('Signature:', 10, yPos);

    if (vendor.signature) {
        doc.addImage(vendor.signature, 'PNG', 32, yPos - 7, 80 / 3.78, 45 / 3.78, undefined, 'FAST');
    } else {
        doc.line(50, yPos, 120, yPos);
    }

    yPos += 15;
    doc.text(`Date: ${currentDate}`, 10, yPos);

    const fileName = `${vendor.vendor_name}_Risk_Report_${new Date().toISOString().split('T')[0]}.pdf`;
    doc.save(fileName);
};
