import { TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// core components
import AppTableCell from './AppTableCell';

const AppTableRow = (props) => {
    const theme = useTheme();
    const { columns, row, rowSpacing, clickableRow, onRowClick, rowBorder, checked_row, applyClickableRowStyles } =
        props;
    const spacingBorderStyles = {
        borderTop: '1px solid ' + theme.palette.gray[50] + 80,
        borderBottom: '1px solid ' + theme.palette.gray[50] + 80,
        '&:first-of-type': {
            borderLeft: '1px solid ' + theme.palette.gray[50] + 80,
        },
        '&:last-of-type': {
            borderRight: '1px solid ' + theme.palette.gray[50] + 80,
        },
    };
    return (
        <TableRow
            sx={{
                my: 3,
                boxShadow:
                    '-23px 170px 68px rgba(209, 209, 209, 0.01), -13px 95px 58px rgba(209, 209, 209, 0.05), -6px 42px 43px rgba(209, 209, 209, 0.09), -1px 11px 24px rgba(209, 209, 209, 0.1), 0px 0px 0px rgba(209, 209, 209, 0.1)',
                backgroundColor: checked_row?.includes(row.id) ? '#F1F5F9' : theme.palette.white.main,
                border: checked_row ? '1ps solid #E2E8F0' : '',
                borderRadius: 1,
                padding: 10,
                cursor: clickableRow && clickableRow() ? 'pointer' : 'default',
                ...(applyClickableRowStyles ? { '&:hover': { backgroundColor: theme.palette.gray[50] } } : {}),
            }}
            onClick={clickableRow && clickableRow() ? onRowClick : null}
        >
            {columns.map((column, index) => {
                return (
                    <AppTableCell
                        key={column?.title + row?.id + index}
                        sx={{
                            fontWeight: 400,
                            color: theme.palette.neutral[500],
                            borderBottom: '1px solid ' + theme.palette.gray[100],
                            border: rowBorder ? '1px solid ' + theme.palette.neutral[20] : 'none',
                            ...(rowSpacing ? spacingBorderStyles : {}),
                            // padding: 1,
                            // maxWidth: '150px',
                        }}
                        align={column.align}
                    >
                        {column.render(row)}
                    </AppTableCell>
                );
            })}
        </TableRow>
    );
};

export default AppTableRow;
