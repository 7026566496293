import { Box, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
// import moment from 'moment';

import MerchantHeader from 'components/new_components/merchant-layout/header';
import MerchantSidebar from 'components/new_components/merchant-layout/sidebar';
import WelcomeModal from '../components/Modal/WelcomeModal';

// routes
import { NotificationsActive } from '@mui/icons-material';
import NotificationModal from 'components/Notification/NotificationModal';
import { connect, useDispatch, useSelector } from 'react-redux';
import getRoutes from 'routes/merchantRoutes.js';
import { GetUserDetails } from 'store/actions/generalActions';
import { GetUserGuideDetails } from 'store/actions/guideActions';
import { GetCompanyDetails, SendDeviceToken, UpdateDeviceToken } from 'store/actions/merchantActions';
import { GetAllMerchantNotifications } from 'store/actions/notificationActions';
import { getType } from '../utils';

import SessionTimeout from 'components/sessionTimeout/SessionTimeout';
import { getToken, onMessage } from 'firebase/messaging';
import { GetUserDeviceToken } from 'store/actions/merchantActions';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import { messaging } from 'utils/firebase-config';
import Setup2FaModal from 'views/merchant/AccountSetup/TwoFactorAuth/Setup2FaModal';
// import TwoFactorCountdownModal from 'views/merchant/AccountSetup/TwoFactorAuth/TwoFactorCountdownModal';
// import DefaultModal from 'components/new_components/merchant-layout/sidebar/UserGuideModal/DefaultModal';
// import UserGuideModal from 'components/new_components/merchant-layout/sidebar/UserGuideModal';
// import CenteredModal from 'components/new_components/merchant-layout/sidebar/UserGuideModal/CenteredModal';
import { CloseOutlined } from '@ant-design/icons';
import SmartDashboardAI from 'components/Dashboard/smartDashboardAI';
import moment from 'moment';
import { GetModuleGuides } from 'store/actions/generalActions';
import * as types from 'store/constants';
import { BANNER_OPEN } from 'store/constants';

const NewMerchantLayout = ({
    pricing_plan,
    GetCompanyDetails,
    GetAllMerchantNotifications,
    GetUserGuideDetails,
    GetUserDeviceToken,
    SendDeviceToken,
    UpdateDeviceToken,
    GetUserDetails,
    user_id,
    user_device_token,
    mfa,
    // userguide_info,
}) => {
    const location = useLocation();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [openWelcomeModal, setOpenWelcomeModal] = useState(false);
    const [routes, setRoutes] = useState(undefined);
    const [pageTitle, setPageTitle] = useState('');
    const [pageSubTitle, setSubPageTitle] = useState('');
    // const [pageSubTitle, setPageSubTitle] = useState('');
    const [notificationModalOpen, setNotificationModalOpen] = useState(false);
    // const [countdownModal, setCountdownModal] = useState(true);
    // const [countdown, setCountdown] = useState(null);
    const [twoFactorModal, setTwoFactorModal] = useState(false);
    // const [openDefaultModal, setOpenDefaultModal] = useState(false);
    // const [page, setPage] = useState(0);
    // const [title, setTitle] = useState('');
    const { updateOneUserGuide } = useContext(UserGuideContext);
    const dispatch = useDispatch();
    const history = useHistory();

    const plan = useSelector((state) => state?.generalReducers?.user_info?.organization?.merchant_plan?.name);
    const sidebar = useSelector((state) => state?.generalReducers?.user_info?.sidebar_menu) ?? false;
    const user_info = useSelector((state) => state?.generalReducers?.user_info);
    const client_id = useSelector((state) => state?.implementerReducer?.client_id);
    const banner_open = useSelector((state) => state?.generalReducers?.banner_open);
    const [aIModalOpen, setAIModalOpen] = useState(false);
    // const userguide = useSelector((state) => state?.userGuideReducers);
    // const { id, isActive } = userguide;
    // functions
    // control the sidebar
    const openSidebar = () => setSidebarOpen(true);
    const closeSidebar = () => setSidebarOpen(false);

    const closeWelcome = () => {
        updateOneUserGuide({ welcome: true });
        setOpenWelcomeModal(false);
    };

    //control notificationModal
    const openNotificationModal = () => setNotificationModalOpen(true);
    const closeNotificationModal = () => setNotificationModalOpen(false);

    //countdown modal
    // const openCountdownModal = () => setCountdownModal(true);
    // const closeCountdownModal = () => setCountdownModal(false);

    // generate the routes
    const generateRoutes = (routes) => {
        const routesList = routes?.map((prop, key) => {
            if (prop.layout === '/merchant') {
                return <Route exact path={prop.layout + prop.path} component={prop.component} key={key} />;
            } else if (prop.subMenus) {
                return prop.subMenus.map((menu, key) => {
                    return <Route path={menu.layout + menu.path} component={menu.component} key={key} exact />;
                });
            } else {
                return null;
            }
        });
        if (routesList && user_info?.user_type !== 'implementer') {
            routesList.push(<Redirect to="/merchant/index" key="redirect" />);
        } else {
            routesList.push(<Redirect to="/merchant/compliance/data-room" key="redirect" />);
        }
        return routesList;
    };

    // get the page header title
    const getPageTitle = (path) => {
        const current = routes?.find((route) => {
            if (!route?.subMenus) {
                return route?.layout + route?.path === path;
            } else {
                return route.subMenus.find((menu) => menu.layout + menu.path === path);
            }
        });
        if (current?.subMenus) {
            const subMenus = current?.subMenus?.find((menu) => menu?.layout + menu?.path === path);
            setPageTitle(subMenus?.name);
            setSubPageTitle(
                subMenus?.path
                    ?.split('/')
                    ?.slice(1, subMenus?.path?.split('/')?.length - 1)
                    ?.join(' / ')
            );

            return subMenus;
        }
        setPageTitle(current?.name);
        setSubPageTitle(current?.sub_name);
    };

    const type = getType('type');
    const role = getType('role');
    // const isAuthenticated = getUserToken('token');
    const auth = useSelector((state) => state?.authReducers?.user_details?.key);
    const VAPID_SECRET_KEY = process.env.REACT_APP_WS_VAPID_SECRET_KEY;
    // const isAuthenticated = getUserToken();

    const askUserPermission = async () => {
        await Notification.requestPermission();

        // If user_device_token exists, return early
        if (user_device_token?.length) {
            const currentToken = await getToken(messaging, {
                vapidKey: VAPID_SECRET_KEY,
            });
            if (user_device_token?.[0]?.device_id !== currentToken) {
                UpdateDeviceToken({ device_id: currentToken }, user_device_token?.[0]?.id);
            }
            return;
        }

        // Get registration token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.
        getToken(messaging, {
            vapidKey: VAPID_SECRET_KEY,
        })
            .then(async (currentToken) => {
                if (currentToken) {
                    SendDeviceToken({ device_id: currentToken, user: user_id });
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            })
            .catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            });
    };

    const getModuleGuides = async () => {
        const res = GetModuleGuides();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        getModuleGuides();
    }, []);

    useEffect(() => {
        const getUserGuideDetails = async () => {
            const res = await GetUserGuideDetails();
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getUserGuideDetails();
    }, []);

    useEffect(() => {
        if (!sidebar && user_info?.user_type !== 'implementer') {
            // setOpenDefaultModal(true);
            dispatch({ type: types.TOGGLE_USERGUIDE, payload: true });
            dispatch({ type: 'SET_USERGUIDE_ID', payload: 0 });
        }
    }, [sidebar]);

    // const show2FaWarning = () => {
    //     if (mfa) {
    //         return false;
    //     } else if (location.pathname !== '/merchant/settings/account' && !mfa) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // };
    // useEffects
    //countdown timer logic
    // useEffect(() => {
    //     const countdownDate = moment('2023-07-17T00:00:00');
    //     const interval = setInterval(() => {
    //         const now = moment();
    //         const duration = moment.duration(countdownDate.diff(now));

    //         if (duration.asSeconds() <= 0) {
    //             clearInterval(interval);
    //         } else {
    //             setCountdown({
    //                 days: duration.days(),
    //                 hours: duration.hours(),
    //                 minutes: duration.minutes(),
    //                 seconds: duration.seconds(),
    //             });
    //         }
    //     }, 1000);

    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, []);

    useEffect(() => {
        const getUserDetails = async () => {
            const res = await GetUserDetails();
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getUserDetails();
    }, []);
    useEffect(() => {
        // window.addEventListener('storage', () => {
        //     // When storage changes refetch
        //     location.reload();
        // });
        const routes = getRoutes(
            user_info?.user_type === 'implementer'
                ? user_info?.organization?.tag
                : pricing_plan?.tag || getType('plan'),
            type === 'employee' ? role : type,
            [
                'pcidss',
                'ndpr',
                'iso27001',
                'iso27017+iso27032',
                'iso27001-2022',
                'soc2',
                'soc2type2',
                'blockchain',
                'iso22301',
                'pcidss4.0.1',
                'kdpa',
                'gdpa',
                'cii',
                'pcislc',
                'iso9001',
                'iso37301',
            ]
        );
        // console.log({ routes });
        setRoutes(routes);
        // return () => {
        //     // When the component unmounts remove the event listener
        //     window.removeEventListener('storage');
        // };
    }, [localStorage.getItem('i18nextLng')]);

    // const removeRoute = routes?.map((route) => {
    //     if (route.subMenus) {
    //         return {
    //             name: route.name,
    //             hasAccess: route.hasAccess,
    //             icon: route.icon,
    //             subRoute: route.subRoute,
    //             subMenus: route.subMenus.filter((subRoute) => !subRoute.exempt),
    //         };
    //     } else return route;
    // });
    const removeRoute = routes?.filter((route) => !route.exempt);

    useEffect(() => {
        const getCompanyDetails = async () => {
            const res = await GetCompanyDetails(client_id);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getCompanyDetails();
    }, []);
    useEffect(() => {
        const getUserGuideDetails = async () => {
            const res = await GetUserGuideDetails();
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getUserGuideDetails();
    }, []);
    useEffect(() => {
        const getAllMerchantNotifications = async () => {
            const res = await GetAllMerchantNotifications();
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllMerchantNotifications();
    }, []);
    useEffect(() => {
        const { pathname } = location;
        getPageTitle(pathname);
    }, [location.pathname, routes]);
    useEffect(() => {
        const getUserDeviceToken = async () => {
            const res = await GetUserDeviceToken();
            if (!res.success) {
                toast.error(res?.message);
            }
        };
        getUserDeviceToken();
    }, []);
    useEffect(() => {
        askUserPermission();
        // Handle incoming messages. Called when:
        // - a message is received while the app has focus
        // - the user clicks on an app notification created by a service worker
        //   `messaging.onBackgroundMessage` handler.

        onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
            // ...
            toast.info(payload?.notification?.body);
        });
    }, [user_device_token]);
    // useEffect(() => {
    //     !userguide_info ? openWelcome() : null;
    // }, [userguide_info]);
    useEffect(() => {
        if (
            location?.pathname !== '/merchant/settings' &&
            getType('mfa') !== 'true' &&
            user_info?.user_type !== 'implementer'
        ) {
            setTwoFactorModal(true);
        } else {
            setTwoFactorModal(false);
        }
    }, [location?.pathname, mfa]);

    const openAIModal = () => {
        setAIModalOpen(true);
    };
    const closeAIModal = () => {
        setAIModalOpen(false);
    };

    const renewal_date = useMemo(() => {
        if (user_info?.renewal_request_info?.show_banner && banner_open) {
            return (
                <>
                    <span className="font-semibold">{moment(user_info?.organization.plan_end_date).fromNow()}</span>
                </>
            );
        }

        return null;
    }, [user_info?.renewal_request_info?.show_banner]);

    const newDesignPages = [
        'settings',
        'risk management',
        'vendors',
        'pcidss',
        'ndpr',
        'soc2',
        'iso27001',
        'pcislc',
        'transaction fraud monitoring',
        'iso9001',
        'kdpa',
        'integrations',
        'iso27017+iso27032',
        'iso27001-2022',
        'iso22301',
        'pcidss4.0.1',
        'gdpa',
        'infrastructures scans',
        'policies',
        'blockchain',
        'cii',
    ];

    const newSubRequirementsPath = {
        pcidss: location.pathname.includes('/merchant/compliance/frameworks/pcidss/'),
        'pcidss4.0.1': location.pathname.includes('/merchant/compliance/frameworks/pcidss4.0.1/'),
    };

    return routes ? (
        <Box
            sx={{
                display: 'flex',
                height: banner_open && user_info?.renewal_request_info?.show_banner ? 'calc(100vh - 3rem)' : '100vh',
                overflow: 'hidden',
            }}
        >
            {banner_open && user_info?.renewal_request_info?.show_banner && (
                <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    gap={1}
                    sx={{
                        height: '3rem',
                        background: '#D97706',
                        width: '100%',
                        py: 1,
                        minWidth: '100vh',
                        position: 'absolute',
                    }}
                >
                    <Typography variant="body1" sx={{ textAlign: 'center', color: '#ffffff', fontSize: 13 }}>
                        Heads up! Your subscription expires in {renewal_date}
                    </Typography>
                    <Box
                        sx={{
                            border: '1px solid #E2E8F0',
                            px: 1,
                            py: 0.5,
                            color: '#ffffff',
                            fontSize: 13,
                            borderRadius: '5px',
                            cursor: 'pointer',
                        }}
                        onClick={() =>
                            history.push('/merchant/settings', {
                                tab_: 2,
                            })
                        }
                    >
                        Renew now
                    </Box>
                    <div className="bg-white border border-[#E2E8F0] px-1.5 cursor-pointer py-0 rounded-full absolute right-10">
                        <CloseOutlined
                            className="text-[#334155] text-xs"
                            onClick={() => {
                                dispatch({ type: BANNER_OPEN, payload: false });
                            }}
                        />
                    </div>
                </Stack>
            )}
            <MerchantSidebar
                open={sidebarOpen}
                handleClose={closeSidebar}
                routes={plan === 'Unified Plan' ? removeRoute : routes}
            />
            {newSubRequirementsPath.pcidss || newSubRequirementsPath['pcidss4.0.1'] ? null : (
                <MerchantHeader
                    openDrawer={openSidebar}
                    openAIModal={openAIModal}
                    title={
                        pageTitle === 'CII'
                            ? 'CII & RISK MANAGEMENT DIRECTIVES'
                            : pageTitle === 'Overview'
                            ? 'Dashboard'
                            : pageTitle === 'KDPA'
                            ? 'KDPA: KENYA DATA PROTECTION ACT & REGULATIONS'
                            : pageTitle === 'GDPA'
                            ? 'GDPA: GHANA DATA PROTECTION ACT'
                            : pageTitle === 'ISO27017+ISO27032'
                            ? 'Cloud Security, Cybersecurity, and Project Management'
                            : pageTitle === 'PCISLC'
                            ? 'PCI Secure Software Lifecycle'
                            : pageTitle === 'KYC'
                            ? 'Anti-Money Laundering Check'
                            : pageTitle
                    }
                    subtitle={pageSubTitle}
                    openNotificationModal={openNotificationModal}
                    notificationModalOpen={notificationModalOpen}
                />
            )}
            <Box
                component="main"
                id="main_section_container"
                sx={{
                    // flexGrow: 1,
                    py: {
                        xs: 0,
                        md:
                            newDesignPages.includes(pageTitle?.toLowerCase()) ||
                            newSubRequirementsPath.pcidss ||
                            newSubRequirementsPath['pcidss4.0.1']
                                ? 0
                                : 3,
                    },
                    px:
                        newDesignPages.includes(pageTitle?.toLowerCase()) ||
                        newSubRequirementsPath.pcidss ||
                        newSubRequirementsPath['pcidss4.0.1']
                            ? 0
                            : 3,
                    width: { sm: `calc(100% - 275px)` },
                    // mt: { xs: '70px', md: '90px' },
                    mt: {
                        xs:
                            newDesignPages.includes(pageTitle?.toLowerCase()) ||
                            pageTitle?.toLowerCase() === 'document review' ||
                            pageTitle?.toLowerCase() === 'dashboard'
                                ? '64px'
                                : '70px',
                        md:
                            newSubRequirementsPath.pcidss || newSubRequirementsPath['pcidss4.0.1']
                                ? '61px'
                                : newDesignPages.includes(pageTitle?.toLowerCase()) ||
                                  pageTitle?.toLowerCase() === 'document review' ||
                                  pageTitle?.toLowerCase() === 'dashboard'
                                ? '64px'
                                : '90px',
                    },
                    maxWidth: '100%',
                    mb: '80px',
                    height: { xs: 'calc(100% - 70px)', md: 'calc(100% - 90px)', lg: 'calc(100% - 90px)' },
                    overflow: 'auto',
                    position: 'fixed',
                    right: 0,
                    backgroundColor: '#f8f9fe',
                    borderTop: newDesignPages.includes(pageTitle?.toLowerCase()) ? '1px solid #F1F5F9' : '',
                }}
            >
                <Box sx={{ height: '100%' }}>
                    <Switch>{generateRoutes(routes)}</Switch>
                </Box>
            </Box>
            <WelcomeModal open={openWelcomeModal} handleClose={closeWelcome} />
            {/* <DefaultModal open={openDefaultModal} handleClose={closeModal} routes={routes} /> */}
            {/* <CenteredModal open={isActive && id == 0}>
                <UserGuideModal />
            </CenteredModal> */}
            <NotificationModal
                open={notificationModalOpen}
                handleClose={closeNotificationModal}
                title="Notifications"
                width={550}
                icon={<NotificationsActive />}
            />
            <SmartDashboardAI open={aIModalOpen} handleClose={closeAIModal} />
            {auth ? <SessionTimeout isAuthenticated={auth} /> : null}
            <Setup2FaModal open={twoFactorModal} />
            {/* <TwoFactorCountdownModal open={countdownModal} handleClose={closeCountdownModal} countdown={countdown} /> */}
        </Box>
    ) : null;
};

const mapStateToProps = (state) => {
    return {
        pricing_plan: state?.authReducers.pricing_plan,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.welcome,
        user_id: state?.generalReducers?.user_info?.id,
        user_device_token: state?.merchantReducers?.user_device_token,
        mfa: state?.generalReducers?.user_info?.mfa,
        guideModal: state?.generalReducers?.module,
    };
};

export default connect(mapStateToProps, {
    GetCompanyDetails,
    GetAllMerchantNotifications,
    GetUserGuideDetails,
    SendDeviceToken,
    GetUserDeviceToken,
    UpdateDeviceToken,
    GetUserDetails,
    GetModuleGuides,
})(NewMerchantLayout);
