/* eslint-disable no-unused-vars */

import EmptyState from 'components/new_components/EmptyState';
import { useMemo, useState } from 'react';
import { acceptanceHistoryTabs } from '../../../db';
import DepartmentCard from './DepartmentCard';
import TabBar from './TabBar';

export default function MainContent({ selectedVersionId, currentPolicyDocument }) {
    const [currentTab, setCurrentTab] = useState(acceptanceHistoryTabs[0]);

    const departmentsData = useMemo(() => {
        const currentVersion = currentPolicyDocument?.policy_versions?.find(
            (version) => version?.id === selectedVersionId
        );
        const allData = currentVersion?.acceptance_history?.accepted?.concat(
            currentVersion?.acceptance_history?.pending
        );
        const uniqueDepartments = new Set();

        allData?.forEach((data) => {
            uniqueDepartments.add(data?.role);
        });

        return {
            accepted: Array.from(uniqueDepartments)?.map((department) => ({
                name: department,
                status: 'accepted',
                people: currentVersion?.acceptance_history?.accepted?.map((data) =>
                    data?.first_name || data?.last_name ? data?.first_name + ' ' + data?.last_name : data?.email
                ),
            })),
            'not accepted': Array.from(uniqueDepartments)?.map((department) => ({
                name: department,
                status: 'not accepted',
                people: currentVersion?.acceptance_history?.pending?.map((data) =>
                    data?.first_name || data?.last_name ? data?.first_name + ' ' + data?.last_name : data?.email
                ),
            })),
        };
    }, [currentPolicyDocument, selectedVersionId]);

    return (
        <div className="flex-1 !px-14">
            <TabBar currentTab={currentTab} setCurrentTab={setCurrentTab} />

            <div className="flex flex-col !gap-3 !pt-10 !h-[calc(100vh-103px)] overflow-y-auto custom-scroll max-w-[742px] !w-full">
                {departmentsData?.[currentTab]?.length ? (
                    departmentsData?.[currentTab]?.map((department) => (
                        <DepartmentCard key={department?.name} department={department} currentTab={currentTab} />
                    ))
                ) : (
                    <EmptyState description="No data available" />
                )}
            </div>
        </div>
    );
}
