/* eslint-disable no-unused-vars */

import { Dialog } from '@mui/material';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { UpdateMerchantDocument } from 'store/actions/complianceActions';

export default function RejectPolicyDialog({ document, mode, isOpen, setIsOpen, handleCallback }) {
    const [comment, setComment] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    function handleClose() {
        setIsOpen(false);
        setComment('');
    }

    async function updateDocumentStatus() {
        try {
            setIsLoading(true);

            const nativeFormData = {
                policy_status: mode === 'approve' ? 'approval_rejected' : 'review_rejected',
            };

            const response = await dispatch(UpdateMerchantDocument(nativeFormData, document?.id));
            if (!response.success) throw new Error(response?.message);

            toast.success('Document rejected successfully');
            handleClose();
            handleCallback();
        } catch (error) {
            toast.error(error?.message || 'Failed to reject document');
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: '4px !important',
                    padding: 0,
                    boxShadow: '0px 16px 24px 0px #00000026',
                    width: '511px',
                },
            }}
        >
            <div className="flex items-center justify-between !px-6 !pt-6">
                <h5 className="text-lg font-semibold text-[#202D66]">Reject Policy</h5>

                <button
                    className="border-[#E2E8F0] border rounded-full !p-1 !h-6 !w-6 grid place-items-center hover:bg-[#E2E8F0] transition-colors"
                    onClick={handleClose}
                >
                    <img src="/img/automated-scan/close.svg" alt="close icon" className="object-contain" />
                </button>
            </div>

            <div className="!p-6 flex flex-col !gap-4">
                <p className="font-medium text-[13px] text-[#64748B]">Reason for rejecting</p>

                <textarea
                    className="w-full !p-2 !border !border-[#E2E8F0] !min-h-[76px] text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                    placeholder="Comment/feedback"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
            </div>

            <div className="flex items-center justify-end !p-6 bg-[#F8FAFC] !gap-2">
                <button
                    className="bg-[#fff] !border !border-[#E2E8F0] !rounded-sm !px-4 !py-2 transition-all hover:brightness-95 text-[#334155] text-sm font-medium"
                    onClick={handleClose}
                >
                    Cancel
                </button>

                <button
                    className="bg-[#FF5449] !rounded-sm !px-4 !py-2 hover:bg-[#151e44] transition-all hover:brightness-95 text-white text-sm font-semibold disabled:opacity-50 disabled:pointer-events-none flex items-center !gap-1"
                    disabled={isLoading}
                    onClick={updateDocumentStatus}
                >
                    {isLoading && <Loader2 className="!h-5 !w-5 animate-spin" />}
                    Reject
                </button>
            </div>
        </Dialog>
    );
}
