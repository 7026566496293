import mammoth from 'mammoth';
import { formatParsedQuillTableData } from 'views/merchant/Compliance/common/document_review/components/PolicyEditorDrawer/Editor/utils/fns';
import { parseQuillDelta } from 'views/merchant/Compliance/common/document_review/components/PolicyEditorDrawer/Editor/utils/quillParser';
import { generateWord } from 'views/merchant/Compliance/common/document_review/components/PolicyEditorDrawer/Editor/utils/quillToWord';
import { extractDocName } from 'views/merchant/Compliance/common/document_review/utils';
import * as actionTypes from '../constants';
import { ConvertPDFToDOCX, DeleteConvertedDocument, GetDocumentLink } from './complianceActions';

export const setBlotLinkAnchorEl = (value) => ({
    type: actionTypes.SET_BLOT_LINK_ANCHOR_EL,
    payload: value,
});

export const setBlotDetails = (value) => ({
    type: actionTypes.SET_BLOT_DETAILS,
    payload: value,
});

export const setCurrentTemplate = (value) => ({
    type: actionTypes.SET_CURRENT_TEMPLATE,
    payload: value,
});

export const setSelectedFont = (value) => ({
    type: actionTypes.SET_SELECTED_FONT,
    payload: value,
});

export const setSelectedSize = (value) => ({
    type: actionTypes.SET_SELECTED_SIZE,
    payload: value,
});

export const setSelectedAlignment = (value) => ({
    type: actionTypes.SET_SELECTED_ALIGNMENT,
    payload: value,
});

export const setSelectedBackgroundColor = (value) => ({
    type: actionTypes.SET_SELECTED_BACKGROUND_COLOR,
    payload: value,
});

export const setColorPickerBackgroundColor = (value) => ({
    type: actionTypes.SET_COLOR_PICKER_BACKGROUND_COLOR,
    payload: value,
});

export const setIsBold = (value) => ({
    type: actionTypes.SET_IS_BOLD,
    payload: value,
});

export const setIsItalic = (value) => ({
    type: actionTypes.SET_IS_ITALIC,
    payload: value,
});

export const setIsUnderline = (value) => ({
    type: actionTypes.SET_IS_UNDERLINE,
    payload: value,
});

export const setIsStrike = (value) => ({
    type: actionTypes.SET_IS_STRIKE,
    payload: value,
});

export const setIsCode = (value) => ({
    type: actionTypes.SET_IS_CODE,
    payload: value,
});

export const setSelectedColor = (value) => ({
    type: actionTypes.SET_SELECTED_COLOR,
    payload: value,
});

export const setColorPickerColor = (value) => ({
    type: actionTypes.SET_COLOR_PICKER_COLOR,
    payload: value,
});

export const setIsCodeBlock = (value) => ({
    type: actionTypes.SET_IS_CODE_BLOCK,
    payload: value,
});

export const clearFormatting = () => ({ type: actionTypes.CLEAR_FORMATTING });

export const setCommentDialogAnchorEl = (value) => ({
    type: actionTypes.SET_COMMENT_DIALOG_ANCHOR_EL,
    payload: value,
});

export const setCommentMode = (value) => ({
    type: actionTypes.SET_COMMENT_MODE,
    payload: value,
});

export const setCommentPopUpAnchorEl = (value) => ({
    type: actionTypes.SET_COMMENT_POP_UP_ANCHOR_EL,
    payload: value,
});

export const setIsLoadingDocument = (value) => ({
    type: actionTypes.SET_IS_LOADING_DOCUMENT,
    payload: value,
});

export const getFile = (fileUrl) => async () => {
    try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        return {
            success: true,
            blob,
        };
    } catch (error) {
        return {
            success: false,
            message: error?.message || 'Failed to get docx file',
        };
    }
};

export const importDocumentIntoEditor =
    ({ document, isNativeFile, quill, isReadOnly, setIsLoading, delta }) =>
    async (dispatch) => {
        try {
            if (!document) {
                return { success: true };
            }

            if (!quill) {
                throw new Error('Editor not found');
            }

            isReadOnly ? setIsLoading(true) : dispatch(setIsLoadingDocument(true));

            const docExtension = isNativeFile
                ? document?.name?.split('.').pop()
                : (document?.filename || document?.default)?.split('.').pop();

            if (!['pdf', 'docx'].includes(docExtension)) {
                throw new Error('Invalid document format');
            }

            const readAndImportDocx = (fileBlob) => {
                const reader = new FileReader();

                reader.onload = async () => {
                    try {
                        quill.setContents([{ insert: '\n' }]);

                        const doc = reader.result;

                        const mammothResult = await mammoth.convertToHtml({
                            arrayBuffer: doc,
                        });

                        const html = mammothResult.value;

                        const delta = quill.clipboard.convert({
                            html: html,
                        });

                        quill.setContents(delta);
                    } catch (error) {
                        console.log(error);
                        throw new Error('Error importing docx file');
                    }
                };

                reader.onerror = () => {
                    throw new Error('Error reading file');
                };

                reader.readAsArrayBuffer(fileBlob);
            };

            if (delta) {
                quill.setContents(delta);
            } else {
                if (docExtension === 'docx') {
                    let fileBlob;

                    if (isNativeFile) {
                        fileBlob = document;
                    } else {
                        const authorizedUrlResponse = await dispatch(
                            GetDocumentLink(document?.document ? 'userdoc' : 'document', document?.id)
                        );
                        if (!authorizedUrlResponse?.success) {
                            throw new Error('Failed to get authorized document link');
                        }

                        const fileResponse = await dispatch(getFile(authorizedUrlResponse?.data));
                        if (!fileResponse?.success) {
                            throw new Error(fileResponse?.message);
                        }

                        fileBlob = fileResponse?.blob;
                    }

                    readAndImportDocx(fileBlob);
                } else {
                    const authorizedUrlResponse = await dispatch(
                        GetDocumentLink(document?.document ? 'userdoc' : 'document', document?.id)
                    );
                    if (!authorizedUrlResponse?.success) {
                        throw new Error('Failed to get authorized document link');
                    }

                    const nativeFormData = new FormData();
                    isNativeFile
                        ? nativeFormData.append('file', document)
                        : nativeFormData.append('file_url', authorizedUrlResponse?.data);

                    const convertPDFToDOCXResponse = await dispatch(ConvertPDFToDOCX(nativeFormData));
                    if (!convertPDFToDOCXResponse?.success) {
                        throw new Error(convertPDFToDOCXResponse?.message);
                    }

                    const fileResponse = await dispatch(getFile(convertPDFToDOCXResponse?.data));
                    if (!fileResponse?.success) {
                        throw new Error(fileResponse?.message);
                    }

                    readAndImportDocx(fileResponse?.blob);

                    setTimeout(() => {
                        dispatch(
                            DeleteConvertedDocument({
                                filename: extractDocName(convertPDFToDOCXResponse?.data),
                            })
                        );
                    }, 10000);
                }
            }

            return { success: true };
        } catch (error) {
            return {
                success: false,
                message: error?.message || 'Failed to import document',
            };
        } finally {
            isReadOnly ? setIsLoading(false) : dispatch(setIsLoadingDocument(false));
        }
    };

export const convertQuillContentsToDOCX = (quill) => async (dispatch) => {
    try {
        if (!quill) {
            throw new Error('Editor not found');
        }

        dispatch(setIsLoadingDocument(true));

        const delta = quill.getContents();

        const parsedQuill = parseQuillDelta(delta);

        const formattedQuill = formatParsedQuillTableData(parsedQuill);

        const doc = await generateWord(
            {
                paragraphs: formattedQuill,
                setup: parsedQuill.setup,
            },
            {
                exportAs: 'blob',
            }
        );

        return {
            success: true,
            doc,
            delta,
        };
    } catch (error) {
        return {
            success: false,
            message: error?.message || 'Failed to export document',
        };
    } finally {
        dispatch(setIsLoadingDocument(false));
    }
};
