import { Route, Switch } from 'react-router-dom';
// import FormCreation from '../Operations/FormCreation';
// import FormsAndDocumentsView from './FormsAndDocumentsView';
import ISO9001Dashboard from './ISO9001Document';
import SubcontrolsView from './SubmanagementView';

const ISO9001 = () => {
    return (
        <Switch>
            <Route path="/admin/iso9001" exact component={ISO9001Dashboard} />
            <Route path="/admin/iso9001/submanagement" exact component={SubcontrolsView} />
            {/* <Route path="/admin/iso9001/submanagement/items" exact component={FormsAndDocumentsView} />
            <Route
                path="/admin/iso9001/submanagement/items/form"
                component={(props) => <FormCreation tag="iso9001" {...props} />}
            /> */}
        </Switch>
    );
};

export default ISO9001;
