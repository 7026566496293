import { ClickAwayListener, Popper } from '@mui/material';
import { Copy, Globe, Link, Pencil, Unlink } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBlotDetails, setBlotLinkAnchorEl } from 'store/actions/editorActions';

export default function LinkToolbar({ editorRef }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [link, setLink] = useState({
        url: '',
        text: '',
    });
    const [editorRange, setEditorRange] = useState(null);

    const dispatch = useDispatch();

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    function handleClick(event) {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }

    function handleLink() {
        if (editorRef.current) {
            const quill = editorRef.current;
            const scrollY = window.scrollY;

            if (editorRange) {
                if (editorRange.length) {
                    if (link.url) {
                        const newEditorIndex = editorRange.index + link.text.length;

                        quill.deleteText(editorRange.index, editorRange.length);
                        quill.insertText(editorRange.index, link.text, 'link', link.url);
                        quill.setSelection(newEditorIndex, 0);

                        window.scrollTo(0, scrollY);
                    } else {
                        quill.removeFormat(editorRange.index, editorRange.length);
                    }
                } else {
                    quill.insertText(editorRange.index, link.text, 'link', link.url);
                    quill.setSelection(editorRange.index + link.text.length, 0);

                    window.scrollTo(0, scrollY);
                }
            } else {
                quill.insertText(0, link.text, 'link', link.url);
                quill.setSelection(link.text.length, 0);

                window.scrollTo(0, scrollY);
            }
        }

        handleClose();
    }

    function handleClose() {
        setAnchorEl(null);
        setLink({
            url: '',
            text: '',
        });
        setEditorRange(null);

        if (editorRef.current) {
            const quill = editorRef.current;
            quill.focus();
        }
    }

    useEffect(() => {
        if (anchorEl) {
            if (editorRef.current) {
                const quill = editorRef.current;
                const range = quill.getSelection();

                if (range) {
                    const existingLink = quill.getFormat(range.index, range.length).link;
                    const existingText = quill.getText(range.index, range.length) || '';

                    if (existingLink) {
                        setLink({
                            url: existingLink,
                            text: existingText,
                        });
                    } else {
                        setLink({
                            url: '',
                            text: existingText,
                        });
                    }

                    setEditorRange(range);
                }
            }
        }
    }, [anchorEl, editorRef]);

    useEffect(() => {
        if (editorRef.current) {
            const quill = editorRef.current;

            // eslint-disable-next-line no-inner-declarations
            function handleSelectionChange(range) {
                if (!quill.hasFocus()) return;
                if (!range) return;

                const existingLink = quill.getFormat(range.index, range.length).link;

                if (!existingLink) {
                    dispatch(setBlotLinkAnchorEl(null));
                    dispatch(
                        setBlotDetails({
                            startingIndex: 0,
                            blotElementLength: 0,
                            blotElementText: '',
                            blotElementLink: '',
                        })
                    );
                    return;
                }

                const linkElement = document.querySelector(`a[href="${existingLink}"]`);

                if (!linkElement) return;

                const blotElement = quill.getLeaf(range.index)[0];
                const startingIndex = quill.getIndex(blotElement);

                const blotElementLength = blotElement.domNode.length;
                const blotElementText = blotElement.domNode.textContent;

                dispatch(setBlotLinkAnchorEl(linkElement));
                dispatch(
                    setBlotDetails({
                        startingIndex,
                        blotElementLength,
                        blotElementText,
                        blotElementLink: existingLink,
                    })
                );
            }

            quill.on('selection-change', handleSelectionChange);

            return () => {
                quill.off('selection-change', handleSelectionChange);
            };
        }
    }, [editorRef]);

    return (
        <>
            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <div>
                    <button
                        aria-describedby={id}
                        className="!h-7 !w-7 !rounded bg-white text-[#1E293B] grid place-items-center transition-all hover:brightness-95"
                        onClick={handleClick}
                    >
                        <Link className="!h-5 !w-5" />
                    </button>

                    <Popper
                        id={id}
                        anchorEl={anchorEl}
                        placement="bottom"
                        open={open}
                        sx={{
                            zIndex: 1200,
                            borderRadius: '4px',
                            boxShadow: '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                            backgroundColor: '#FFFFFF',
                        }}
                    >
                        <div className="flex w-fit flex-col !gap-2 !p-2">
                            <input
                                type="text"
                                placeholder="Enter Name"
                                className="!w-full !h-9 !p-2 !border !border-[#E2E8F0] text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                value={link.text}
                                onChange={(event) =>
                                    setLink((prev) => ({
                                        ...prev,
                                        text: event.target.value,
                                    }))
                                }
                            />

                            <input
                                type="url"
                                placeholder="Enter URL"
                                className="!w-full !h-9 !p-2 !border !border-[#E2E8F0] text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                value={link.url}
                                onChange={(event) =>
                                    setLink((prev) => ({
                                        ...prev,
                                        url: event.target.value,
                                    }))
                                }
                            />

                            <div className="!mt-1 flex items-center justify-between !gap-4">
                                <button
                                    className="flex-1 bg-[#fff] !border !border-[#E2E8F0] !rounded !px-2 !py-2 hover:bg-red-500 hover:text-white transition-colors text-[#334155] text-sm font-medium"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>

                                <button
                                    className="flex-1 bg-[#202D66] !rounded !px-2 !py-2 hover:bg-[#151e44] transition-all text-white text-sm font-semibold disabled:opacity-50 disabled:pointer-events-none"
                                    onClick={handleLink}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </Popper>
                </div>
            </ClickAwayListener>

            <RenderLinkPopover editorRef={editorRef} />
        </>
    );
}

function RenderLinkPopover({ editorRef }) {
    const [isEditingLink, setIsEditingLink] = useState(false);
    const [link, setLink] = useState({
        url: '',
        text: '',
    });

    const blotLinkAnchorEl = useSelector((state) => state.editorReducers.blotLinkAnchorEl);
    const blotDetails = useSelector((state) => state.editorReducers.blotDetails);

    const open = Boolean(blotLinkAnchorEl);

    function handleCopyLink() {
        navigator.clipboard.writeText(blotDetails.blotElementLink);
    }

    function handleEditLink() {
        setIsEditingLink(true);
        setLink({
            url: blotDetails.blotElementLink,
            text: blotDetails.blotElementText,
        });
    }

    function handleSaveLink() {
        if (editorRef.current) {
            const quill = editorRef.current;
            const scrollY = window.scrollY;

            const newEditorIndex = blotDetails.startingIndex + link.text.length;

            quill.deleteText(blotDetails.startingIndex, blotDetails.blotElementLength);
            quill.insertText(blotDetails.startingIndex, link.text, 'link', link.url);
            quill.setSelection(newEditorIndex, 0);

            window.scrollTo(0, scrollY);

            handleReset();
        }
    }

    function handleDeleteLink() {
        if (editorRef.current) {
            const quill = editorRef.current;

            quill.removeFormat(blotDetails.startingIndex, blotDetails.blotElementLength);
            quill.setSelection(blotDetails.startingIndex, 0);
        }
    }

    function handleReset() {
        setIsEditingLink(false);
        setLink({
            url: '',
            text: '',
        });
    }

    return (
        <Popper
            anchorEl={blotLinkAnchorEl}
            placement="bottom"
            open={open}
            sx={{
                zIndex: 1200,
                borderRadius: '4px',
                boxShadow: '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                backgroundColor: '#FFFFFF',
            }}
        >
            <div className="flex w-fit items-center !gap-4 !px-2 !py-1">
                {isEditingLink ? (
                    <div className="flex flex-col !gap-2 !py-1">
                        <input
                            type="text"
                            placeholder="Enter Name"
                            className="!h-8 text-xs !w-full !p-2 !border !border-[#E2E8F0] font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                            value={link.text}
                            onChange={(event) =>
                                setLink((prev) => ({
                                    ...prev,
                                    text: event.target.value,
                                }))
                            }
                        />

                        <input
                            type="url"
                            placeholder="Enter URL"
                            className="!h-8 text-xs !w-full !p-2 !border !border-[#E2E8F0] font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                            value={link.url}
                            onChange={(event) =>
                                setLink((prev) => ({
                                    ...prev,
                                    url: event.target.value,
                                }))
                            }
                        />

                        <div className="!mt-1 flex items-center justify-between !gap-2">
                            <button
                                className="!h-8 flex-1 bg-[#fff] !border !border-[#E2E8F0] !rounded !px-2 !py-2 hover:bg-red-500 hover:text-white transition-colors text-[#334155] text-sm font-medium"
                                onClick={handleReset}
                            >
                                Cancel
                            </button>

                            <button
                                className="!h-8 flex-1 bg-[#202D66] !rounded !px-2 !py-2 hover:bg-[#151e44] transition-all text-white text-sm font-semibold disabled:opacity-50 disabled:pointer-events-none"
                                disabled={!link.text || !link.url}
                                onClick={handleSaveLink}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="group flex items-center !gap-2">
                            <Globe className="!h-3 !w-3 transition-colors group-hover:text-blue-400" />

                            <a
                                href={blotDetails.blotElementLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="cursor-pointer text-xs font-medium text-blue-500 transition-colors duration-200 group-hover:text-blue-400"
                            >
                                {blotDetails.blotElementLink}
                            </a>
                        </div>

                        <div className="flex items-center !gap-3">
                            <button className="!h-3 !w-3 hover:text-yellow-500" onClick={handleCopyLink}>
                                <Copy className="!h-3 !w-3" />
                            </button>

                            <button className="!h-3 !w-3 hover:text-blue-500" onClick={handleEditLink}>
                                <Pencil className="!h-3 !w-3" />
                            </button>

                            <button className="!h-3 !w-3 hover:text-red-500" onClick={handleDeleteLink}>
                                <Unlink className="!h-3 !w-3" />
                            </button>
                        </div>
                    </>
                )}
            </div>
        </Popper>
    );
}
