import { DeleteOutlined, DownloadOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';

const Operations = ({ template, openEditDocumentModal, deleteModal, getDocumentLink }) => {
    const menu = (
        <Menu className="tableaction">
            <Menu.Item key="1" onClick={() => getDocumentLink(template?.id)}>
                <DownloadOutlined /> Download
            </Menu.Item>
            <Menu.Item key="2" onClick={() => openEditDocumentModal(template)}>
                <EditOutlined /> Edit
            </Menu.Item>
            <Menu.Item key="3" style={{ color: 'red' }} onClick={() => deleteModal(template)}>
                <DeleteOutlined /> Delete
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <button className="ant-dropdown-link">
                <MoreOutlined />
            </button>
        </Dropdown>
    );
};

export default Operations;
