export const policyStatementsDummyData = [
    {
        id: 1,
        name: 'The organization must establish policies for reviewing and enforcing firewall and router settings. Regular audits must be conducted to verify compliance with - The organization must establish policies for reviewing and enforcing firewall and router settings. Regular audits must be conducted to verify compliance.',
        source: 'change management template',
        status: 'implemented',
    },
    {
        id: 2,
        name: 'A document management policy must be in place to ensure that network security policies are regularly updated and distributed to rele - A document management policy must be in place to ensure that network security policies are regularly updated and distributed to rele.',
        source: 'change management template',
        status: 'not implemented',
        isNew: true,
    },
    {
        id: 3,
        name: 'A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov - A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov.',
        source: 'change management template',
        status: 'not implemented',
        gaps: true,
    },
    {
        id: 4,
        name: 'A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov - A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov.',
        source: 'change management template',
        status: 'not implemented',
        gaps: true,
    },
    {
        id: 5,
        name: 'A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov - A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov.',
        source: 'change management template',
        status: 'implemented',
    },
    {
        id: 6,
        name: 'A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov - A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov.',
        source: 'change management template',
        status: 'implemented',
    },
    {
        id: 7,
        name: 'The organization must establish policies for reviewing and enforcing firewall and router settings. Regular audits must be conducted to verify compliance with - The organization must establish policies for reviewing and enforcing firewall and router settings. Regular audits must be conducted to verify compliance.',
        source: 'change management template',
        status: 'implemented',
    },
    {
        id: 8,
        name: 'A document management policy must be in place to ensure that network security policies are regularly updated and distributed to rele - A document management policy must be in place to ensure that network security policies are regularly updated and distributed to rele.',
        source: 'change management template',
        status: 'not implemented',
        isNew: true,
    },
    {
        id: 9,
        name: 'A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov - A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov.',
        source: 'change management template',
        status: 'not implemented',
        gaps: true,
    },
    {
        id: 10,
        name: 'A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov - A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov.',
        source: 'change management template',
        status: 'not implemented',
        gaps: true,
    },
    {
        id: 11,
        name: 'A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov - A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov.',
        source: 'change management template',
        status: 'implemented',
    },
    {
        id: 12,
        name: 'A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov - A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov.',
        source: 'change management template',
        status: 'implemented',
    },
    {
        id: 13,
        name: 'The organization must establish policies for reviewing and enforcing firewall and router settings. Regular audits must be conducted to verify compliance with - The organization must establish policies for reviewing and enforcing firewall and router settings. Regular audits must be conducted to verify compliance.',
        source: 'change management template',
        status: 'implemented',
    },
    {
        id: 14,
        name: 'A document management policy must be in place to ensure that network security policies are regularly updated and distributed to rele - A document management policy must be in place to ensure that network security policies are regularly updated and distributed to rele.',
        source: 'change management template',
        status: 'not implemented',
        isNew: true,
    },
    {
        id: 15,
        name: 'A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov - A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov.',
        source: 'change management template',
        status: 'not implemented',
        gaps: true,
    },
    {
        id: 16,
        name: 'A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov - A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov.',
        source: 'change management template',
        status: 'not implemented',
        gaps: true,
    },
    {
        id: 17,
        name: 'A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov - A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov.',
        source: 'change management template',
        status: 'implemented',
    },
    {
        id: 18,
        name: 'A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov - A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and prov.',
        source: 'change management template',
        status: 'implemented',
    },
];

export const policyStatementsTabs = ['all', 'implemented', 'gaps'];

export const policyStatementsTemplates = [
    {
        id: 1,
        name: 'Change management policy',
        implemented: 0,
        total: 12,
        status: 'not started',
    },
    {
        id: 2,
        name: 'Change management policy',
        implemented: 0,
        total: 12,
        status: 'draft',
    },
    {
        id: 3,
        name: 'Change management policy',
        implemented: 0,
        total: 12,
        status: 'awaiting approval',
    },
    {
        id: 4,
        name: 'Secure coding policy',
        implemented: 12,
        total: 12,
        status: 'awaiting review',
    },
    {
        id: 5,
        name: 'Adherance policy',
        implemented: 7,
        total: 7,
        status: 'approved',
    },
    {
        id: 6,
        name: 'Change management policy',
        implemented: 0,
        total: 12,
        status: 'not started',
    },
    {
        id: 7,
        name: 'Change management policy',
        implemented: 0,
        total: 12,
        status: 'draft',
    },
    {
        id: 8,
        name: 'Change management policy',
        implemented: 0,
        total: 12,
        status: 'awaiting approval',
    },
    {
        id: 9,
        name: 'Secure coding policy',
        implemented: 12,
        total: 12,
        status: 'awaiting review',
    },
    {
        id: 10,
        name: 'Adherance policy',
        implemented: 7,
        total: 7,
        status: 'approved',
    },
];

export const policyStatementsOptions = [
    'change management policy',
    'secure coding policy',
    'adherance policy',
    'change management policy 2',
    'secure coding policy 2',
    'adherance policy 2',
    'change management policy 3',
    'secure coding policy 3',
    'adherance policy 3',
    'change management policy 4',
    'secure coding policy 4',
    'adherance policy 5',
    'change management policy 6',
    'secure coding policy 6',
    'adherance policy 6',
];

export const userPolicyStatements = [
    {
        id: 1,
        name: 'all policies',
        count: 12,
    },
    {
        id: 2,
        name: 'change management policy',
        count: 12,
    },
    {
        id: 3,
        name: 'secure coding policy',
        count: 12,
    },
    {
        id: 4,
        name: 'adherance policy',
        count: 0,
    },
    {
        id: 5,
        name: 'all policies',
        count: 12,
    },
    {
        id: 6,
        name: 'change management policy',
        count: 12,
    },
    {
        id: 7,
        name: 'secure coding policy',
        count: 12,
    },
    {
        id: 8,
        name: 'adherance policy',
        count: 0,
    },
    {
        id: 9,
        name: 'all policies',
        count: 12,
    },
    {
        id: 10,
        name: 'change management policy',
        count: 12,
    },
    {
        id: 11,
        name: 'secure coding policy',
        count: 12,
    },
    {
        id: 12,
        name: 'adherance policy',
        count: 0,
    },
];

export const existingPolicyStatements = [
    {
        id: 1,
        name: 'my_change management file.docx',
        total: 10,
        status: 'awaiting approval',
    },
    {
        id: 2,
        name: 'security_policy issues.docx',
        total: 10,
        status: 'awaiting review',
    },
    {
        id: 3,
        name: 'zaddy management file.docx',
        total: 10,
        status: 'approved',
    },
];

export const policyHistoryData = [
    {
        version: '4.0',
        date: '2024-01-22',
        changeNumber: 203646473,
    },
    {
        version: '3.0',
        date: '2023-01-22',
        changeNumber: 203646573,
    },
    {
        version: '2.0',
        date: '2022-01-22',
        changeNumber: 203646273,
    },
    {
        version: '1.0',
        date: '2021-01-22',
        changeNumber: 203646173,
    },
];

export const acceptanceHistoryTabs = ['accepted', 'not accepted'];

export const departmentsDummyData = [
    {
        id: 1,
        name: 'IT',
        date: '2024-01-22',
        people: ['John Doe', 'Sarah Doe', 'Jane Doe', 'Doe Doe', 'Jill Doe'],
        status: 'accepted',
    },
    {
        id: 2,
        name: 'HR',
        date: '2024-01-12',
        people: ['John Doe', 'Sarah Doe', 'Jane Doe', 'Doe Doe', 'Jill Doe'],
        status: 'not accepted',
    },
    {
        id: 3,
        name: 'Finance',
        date: '2024-01-02',
        people: ['John Doe', 'Sarah Doe', 'Jane Doe', 'Doe Doe', 'Jill Doe'],
        status: 'accepted',
    },
    {
        id: 4,
        name: 'Marketing',
        date: '2023-2-22',
        people: ['John Doe', 'Sarah Doe', 'Jane Doe', 'Doe Doe', 'Jill Doe'],
        status: 'not accepted',
    },
    {
        id: 5,
        name: 'Legal',
        date: '2023-01-22',
        people: ['John Doe', 'Sarah Doe', 'Jane Doe', 'Doe Doe', 'Jill Doe'],
        status: 'accepted',
    },
];

export const policyChangesOptions = [
    {
        id: 1,
        changeType: 'minor',
        description:
            'Use this option only if you have made minor changes like grammatical error and typos which doesn’t affect the policy.',
    },
    {
        id: 2,
        changeType: 'major',
        description:
            'Use this option only if you have made substantial changes that may constitute a new addition to the existing policy.',
    },
];

export const userPolicyStatementsDummyData = [
    {
        id: 1,
        name: 'Security and incident reporting',
        status: 'not implemented',
        description:
            'A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and provide formal acknowledgment of their responsibilities.',
        controls: [
            {
                id: 1,
                name: 'Technical control',
                description:
                    'Use document management tools (e.g., SharePoint, Confluence) to maintain and track updates to network security policies. Implement acknowledgment logs and training tracking systems for compliance purposes.',
            },
        ],
        gaps: true,
    },
    {
        id: 2,
        name: 'Whistleblower anonymous fraud reporting',
        status: 'implemented',
        description:
            'A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and provide formal acknowledgment of their responsibilities.',
        controls: [
            {
                id: 1,
                name: 'Technical control',
                description:
                    'Use document management tools (e.g., SharePoint, Confluence) to maintain and track updates to network security policies. Implement acknowledgment logs and training tracking systems for compliance purposes.',
            },
        ],
        gaps: false,
    },
    {
        id: 3,
        name: 'Whistleblower anonymous fraud reporting',
        status: 'not implemented',
        description:
            'A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and provide formal acknowledgment of their responsibilities.',
        controls: [
            {
                id: 1,
                name: 'Technical control',
                description:
                    'Use document management tools (e.g., SharePoint, Confluence) to maintain and track updates to network security policies. Implement acknowledgment logs and training tracking systems for compliance purposes.',
            },
        ],
        gaps: true,
    },
    {
        id: 4,
        name: 'Mobile device policy',
        status: 'not implemented',
        description:
            'A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and provide formal acknowledgment of their responsibilities.',
        controls: [
            {
                id: 1,
                name: 'Technical control',
                description:
                    'Use document management tools (e.g., SharePoint, Confluence) to maintain and track updates to network security policies. Implement acknowledgment logs and training tracking systems for compliance purposes.',
            },
        ],
        gaps: false,
    },
    {
        id: 5,
        name: 'Mobile device policy',
        status: 'implemented',
        description:
            'A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and provide formal acknowledgment of their responsibilities.',
        controls: [
            {
                id: 1,
                name: 'Technical control',
                description:
                    'Use document management tools (e.g., SharePoint, Confluence) to maintain and track updates to network security policies. Implement acknowledgment logs and training tracking systems for compliance purposes.',
            },
        ],
        gaps: true,
    },
    {
        id: 6,
        name: 'Security and incident reporting',
        status: 'not implemented',
        description:
            'A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and provide formal acknowledgment of their responsibilities.',
        controls: [
            {
                id: 1,
                name: 'Technical control',
                description:
                    'Use document management tools (e.g., SharePoint, Confluence) to maintain and track updates to network security policies. Implement acknowledgment logs and training tracking systems for compliance purposes.',
            },
        ],
        gaps: true,
    },
    {
        id: 7,
        name: 'Whistleblower anonymous fraud reporting',
        status: 'implemented',
        description:
            'A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and provide formal acknowledgment of their responsibilities.',
        controls: [
            {
                id: 1,
                name: 'Technical control',
                description:
                    'Use document management tools (e.g., SharePoint, Confluence) to maintain and track updates to network security policies. Implement acknowledgment logs and training tracking systems for compliance purposes.',
            },
        ],
        gaps: false,
    },
    {
        id: 8,
        name: 'Whistleblower anonymous fraud reporting',
        status: 'not implemented',
        description:
            'A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and provide formal acknowledgment of their responsibilities.',
        controls: [
            {
                id: 1,
                name: 'Technical control',
                description:
                    'Use document management tools (e.g., SharePoint, Confluence) to maintain and track updates to network security policies. Implement acknowledgment logs and training tracking systems for compliance purposes.',
            },
        ],
        gaps: true,
    },
    {
        id: 9,
        name: 'Mobile device policy',
        status: 'not implemented',
        description:
            'A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and provide formal acknowledgment of their responsibilities.',
        controls: [
            {
                id: 1,
                name: 'Technical control',
                description:
                    'Use document management tools (e.g., SharePoint, Confluence) to maintain and track updates to network security policies. Implement acknowledgment logs and training tracking systems for compliance purposes.',
            },
        ],
        gaps: false,
    },
    {
        id: 10,
        name: 'Mobile device policy',
        status: 'implemented',
        description:
            'A formal RACI matrix must be maintained to define roles and responsibilities for network security controls. All personnel must be trained on their roles and provide formal acknowledgment of their responsibilities.',
        controls: [
            {
                id: 1,
                name: 'Technical control',
                description:
                    'Use document management tools (e.g., SharePoint, Confluence) to maintain and track updates to network security policies. Implement acknowledgment logs and training tracking systems for compliance purposes.',
            },
        ],
        gaps: true,
    },
];

export const policyEditHistoryDummyData = [
    {
        id: 1,
        date: '2024-01-22',
        edittedBy: 'John Doe',
        editCount: 9,
    },
    {
        id: 2,
        date: '2023-01-22',
        edittedBy: 'John Doe',
        editCount: 7,
    },
    {
        id: 3,
        date: '2022-01-22',
        edittedBy: 'John Doe',
        editCount: 6,
    },
    {
        id: 4,
        date: '2021-01-22',
        edittedBy: 'John Doe',
        editCount: 5,
    },
];

export const useUploadedFileTabs = ['new upload', 'my files'];
