import { Box } from '@mui/material';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';

// core components
import PageHeader from 'components/new_components/PageHeader';
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import CategorySelect from '../common/CategorySelect';
import ComplianceLayout from '../common/ComplianceLayout';
// import AuditQuestions from './AuditQuestions';
import Documents from './Documents';
// import Exceptions from './Exceptions';
// import ComplianceAITipsModal from '../common/ComplianceAITipsModal';
// import AppTableActionMenu from 'components/new_components/app-table/AppTableActionMenu';
// import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';

// redux
import { connect, useDispatch, useSelector } from 'react-redux';
import {
    // GetAllDocumentsByTags,
    // GetAllFormsByTags,
    GetAllIsogroupsTwo,
} from 'store/actions/adminActions';
import {
    // GetAllMerchantAnswers,
    // GetAllMerchantDocuments,
    GetIsogroupTwoMetrics,
} from 'store/actions/complianceActions';
import {
    CreateModuleGuide,
    GetModuleGuides,
    GetUserDetails,
    UpdateModuleGuide,
    UpdateUserDetails,
} from 'store/actions/generalActions';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';
import AllTaskModal from '../common/AllTaskModal';
// import AssignTaskModal from '../common/AssignTaskModal';
import TaskIntroductionModal from '../common/TaskIntroductionModal';

//userguide
import { Tour } from 'antd';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideHeader } from 'components/new_components/AppUserGuide';
import { GetAllExemptedControls } from 'store/actions/complianceActions';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import AssignToDialog from '../common/document_review/components/AssignToDialog';
import ExemptRequirementDialog from '../common/document_review/components/ExemptRequirementDialog';
import RevertRequirementDialog from '../common/document_review/components/RevertRequirementDialog';

const ISO27001 = (props) => {
    const {
        GetAllIsogroupsTwo,
        isogroup_fetched,
        isogroup_metrics_fetched,
        GetIsogroupTwoMetrics,
        // GetAllMerchantDocuments,
        // GetAllMerchantAnswers,
        GetUserDetails,
        // UpdateUserDetails,
        GetModuleGuides,
        UpdateModuleGuide,
        CreateModuleGuide,
        // GetAllDocumentsByTags,
        // GetAllFormsByTags,
        guideModal,
        userguide_info,
        all_article_header,
    } = props;

    const { activeMerchant } = useContext(SelectedMerchantContext);
    const user_type = useSelector((state) => state?.authReducers?.user_type);

    // state
    const [currentTab, setCurrentTab] = useState(0);
    const [category, setCategory] = useState('clause');
    const [loading, setLoading] = useState({ content: false, exceptions: false, metrics: false });
    // const [view, setView] = useState(0);
    // const [selectedIsogroup, setSelectedIsogroup] = useState(null);
    const [introModalOpen, setIntroModalOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [tasksModalOpen, setTaskModalOpen] = useState(false);
    const [exmeptModalOpen, setExemptModalOpen] = useState(false);
    const [revertModalOpen, setRevertModalOpen] = useState(false);
    // const [modalType, setModalType] = useState(null);
    const [taskInfo, setTaskInfo] = useState({});
    // const [openAiModal, setOpenAiModal] = useState(false);
    // const [anchor, setAnchor] = useState(null);
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    //userguide
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);
    const ref1 = useRef(null);
    // const ref2 = useRef(null);
    const ref3 = useRef(null);

    const dispatch = useDispatch();
    const STEPS_LENGTH = 2;

    const steps = [
        {
            title: <UserGuideHeader title="Documents" id={1} length={STEPS_LENGTH} />,
            description: 'Here you can Upload compliance policies.',
            target: () => ref1.current,
            placement: 'rightTop',
        },
        // {
        //     title: <UserGuideHeader title="Audit Questions" id={2} length={STEPS_LENGTH} />,
        //     description: 'Respond to audit-related queries here.',
        //     target: () => ref2.current,
        //     placement: 'rightTop',
        // },
        {
            title: <UserGuideHeader title="Exceptions" id={3} length={STEPS_LENGTH} />,
            description: 'This tab displays requirements that are exempted',
            target: () => ref3.current,
            placement: 'rightTop',
        },
    ];

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Compliance'),
        [all_article_header]
    );

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ compliance: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, compliance: false });
        setOpenArticle(false);
    };

    // const theme = useTheme();
    // const openMenu = (e) => {
    //     e.stopPropagation();
    //     setAnchor(e.target);
    // };
    // const closeMenu = () => setAnchor(null);

    // functions
    // logic functions
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
        // goBack();
    };
    const handleCategoryChange = (category) => {
        setCategory(category);
    };
    // handle the view on the standard
    // const handleViewChange = (isogroupId) => {
    //     setView(1);
    //     setSelectedIsogroup(isogroupId);
    // };
    // const goBack = () => {
    //     setView(0);
    //     setSelectedIsogroup(null);
    // };

    // const handleOpenAiModal = () => {
    //     setOpenAiModal(true);
    // };
    // const handleCloseAiModal = () => {
    //     setOpenAiModal(false);
    // };

    //task introduction/explanation modal
    const closeIntroModal = () => {
        setIntroModalOpen(false);
    };

    const openTaskAssignModal = (type, info) => {
        setModalOpen(true);
        // setModalType(type);
        setTaskInfo(info);
    };
    const closeTaskAssignModal = () => {
        setModalOpen(false);
        // setTimeout(() => setModalType(null), 500);
        setTaskInfo({});
    };

    const openExemptModal = (type, info) => {
        setExemptModalOpen(true);
        setTaskInfo(info);
    };
    const closeExemptModal = () => {
        setExemptModalOpen(false);
        setTaskInfo({});
    };

    const openRevertModal = (type, info) => {
        setRevertModalOpen(true);
        setTaskInfo(info);
    };
    const closeRevertModal = () => {
        setRevertModalOpen(false);
        setTaskInfo({});
    };

    const openTasksModal = () => {
        setTaskModalOpen(true);
    };
    const closeTasksModal = () => {
        setTaskModalOpen(false);
    };

    // async functions
    const getAllExemptedControls = async () => {
        setLoading((current) => ({
            ...current,
            exceptions: true,
        }));
        const res = await dispatch(GetAllExemptedControls());
        setLoading((current) => ({ ...current, exceptions: false }));
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch exempted controls.");
        }
    };
    const getAllIsogroups = async (category, shouldLoad) => {
        setLoading((curr) => ({
            ...curr,
            content: shouldLoad || !isogroup_fetched,
        }));
        const res = await GetAllIsogroupsTwo(category);
        setLoading((curr) => ({ ...curr, content: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log('Error:::', res?.message);
        }
    };
    const getAllIsogroupsMetric = async () => {
        setLoading((curr) => ({ ...curr, metrics: !isogroup_metrics_fetched }));
        const res = await GetIsogroupTwoMetrics(activeMerchant);
        setLoading((curr) => ({ ...curr, metrics: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };
    // const getAllMerchantDocuments = async () => {
    //     const res = await GetAllMerchantDocuments(activeMerchant);
    //     if (!res.success) {
    //         toast.error("Something went wrong! Couldn't fetch your documents.");
    //         console.log(res.message);
    //     }
    // };
    // const getMerchantAnswers = async () => {
    //     const res = await GetAllMerchantAnswers(activeMerchant);
    //     if (!res?.success) {
    //         toast.error("Something went wrong! Couldn't fetch previous answers.");
    //         console.log('Error: ', res);
    //     }
    // };

    const getUserDetails = async () => {
        const res = await GetUserDetails();
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch user details");
        }
    };
    const getModuleGuides = async () => {
        const res = await GetModuleGuides();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    // const getAllDocumentsByTags = async () => {
    //     const res = await GetAllDocumentsByTags('isms');
    //     if (!res?.success) {
    //         toast.error(res?.message);
    //     }
    // };
    // const getAllFormsByTags = async () => {
    //     const res = await GetAllFormsByTags('isms');
    //     if (!res?.success) {
    //         toast.error(res?.message);
    //     }
    // };

    const updateModuleIntroModal = async () => {
        const res =
            guideModal && guideModal[0]
                ? await UpdateModuleGuide({ compliance_task: true }, guideModal[0]?.id)
                : await CreateModuleGuide({ compliance_task: true });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        if (category) {
            getAllIsogroups(category, true);
        }
    }, [category]);
    useEffect(() => {
        getAllExemptedControls();
        getAllIsogroupsMetric();
        // getAllMerchantDocuments();
        // getMerchantAnswers();
    }, [activeMerchant]);

    useEffect(() => {
        if (user_type !== 'auditor') {
            getUserDetails();
            getModuleGuides();
            // getAllDocumentsByTags();
            // getAllFormsByTags();
        }
    }, [user_type]);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.compliance ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.compliance]);

    // useEffect(() => {
    //     handleOpenAiModal();
    // }, []);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.compliance ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.compliance]);

    return (
        <>
            <PageHeader browserTitle="ISO27001 (2022) -  Compliance | Smartcomply" />
            <Box sx={{ px: { xs: 0, md: 0 }, py: { xs: 0, md: 0.3 } }}>
                {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 3 }}>
                    <Box></Box>
                    <IconButton sx={{ color: theme.palette.gray[900], width: '30px' }} onClick={openMenu}>
                        <MoreHorizOutlinedIcon />
                    </IconButton>
                </Box> */}
                <ComplianceLayout
                    onTabChange={handleTabChange}
                    tab={currentTab}
                    openTasksModal={openTasksModal}
                    ref1={ref1}
                    // ref2={ref2}
                    ref3={ref3}
                    standard="iso27001-2022"
                >
                    {/* {view === 0 && <CategorySelect onCategoryChange={handleCategoryChange} category={category} />} */}
                    <CategorySelect onCategoryChange={handleCategoryChange} category={category} />
                    <AppTabPanel value={currentTab} index={0}>
                        <Documents
                            loading={loading?.content || loading?.metrics || loading?.exceptions}
                            // getIsogroups={getAllIsogroups}
                            // category={category}
                            // view={view}
                            // handleViewChange={handleViewChange}
                            // goBack={goBack}
                            // selectedIsogroup={selectedIsogroup}
                            openTaskAssignModal={openTaskAssignModal}
                            openExemptModal={openExemptModal}
                        />
                    </AppTabPanel>
                    {/* <AppTabPanel value={currentTab} index={1}>
                        <AuditQuestions
                            loading={loading?.content}
                            getIsogroups={getAllIsogroups}
                            category={category}
                            view={view}
                            handleViewChange={handleViewChange}
                            goBack={goBack}
                            selectedIsogroup={selectedIsogroup}
                            openTaskAssignModal={openTaskAssignModal}
                        />
                    </AppTabPanel> */}
                    <AppTabPanel value={currentTab} index={2}>
                        {/* <Exceptions getIsogroups={getAllIsogroups} category={category} loading={loading?.content} /> */}
                        <Documents
                            loading={loading?.content || loading?.metrics || loading?.exceptions}
                            // getIsogroups={getAllIsogroups}
                            // category={category}
                            // view={view}
                            // handleViewChange={handleViewChange}
                            // goBack={goBack}
                            // selectedIsogroup={selectedIsogroup}
                            isExceptionTab={true}
                            openRevertModal={openRevertModal}
                        />
                    </AppTabPanel>
                </ComplianceLayout>
                {/* <ComplianceAITipsModal open={openAiModal} handleClose={handleCloseAiModal} width="700px" minWidth={900} /> */}
            </Box>

            <TaskIntroductionModal
                open={introModalOpen}
                handleClose={closeIntroModal}
                updateModuleIntroModal={updateModuleIntroModal}
            />
            {/* <AppTableActionMenu
                anchor={anchor}
                closeMenu={closeMenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    sx={{
                        fontSize: 13,
                        fontWeight: 500,
                        color: theme.palette.gray[700],
                    }}
                >
                    Generate Report
                </MenuItem>
            </AppTableActionMenu> */}
            {/* <AssignTaskModal open={modalOpen} onClose={closeTaskAssignModal} type={modalType} taskInfo={taskInfo} /> */}
            <AssignToDialog isOpen={modalOpen} handleCallback={closeTaskAssignModal} requirementData={taskInfo} />
            <ExemptRequirementDialog
                title="Control"
                subTitle="controls"
                isOpen={exmeptModalOpen}
                handleCallback={closeExemptModal}
                requirementData={taskInfo}
            />
            <RevertRequirementDialog
                title="Control"
                isOpen={revertModalOpen}
                handleCallback={closeRevertModal}
                requirementData={taskInfo}
            />
            <AllTaskModal open={tasksModalOpen} handleClose={closeTasksModal} standard="isms" />

            {!loading.content && <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} />}
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Compliance"
                headerId={oneArticle?.id}
                icon={true}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        isogroup_fetched: state?.adminReducers?.all_isogroupstwo?.length,
        isogroup_metrics_fetched: state?.complianceReducers?.all_isogrouptwo_metrics?.length,
        guideModal: state?.generalReducers?.module,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.compliance,
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};
export default connect(mapStateToProps, {
    GetAllIsogroupsTwo,
    GetIsogroupTwoMetrics,
    // GetAllMerchantDocuments,
    // GetAllMerchantAnswers,
    GetUserDetails,
    UpdateUserDetails,
    GetModuleGuides,
    UpdateModuleGuide,
    CreateModuleGuide,
    // GetAllDocumentsByTags,
    // GetAllFormsByTags,
})(ISO27001);
