import { Route, Switch } from 'react-router-dom';
import IsoDashboard from './IsoDashboard';
// import FormsAndDocumentsView from './Annex/FormsAndDocumentsView';
// import IsoFormCreation from '../Operations/FormCreation';
import AnnexesView from './Annex/AnnexesView';
import ManagementClausesView from './ManagementClause/ManagementClausesView';
// import ClauseFormsAndDocumentsView from './ManagementClause/ClauseFormsAndDocumentView';
import CybersecurityView from './Cybersecurity/CybersecurityView';
// import CybersecurityFormsAndDocumentView from './Cybersecurity/CybersecurityFormsAndDocumentView';
import ProjectManagementView from './Project Management/ProjectManagementView';
// import ProjectManagementFormsAndDocumentsView from './Project Management/ProjectManagementFormsAndDocumentView';
import AnnexPage from './Annex/AnnexPage';
import CloudSecurityPage from './CloudSecurity/CloudSecurityPage';
import CloudSecurityView from './CloudSecurity/CloudSecurityView';
import CyberSecurityPage from './Cybersecurity/CyberSecurityPage';
import ClausePage from './ManagementClause/ClausePage';
import ProjectManagementPage from './Project Management/ProjectManagementPage';
// import CloudSecurityFormsAndDocumentsView from './CloudSecurity/CloudSecurityFormsAndDocumentView';

const ISO27017_27032 = () => {
    return (
        <Switch>
            <Route path="/admin/iso27017+27032" exact component={IsoDashboard} />
            <Route path="/admin/iso27017+27032/annexes" exact component={AnnexesView} />
            <Route path="/admin/iso27017+27032/annexes/annex" exact component={AnnexPage} />
            <Route path="/admin/iso27017+27032/clauses" exact component={ManagementClausesView} />
            <Route path="/admin/iso27017+27032/clauses/clause" exact component={ClausePage} />
            <Route path="/admin/iso27017+27032/cybersecurities" exact component={CybersecurityView} />
            <Route path="/admin/iso27017+27032/cybersecurities/cyber_security" exact component={CyberSecurityPage} />
            <Route path="/admin/iso27017+27032/project_managements" exact component={ProjectManagementView} />
            <Route
                path="/admin/iso27017+27032/project_managements/project_management"
                exact
                component={ProjectManagementPage}
            />
            <Route path="/admin/iso27017+27032/cloud_securities" exact component={CloudSecurityView} />
            <Route path="/admin/iso27017+27032/cloud_securities/cloud_security" exact component={CloudSecurityPage} />
            {/* <Route path="/admin/iso27017+27032/clause" exact component={ClauseFormsAndDocumentsView} />
            <Route path="/admin/iso27017+27032/annex" exact component={FormsAndDocumentsView} />
            <Route path="/admin/iso27017+27032/cybersecurity" exact component={CybersecurityFormsAndDocumentView} />
            <Route
                path="/admin/iso27017+27032/project_management"
                exact
                component={ProjectManagementFormsAndDocumentsView}
            />
            <Route path="/admin/iso27017+27032/cloud_security" exact component={CloudSecurityFormsAndDocumentsView} />
            <Route
                path="/admin/iso27017+27032/form"
                component={(props) => <IsoFormCreation tag="tech_sec" {...props} />}
            /> */}
        </Switch>
    );
};

export default ISO27017_27032;
