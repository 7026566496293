import { useMemo } from 'react';
//components
import { Grid } from '@mui/material';
import EmptyState from 'components/new_components/EmptyState';
import CategoryCard from '../../common/CategoryCard';

//redux
import { connect, useSelector } from 'react-redux';

//translations
import LoadingState from 'components/new_components/LoadingState';
import { useTranslation } from 'react-i18next';

const ContinuityGroups = (props) => {
    const {
        loading,
        // resource,
        // onViewChange,
        continuity_groups,
        all_continuitygroups_metrics,
        all_compliance_assigned_tasks,
        openTaskAssignModal,
        openExemptModal,
        openRevertModal,
        // all_iso22301_documents,
        // all_iso22301_forms,
        isExceptionTab,
    } = props;

    //translation
    const { t } = useTranslation('compliance');

    const all_exempted_controls = useSelector((state) => state?.complianceReducers?.all_exempted_controls);

    const exmeptedGroupsId = useMemo(
        () =>
            all_exempted_controls?.filter((item) => item?.standard === 'is022301')?.map((item) => item?.continuity_iso),
        [all_exempted_controls]
    );

    const filteredGroups = useMemo(() => {
        if (isExceptionTab) {
            return continuity_groups?.filter((item) => exmeptedGroupsId?.includes(item?.id));
        } else {
            return continuity_groups?.filter((item) => !exmeptedGroupsId?.includes(item?.id));
        }
    }, [continuity_groups, exmeptedGroupsId, isExceptionTab]);

    // const documentsByCategory = useMemo(() => {
    //     return all_iso22301_documents
    //         ?.filter((document) => {
    //             const iso22301Documents = all_compliance_assigned_tasks?.documents?.forms?.is022301 || [];

    //             const res = iso22301Documents?.length ? iso22301Documents.filter((item) => document?.id === item) : [];

    //             return res?.length ? document : null;
    //         })
    //         .map((document) => document?.continuity_iso);
    // }, [all_iso22301_documents, all_compliance_assigned_tasks?.documents?.forms?.is022301]);

    // const formsByCategory = useMemo(() => {
    //     return all_iso22301_forms
    //         ?.filter((form) => {
    //             const iso22301Forms = all_compliance_assigned_tasks?.questions?.forms?.is022301 || [];

    //             const res = iso22301Forms?.length ? iso22301Forms.filter((item) => form?.id === item) : [];

    //             return res?.length ? form : null;
    //         })
    //         .map((form) => form?.continuity_iso);
    // }, [all_iso22301_forms, all_compliance_assigned_tasks?.documents?.forms?.is022301]);

    return !loading ? (
        <Grid container spacing={2.5} sx={{ p: 4 }}>
            {filteredGroups && filteredGroups?.length ? (
                filteredGroups
                    ?.sort((a, b) => a?.id - b?.id)
                    ?.map((continuitygroup) => {
                        {
                            /* get and descructure the categories metrics */
                        }
                        // const { total_form, total_doc, user_form, user_doc } =
                        //   all_isogroup_metrics?.[isogroup?.title];

                        {
                            /* format metric and label based on resource type */
                        }
                        const metric =
                            // resource === 'doc'
                            // ? {
                            //       totalValue: all_continuitygroups_metrics?.[continuitygroup?.title]?.total_doc,
                            //       totalAttendedTo: all_continuitygroups_metrics?.[continuitygroup?.title]?.user_doc,
                            //   }
                            {
                                totalValue:
                                    all_continuitygroups_metrics?.[continuitygroup?.title]?.doc_review_stats?.split(
                                        '/'
                                    )[1],
                                totalAttendedTo:
                                    all_continuitygroups_metrics?.[continuitygroup?.title]?.doc_review_stats?.split(
                                        '/'
                                    )[0],
                            };
                        // : {
                        //       totalValue: all_continuitygroups_metrics?.[continuitygroup?.title]?.total_form,
                        //       totalAttendedTo:
                        //           all_continuitygroups_metrics?.[continuitygroup?.title]?.user_form,
                        //   };
                        // const resourceLabel = resource === 'doc' ? t('fileUploaded') : t('questionAnswered');
                        const isCategoryAssigned =
                            // resource === 'doc'
                            // ? all_compliance_assigned_tasks?.documents?.category?.is022301?.includes(
                            //       continuitygroup?.id
                            //   )
                            all_compliance_assigned_tasks?.documents?.category?.is022301?.includes(continuitygroup?.id);
                        // : all_compliance_assigned_tasks?.questions?.category?.is022301?.includes(
                        //       continuitygroup?.id
                        //   );
                        const exmeptedGroup = all_exempted_controls
                            ?.filter((item) => item?.standard === 'is022301')
                            ?.find((item) => item?.continuity_iso === continuitygroup?.id);
                        return (
                            <CategoryCard
                                key={continuitygroup?.id}
                                id={continuitygroup?.id}
                                title={continuitygroup?.title}
                                metric={metric}
                                // onViewChange={onViewChange}
                                // resourceLabel={resourceLabel}
                                standard="is022301"
                                openTaskAssignModal={() =>
                                    openTaskAssignModal('clause', {
                                        continuity_iso: continuitygroup?.id,
                                        // is_document: resourceLabel !== 'Questions Answered',
                                        is_document: true,
                                        standard: 'is022301',
                                    })
                                }
                                openExemptModal={() =>
                                    openExemptModal('clause', {
                                        continuity_iso: continuitygroup?.id,
                                        standard: 'is022301',
                                    })
                                }
                                openRevertModal={() =>
                                    openRevertModal('clause', {
                                        id: exmeptedGroup?.id,
                                    })
                                }
                                isCategoryAssigned={isCategoryAssigned}
                                // resourceAssigned={resource === 'doc' ? documentsByCategory : formsByCategory}
                                isExceptionTab={isExceptionTab}
                            />
                        );
                    })
            ) : (
                <EmptyState description={isExceptionTab ? t('noException') : t('noISO')} />
            )}
        </Grid>
    ) : (
        <LoadingState />
    );
};

const mapStateToProps = (state) => {
    return {
        continuity_groups: state?.adminReducers?.all_continuity_clauses,
        all_continuitygroups_metrics: state?.complianceReducers?.all_continuitygroups_metrics,
        // all_iso22301_forms: state?.adminReducers?.all_forms_by_tag,
        // all_iso22301_documents: state?.adminReducers?.all_documents_by_tags,
    };
};
export default connect(mapStateToProps, {})(ContinuityGroups);
