import { List } from 'lucide-react';
import { useEffect, useState } from 'react';

export default function UnorderedListTool({ editorRef }) {
    const [isUnorderedList, setIsUnorderedList] = useState(false);

    function handleUnorderedList() {
        if (editorRef.current) {
            const quill = editorRef.current;
            const selection = quill.getSelection();
            const format = quill.getFormat();

            if (!selection) return;

            const blot = quill.getLeaf(selection.index)[0];

            if (blot && blot?.parent?.domNode?.closest('table')) {
                return;
            }

            if (format['list']) {
                quill.format('list', false);
                setIsUnorderedList(false);
            } else {
                quill.format('list', 'bullet');
                setIsUnorderedList(true);
            }

            quill.focus();
        }
    }

    useEffect(() => {
        if (editorRef.current) {
            const quill = editorRef.current;

            // eslint-disable-next-line no-inner-declarations
            function handleEditorChange() {
                if (!quill.hasFocus()) return;
                const format = quill.getFormat();

                if (format['list'] === 'bullet') {
                    setIsUnorderedList(true);
                } else {
                    setIsUnorderedList(false);
                }
            }

            quill.on('editor-change', handleEditorChange);

            return () => {
                quill.off('editor-change', handleEditorChange);
            };
        }
    }, [editorRef]);

    return (
        <button
            className={`!h-7 !w-7 !rounded grid place-items-center transition-all hover:brightness-95 ${
                isUnorderedList ? 'text-[#395BA9] bg-[#395BA910]' : 'text-[#1E293B] bg-white'
            }`}
            onClick={handleUnorderedList}
        >
            <List className="!h-5 !w-5" />
        </button>
    );
}
