export const getResourceDetailsByStandard = (resource, standard) => {
    const details = {};
    const admin_resource = resource?.document_type;

    details.title =
        standard === 'pcidss'
            ? admin_resource?.document_title || admin_resource?.title
            : standard === 'ndpr'
            ? admin_resource?.ndpr_title
            : standard === 'soc2'
            ? admin_resource?.soc2_title
            : admin_resource?.iso_title;
    details.description =
        standard === 'pcidss'
            ? admin_resource?.document_description || admin_resource?.description
            : standard === 'ndpr'
            ? admin_resource?.ndpr_document_description
            : standard === 'soc2'
            ? admin_resource?.soc2_description
            : admin_resource?.iso_description;
    details.doc_id = admin_resource?.id;
    details.user_doc_id = resource?.id;
    return details;
};

export const formatVersionDate = (date) => {
    let formatedDate = new Date(date);
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };
    return `${formatedDate.toLocaleDateString('en-US', dateOptions)} (${formatedDate.toLocaleTimeString(
        'en-US',
        timeOptions
    )})`;
};

export const tabs = ['All', 'Undergoing compliance', 'Certified', 'Not subscribed', 'Disabled'];

export const all_standards = [
    'sslc',
    'pcidss',
    'pcidss4.0',
    'ndpr',
    'iso27001',
    'blockchain',
    'soc2',
    'iso22301',
    'iso27001-2022',
    'iso9001',
    'iso37301',
    'iso27017+iso27032',
    'gdpa',
    'kdpa',
    'cii',
];
