import { ClickAwayListener, Popper } from '@mui/material';
import { ChevronDown } from 'lucide-react';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedFont } from 'store/actions/editorActions';
import { fonts } from '../utils/db';

export default function FontTool({ editorRef }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const selectedFont = useSelector((state) => state.editorReducers.selectedFont);

    const dispatch = useDispatch();

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    function handleClick(event) {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }

    function handleFontChange(value) {
        if (editorRef.current) {
            const quill = editorRef.current;
            quill.format('font', value);
            dispatch(setSelectedFont(value));
            setAnchorEl(null);
            quill.focus();
        }
    }

    useEffect(() => {
        if (editorRef.current) {
            const quill = editorRef.current;

            // eslint-disable-next-line no-inner-declarations
            function handleSelectionChange() {
                if (!quill.hasFocus()) return;
                const format = quill.getFormat();

                if (format['font']) {
                    dispatch(setSelectedFont(format['font']));
                } else {
                    dispatch(setSelectedFont('sans-serif'));
                }
            }

            quill.on('selection-change', handleSelectionChange);

            return () => {
                quill.off('selection-change', handleSelectionChange);
            };
        }
    }, [editorRef]);

    return (
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <div>
                <button
                    aria-describedby={id}
                    className="capitalize !py-1 !px-2 !rounded bg-white text-[#395BA9] font-bold text-sm flex items-center !gap-0.5 transition-all hover:brightness-95"
                    onClick={handleClick}
                >
                    {selectedFont} <ChevronDown className="!h-4 !w-4 text-[#1E293B]" />
                </button>

                <Popper
                    id={id}
                    anchorEl={anchorEl}
                    placement="bottom"
                    open={open}
                    sx={{
                        zIndex: 1200,
                        borderRadius: '4px',
                        boxShadow: '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                        backgroundColor: '#FFFFFF',
                    }}
                >
                    <div className="flex w-fit flex-col !gap-0.5 !p-2">
                        {fonts.map((font) => (
                            <Fragment key={font}>
                                <button
                                    className={`!w-full !px-4 !py-2 text-left text-sm font-medium capitalize transition-all hover:brightness-95 ${
                                        selectedFont === font
                                            ? 'text-[#395BA9] bg-[#395BA910]'
                                            : 'text-[#475569] bg-white'
                                    }`}
                                    style={{
                                        fontFamily: font,
                                    }}
                                    onClick={() => handleFontChange(font)}
                                >
                                    {font}
                                </button>

                                {font !== fonts[fonts.length - 1] && <div className="!h-px !w-full bg-[#F1F5F9]" />}
                            </Fragment>
                        ))}
                    </div>
                </Popper>
            </div>
        </ClickAwayListener>
    );
}
