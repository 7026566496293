/* eslint-disable no-unused-vars */

import { Dialog } from '@mui/material';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ExemptControl } from 'store/actions/complianceActions';

export default function ExemptRequirementDialog({ title, subTitle, requirementData, isOpen, handleCallback, goBack }) {
    const [reason, setReason] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const history = useHistory();

    async function handleSubmit() {
        try {
            setIsLoading(true);

            const res = await dispatch(
                ExemptControl({
                    ...requirementData,
                })
            );

            if (res?.success) {
                toast.success(`${title || 'Requirement'} exempted successfully`);
                handleClose();
                goBack && history.goBack();
            } else {
                throw new Error(res?.message);
            }
        } catch (error) {
            console.error(error);
            toast.error(error?.message || `Failed to exempt ${title || 'requirement'}`);
        } finally {
            setIsLoading(false);
        }
    }

    function handleClose() {
        handleCallback();
        setReason('');
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: '4px !important',
                    padding: 0,
                    boxShadow: '0px 16px 24px 0px #00000026',
                    width: '511px',
                },
            }}
        >
            <div className="flex items-center justify-between !px-6 !pt-6">
                <h5 className="text-lg font-semibold text-[#202D66]">Exempt {title || 'requirement'}</h5>

                <button
                    className="border-[#E2E8F0] border rounded-full !p-1 !h-6 !w-6 grid place-items-center hover:bg-[#E2E8F0] transition-colors"
                    onClick={handleClose}
                >
                    <img src="/img/automated-scan/close.svg" alt="close icon" className="object-contain" />
                </button>
            </div>

            <div className="!p-6 flex flex-col !gap-4">
                <textarea
                    className="w-full !p-2 !border !border-[#E2E8F0] !min-h-[76px] text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                    placeholder="Reason for exempting"
                    value={reason}
                    onChange={(event) => setReason(event.target.value)}
                />

                <div className="bg-[#F8FAFC] !border !border-[#F0F0FA] !rounded flex !gap-2 !px-3 !py-2 items-start">
                    <img src="/img/frameworks/lightbulb.svg" alt="Bulb Icon" className="!mt-0.5 !w-6 !h-6" />

                    <p className="text-[#64748B] font-normal text-[13px] leading-5">
                        You can view exempted {subTitle || 'requirements'} in the exceptions tab and also revert them.
                    </p>
                </div>
            </div>

            <div className="flex items-center justify-end !p-6 bg-[#F8FAFC] !gap-2">
                <button
                    className="bg-[#fff] border border-[#E2E8F0] rounded-sm !px-4 !py-2 hover:bg-red-500 hover:text-white transition-colors text-[#334155] text-sm font-medium"
                    onClick={handleClose}
                >
                    Cancel
                </button>

                <button
                    className="bg-[#202D66] rounded-sm !px-4 !py-2 hover:bg-[#151e44] transition-all text-white text-sm font-semibold disabled:opacity-50 disabled:pointer-events-none flex items-center !gap-2"
                    disabled={isLoading}
                    onClick={handleSubmit}
                >
                    {isLoading && <Loader2 className="!h-4 !w-4 animate-spin" />}
                    Exempt
                </button>
            </div>
        </Dialog>
    );
}
