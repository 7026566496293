import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Box, Button, Stack, Typography } from '@mui/material';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { useState, useRef, useEffect, useCallback } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useDropzone } from 'react-dropzone';
import folder from 'assets/img/folder.svg';
import AppInput from 'components/new_components/AppInput';
import deleteIcon from 'assets/img/delete.svg';
import moment from 'moment';
import { getSize } from 'utils';
import pngIcon from 'assets/img/png.svg';
import jpgIcon from 'assets/img/jpg.svg';
import { LoadingButton } from '@mui/lab';

export const VendorAttestation = (props) => {
    const { open, handleClose, vendor, handleSubmit, loading } = props;
    const [tab, setTab] = useState(0);
    const [penColor, setPenColor] = useState('#395BA9');
    const [image, setImage] = useState(null);
    const [error, setError] = useState('');
    const [position, setPosition] = useState('');
    const [positionError, setPositionError] = useState('');
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');

    // const [acceptedFile, setAcceptedFile] = useState(null);

    const sigCanvas = useRef();

    const colors = [
        { class: 'bg-[#395BA9]', color: '#395BA9' },
        { class: 'bg-[#7E0007]', color: '#7E0007' },
        { class: 'bg-[#201A18]', color: '#201A18' },
    ];

    const resetForm = () => {
        if (sigCanvas?.current) sigCanvas.current.clear();
        setImage(null);
        setError('');
        setPositionError('');
        setNameError('');
    };

    function dataUrlToFile(dataUrl, filename) {
        // Convert data URL to a Blob
        const byteString = atob(dataUrl.split(',')[1]);
        const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];

        // Create a typed array of the bytes
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
        }

        // create a Blob from the typed array and the MIME type
        const blob = new Blob([uint8Array], { type: mimeString });

        // convert the Blob to a File
        const file = new File([blob], filename, { type: mimeString });
        return file;
    }

    const checkIfEmpty = () => {
        return sigCanvas?.current ? sigCanvas?.current?.isEmpty() : true;
    };

    const submitForm = async () => {
        if (!name) {
            setNameError('Please enter your fullname');
            return;
        }
        if (!position) {
            setPositionError('Please enter your position');
            return;
        }

        if (tab === 0) {
            if (checkIfEmpty()) {
                setError('Please add your signature');
                return;
            }

            if (sigCanvas?.current?.getTrimmedCanvas()) {
                const canvasDataURL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
                const file = dataUrlToFile(canvasDataURL, `${vendor?.name}_signature.png`);
                // console.log(canvasDataURL.slice(0, 10), file);
                setImage(file);
                setError('');

                await handleSubmit(file, position, name);
            }
        } else {
            if (!image) {
                setError('Please add your signature');
                return;
            }

            await handleSubmit(image, position, name);
        }
    };

    useEffect(() => {
        resetForm();
    }, [tab]);

    // file
    const acceptedFileTypes = {
        // 'image/jpeg': ['.jpeg', '.jpg'],
        'image/png': ['.png'],
    };

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setImage(acceptedFiles[0]);
            setError('');
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        onDropAccepted() {
            setError('');
        },
        onDropRejected(fileRejections) {
            if (fileRejections.length > 1) {
                setError('Max number of files is 1');
            } else if (fileRejections.some((file) => file.file.size > 1048576)) {
                setError('Max file size is 1MB');
            } else {
                setError('Valid files include PNG');
            }
        },
        maxFiles: 1,
        multiple: false,
        accept: acceptedFileTypes,
        maxSize: 1048576,
    });

    const handleDelete = () => {
        setImage(null);
    };

    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            width={{ xs: '90%', sm: '50%' }}
            minWidth={{ sm: 550 }}
            maxWidth={800}
            padding={'0px'}
            title={<h3 className="text-[#202D66]">Attestation and Indemnity</h3>}
            dialogActionsStyle={{ px: 0, py: 0 }}
            headerAction={
                <div
                    onClick={handleClose}
                    className="cursor-pointer bg-white rounded-full border border-[#E2E8F0] w-6 h-6 flex items-center justify-center"
                >
                    <CloseOutlined className="text-[#334155] text-xs" />
                </div>
            }
            actions={
                <Stack direction="column" justifyContent="center" alignItems="center" sx={{ my: 0, gap: 1 }}>
                    <div className="h-20 px-6 bg-[#F8FAFC] w-full flex justify-end items-center gap-3">
                        <Button
                            variant="text"
                            sx={{
                                backgroundColor: '#FFFFFF !important',
                                color: '#334155 !important',
                                textTransform: 'inherit !Important',
                                border: '1px solid #E2E8F0 !important',
                                px: 2,
                                py: 1,
                                borderRadius: 1,
                                '&:hover': {
                                    backgroundColor: '#FFFFFF !important',
                                },
                                height: 'fit-content',
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            variant="text"
                            sx={{
                                backgroundColor: '#202D66 !important',
                                color: '#FFFFFF !important',
                                textTransform: 'inherit !Important',
                                border: '1px solid #E2E8F0 !important',
                                px: 2,
                                py: 1,
                                borderRadius: 1,
                                '&:hover': {
                                    backgroundColor: '#202D66 !important',
                                },
                                height: 'fit-content',
                            }}
                            onClick={submitForm}
                            loading={loading}
                        >
                            Accept and sign
                        </LoadingButton>
                    </div>
                </Stack>
            }
        >
            <Stack px={3} py={2.5}>
                <Stack mb={3} flexDirection="row" gap={2} alignItems="flex-end" justifyContent="flex-start">
                    <Box
                        sx={{ position: 'relative', cursor: 'pointer', width: 'fit-content' }}
                        onClick={() => setTab(0)}
                    >
                        <Typography sx={{ color: tab === 0 ? '#202D66' : '#94A3B8', fontWeight: 500, fontSize: 14 }}>
                            Signature
                        </Typography>
                        <div
                            className={`${
                                tab === 0 ? 'bg-[#202D66]' : 'hidden'
                            } absolute -bottom-2 w-full h-[5px] rounded-t-[5px]`}
                        />
                    </Box>
                    <Box
                        sx={{ position: 'relative', cursor: 'pointer', width: 'fit-content' }}
                        onClick={() => setTab(1)}
                    >
                        <Typography sx={{ color: tab === 1 ? '#202D66' : '#94A3B8', fontWeight: 500, fontSize: 14 }}>
                            Upload
                        </Typography>
                        <div
                            className={`${
                                tab === 1 ? 'bg-[#202D66]' : 'hidden'
                            } absolute -bottom-2 w-full h-[5px] rounded-t-[5px]`}
                        />
                    </Box>
                </Stack>

                <Box sx={{ width: '100%', height: '90px', mb: 5 }}>
                    <AppInput
                        placeholder="Fullname"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                            e.target.value && setNameError('');
                        }}
                    />
                    {nameError ? (
                        <Typography sx={{ color: 'red', fontWeight: 500, fontSize: 12, mt: 0.7 }}>
                            {nameError}
                        </Typography>
                    ) : null}
                    <AppInput
                        placeholder="Position"
                        value={position}
                        onChange={(e) => {
                            setPosition(e.target.value);
                            e.target.value && setPositionError('');
                        }}
                    />
                    {positionError ? (
                        <Typography sx={{ color: 'red', fontWeight: 500, fontSize: 12, mt: 0.7 }}>
                            {positionError}
                        </Typography>
                    ) : null}
                </Box>

                {tab === 0 ? (
                    <>
                        <div className="border rounded-sm border-[#CBD5E1] !border-dashed relative mt-3">
                            <div className="flex justify-between items-center gap-3 p-3 !pb-1 w-full">
                                <Typography
                                    sx={{ color: '#94A3B8', cursor: 'pointer', fontSize: 12 }}
                                    onClick={() => resetForm()}
                                >
                                    Clear
                                </Typography>
                                <div className="flex items-center justify-end gap-3">
                                    {colors?.map((color) => (
                                        <div
                                            key={color.color}
                                            className={`w-5 h-5 ${color.class} rounded-full flex-shrink-0 cursor-pointer flex items-center justify-center`}
                                            onClick={() => setPenColor(color.color)}
                                        >
                                            {penColor === color.color ? (
                                                <CheckOutlined className="text-white text-[10px]" />
                                            ) : null}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <SignatureCanvas
                                penColor={penColor}
                                canvasProps={{
                                    className: 'w-full',
                                    width: 400,
                                    height: 120,
                                }}
                                ref={sigCanvas}
                                minDistance={1}
                            />
                        </div>
                        {error ? (
                            <Typography sx={{ color: 'red', fontWeight: 500, fontSize: 12, mt: 1 }}>{error}</Typography>
                        ) : null}
                    </>
                ) : (
                    <div className="">
                        <div
                            {...getRootProps({ className: 'dropzone' })}
                            className="flex w-full flex-col items-center justify-center gap-1 rounded-sm border border-dashed border-[#CBD5E1] px-4 py-6"
                        >
                            <input {...getInputProps()} />
                            <img src={folder} width={15} height={15} className="h-6 w-6" alt="folder" />
                            <p className="text-center text-xs text-gray-500">Drop your files or click to upload</p>
                            <p className="text-center text-xs text-gray-400">Supported file types: PNG</p>
                            <button
                                type="button"
                                className="mt-2 rounded-sm border border-solid border-gray-200 p-2 py-1"
                            >
                                <p className="text-center text-xs text-gray-600">Browse</p>
                            </button>
                        </div>
                        {error ? (
                            <Typography sx={{ color: 'red', fontWeight: 500, fontSize: 12, mt: 1 }}>{error}</Typography>
                        ) : null}

                        {image ? (
                            <ul className="mt-4">
                                <li
                                    key={image?.path}
                                    className="m-0 mb-1 py-1.5 px-1 flex justify-between rounded-md border border-[#EEF0FF] bg-[#F8FAFC]"
                                >
                                    <div className="flex flex-1 gap-3">
                                        {image?.type?.toLowerCase() === 'image/png' ||
                                        image?.type?.toLowerCase() === 'image/png' ? (
                                            <img
                                                src={pngIcon}
                                                alt="image/png"
                                                width={15}
                                                height={15}
                                                className="h-8 w-8"
                                            />
                                        ) : (
                                            <img
                                                src={jpgIcon}
                                                alt="image/jpg"
                                                width={15}
                                                height={15}
                                                className="h-8 w-8"
                                            />
                                        )}
                                        <div className="flex flex-1 flex-col">
                                            <p className="break-all text-left text-[12px] font-normal text-[#475569]">
                                                {image?.name}
                                            </p>
                                            <p className="text-left text-[11px] font-normal text-[#94A3B8]">
                                                {moment(image?.lastModifiedDate).format('D MMMM, YYYY')}.{' '}
                                                {getSize(image?.size)}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex cursor-pointer gap-2 items-center">
                                        <img
                                            src={deleteIcon}
                                            width={15}
                                            height={15}
                                            className="h-5 w-5"
                                            alt="delete"
                                            onClick={handleDelete}
                                        />
                                    </div>
                                </li>
                            </ul>
                        ) : null}
                    </div>
                )}
                <Box
                    sx={{
                        backgroundColor: '#F8FAFC',
                        px: 2,
                        pt: 1,
                        pb: 2,
                        borderRadius: '5px',
                        border: '1px solid #F0F0FA',
                        mt: 2,
                    }}
                >
                    <Typography sx={{ color: '#3E414D', fontSize: 14 }}>
                        I, {name || vendor?.name}, confirm that the information provided in this questionnaire is
                        accurate to the best of my knowledge. I understand that {vendor?.merchant_name} relies on the
                        accuracy of this information for its risk assessment and compliance purposes. By signing this
                        document, I agree to indemnify {vendor?.merchant_name} against any losses or damages resulting
                        from any inaccuracies or omissions in the responses provided here.
                    </Typography>
                </Box>
            </Stack>
        </AppCenteredModal>
    );
};
