import { Box, Button, Checkbox, FormControlLabel, Tooltip, Typography } from '@mui/material';
import { useEffect, useState, memo } from 'react';
import { FiHelpCircle } from 'react-icons/fi';
import { toast } from 'react-toastify';
// import { Edit, Add } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import AppButton from 'components/new_components/AppButton';
import { AppForm, AppFormInput, AppFormSelect } from 'components/new_components/forms';

//redux
import { useDispatch, useSelector } from 'react-redux';
import { AddVendor, EditVendor } from 'store/actions/vendorActions';

//validation
import { riskLevelOptions } from '../../utils/constants';
import { AMLVendorsValidation, vendorsValidation } from '../../utils/validation';

//translations
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AppDrawer from 'components/new_components/AppDrawer';
import { AppSubmitButton } from 'components/new_components/forms';
import { useTranslation } from 'react-i18next';
// import AppCheckboxInput from 'components/new_components/AppCheckboxInput';
import { AppFormContext } from 'components/new_components/forms';
// import AppFormCheckbox from 'components/new_components/forms/AppFormCheckbox';
import { AddInAppSearch } from 'store/actions/vendorActions';
import AMLSettingsModal from './AMLSettingsModal';
import { HelpOutline } from '@mui/icons-material';
import AppCheckboxInput from 'components/new_components/AppCheckboxInput';
// import VendorFormSelect from './components/VendorFormSelect';

const FormLabel = memo(({ label, tips, toolTip = false }) => {
    const theme = useTheme();

    return (
        <div className="flex -mb-3 mt-3 items-center z-[1]">
            <Typography
                sx={{
                    color: theme.palette.neutral[500],
                    fontSize: '13px',
                    fontWeight: 500,
                    width: 'fit-content',
                }}
            >
                {label}
            </Typography>
            {toolTip && tips && (
                <Tooltip
                    title={
                        <Box sx={{ pl: 0, w: 5 }}>
                            <Typography sx={{ fontWeight: 400, fontSize: '12px' }}>{tips}</Typography>
                        </Box>
                    }
                    arrow
                    placement="bottom"
                    PopperProps={{
                        sx: {
                            '& .MuiTooltip-arrow': {
                                color: '#000',
                            },
                            '& .MuiTooltip-tooltip': {
                                backgroundColor: '#000',
                                color: theme.palette.white.main,
                                p: 1,
                                borderRadius: '8px',
                            },
                        },
                    }}
                >
                    <HelpOutline
                        sx={{
                            fontSize: '22px',
                            color: theme.palette.primary[500],
                            ml: 1,
                            cursor: 'pointer',
                            padding: '5px',
                        }}
                    />
                </Tooltip>
            )}
        </div>
    );
});

const VendorModal = (props) => {
    const { open, handleClose, isAddModal, id, payload, getAllVendors, openRiskLevelsModal } = props;

    const theme = useTheme();
    const [loadingState, setLoadingState] = useState(false);
    const [openAMLSettings, setOpenAMLSettings] = useState(false);
    const [type, setType] = useState('AML');
    const [values, setValues] = useState(payload);
    const [sources, setSources] = useState([]);
    const [continous_monitoring, setcontinous_monitoring] = useState(false);
    const dispatch = useDispatch();
    const all_vendors = useSelector((state) => state?.vendorReducers?.all_vendors);
    const [sendMail, setSendMail] = useState(true);
    const [runAml, setRunAml] = useState(false);

    const handleOpenAML = (e) => {
        e.preventDefault();
        setOpenAMLSettings(true);
    };

    const handleCloseAML = () => {
        setSources([]);
        setOpenAMLSettings(false);
    };
    const handleUncheckAML = (e) => {
        if (runAml) {
            setSources([]);
            setOpenAMLSettings(false);
            setRunAml(false);
        } else {
            e.preventDefault();
        }
    };

    const handleSaveAML = () => {
        setOpenAMLSettings(false);
        if (sources.length > 0) {
            setRunAml(true);
        } else {
            setRunAml(false);
        }
    };

    //translation
    const { t } = useTranslation('vendorManagement');

    const addInAppSearch = async () => {
        const body = {
            entity_name: values.name,
            sources,
            continous_monitoring,
        };
        const res = await dispatch(AddInAppSearch(body));
        if (res?.success) {
            setLoadingState(false);
            toast.success(isAddModal ? 'Vendor Added Successfully' : 'Vendor Credentials Updated Successfully');
            handleClose();
        } else {
            setLoadingState(false);
            toast.error(res?.message);
        }
    };

    const handleSubmit = async (values) => {
        setLoadingState(true);
        const non_amlValues = {
            name: values.name,
            description: values.description,
            data: values.data,
            risk_level: values.risk_level,
            products: values.products,
            min_risk_level: values.min_risk_level,
            email: values.email,
            is_aml_vendor: false,
            send_questionnaire_mail: sendMail,
            department: values.department,
            to: values.to,
        };

        const amlValues = {
            name: values.name,
            description: values.description,
            data: values.data,
            risk_level: values.risk_level,
            products: values.products,
            email: values.email,
            min_risk_level: values.min_risk_level,
            is_aml_vendor: true,
            ran_aml: runAml,
            send_questionnaire_mail: sendMail,
            department: values.department,
            to: values.to,
        };
        const body = type === 'AML' ? amlValues : non_amlValues;
        const res = isAddModal ? await dispatch(AddVendor(body)) : await dispatch(EditVendor(body, id));
        if (res?.success) {
            if (values?.ran_aml) {
                addInAppSearch();
            } else {
                setLoadingState(false);
                toast.success(isAddModal ? 'Vendor Added Successfully' : 'Vendor Credentials Updated Successfully');
                handleClose();
            }
            await getAllVendors();
        } else {
            setLoadingState(false);
            toast.error(res?.message);
        }
    };
    const getCurrentValues = (values) => setValues(values);
    useEffect(() => {
        if (payload) {
            setType(payload.is_aml_vendor ? 'AML' : 'NON-AML');
        }
    }, [payload]);
    const filtered_vendors = all_vendors?.filter((vendor) => vendor?.is_aml_vendor === true);

    return (
        <AppForm
            initialValues={payload}
            onSubmit={handleSubmit}
            validate={type === 'AML' ? AMLVendorsValidation : vendorsValidation}
        >
            <AppFormContext getValues={getCurrentValues}>
                <AppDrawer
                    open={open}
                    handleClose={handleClose}
                    title={isAddModal ? t('vendorModal.addVendor') : t('vendorModal.editVendor')}
                    icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
                    noShadow
                    noClose
                    headerAction={
                        <>
                            <AppButton
                                name={t('vendorModal.sensitivity')}
                                sx={{
                                    fontSize: '12px',
                                    fontStyle: 'italic',
                                    fontWeight: '400',
                                    color: theme.palette.primary[200],
                                    '&:hover': {
                                        background: 'transparent !important',
                                    },
                                }}
                                icon={<FiHelpCircle size="13px" />}
                                onClick={openRiskLevelsModal}
                            />
                        </>
                    }
                    sx={{ overflow: 'hidden' }}
                    width="450px"
                    // height="520px"
                >
                    <Box sx={{ mx: 2 }}>
                        <Typography sx={{ color: '#64748B', fontSize: '13px', fontWeight: '600' }}>
                            Vendor questionnaire type
                        </Typography>
                        <Box
                            sx={{
                                border: '2px solid #395BA9',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    backgroundColor: type === 'AML' ? '#395BA9' : 'transpparent',
                                    width: '50%',
                                    color: type === 'AML' ? '#FFFFFF !important' : '#395BA9 !important',
                                    textAlign: 'center',
                                    py: 1.2,
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                }}
                                onClick={() => setType('AML')}
                            >
                                AML vendor
                            </Typography>
                            <Typography
                                sx={{
                                    backgroundColor: type === 'NON-AML' ? '#395BA9' : 'transpparent',
                                    width: '50%',
                                    color: type === 'NON-AML' ? '#FFFFFF !important' : '#395BA9 !important',
                                    textAlign: 'center',
                                    py: 1.2,
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                }}
                                onClick={() => setType('NON-AML')}
                            >
                                NON-AML vendor
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            mx: 2,
                            my: 2,
                            overflow: 'auto !important',
                            height: type === 'AML' ? '65dvh' : '62dvh',
                            msOverflowStyle: 'none',
                            pr: 1,
                            '&::-webkit-scrollbar': {
                                width: '8px',
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: 'transparent',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: theme.palette.primary[900] + '30',
                                borderRadius: '10px',
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                                backgroundColor: theme.palette.primary[900] + '50',
                            },
                            '&::-webkit-scrollbar-track-piece': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        {type === 'AML' && (
                            <Box>
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#64748B', my: 1 }}>
                                    Vendors whose operations involve activities that fall under the scope of AML laws.
                                    Examples include: Banks and financial institutions, Payment service providers,
                                    Cryptocurrency exchanges, Money transfer operators, Casinos and gaming businesses
                                    etc.
                                </Typography>
                                <Box>
                                    <AppFormInput
                                        type="text"
                                        name="name"
                                        label={t('vendorModal.vendorName')}
                                        placeholder={t('vendorModal.vendorName')}
                                    />
                                    <AppFormInput
                                        type="text"
                                        name="email"
                                        label={t('vendorModal.questionnaireRecipient')}
                                        placeholder={t('vendorModal.emailAddress')}
                                        disabled={!isAddModal}
                                    />
                                    <Box>
                                        <FormControlLabel
                                            label={
                                                <Typography
                                                    sx={{
                                                        color: '#395BA9',
                                                        fontSize: '13px',
                                                        fontWeight: 500,
                                                        ml: '-5px',
                                                    }}
                                                >
                                                    Don’t send questionnaire to email
                                                    <Tooltip
                                                        title={
                                                            <Box sx={{ p: '1px' }}>
                                                                <Typography sx={{ fontWeight: 400, fontSize: '12px' }}>
                                                                    {`Use this option if you just want to add a
                                                                    vendor without sending them a questionnaire link`}
                                                                </Typography>
                                                            </Box>
                                                        }
                                                        arrow
                                                        placement={'bottom'}
                                                        PopperProps={{
                                                            sx: {
                                                                '& .MuiTooltip-arrow': {
                                                                    color: '#000',
                                                                },
                                                                '& .MuiTooltip-tooltip': {
                                                                    backgroundColor: '#000',
                                                                    color: theme.palette.white.main,
                                                                    p: 1,
                                                                    borderRadius: '8px',
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <HelpOutline
                                                            sx={{
                                                                fontSize: '12px',
                                                                color: theme.palette.primary[500],
                                                                ml: 1,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </Typography>
                                            }
                                            control={
                                                <Checkbox
                                                    name="send_questionnaire_mail"
                                                    onChange={(e) => {
                                                        setSendMail(!e.target.checked);
                                                    }}
                                                    checked={!sendMail}
                                                />
                                            }
                                            value={!sendMail}
                                        ></FormControlLabel>
                                        <Typography sx={{ fontSize: '13px', mt: '-0.5rem' }}>
                                            Want to send questionnaire manually?. &nbsp;
                                            <a
                                                href={
                                                    'https://secure-gen.s3.amazonaws.com/AML Vendor Questionnaire.xlsx'
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{ fontWeight: 500, color: '#395BA9', cursor: 'pointer' }}
                                            >
                                                Download our EXCEL template
                                            </a>{' '}
                                            and send to recipient.
                                        </Typography>
                                    </Box>
                                    <AppFormInput
                                        type="text"
                                        name="data"
                                        label={t('vendorModal.dataShared')}
                                        placeholder={t('vendorModal.dataShared')}
                                    />
                                    <AppFormInput
                                        type="text"
                                        name="products"
                                        placeholder={'Products'}
                                        label={t('vendorModal.product')}
                                    />
                                    <AppFormInput
                                        type="text"
                                        name="department"
                                        label={t('vendorModal.department')}
                                        placeholder={t('vendorModal.departmentPlaceholder')}
                                    />
                                    <AppFormInput
                                        type="text"
                                        name="to"
                                        label={t('vendorModal.to')}
                                        placeholder={t('vendorModal.toPlaceholder')}
                                    />

                                    <FormLabel
                                        label="Risk level"
                                        toolTip={true}
                                        tips={`The lower the score, the more the risk. Highest score is 100`}
                                    />

                                    <AppFormSelect
                                        name="risk_level"
                                        defaultValue="Risk level"
                                        options={riskLevelOptions}
                                        sx={{
                                            height: '40px',

                                            '& .MuiSelect-select': {
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: theme.palette.grey[900],
                                                pt: '10px',
                                                h: '100%',
                                            },
                                        }}
                                    />

                                    <FormLabel
                                        label="Vendor minimum risk alert level (0-100)"
                                        toolTip={true}
                                        tips={
                                            ' Get alerted when a vendor scorecard is within the minimum risk alert level.'
                                        }
                                    />
                                    <AppFormInput
                                        type="number"
                                        name="min_risk_level"
                                        placeholder="Vendor minimum risk alert level"
                                        sx={{ margin: 0 }}
                                        max={100}
                                        min={0}
                                        onInput={(e) => {
                                            const target = e.target;
                                            let value = target.value.replace(/^0+(?=\d)/, '');
                                            let num = parseInt(value, 10);

                                            if (isNaN(num)) {
                                                target.value = '';
                                                return;
                                            }

                                            if (num < 0) num = 0;
                                            if (num > 100) num = 100;

                                            target.value = num.toString();
                                        }}
                                    />

                                    <AppFormInput
                                        type="text"
                                        name="description"
                                        placeholder={'Description of service'}
                                        label={'Description of service'}
                                        multiline={true}
                                        rows={3}
                                    />
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            mb: 4,
                                        }}
                                    >
                                        <Box>
                                            <AppCheckboxInput
                                                name="ran_aml"
                                                onClick={handleUncheckAML}
                                                onChange={handleUncheckAML}
                                                value={runAml}
                                                label={
                                                    <Typography
                                                        onClick={handleOpenAML}
                                                        sx={{
                                                            color: '#395BA9',
                                                            fontSize: '13px',
                                                            fontWeight: 500,
                                                            mb: 0.3,
                                                        }}
                                                    >
                                                        Run AML check on this vendor
                                                    </Typography>
                                                }
                                            />
                                            <Typography sx={{ fontSize: '11px', mt: '-0.5rem' }}>
                                                You have{' '}
                                                <span style={{ fontWeight: 500, color: '#395BA9' }}>
                                                    {filtered_vendors?.length} vendors
                                                </span>{' '}
                                                to run AML check on
                                            </Typography>
                                        </Box>
                                        {/*                                         {values?.ran_aml && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                }}

                                            >
                                                <SettingsOutlinedIcon />
                                                <Typography
                                                    sx={{
                                                        color: '#475569',
                                                        fontWeight: 500,
                                                        fontSize: '12px',
                                                        ml: 0.5,
                                                    }}
                                                >
                                                    Settings
                                                </Typography>
                                            </Box>
                                        )}
 */}{' '}
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        {type === 'NON-AML' && (
                            <Box>
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#64748B', my: 1.5 }}>
                                    Vendors whose operations do not fall under the scope of AML laws. Examples include:
                                    Software providers, Marketing agencies, Event organizers, Retail businesses,
                                    Logistics companies etc.
                                </Typography>
                                <AppFormInput
                                    type="text"
                                    name="name"
                                    placeholder={t('vendorModal.vendorName')}
                                    label={t('vendorModal.vendorName')}
                                />
                                <AppFormInput
                                    type="text"
                                    name="email"
                                    label={t('vendorModal.questionnaireRecipient')}
                                    placeholder={t('vendorModal.emailAddress')}
                                    disabled={!isAddModal}
                                />

                                <Box>
                                    <FormControlLabel
                                        label={
                                            <Typography
                                                sx={{
                                                    color: '#395BA9',
                                                    fontSize: '13px',
                                                    fontWeight: 500,
                                                    ml: '-5px',
                                                }}
                                            >
                                                Don’t send questionnaire to email
                                                <Tooltip
                                                    title={
                                                        <Box sx={{ p: '1px' }}>
                                                            <Typography sx={{ fontWeight: 400, fontSize: '12px' }}>
                                                                {`Use this option if you just want to add a
                                                                    vendor without sending them a questionnaire link`}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                    arrow
                                                    placement={'bottom'}
                                                    PopperProps={{
                                                        sx: {
                                                            '& .MuiTooltip-arrow': {
                                                                color: '#000',
                                                            },
                                                            '& .MuiTooltip-tooltip': {
                                                                backgroundColor: '#000',
                                                                color: theme.palette.white.main,
                                                                p: 1,
                                                                borderRadius: '8px',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <HelpOutline
                                                        sx={{
                                                            fontSize: '12px',
                                                            color: theme.palette.primary[500],
                                                            ml: 1,
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Typography>
                                        }
                                        control={
                                            <Checkbox
                                                name="send_questionnaire_mail"
                                                onChange={(e) => {
                                                    setSendMail(!e.target.checked);
                                                }}
                                                checked={!sendMail}
                                            />
                                        }
                                    ></FormControlLabel>
                                    <Typography sx={{ fontSize: '13px', mt: '-0.5rem' }}>
                                        Want to send questionnaire manually?. &nbsp;
                                        <a
                                            href={
                                                'https://secure-gen.s3.amazonaws.com/NON-AML Vendor Questionnaire.xlsx'
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                            style={{ fontWeight: 500, color: '#395BA9', cursor: 'pointer' }}
                                        >
                                            Download our EXCEL template
                                        </a>{' '}
                                        and send to recipient.
                                    </Typography>
                                </Box>

                                <AppFormInput
                                    type="text"
                                    name="data"
                                    placeholder={t('vendorModal.dataShared')}
                                    label={t('vendorModal.dataShared')}
                                />
                                <AppFormInput
                                    type="text"
                                    name="products"
                                    placeholder={t('vendorModal.product')}
                                    label={t('vendorModal.product')}
                                />

                                <AppFormInput
                                    type="text"
                                    name="department"
                                    label={t('vendorModal.department')}
                                    placeholder={t('vendorModal.departmentPlaceholder')}
                                />
                                <AppFormInput
                                    type="text"
                                    name="to"
                                    label={t('vendorModal.to')}
                                    placeholder={t('vendorModal.toPlaceholder')}
                                />

                                <FormLabel
                                    label="Risk level"
                                    toolTip={true}
                                    tips={'The lower the score, the more the risk. Highest score is 100'}
                                />
                                <AppFormSelect
                                    name="risk_level"
                                    options={riskLevelOptions}
                                    defaultValue="Risk level"
                                    sx={{
                                        height: '40px',

                                        '& .MuiSelect-select': {
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: theme.palette.grey[900],
                                            pt: '10px',
                                            h: '100%',
                                        },
                                    }}
                                />

                                <FormLabel
                                    label="Vendor minimum risk alert level (0-100)"
                                    toolTip={true}
                                    tips={
                                        ' Get alerted when a vendor scorecard is within the minimum risk alert level.'
                                    }
                                />
                                <AppFormInput
                                    type="number"
                                    name="min_risk_level"
                                    placeholder="Vendor minimum risk alert level"
                                    max={100}
                                    min={0}
                                    onInput={(e) => {
                                        const target = e.target;
                                        let value = target.value.replace(/^0+(?=\d)/, '');
                                        let num = parseInt(value, 10);

                                        if (isNaN(num)) {
                                            target.value = '';
                                            return;
                                        }

                                        if (num < 0) num = 0;
                                        if (num > 100) num = 100;

                                        target.value = num.toString();
                                    }}
                                />

                                <AppFormInput
                                    type="text"
                                    name="description"
                                    placeholder={'Description of service'}
                                    label={'Description of service'}
                                    multiline={true}
                                    rows={3}
                                />
                            </Box>
                        )}
                    </Box>
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            paddingLeft: '23rem',
                            backgroundColor: '#F8FAFC',
                            width: '100% !important',
                            py: 2.5,
                            px: 2,
                            display: 'flex',
                            right: 0,
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            variant="outlined"
                            sx={{ mr: 1, border: '1px solid #E2E8F0', textTransform: 'inherit' }}
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                        <AppSubmitButton
                            text={isAddModal ? 'ADD' : 'UPDATE'}
                            variant="contained"
                            color="primary"
                            loading={loadingState}
                            loadingPosition="center"
                            sx={{ borderRadius: 1 }}
                        />
                    </Box>
                    <AMLSettingsModal
                        open={openAMLSettings}
                        handleClose={handleCloseAML}
                        handleSave={handleSaveAML}
                        values={values}
                        sources={sources}
                        setSources={setSources}
                        continous_monitoring={continous_monitoring}
                        setcontinous_monitoring={setcontinous_monitoring}
                    />
                </AppDrawer>
            </AppFormContext>
        </AppForm>
    );
};

export default VendorModal;
