/* eslint-disable no-unused-vars */

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';

export default function DepartmentCard({ department, currentTab }) {
    const [expandedAccordion, setExpandedAccordion] = useState(false);

    const handleAccordionChange = (departmentID) => (event, isAccordionExpanded) => {
        setExpandedAccordion(isAccordionExpanded ? departmentID : false);
    };

    return (
        <Accordion
            expanded={expandedAccordion === department?.name}
            onChange={handleAccordionChange(department?.name)}
            className="!shadow-none !border !border-[#F1F5F9] !p-0 !rounded-lg [&.css-9mbo5h-MuiPaper-root-MuiAccordion-root.Mui-expanded]:!my-0 before:hidden"
        >
            <AccordionSummary
                expandIcon={<></>}
                aria-controls={`${department?.name}-content`}
                id={`${department?.name}header`}
                className="!px-0 [&>.MuiAccordionSummary-content]:!my-0 !min-h-fit transition-colors"
            >
                <div className="!p-5 flex items-center justify-between !w-full">
                    <h5 className="text-[#475569] font-bold text-base capitalize">{department?.name} Role</h5>

                    {expandedAccordion === department?.name ? (
                        <img src="/img/frameworks/minus-square.svg" alt="minus" className="!w-6 !h-6" />
                    ) : (
                        <img src="/img/frameworks/plus-square.svg" alt="plus" className="!w-6 !h-6" />
                    )}
                </div>
            </AccordionSummary>

            <AccordionDetails className="!px-0 !py-0">
                <div className="!pb-5 !px-5 flex flex-col !gap-6">
                    {department?.people?.length ? (
                        <>
                            <div className="flex items-center !gap-[2px] text-[#64748B] text-sm">
                                <p>People who have {currentTab} the policy as at:</p>

                                <div className="flex items-center !gap-2">
                                    <img src="/img/frameworks/calendar.svg" alt="calendar" className="!h-4 !w-4" />

                                    <p>{moment().format('DD MMM, YYYY')}</p>
                                </div>
                            </div>

                            <div className="flex flex-wrap items-center !gap-2">
                                {department?.people?.map((person) => (
                                    <p
                                        key={person}
                                        className="!border !border-[#E2E8F0] bg-[#F1F5F9] !rounded-full !py-1 !px-1.5 text-[#5E5E62] font-medium text-[13px]"
                                    >
                                        {person}
                                    </p>
                                ))}
                            </div>
                        </>
                    ) : (
                        <p className="text-[#64748B] text-sm">No one in this role has {currentTab} the policy yet.</p>
                    )}
                </div>
            </AccordionDetails>
        </Accordion>
    );
}
