import React, { memo, useState } from 'react';
import SearchIcon from 'assets/img/Searchicon.svg';
import { Button, Menu, MenuItem } from '@mui/material';
import VectorDown from 'assets/img/vector-down.svg';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';

//translations
// import { useTranslation } from 'react-i18next';

const OnboardTableTitle = memo((props) => {
    const { onSearch, filter, onFilterChange, openModal, type, loading } = props;
    const [sourceAnchorEl, setSourceAnchorEl] = useState(null);
    const [statusAnchorEl, setStatusAnchorEl] = useState(null);

    //translation
    const { t } = useTranslation('onboardingPolicy');

    // Functions
    const sourceOpenMenu = (event) => {
        if (!loading) setSourceAnchorEl(event.currentTarget);
    };

    const statusOpenMenu = (event) => {
        if (!loading) setStatusAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setSourceAnchorEl(null);
        setStatusAnchorEl(null);
    };

    return (
        <div className="flex justify-between items-end w-full relative mt-4 mb-3  min-w-full flex-wrap gap-2 px-3">
            <div className="flex items-center relative gap-2 w-fit">
                <div className="relative">
                    <input
                        placeholder="Search..."
                        onChange={onSearch}
                        className="text-gray-400 text-sm font-400 placeholder:text-gray-400 border border-gray-200 py-2.5 rounded-[4px] w-[230px] px-[30px] font-normal"
                    />
                    <img src={SearchIcon} alt="SearchIcon" className="absolute left-2 top-3 w-[17px]" />
                </div>

                <div className="relative">
                    <button
                        className={`border border-gray-200 py-2.5 ${
                            filter.source === 'medium'
                                ? 'text-[#395BA9] w-[160px]'
                                : filter.status !== 'all'
                                ? 'text-[#395BA9] w-[140px]'
                                : 'text- gray-500 w-[123px]'
                        } text-[13px] font-medium rounded-[4px] flex justify-between items-center px-2 capitalize`}
                        onClick={statusOpenMenu}
                    >
                        Status: {filter.status || 'All'}
                    </button>
                    <img
                        src={VectorDown}
                        alt="VectorDown"
                        className="text-neutral-40 w-[8px] h-[5px] absolute right-3 top-5 font-normal"
                    />
                </div>
                <Menu open={!!statusAnchorEl} anchorEl={statusAnchorEl} onClose={closeMenu}>
                    {[
                        {
                            name: 'All',
                            value: '',
                        },
                        {
                            name: 'Enabled',
                            value: 'enabled',
                            icon: '#0B885A',
                        },
                        {
                            name: 'Disabled',
                            value: 'disabled',
                            icon: '#94A3B8',
                        },
                    ]?.map((option) => (
                        <MenuItem
                            key={option.value}
                            sx={{
                                color: '#64748B',
                                fontSize: '14px',
                                fontWeight: 500,
                                width: '140px',
                                borderBottom: '1px solid #F1F5F9',
                                '&:hover': {
                                    backgroundColor: '#F1F5F9',
                                },
                            }}
                            onClick={() => {
                                onFilterChange({
                                    name: 'status',
                                    value: option?.value,
                                });
                                closeMenu();
                            }}
                        >
                            {option.icon && (
                                <SquareRoundedIcon
                                    sx={{
                                        color: option.icon,
                                        fontSize: '14px',
                                        height: 'fit-content',
                                        fontStyle: 'normal',
                                        my: 0,
                                        mr: '2px',
                                    }}
                                />
                            )}
                            {option.name}
                        </MenuItem>
                    ))}
                </Menu>

                <div className="relative">
                    <button
                        className={`border border-gray-200 py-2.5 ${
                            filter.source === 'medium'
                                ? 'text-[#395BA9] w-[160px]'
                                : filter.source !== 'all'
                                ? 'text-[#395BA9] w-[145px]'
                                : 'text- gray-500 w-[123px]'
                        } text-[13px] font-medium rounded-[4px] flex justify-between items-center px-2 capitalize`}
                        onClick={sourceOpenMenu}
                    >
                        Source: {filter.source || 'All'}
                    </button>
                    <img
                        src={VectorDown}
                        alt="VectorDown"
                        className="text-neutral-40 w-[8px] h-[5px] absolute right-3 top-5 font-normal"
                    />
                </div>
                <Menu open={!!sourceAnchorEl} anchorEl={sourceAnchorEl} onClose={closeMenu}>
                    {[
                        {
                            name: 'All',
                            value: '',
                        },
                        {
                            name: 'Merchant',
                            value: 'merchant',
                        },
                        {
                            name: 'Admin',
                            value: 'admin',
                        },
                    ]?.map((option) => (
                        <MenuItem
                            sx={{
                                color: '#64748B',
                                fontSize: '14px',
                                fontWeight: 500,
                                width: '145px',
                                borderBottom: '1px solid #F1F5F9',
                                '&:hover': {
                                    backgroundColor: '#F1F5F9',
                                },
                            }}
                            onClick={() => {
                                onFilterChange({
                                    value: option?.value,
                                    name: 'source',
                                });
                                closeMenu();
                            }}
                        >
                            {option.name}
                        </MenuItem>
                    ))}
                </Menu>
            </div>

            <Button
                startIcon={<Add />}
                color="primary"
                variant="contained"
                disableElevation
                onClick={openModal}
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: '600',
                    borderRadius: '2px',
                    py: 1.2,
                    px: 2.4,
                    m: 0,
                    textTransform: 'unset',
                    flexWrap: 'nowrap',
                    wordWrap: 'nowrap',
                }}
            >
                {type === 'video' ? t('table.newVideo') : t('table.newPolicy')}
            </Button>
        </div>
    );
});

export default OnboardTableTitle;
