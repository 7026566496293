import * as types from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case types.ALL_REQUIREMENTS:
            return {
                ...state,
                all_requirements: action.payload,
            };
        case types.ONE_REQUIREMENT:
            return {
                ...state,
                one_requirement: action.payload,
            };
        case types.ALL_PCIDSS4POINT0_REQUIREMENTS:
            return {
                ...state,
                all_pcidss4point0_requirements: action.payload,
            };
        case types.ONE_PCIDSS4POINT0_REQUIREMENT:
            return {
                ...state,
                one_pcidss4point0_requirement: action.payload,
            };
        case types.ALL_ADMIN_DOCUMENTS_BY_REQ:
            return {
                ...state,
                all_documents_by_req: action.payload,
            };
        case types.ALL_ADMIN_DOCUMENTS_BY_SUBREQ:
            return {
                ...state,
                all_documents_by_subreq: action.payload,
            };
        case types.ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_REQ:
            return {
                ...state,
                all_pcidss4point0_documents_by_req: action.payload,
            };
        case types.ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_SUBREQ:
            return {
                ...state,
                all_pcidss4point0_documents_by_subreq: action.payload,
            };
        case types.ALL_ADMIN_PCIDSS_FORMS:
            return {
                ...state,
                all_pcidss_forms: action.payload,
            };
        case types.ALL_ADMIN_PCIDSS4POINT0_FORMS:
            return {
                ...state,
                all_pcidss4point0_forms: action.payload,
            };
        case types.ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_TAGS:
            return {
                ...state,
                all_pcidss4point0_documents_by_tags: action.payload,
            };
        case types.ALL_ADMINPCIDSS4POINT0_DOCUMENTS_BY_ALTTAGS:
            return {
                ...state,
                all_pcidss4point0_documents_by_alttags: action.payload,
            };
        case types.ALL_REQUIREMENTS_METRICS:
            return {
                ...state,
                all_requirements_metrics: action.payload,
            };
        case types.ALL_PCIDSS4POINT0_REQUIREMENTS_METRICS:
            return {
                ...state,
                all_pcidss4point0_requirements_metrics: action.payload,
            };
        case types.ALL_ADMIN_EVIDENCE_BY_CARD4:
            return {
                ...state,
                all_evidence_by_card4: action.payload,
            };
        default:
            return state;
    }
};
