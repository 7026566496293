import { MoreVert } from '@mui/icons-material';
import { IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { forwardRef, useMemo, useState } from 'react';

// core component
import AppTableActionMenu from 'components/new_components/app-table/AppTableActionMenu';
import GroupCard from '../../common/GroupCard';

//translations
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const RequirementCard = forwardRef((props, ref) => {
    const theme = useTheme();
    const {
        id,
        requirement_number,
        title,
        metric,
        // resourceLabel,
        // onViewChange,
        openTaskAssignModal,
        openExemptModal,
        openRevertModal,
        isRequirementAssigned,
        // isSubRequirementAssigned,
        // resourceAssigned,
        isExceptionTab,
    } = props;
    const [anchor, setAnchor] = useState(null);
    //translation
    const { t } = useTranslation('compliance');

    const history = useHistory();

    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const organization = useSelector((state) => state?.generalReducers?.user_info?.organization);
    const all_exempted_controls = useSelector((state) => state?.complianceReducers?.all_exempted_controls);

    const exmeptedRequirement = useMemo(
        () => all_exempted_controls?.filter((item) => item?.standard === 'card4')?.find((item) => item?.card4 === id),
        [all_exempted_controls, id]
    );

    const openMenu = (e) => {
        e.stopPropagation();
        setAnchor(e.target);
    };
    const closeMenu = () => setAnchor(null);

    const handleOpenTaskModal = (e) => {
        e.stopPropagation();
        openTaskAssignModal('requirement', {
            card4_requirement: id,
            // is_document: resourceLabel !== 'Questions Answered',
            is_document: true,
            standard: 'card4',
        });
        closeMenu();
    };

    const handleOpenExemptModal = (e) => {
        e.stopPropagation();
        openExemptModal('requirement', {
            card4: id,
            standard: 'card4',
        });
        closeMenu();
    };

    const handleOpenRevertModal = (e) => {
        e.stopPropagation();
        openRevertModal('requirement', {
            id: exmeptedRequirement?.id,
        });
        closeMenu();
    };

    const handleCardClick = () => {
        if (metric?.totalValue) {
            // onViewChange(id);
            history.push(`/merchant/compliance/frameworks/pcidss4.0.1/${id}`);
        }
    };
    return (
        <GroupCard
            percentCompleted={
                !metric?.totalValue && !metric?.totalAttendedTo
                    ? 100
                    : (metric?.totalAttendedTo / metric?.totalValue) * 100
            }
            totalValue={metric?.totalValue}
            totalAttendedTo={metric?.totalAttendedTo}
            // resourceLabel={resourceLabel}
            onCardClick={handleCardClick}
            empty={metric?.totalValue == 0}
            isCategoryAssigned={isRequirementAssigned}
            // isSubCatgoryAssigned={isSubRequirementAssigned}
            // isResourceAssigned={resourceAssigned?.includes(requirement_number)}
            ref={ref}
            // standard="card4"
            isExceptionTab={isExceptionTab}
        >
            <Stack direction="row" justifyContent="space-between" onClick={(event) => event.stopPropagation()}>
                <Typography variant="h3" component="h3" sx={{ fontWeight: 600, mb: 0, color: '#475569', fontSize: 15 }}>
                    {t('requirement')} {requirement_number}
                </Typography>
                {metric?.totalValue > 0 &&
                    (!isExceptionTab ? (
                        !isRequirementAssigned && user_type !== 'auditor' ? (
                            <>
                                {organization?.merchant_plan?.name !== 'free_version' &&
                                    organization?.merchant_plan?.name !== '3_days_trial' && (
                                        <IconButton
                                            sx={{ color: theme.palette.gray[900], minWidth: 'unset', p: 0 }}
                                            onClick={openMenu}
                                        >
                                            <MoreVert
                                                sx={{
                                                    color: '#475569',
                                                }}
                                            />
                                        </IconButton>
                                    )}
                                <AppTableActionMenu
                                    anchor={anchor}
                                    closeMenu={closeMenu}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <MenuItem
                                        sx={{ fontSize: 14, fontWeight: 500, color: '#64748B' }}
                                        onClick={handleOpenTaskModal}
                                    >
                                        Assign to
                                    </MenuItem>

                                    <MenuItem
                                        sx={{ fontSize: 14, fontWeight: 500, color: '#64748B' }}
                                        onClick={handleOpenExemptModal}
                                    >
                                        Exempt
                                    </MenuItem>
                                </AppTableActionMenu>
                            </>
                        ) : null
                    ) : (
                        <>
                            {organization?.merchant_plan?.name !== 'free_version' &&
                                organization?.merchant_plan?.name !== '3_days_trial' && (
                                    <IconButton
                                        sx={{ color: theme.palette.gray[900], minWidth: 'unset', p: 0 }}
                                        onClick={openMenu}
                                    >
                                        <MoreVert
                                            sx={{
                                                color: '#475569',
                                            }}
                                        />
                                    </IconButton>
                                )}
                            <AppTableActionMenu
                                anchor={anchor}
                                closeMenu={closeMenu}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem
                                    sx={{ fontSize: 14, fontWeight: 500, color: '#64748B' }}
                                    onClick={handleOpenRevertModal}
                                >
                                    Revert
                                </MenuItem>
                            </AppTableActionMenu>
                        </>
                    ))}
            </Stack>
            <Typography component="p" sx={{ fontSize: 12, fontWeight: 500, color: '#64748B' }}>
                {title}
            </Typography>
        </GroupCard>
    );
});

export default RequirementCard;
