/* eslint-disable no-unused-vars */

import { Dialog } from '@mui/material';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { extractDocName, shortenDocumentTitle } from '../utils';
import PolicyEditorDrawer from './PolicyEditorDrawer';
import ViewDocumentDialog from './ViewDocumentDialog';

export default function DownloadTemplateDialog({
    isOpen,
    setIsOpen,
    setIsUsePolicyEditorDrawerOpen,
    standard,
    templates,
    isPaid,
    currentRequirement,
    fetchDocReviewStats,
}) {
    const [document, setDocument] = useState(null);
    const [isViewDocumentDialogOpen, setIsViewDocumentDialogOpen] = useState(false);
    const [isPolicyEditorDrawerOpen, setIsPolicyEditorDrawerOpen] = useState(false);

    const all_merchant_documents = useSelector((state) => state?.complianceReducers?.all_merchant_documents);

    const allDocuments = useMemo(
        () =>
            all_merchant_documents
                ?.filter((doc) => doc?.tag === standard)
                ?.map((doc) => ({ ...doc, default: doc?.document })),
        [all_merchant_documents]
    );

    function handleClose() {
        setIsOpen(false);
        setDocument(null);
    }

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        borderRadius: '4px !important',
                        padding: 0,
                        boxShadow: '0px 16px 24px 0px #00000026',
                        width: '650px',
                    },
                }}
            >
                <div className="flex items-center justify-between !px-6 !pt-6">
                    <h5 className="text-lg font-semibold text-[#202D66]">Templates</h5>

                    <button
                        className="border-[#E2E8F0] border rounded-full !p-1 !h-6 !w-6 grid place-items-center hover:bg-[#E2E8F0] transition-colors"
                        onClick={handleClose}
                    >
                        <img src="/img/automated-scan/close.svg" alt="close icon" className="object-contain" />
                    </button>
                </div>

                <div className="!p-6 flex flex-col !gap-4">
                    <p className="text-[#64748B] text-sm">
                        Policy statements in this requirement are divided into{' '}
                        <span className="font-bold">{templates?.length}</span> templates
                    </p>

                    {allDocuments?.length > 0 ? (
                        <div className="bg-[#FFFBEB] !border !border-[#FDE68A] !rounded flex !gap-2 !px-3 !py-1 items-start">
                            <img src="/img/frameworks/danger.svg" alt="Danger Icon" className="!mt-0.5 !w-5 !h-5" />

                            <p className="text-[#D97706] font-normal text-sm leading-5">
                                If you already have files containing all the necessary policy statements, we advise you
                                to{' '}
                                <span
                                    className="font-semibold underline decoration-[#D97706] cursor-pointer transition-all hover:no-underline"
                                    role="button"
                                    onClick={() => {
                                        handleClose();
                                        setIsUsePolicyEditorDrawerOpen(true);
                                    }}
                                >
                                    click here
                                </span>{' '}
                                instead of using the blank templates listed below.
                            </p>
                        </div>
                    ) : null}

                    <div className="flex flex-col !gap-4 custom-scroll overflow-y-auto !max-h-[425px] !pr-1">
                        {templates?.map((template) => (
                            <RenderTemplate
                                key={template.id}
                                isPaid={isPaid}
                                template={template}
                                setDocument={setDocument}
                                setIsViewDocumentDialogOpen={setIsViewDocumentDialogOpen}
                                setIsPolicyEditorDrawerOpen={setIsPolicyEditorDrawerOpen}
                                setIsOpen={setIsOpen}
                            />
                        ))}
                    </div>
                </div>
            </Dialog>

            <ViewDocumentDialog
                document={document}
                isOpen={isViewDocumentDialogOpen}
                handleCallback={() => {
                    setIsViewDocumentDialogOpen(false);
                    setDocument(null);
                }}
            />

            <PolicyEditorDrawer
                isOpen={isPolicyEditorDrawerOpen}
                setIsOpen={setIsPolicyEditorDrawerOpen}
                handleCallback={() => {
                    handleClose();
                    fetchDocReviewStats();
                }}
                mode="create"
                standard={standard}
                templates={templates}
                currentRequirement={currentRequirement}
                document={document}
                filename={extractDocName(document?.default)?.replaceAll('_', ' ')}
            />
        </>
    );
}

function RenderTemplate({
    isPaid,
    template,
    setDocument,
    setIsViewDocumentDialogOpen,
    setIsPolicyEditorDrawerOpen,
    setIsOpen,
}) {
    return (
        <div
            className="!border !border-[#E2E8F0] !rounded-lg !py-2 !px-4 flex items-center justify-between bg-white transition-all hover:brightness-95 cursor-pointer"
            role="button"
            onClick={() => {
                setDocument(template);
                isPaid ? setIsPolicyEditorDrawerOpen(true) : setIsViewDocumentDialogOpen(true);
                setIsOpen(false);
            }}
        >
            <div className="flex flex-col !gap-1">
                <p className="text-[#475569] font-medium text-sm">
                    {shortenDocumentTitle(extractDocName(template?.default?.replaceAll('_', ' ')))}
                </p>

                <p className="!border !border-[#E2E8F0] !rounded-full !py-1 !px-2.5 text-[#64748B] font-medium text-[13px] !w-fit">
                    {template?.policy_statements_count} policy statements
                </p>
            </div>

            <button className="bg-white !border !border-[#E2E8F0] !rounded-lg !py-1 !px-3 text-[#64748B] font-medium text-sm flex items-center">
                {isPaid ? 'Use editor' : 'View'}
            </button>
        </div>
    );
}
