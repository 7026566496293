import { ClickAwayListener, Popper } from '@mui/material';
import { Table } from 'lucide-react';
import { useState } from 'react';

export default function TableTool({ editorRef }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [tableRows, setTableRows] = useState('2');
    const [tableColumns, setTableColumns] = useState('2');

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    function handleClick(event) {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }

    function handleTableCreate() {
        if (isNaN(parseInt(tableRows)) || isNaN(parseInt(tableColumns))) return;

        if (editorRef.current) {
            const quill = editorRef.current;
            const betterTable = quill.getModule('better-table');
            const scrollY = window.scrollY;

            betterTable.insertTable(parseInt(tableRows), parseInt(tableColumns));

            setAnchorEl(null);
            setTableRows('2');
            setTableColumns('2');

            window.scrollTo(0, scrollY);
        }
    }

    return (
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <div>
                <button
                    aria-describedby={id}
                    className="!h-7 !w-7 !rounded bg-white text-[#1E293B] grid place-items-center transition-all hover:brightness-95"
                    onClick={handleClick}
                >
                    <Table className="!h-5 !w-5" />
                </button>

                <Popper
                    id={id}
                    anchorEl={anchorEl}
                    placement="bottom"
                    open={open}
                    sx={{
                        zIndex: 1200,
                        borderRadius: '4px',
                        boxShadow: '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                        backgroundColor: '#FFFFFF',
                    }}
                >
                    <div className="flex w-fit flex-col !gap-2 !p-2">
                        <div className="flex flex-col !gap-1">
                            <span className="text-xs">Rows</span>

                            <input
                                type="text"
                                inputMode="numeric"
                                className="!w-full !h-9 !p-2 !border !border-[#E2E8F0] text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                value={tableRows}
                                onChange={(event) => {
                                    const value = parseInt(event.target.value);

                                    if (isNaN(value)) {
                                        setTableRows('');
                                        return;
                                    }

                                    if (value < 2) {
                                        setTableRows('2');
                                        return;
                                    }

                                    if (value > 50) {
                                        setTableRows('50');
                                        return;
                                    }

                                    setTableRows(event.target.value);
                                }}
                            />
                        </div>

                        <div className="flex flex-col !gap-1">
                            <span className="text-xs">Columns</span>

                            <input
                                type="text"
                                inputMode="numeric"
                                className="!w-full !h-9 !p-2 !border !border-[#E2E8F0] text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                value={tableColumns}
                                onChange={(event) => {
                                    const value = parseInt(event.target.value);

                                    if (isNaN(value)) {
                                        setTableColumns('');
                                        return;
                                    }

                                    if (value < 2) {
                                        setTableColumns('2');
                                        return;
                                    }

                                    if (value > 6) {
                                        setTableColumns('6');
                                        return;
                                    }

                                    setTableColumns(event.target.value);
                                }}
                            />
                        </div>

                        <div className="!mt-1 flex items-center justify-between !gap-2">
                            <button
                                className="flex-1 bg-[#fff] !border !border-[#E2E8F0] !rounded !px-2 !py-2 hover:bg-red-500 hover:text-white transition-colors text-[#334155] text-sm font-medium"
                                onClick={() => setAnchorEl(null)}
                            >
                                Cancel
                            </button>

                            <button
                                className="flex-1 bg-[#202D66] !rounded !px-2 !py-2 hover:bg-[#151e44] transition-all text-white text-sm font-semibold disabled:opacity-50 disabled:pointer-events-none"
                                onClick={handleTableCreate}
                            >
                                Create
                            </button>
                        </div>
                    </div>
                </Popper>
            </div>
        </ClickAwayListener>
    );
}
