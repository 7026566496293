import { Route, Switch } from 'react-router-dom';
// import FormCreation from '../Operations/FormCreation';
// import FormsAndDocumentsView from './FormsAndDocumentsView';
import Soc2Dashboard from './Soc2Dashboard';
import SubcontrolsView from './SubcontrolsView';

const SOC2 = () => {
    return (
        <Switch>
            <Route path="/admin/soc2" exact component={Soc2Dashboard} />
            <Route path="/admin/soc2/subcontrols" exact component={SubcontrolsView} />
            {/* <Route path="/admin/soc2/subcontrols/items" exact component={FormsAndDocumentsView} />
            <Route
                path="/admin/soc2/subcontrols/items/form"
                component={(props) => <FormCreation tag="soc2" {...props} />}
            /> */}
        </Switch>
    );
};

export default SOC2;
