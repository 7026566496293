/* eslint-disable no-unused-vars */

import { Menu, MenuItem, Skeleton } from '@mui/material';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import AcceptanceHistoryDrawer from './AcceptanceHistoryDrawer';
import AssignToDialog from './AssignToDialog';
import AwarenessDialog from './AwarenessDialog';
import DownloadTemplateDialog from './DownloadTemplateDialog';
import ExemptRequirementDialog from './ExemptRequirementDialog';
import PolicyHistoryDrawer from './PolicyHistoryDrawer';
import UseExistingPolicyDialog from './UseExistingPolicyDialog';
import UsePolicyEditorDrawer from './UsePolicyEditorDrawer';
import UseUploadedFileDrawer from './UseUploadedFileDrawer';

export default function TitleContent({
    currentRequirement,
    title,
    subTitle,
    standard,
    templates,
    formattedDocReviewStats,
    assignToRequirementData,
    exemptRequirementData,
    isLoading,
    fetchDocReviewStats,
    isUsePolicyEditorDrawerOpen,
    setIsUsePolicyEditorDrawerOpen,
    isUseUploadedFileDrawerOpen,
    setIsUseUploadedFileDrawerOpen,
    defaultTab,
    setDefaultTab,
}) {
    const [startAnchorEl, setStartAnchorEl] = useState(null);
    const [moreAnchorEl, setMoreAnchorEl] = useState(null);
    const [isUseExistingPolicyDialogOpen, setIsUseExistingPolicyDialogOpen] = useState(false);
    const [isAssignToDialogOpen, setIsAssignToDialogOpen] = useState(false);
    const [isExemptRequirementDialogOpen, setIsExemptRequirementDialogOpen] = useState(false);
    const [isAcceptanceHistoryDrawerOpen, setIsAcceptanceHistoryDrawerOpen] = useState(false);
    const [isPolicyHistoryDrawerOpen, setIsPolicyHistoryDrawerOpen] = useState(false);
    const [isDownloadTemplateDialogOpen, setIsDownloadTemplateDialogOpen] = useState(false);
    const [isAwarenessDialogOpen, setIsAwarenessDialogOpen] = useState(false);

    const all_addons = useSelector((state) => state?.generalReducers?.all_addons);
    const all_merchant_documents = useSelector((state) => state?.complianceReducers?.all_merchant_documents);

    const isPaid = all_addons?.[0]?.policy_editor?.status === 'approved';

    const totalImplemented = useMemo(
        () => formattedDocReviewStats?.filter((stat) => stat?.status === 'implemented')?.length,
        [formattedDocReviewStats]
    );

    const totalObjectives = useMemo(() => formattedDocReviewStats?.length, [formattedDocReviewStats]);

    const allDocuments = useMemo(
        () =>
            all_merchant_documents
                ?.filter((doc) => doc?.tag === standard)
                ?.map((doc) => ({ ...doc, default: doc?.document })),
        [all_merchant_documents]
    );

    const objectivesStatus = useMemo(() => {
        if (totalImplemented === totalObjectives) {
            return 'All implemented';
        } else {
            return 'In progress';
        }
    }, [totalImplemented, totalObjectives]);

    return (
        <>
            <div className="flex items-center justify-between !p-5 !border-b !border-[#E2E8F0]">
                <div className="flex items-center !gap-2">
                    <h4 className="text-[#1E293B] font-semibold">{title || 'Requirement'} objectives</h4>

                    {isLoading ? (
                        <Skeleton variant="rounded" width={79} height={22} />
                    ) : (
                        <p
                            className={`bg-[#F8FAFC] !border !border-[#CBD5E1] !rounded-sm !py-0.5 !px-1.5 text-xs ${
                                objectivesStatus === 'All implemented'
                                    ? 'text-[#37A372]'
                                    : allDocuments?.length > 0
                                    ? 'text-[#D2A500]'
                                    : 'text-[#475569]'
                            }`}
                        >
                            {allDocuments?.length > 0 ? objectivesStatus : 'Not started'}
                        </p>
                    )}
                </div>

                <div className="flex items-center !gap-3">
                    {isLoading ? (
                        <Skeleton variant="rounded" width={138} height={18} />
                    ) : (
                        <p className="!px-2 text-[#475569] text-sm">
                            <span className="font-bold">
                                {totalImplemented}/{totalObjectives}
                            </span>{' '}
                            implemented
                        </p>
                    )}

                    <div>
                        <button
                            aria-controls={startAnchorEl ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={startAnchorEl ? 'true' : undefined}
                            onClick={(event) => {
                                event.stopPropagation();
                                setStartAnchorEl(event.currentTarget);
                            }}
                            className="!border-[1.5px] !border-[#395BA9] bg-white !rounded !px-2 !py-1.5 flex items-center !gap-1 font-medium text-sm text-[#395BA9] transition-all hover:brightness-95"
                        >
                            <span>{allDocuments?.length > 0 ? 'Continue' : 'Start'}</span>

                            <img
                                src="/img/frameworks/material-symbols_keyboard-arrow-down-rounded.svg"
                                alt="chevron down"
                                className="!w-4 !h-4"
                            />
                        </button>

                        <Menu
                            anchorEl={startAnchorEl}
                            open={Boolean(startAnchorEl)}
                            onClose={(event) => {
                                event.stopPropagation();
                                setStartAnchorEl(null);
                            }}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow:
                                        '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                                },
                                '& .MuiList-root': {
                                    padding: '0 0',
                                },
                            }}
                        >
                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setStartAnchorEl(null);
                                    isPaid ? setIsUsePolicyEditorDrawerOpen(true) : setIsAwarenessDialogOpen(true);
                                }}
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    textTransform: 'none',
                                    padding: '12px 52px 12px 16px',
                                }}
                            >
                                <div className="relative">
                                    <span>Use policy editor</span>

                                    <span className="absolute !-top-1 !-right-9 !border !border-[#395BA9] !rounded-sm leading-3 grid place-items-center !py-px !px-1 font-semibold text-[9px]">
                                        BETA
                                    </span>
                                </div>
                            </MenuItem>

                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setStartAnchorEl(null);
                                    setIsUseUploadedFileDrawerOpen(true);
                                }}
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    textTransform: 'none',
                                    padding: '8px 16px',
                                }}
                            >
                                Use uploaded file
                            </MenuItem>

                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setStartAnchorEl(null);
                                    setIsUseExistingPolicyDialogOpen(true);
                                }}
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    textTransform: 'none',
                                    padding: '8px 16px',
                                }}
                            >
                                Use existing policy
                            </MenuItem>
                        </Menu>
                    </div>

                    <div className="flex items-center !pl-2 !border-l !border-[#CBD5E1] !gap-2">
                        <button
                            className="transition-all hover:brightness-95 !border !border-[#E2E8F0] bg-white !rounded-full !py-1 !px-2.5 text-[#475569] font-medium text-[13px]"
                            onClick={() => setIsAssignToDialogOpen(true)}
                        >
                            Assign
                        </button>

                        <button
                            className="transition-all hover:brightness-95 !border !border-[#E2E8F0] bg-white !rounded-full !py-1 !px-2.5 text-[#937300] font-medium text-[13px]"
                            onClick={() => setIsExemptRequirementDialogOpen(true)}
                        >
                            Exempt
                        </button>

                        <div>
                            <button
                                aria-controls={moreAnchorEl ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={moreAnchorEl ? 'true' : undefined}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setMoreAnchorEl(event.currentTarget);
                                }}
                                className="transition-all hover:brightness-95 !border !border-[#E2E8F0] bg-white !rounded-lg !py-1.5 !px-3"
                            >
                                <img src="/img/frameworks/more.svg" alt="view more" className="!h-4 !w-1" />
                            </button>

                            <Menu
                                anchorEl={moreAnchorEl}
                                open={Boolean(moreAnchorEl)}
                                onClose={(event) => {
                                    event.stopPropagation();
                                    setMoreAnchorEl(null);
                                }}
                                sx={{
                                    '& .MuiPaper-root': {
                                        borderRadius: '4px',
                                        boxShadow:
                                            '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                                    },
                                    '& .MuiList-root': {
                                        padding: '0 0',
                                    },
                                }}
                            >
                                {allDocuments?.length > 0 && !allDocuments?.every((doc) => !doc?.policy_versions) ? (
                                    <MenuItem
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setMoreAnchorEl(null);
                                            setIsPolicyHistoryDrawerOpen(true);
                                        }}
                                        sx={{
                                            color: '#64748B',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            textTransform: 'none',
                                            padding: '8px 16px',
                                        }}
                                    >
                                        Policy version
                                    </MenuItem>
                                ) : null}

                                {allDocuments?.length > 0 && !allDocuments?.every((doc) => !doc?.policy_versions) ? (
                                    <MenuItem
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setMoreAnchorEl(null);
                                            setIsAcceptanceHistoryDrawerOpen(true);
                                        }}
                                        sx={{
                                            color: '#64748B',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            textTransform: 'none',
                                            padding: '8px 16px',
                                        }}
                                    >
                                        Acceptance history
                                    </MenuItem>
                                ) : null}

                                <MenuItem
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setMoreAnchorEl(null);
                                        setIsDownloadTemplateDialogOpen(true);
                                    }}
                                    sx={{
                                        color: '#64748B',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        textTransform: 'none',
                                        padding: '8px 16px',
                                    }}
                                >
                                    View templates
                                </MenuItem>
                            </Menu>
                        </div>
                    </div>
                </div>
            </div>

            <AwarenessDialog isOpen={isAwarenessDialogOpen} setIsOpen={setIsAwarenessDialogOpen} />

            <UsePolicyEditorDrawer
                isOpen={isUsePolicyEditorDrawerOpen}
                setIsOpen={setIsUsePolicyEditorDrawerOpen}
                mode={allDocuments?.length > 0 ? 'update' : 'create'}
                standard={standard}
                templates={templates}
                currentRequirement={currentRequirement}
                title={title}
                fetchDocReviewStats={fetchDocReviewStats}
            />

            <UseUploadedFileDrawer
                isPaid={isPaid}
                isOpen={isUseUploadedFileDrawerOpen}
                setIsOpen={setIsUseUploadedFileDrawerOpen}
                setIsUsePolicyEditorDrawerOpen={setIsUsePolicyEditorDrawerOpen}
                standard={standard}
                templates={templates}
                currentRequirement={currentRequirement}
                title={title}
                fetchDocReviewStats={fetchDocReviewStats}
                defaultTab={defaultTab}
                setDefaultTab={setDefaultTab}
            />

            <UseExistingPolicyDialog
                isOpen={isUseExistingPolicyDialogOpen}
                setIsOpen={setIsUseExistingPolicyDialogOpen}
                isPaid={isPaid}
                standard={standard}
                currentRequirement={currentRequirement}
                title={title}
                fetchDocReviewStats={fetchDocReviewStats}
            />

            <AssignToDialog
                isOpen={isAssignToDialogOpen}
                handleCallback={() => setIsAssignToDialogOpen(false)}
                requirementData={assignToRequirementData}
            />

            <ExemptRequirementDialog
                title={title}
                subTitle={subTitle}
                isOpen={isExemptRequirementDialogOpen}
                handleCallback={() => setIsExemptRequirementDialogOpen(false)}
                requirementData={exemptRequirementData}
                goBack={true}
            />

            <AcceptanceHistoryDrawer
                isOpen={isAcceptanceHistoryDrawerOpen}
                setIsOpen={setIsAcceptanceHistoryDrawerOpen}
                standard={standard}
            />

            <PolicyHistoryDrawer
                isOpen={isPolicyHistoryDrawerOpen}
                setIsOpen={setIsPolicyHistoryDrawerOpen}
                standard={standard}
            />

            <DownloadTemplateDialog
                isOpen={isDownloadTemplateDialogOpen}
                setIsOpen={setIsDownloadTemplateDialogOpen}
                setIsUsePolicyEditorDrawerOpen={setIsUsePolicyEditorDrawerOpen}
                standard={standard}
                templates={templates}
                isPaid={isPaid}
                currentRequirement={currentRequirement}
                fetchDocReviewStats={fetchDocReviewStats}
            />
        </>
    );
}
