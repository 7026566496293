/* eslint-disable no-unused-vars */

import { Dialog, Menu, MenuItem } from '@mui/material';
import { Loader2 } from 'lucide-react';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { GetAllCompanyEmployees } from 'store/actions/generalActions';
import { AssignComplianceTask } from 'store/actions/taskActions';

export default function AssignToDialog({ requirementData, isOpen, handleCallback }) {
    const [users, setUsers] = useState([]);
    const [comment, setComment] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({
        users: '',
        dateTime: '',
    });
    const [usersAnchorEl, setUsersAnchorEl] = useState(null);
    const [search, setSearch] = useState('');

    const dispatch = useDispatch();

    const raw_all_employees = useSelector((state) => state?.generalReducers?.all_employees);
    const user_id = useSelector((state) => state?.authReducers?.user_id);
    const employee_id = useSelector((state) => state?.authReducers?.user_info?.employee?.id);

    const all_employees = useMemo(
        () =>
            raw_all_employees
                ?.filter((employee) => employee?.id !== employee_id)
                ?.map((employee) => ({
                    ...employee,
                    name: employee?.user_detail?.first_name
                        ? `${employee?.user_detail?.first_name} ${employee?.user_detail?.last_name}`
                        : employee?.user_detail?.email,
                }))
                ?.filter((employee) => employee?.name?.toLowerCase().includes(search.toLowerCase())),
        [raw_all_employees, search]
    );

    function validateForm() {
        const errors = {
            users: '',
            dateTime: '',
        };

        let isValid = true;

        if (!users.length) {
            errors.users = 'Please select at least one user';
            isValid = false;
        }

        if (!date || !time) {
            errors.dateTime = 'Please select a date and time';
            isValid = false;
        }

        setError(errors);

        return isValid;
    }

    async function handleSubmit() {
        if (!validateForm()) return;

        try {
            setIsLoading(true);

            const res = await dispatch(
                AssignComplianceTask({
                    assigned_to: users?.map((user) => user?.id),
                    comments: comment,
                    assigned_by: user_id,
                    tag: 'compliance',
                    completion_status: 'not_completed',
                    mark_as_completed: false,
                    ...requirementData,
                    due_date: `${moment(date).format('YYYY-MM-DD')} ${time}:00`,
                })
            );

            if (res?.success) {
                toast.success('Task has been assigned to the employee(s)');
                handleClose();
            } else {
                throw new Error(res?.message);
            }
        } catch (error) {
            console.error(error);
            toast.error(error?.message || "Something went wrong, couldn't assign task to employee(s)");
        } finally {
            setIsLoading(false);
        }
    }

    function handleClose() {
        handleCallback();
        setUsers([]);
        setComment('');
        setDate('');
        setTime('');
        setError({
            users: '',
            comment: '',
            dateTime: '',
        });
        setSearch('');
    }

    useEffect(() => {
        const getAllCompanyEmployees = async () => {
            setIsLoading(true);
            const res = await dispatch(GetAllCompanyEmployees());
            if (res?.success) {
                setIsLoading(false);
            } else {
                setIsLoading(false);
                toast.error(res?.message);
            }
        };
        getAllCompanyEmployees();
    }, []);

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: '4px !important',
                    padding: 0,
                    boxShadow: '0px 16px 24px 0px #00000026',
                    width: '511px',
                },
            }}
        >
            <div className="flex items-center justify-between !px-6 !pt-6">
                <h5 className="text-lg font-semibold text-[#202D66]">Assign to</h5>

                <button
                    className="border-[#E2E8F0] border rounded-full !p-1 !h-6 !w-6 grid place-items-center hover:bg-[#E2E8F0] transition-colors"
                    onClick={handleClose}
                >
                    <img src="/img/automated-scan/close.svg" alt="close icon" className="object-contain" />
                </button>
            </div>

            <div className="!p-6 flex flex-col !gap-4">
                <div className="flex flex-col !gap-2">
                    <p className="font-medium text-[13px] text-[#64748B]">User</p>

                    <div>
                        <div
                            className="w-full flex flex-wrap !gap-2 !border !border-[#E2E8F0] !rounded-sm !px-2 !py-[5px] focus-within:ring-2 focus-within:ring-[#395BA9] focus-within:ring-opacity-75 cursor-pointer"
                            aria-controls={usersAnchorEl ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={usersAnchorEl ? 'true' : undefined}
                            onClick={(event) => {
                                event.stopPropagation();
                                setUsersAnchorEl(event.currentTarget);
                            }}
                        >
                            {users?.length ? (
                                <>
                                    {users?.map((user, index) => (
                                        <button
                                            key={index}
                                            className="flex items-center gap-2 !border !border-[#E2E8F0] bg-[#F1F5F9] !rounded !py-0 !px-2 hover:brightness-90 transition-all h-6"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setUsers((prev) => prev.filter((item) => item?.name !== user?.name));
                                            }}
                                        >
                                            <p className="font-normal capitalize text-sm text-[#64748B]">
                                                {user?.name}
                                            </p>

                                            <img
                                                src="/img/risk-assessment/x-close-small.svg"
                                                alt="Delete Icon"
                                                className="object-contain"
                                            />
                                        </button>
                                    ))}
                                </>
                            ) : (
                                <span className="!w-auto focus-visible:outline-none !rounded-none !p-0 h-6 !border-none text-sm font-normal cursor-pointer self-center flex items-center text-[#94A3B8]">
                                    {users?.length === 0 && 'Select user(s)'}
                                </span>
                            )}
                        </div>

                        <Menu
                            anchorEl={usersAnchorEl}
                            open={Boolean(usersAnchorEl)}
                            onClose={() => {
                                setUsersAnchorEl(null);
                            }}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                },
                                '& .MuiList-root': {
                                    padding: 0,
                                },
                            }}
                            PaperProps={{
                                style: {
                                    width: 300,
                                },
                            }}
                        >
                            <div className="min-h-[200px] max-h-[300px] overflow-auto custom-scroll">
                                <div className="!mx-2 !mt-4">
                                    <div
                                        className="relative"
                                        onKeyDown={(event) => {
                                            event.stopPropagation();
                                        }}
                                    >
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="w-full !py-2.5 !pr-2.5 !pl-8 !border !border-[#E2E8F0] !h-7 text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                            value={search}
                                            onChange={(event) => {
                                                event.stopPropagation();
                                                setSearch(event.target.value);
                                            }}
                                        />

                                        <img
                                            src="/img/risk-assessment/ri_search-line-small.svg"
                                            alt="Search Icon"
                                            className="absolute object-contain top-1/2 -translate-y-1/2 left-[10px]"
                                        />
                                    </div>
                                </div>

                                {all_employees?.length ? (
                                    <div className="!p-2 flex flex-col min-w-[224px]">
                                        {all_employees
                                            ?.filter((employee) => !users?.includes(employee))
                                            ?.map((employee, index) => (
                                                <MenuItem
                                                    key={index}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setUsers((prev) => [...prev, employee]);
                                                        error?.users && setError((prev) => ({ ...prev, users: '' }));
                                                    }}
                                                    sx={{
                                                        color: '#64748B',
                                                        fontWeight: 500,
                                                        fontSize: '13px',
                                                        textTransform: 'capitalize',
                                                        padding: '6px 8px',
                                                    }}
                                                >
                                                    {employee?.name}
                                                </MenuItem>
                                            ))}
                                    </div>
                                ) : (
                                    <p className="flex items-center justify-center !my-4 text-sm text-[#64748B] font-medium">
                                        User not found
                                    </p>
                                )}
                            </div>
                        </Menu>
                    </div>

                    {error?.users && !users?.length && (
                        <span className="text-[10px] !-mt-1 text-[#FF0000] font-normal">{error?.users}</span>
                    )}
                </div>

                <div className="flex flex-col !gap-2">
                    <textarea
                        className="w-full !p-2 !border !border-[#E2E8F0] !min-h-[76px] text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                        placeholder="Comment"
                        value={comment}
                        onChange={(event) => {
                            setComment(event.target.value);
                        }}
                    ></textarea>
                </div>

                <div className="flex flex-col !gap-2">
                    <p className="font-medium text-[13px] text-[#64748B]">Set deadline for this task</p>

                    <div className="flex items-center !gap-2">
                        <input
                            type="date"
                            placeholder="Date"
                            value={date}
                            onChange={(event) => {
                                setDate(event.target.value);
                                error?.dateTime && setError((prev) => ({ ...prev, dateTime: '' }));
                            }}
                            className="text-sm flex-1 font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 !h-9 !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                        />

                        <input
                            type="time"
                            placeholder="Time"
                            value={time}
                            onChange={(event) => {
                                setTime(event.target.value);
                                error?.dateTime && setError((prev) => ({ ...prev, dateTime: '' }));
                            }}
                            className="text-sm flex-1 font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 !h-9 !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                        />
                    </div>

                    {error?.dateTime && (!date || !time) && (
                        <span className="text-[10px] !-mt-1 text-[#FF0000] font-normal">{error?.dateTime}</span>
                    )}
                </div>

                <div className="bg-[#F8FAFC] !border !border-[#F0F0FA] !rounded flex !gap-2 !px-3 !py-2 items-start">
                    <img src="/img/frameworks/lightbulb.svg" alt="Bulb Icon" className="!mt-0.5 !w-6 !h-6" />

                    <p className="text-[#64748B] font-normal text-[13px] leading-5">
                        You can view tasks you assigned to users on the task board page of the secure application.
                    </p>
                </div>
            </div>

            <div className="flex items-center justify-end !p-6 bg-[#F8FAFC] !gap-2">
                <button
                    className="bg-[#fff] border border-[#E2E8F0] rounded-sm !px-4 !py-2 hover:bg-red-500 hover:text-white transition-colors text-[#334155] text-sm font-medium"
                    onClick={handleClose}
                >
                    Cancel
                </button>

                <button
                    className="bg-[#202D66] rounded-sm !px-4 !py-2 hover:bg-[#151e44] transition-all text-white text-sm font-semibold disabled:opacity-50 disabled:pointer-events-none flex items-center !gap-2"
                    disabled={isLoading}
                    onClick={handleSubmit}
                >
                    {isLoading && <Loader2 className="!h-4 !w-4 animate-spin" />}
                    Assign
                </button>
            </div>
        </Dialog>
    );
}
