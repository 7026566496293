/* eslint-disable no-unused-vars */

import { Drawer, Slide } from '@mui/material';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { extractDocName, shortenDocumentTitle } from '../utils';
import PolicyEditorDrawer from './PolicyEditorDrawer';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

export default function UsePolicyEditorDrawer({
    isOpen,
    setIsOpen,
    mode,
    standard,
    templates,
    currentRequirement,
    title,
    fetchDocReviewStats,
}) {
    const [document, setDocument] = useState(null);
    const [role, setRole] = useState('');
    const [isPolicyEditorDrawerOpen, setIsPolicyEditorDrawerOpen] = useState(false);

    const all_merchant_documents = useSelector((state) => state?.complianceReducers?.all_merchant_documents);
    const user_id = useSelector((state) => state?.authReducers?.user_id);

    const allDocuments = useMemo(
        () =>
            all_merchant_documents
                ?.filter((doc) => doc?.tag === standard && doc?.current)
                ?.map((doc) => ({ ...doc, default: doc?.document })),
        [all_merchant_documents]
    );

    function handleClose() {
        setIsOpen(false);
        setDocument(null);
        setRole('');
    }

    useEffect(() => {
        if (isOpen) {
            setDocument(null);
            setRole('');
        }
    }, [isOpen]);

    return (
        <>
            <Drawer
                open={isOpen}
                anchor="right"
                TransitionComponent={Transition}
                transitionDuration={1000}
                keepMounted
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                        width: '620px',
                        flex: 1,
                        overflow: 'auto',
                        zIndex: 9999,
                    },
                }}
                scroll="paper"
            >
                <div className="border-b border-[#F1F5F9] !p-4 flex items-center justify-between">
                    <div className="flex items-center !gap-4">
                        <img
                            src="/img/automated-scan/arrow-left-icon.svg"
                            alt="arrow-left-icon"
                            onClick={handleClose}
                            className="cursor-pointer object-contain"
                        />

                        <div className="relative">
                            <h3 className="text-[#002C72] capitalize font-semibold text-lg">Use policy editor</h3>

                            <span className="absolute !-top-1 !-right-9 !border !border-[#395BA9] !rounded-sm leading-3 grid place-items-center !py-px !px-1 font-semibold text-[9px]">
                                BETA
                            </span>
                        </div>
                    </div>
                </div>

                <div className="!py-4 flex flex-col !gap-4">
                    <div className="bg-[#F8FAFC] !border !border-[#F0F0FA] !rounded flex !gap-1 !mx-4 !px-4 !py-2 items-start">
                        <img src="/img/frameworks/lightbulb.svg" alt="Bulb Icon" className="!mt-0.5 !w-6 !h-6" />

                        <p className="text-[#395BA9] font-normal text-[13px] leading-5">
                            {mode === 'create'
                                ? `All policy statements in this ${title} can be found in the listed templates below. Open each and implement the policy statements needed.`
                                : `All your recent file version that contains the ${title} policy statements are found here. You can simply edit them with the policy editor. `}
                        </p>
                    </div>

                    <div className="!px-4 !h-[calc(100vh-167px)] overflow-y-auto custom-scroll">
                        <div className="!border !border-[#E2E8F0] !rounded-lg !flex !flex-col !gap-4 !p-4">
                            {mode === 'create'
                                ? templates?.map((template) => {
                                      return (
                                          <div
                                              key={template.id}
                                              className="!border !border-[#E2E8F0] bg-white !rounded-lg !py-2 !px-4 flex items-center justify-between cursor-pointer transition-all hover:brightness-95"
                                              role="button"
                                              onClick={() => {
                                                  setDocument(template);
                                                  setIsPolicyEditorDrawerOpen(true);
                                              }}
                                          >
                                              <div className="flex flex-col !gap-1">
                                                  <p className="text-[#475569] font-medium text-sm">
                                                      {shortenDocumentTitle(
                                                          extractDocName(template?.default?.replaceAll('_', ' '))
                                                      )}
                                                  </p>

                                                  <div className="!py-1 text-[#64748B] font-medium text-[13px] flex items-center !gap-1">
                                                      <p>
                                                          {template?.policy_statements_count} policy statements
                                                          available
                                                      </p>
                                                  </div>
                                              </div>

                                              <button className="!border !border-[#E2E8F0] !rounded-lg !py-1 !px-3 text-[#64748B] font-medium text-sm">
                                                  Open
                                              </button>
                                          </div>
                                      );
                                  })
                                : allDocuments?.map((doc) => {
                                      const isApprover =
                                          doc?.approved_by === user_id && doc?.policy_status === 'awaiting_approval';
                                      const isReviewer =
                                          doc?.reviewer === user_id && doc?.policy_status === 'awaiting_review';

                                      return (
                                          <div
                                              key={doc.id}
                                              className="relative !border !border-[#E2E8F0] bg-white !rounded-lg !py-2 !px-4 flex items-center justify-between cursor-pointer transition-all hover:brightness-95"
                                              role="button"
                                              onClick={() => {
                                                  setDocument(doc);
                                                  setRole(isReviewer ? 'reviewer' : isApprover ? 'approver' : '');
                                                  setIsPolicyEditorDrawerOpen(true);
                                              }}
                                          >
                                              {(isApprover || isReviewer) && (
                                                  <>
                                                      <div className="absolute !-left-1 !-top-1 !h-2 !w-2 rounded-full bg-[#FF5449]" />

                                                      <div className="absolute !-left-1 !-top-1 !h-2 !w-2 animate-ping rounded-full bg-[#FF5449] opacity-75" />
                                                  </>
                                              )}

                                              <div className="flex flex-col !gap-1">
                                                  <p className="text-[#475569] font-medium text-sm">
                                                      {shortenDocumentTitle(
                                                          extractDocName(
                                                              (doc?.filename || doc?.default)?.replaceAll('_', ' ')
                                                          )
                                                      )}{' '}
                                                      {doc?.version_no}
                                                  </p>

                                                  <div className="!py-1 text-[#64748B] font-medium text-[13px] flex items-center !gap-1">
                                                      <p>
                                                          {doc?.policy_info?.implemented?.length || 0} policy statements
                                                          available
                                                      </p>

                                                      {doc?.policy_status && (
                                                          <>
                                                              <span className="bg-[#94A3B8] !w-1 !h-1 !rounded-full" />

                                                              <p
                                                                  className={`capitalize ${
                                                                      doc?.policy_status === 'awaiting_approval'
                                                                          ? 'text-[#395BA9]'
                                                                          : doc?.policy_status === 'awaiting_review'
                                                                          ? 'text-[#D2A500]'
                                                                          : doc?.policy_status === 'approved'
                                                                          ? 'text-[#37A372]'
                                                                          : doc?.policy_status?.includes('rejected')
                                                                          ? 'text-[#FF5449]'
                                                                          : ''
                                                                  }`}
                                                              >
                                                                  {doc?.policy_status?.replaceAll('_', ' ')}
                                                              </p>
                                                          </>
                                                      )}
                                                  </div>
                                              </div>

                                              <button className="!border !border-[#E2E8F0] !rounded-lg !py-1 !px-3 text-[#64748B] font-medium text-sm">
                                                  Open
                                              </button>
                                          </div>
                                      );
                                  })}
                        </div>
                    </div>
                </div>
            </Drawer>

            <PolicyEditorDrawer
                isOpen={isPolicyEditorDrawerOpen}
                setIsOpen={setIsPolicyEditorDrawerOpen}
                handleCallback={() => {
                    handleClose();
                    fetchDocReviewStats();
                }}
                mode={mode}
                standard={standard}
                templates={templates}
                currentRequirement={currentRequirement}
                document={document}
                role={role}
                existingPolicyId={mode === 'update' ? document?.id : ''}
                filename={
                    mode === 'update'
                        ? extractDocName(document?.filename || document?.default)?.replaceAll('_', ' ')
                        : extractDocName(document?.default?.replaceAll('_', ' '))
                }
            />
        </>
    );
}
