/* eslint-disable no-unused-vars */

import { Menu, MenuItem } from '@mui/material';
import { useMemo, useState } from 'react';
import { shortenDocumentTitle } from '../../../utils';
import PolicyEditorDrawer from '../../PolicyEditorDrawer';
import ChangesDialog from './ChangesDialog';
import PolicyApprovalDrawer from './PolicyApprovalDrawer';

export default function NavBar({
    title,
    mode,
    currentPolicyDocument,
    setCurrentPolicyDocument,
    isPaid,
    handleClose,
    handleCallback,
    document,
    documentNotes,
    policyInformation,
    existingPolicyId,
    standard,
    showChangesDialog,
    fetchDocReviewStats,
    formattedDocReviewStats,
    useExistingPolicy,
    currentRequirement,
    isNativeFile,
    templates,
    filename,
}) {
    const [changePolicyDocumentAnchorEl, setChangePolicyDocumentAnchorEl] = useState(null);
    const [isChangesDialogOpen, setIsChangesDialogOpen] = useState(false);
    const [isPolicyApprovalDrawerOpen, setIsPolicyApprovalDrawerOpen] = useState(false);
    const [isPolicyEditorDrawerOpen, setIsPolicyEditorDrawerOpen] = useState(false);

    const policyDocuments = useMemo(
        () =>
            formattedDocReviewStats?.reduce((acc, curr) => {
                if (!acc.includes(curr?.document)) {
                    acc.push(curr?.document);
                }

                return acc;
            }, []),
        [formattedDocReviewStats]
    );

    return (
        <>
            <div className="border-b border-[#F1F5F9] !px-6 !py-4 flex items-center justify-between">
                <div className="flex items-center !gap-6">
                    <img
                        src="/img/automated-scan/arrow-left-icon.svg"
                        alt="arrow-left-icon"
                        onClick={handleClose}
                        className="cursor-pointer object-contain"
                    />

                    <h3 className="text-[#002C72] capitalize font-bold text-lg">
                        {title?.replaceAll('_', ' ') ||
                            (currentPolicyDocument === 'all'
                                ? 'All policy documents'
                                : currentPolicyDocument?.replaceAll('_', ' '))}
                    </h3>
                </div>

                <div className="flex items-center !gap-2">
                    {mode === 'view-gaps' && (
                        <div>
                            <button
                                aria-controls={changePolicyDocumentAnchorEl ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={changePolicyDocumentAnchorEl ? 'true' : undefined}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setChangePolicyDocumentAnchorEl(event.currentTarget);
                                }}
                                className="transition-all hover:brightness-95 bg-white !rounded !py-1.5 !px-3 flex items-center !gap-1.5 text-[#64748B] font-medium text-sm capitalize"
                            >
                                <span>
                                    {currentPolicyDocument === 'all'
                                        ? 'All policy documents'
                                        : shortenDocumentTitle(currentPolicyDocument)?.replaceAll('_', ' ')}
                                </span>

                                <img
                                    src="/img/frameworks/material-symbols_keyboard-arrow-down-rounded.svg"
                                    alt="chevron down"
                                    className="!w-4 !h-4"
                                />
                            </button>

                            <Menu
                                anchorEl={changePolicyDocumentAnchorEl}
                                open={Boolean(changePolicyDocumentAnchorEl)}
                                onClose={(event) => {
                                    event.stopPropagation();
                                    setChangePolicyDocumentAnchorEl(null);
                                }}
                                sx={{
                                    '& .MuiPaper-root': {
                                        borderRadius: '4px',
                                        boxShadow:
                                            '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                                    },
                                    '& .MuiList-root': {
                                        padding: '0 0',
                                    },
                                }}
                            >
                                <div className="max-h-[132px] max-w-[281px] overflow-auto custom-scroll">
                                    <MenuItem
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setChangePolicyDocumentAnchorEl(null);
                                            setCurrentPolicyDocument('all');
                                        }}
                                        sx={{
                                            color: '#64748B',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            textTransform: 'capitalize',
                                            padding: '6px 16px',
                                            border: '1px solid #F1F5F9',
                                        }}
                                    >
                                        All policy documents
                                    </MenuItem>

                                    {policyDocuments.map((document) => (
                                        <MenuItem
                                            key={document}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setChangePolicyDocumentAnchorEl(null);
                                                setCurrentPolicyDocument(document);
                                            }}
                                            sx={{
                                                color: '#64748B',
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                textTransform: 'capitalize',
                                                padding: '6px 16px',
                                                border: '1px solid #F1F5F9',
                                                whiteSpace: 'pre-wrap',
                                            }}
                                        >
                                            {document?.replaceAll('_', ' ')}
                                        </MenuItem>
                                    ))}
                                </div>
                            </Menu>
                        </div>
                    )}

                    {mode === 'upload' ? (
                        <button
                            className="transition-all hover:brightness-95 bg-[#202D66] !rounded !p-2.5 text-white font-medium text-sm"
                            onClick={() => {
                                if (showChangesDialog && existingPolicyId) {
                                    setIsChangesDialogOpen(true);
                                } else {
                                    setIsPolicyApprovalDrawerOpen(true);
                                }
                            }}
                        >
                            Save policy
                        </button>
                    ) : null}

                    {isPaid && mode === 'upload' && (
                        <button
                            className="transition-all hover:brightness-95 bg-[#F1F5F9] !border !border-[#E2E8F0] !rounded !p-2.5 text-[#395BA9] font-medium text-sm"
                            onClick={() => setIsPolicyEditorDrawerOpen(true)}
                        >
                            Edit with editor
                        </button>
                    )}
                </div>
            </div>

            <ChangesDialog
                isOpen={isChangesDialogOpen}
                setIsOpen={setIsChangesDialogOpen}
                handleCallback={() => {
                    handleClose();
                    handleCallback();
                    fetchDocReviewStats();
                }}
                document={document}
                documentNotes={documentNotes}
                standard={standard}
                policyInformation={policyInformation}
                existingPolicyId={existingPolicyId}
                useExistingPolicy={useExistingPolicy}
                setIsPolicyApprovalDrawerOpen={setIsPolicyApprovalDrawerOpen}
                filename={filename}
            />

            <PolicyApprovalDrawer
                role=""
                mode={mode}
                isOpen={isPolicyApprovalDrawerOpen}
                setIsOpen={setIsPolicyApprovalDrawerOpen}
                handleCallback={() => {
                    handleClose();
                    handleCallback();
                    fetchDocReviewStats();
                }}
                document={document}
                documentNotes={documentNotes}
                standard={standard}
                policyInformation={policyInformation}
                existingPolicyId={existingPolicyId}
                isPaid={isPaid}
                useExistingPolicy={useExistingPolicy}
                currentRequirement={currentRequirement}
                filename={filename}
            />

            <PolicyEditorDrawer
                isOpen={isPolicyEditorDrawerOpen}
                setIsOpen={setIsPolicyEditorDrawerOpen}
                handleCallback={() => {
                    handleClose();
                    handleCallback();
                    fetchDocReviewStats();
                }}
                mode={existingPolicyId ? 'update' : 'create'}
                standard={standard}
                templates={templates}
                currentRequirement={currentRequirement}
                document={document}
                isNativeFile={isNativeFile}
                existingPolicyId={existingPolicyId}
                useExistingPolicy={useExistingPolicy}
                filename={filename}
            />
        </>
    );
}
