import { IterationCw } from 'lucide-react';

export default function UndoTool({ editorRef }) {
    function handleUndo() {
        if (editorRef.current) {
            const quill = editorRef.current;
            quill.getModule('history').undo();
            quill.focus();
        }
    }

    return (
        <button
            className="!h-7 !w-7 !rounded grid place-items-center bg-white transition-all hover:brightness-95"
            onClick={handleUndo}
        >
            <IterationCw className="!h-5 !w-5 scale-y-[-1] transform text-[#1E293B]" />
        </button>
    );
}
