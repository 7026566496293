// redux
import { useSelector } from 'react-redux';
import Isogroups from './components/Isogroups';

const Documents = (props) => {
    // props
    const {
        loading: loadingIsogroups,
        // getIsogroups,
        // category,
        // view,
        // handleViewChange,
        // goBack,
        // selectedIsogroup,
        openTaskAssignModal,
        openExemptModal,
        openRevertModal,
        isExceptionTab,
    } = props;

    // state
    // const [loading, setLoading] = useState({ content: false });

    // const dispatch = useDispatch();

    // const merchant_documents = useSelector((state) => state?.complianceReducers?.all_merchant_documents);
    // const all_tech_sec_documents = useSelector((state) => state?.iso27001plusiso27035Reducers?.all_tech_sec_documents);
    const all_compliance_assigned_tasks = useSelector(
        (state) => state?.generalReducers?.user_info?.compliance_assigned_tasks
    );

    // async functions
    // const getIsogroupDocuments = async (techsec_id) => {
    //     setLoading((curr) => ({ ...curr, content: true }));
    //     const res = await dispatch(GetAllDocumentsByTechSec(techsec_id));
    //     setLoading((curr) => ({ ...curr, content: false }));
    //     if (!res?.success) {
    //         toast.error('Something went wrong!');
    //         console.log('Error:::', res?.message);
    //     }
    // };
    // const handleDocumentExempted = (techsecId) => {
    //     getIsogroups(category);
    //     getIsogroupDocuments(techsecId);
    // };

    // // useeffect
    // useEffect(() => {
    //     if (selectedIsogroup) {
    //         getIsogroupDocuments(selectedIsogroup);
    //     }
    // }, [selectedIsogroup]);

    // return view === 0 ? (
    return (
        <Isogroups
            // resource="doc"
            // onViewChange={handleViewChange}
            loading={loadingIsogroups}
            openTaskAssignModal={openTaskAssignModal}
            openExemptModal={openExemptModal}
            openRevertModal={openRevertModal}
            all_compliance_assigned_tasks={all_compliance_assigned_tasks}
            isExceptionTab={isExceptionTab}
        />
        // ) : (
        //     <>
        //         <ResourceView
        //             goBack={goBack}
        //             isogroupID={selectedIsogroup}
        //             resource="doc"
        //             merchantResources={merchant_documents}
        //             isogroupResources={all_tech_sec_documents}
        //             loading={loading?.content}
        //             handleResourceExempted={handleDocumentExempted}
        //             openTaskAssignModal={openTaskAssignModal}
        //             all_compliance_assigned_tasks={all_compliance_assigned_tasks}
        //         />
        //     </>
    );
};
export default Documents;
