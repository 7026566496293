import React, { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Card, Stack, Typography } from '@mui/material';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';

const SubgroupChipNew = forwardRef((props, ref) => {
    const theme = useTheme();
    const { label, id, onClick, active, disabled, completed, showCheck, isAssigned, standard } = props;

    /*    React.useEffect(() => {
        console.log(props, 'pops');
    }, [props]); */
    return (
        <Card
            elevation={active ? 5 : 0}
            sx={{
                fontSize: 14,
                fontWeight: 700,
                display: 'flex',
                alignItems: 'center',
                textTransform: 'capitalize',
                color: disabled ? '#CBD5E1' : active ? '#1D438F' : '#94A3B8]',
                cursor: !disabled ? 'pointer' : 'not-allowed',
                py: 0.5,
                px: 0.7,
            }}
            onClick={() => {
                if (!disabled) {
                    onClick(id);
                }
            }}
            ref={ref}
        >
            <Typography
                sx={{
                    fontSize: 14,
                    fontWeight: 700,
                    display: 'flex',
                    alignItems: 'center',
                    textTransform: 'capitalize',
                    color: disabled ? '#CBD5E1' : active ? '#1D438F' : '#94A3B8]',
                }}
            >
                {standard === 'sslc' ? 'CO' : ''}
                {label}{' '}
            </Typography>
            {showCheck &&
                (completed ? (
                    <CheckCircleOutline sx={{ color: theme.palette.success[300], ml: 0.7, width: 16, height: 16 }} />
                ) : (
                    <ErrorOutline sx={{ color: theme.palette.error[500], ml: 0.7, width: 16, height: 16 }} />
                ))}
            {isAssigned ? (
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        position: 'absolute',
                        right: '-1.2px',
                        top: '-4px',
                        backgroundColor: theme.palette.shades.white,
                        width: '13px',
                        height: '13px',
                        borderRadius: '50%',
                    }}
                >
                    <Box
                        sx={{
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            backgroundColor: theme.palette.tertiary[70],
                        }}
                    />
                </Stack>
            ) : null}
        </Card>
    );
});

export default SubgroupChipNew;
