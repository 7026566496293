import React, { useState, useContext, useRef, useEffect, useMemo } from 'react';

//core components
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import TabLayout from './tabLayout';
import Vendors from './MyVendors/Vendors';
import ActingAsAVendorLayout from './ActingAsAVendor';
import PageHeader from 'components/new_components/PageHeader';
import { connect, useSelector } from 'react-redux';

//userguide
import { Tour } from 'antd';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideHeader } from 'components/new_components/AppUserGuide';

//translations
import { useTranslation } from 'react-i18next';

import { useSmartIntelContext } from 'store/context/SmartIntelContextProvider';
import Carousel from 'components/new_components/carousel/Carousel';
import SmartIntelLogo from 'assets/img/Smartcomply Intel fav 1.svg';
import ArrowRight from 'assets/img/arrow-up-right.svg';
import SmartIntel from 'components/new_components/SmartIntel';
import { useLocation } from 'react-router-dom';

const VendorLayout = (props) => {
    const { organization, userguide_info, all_article_header } = props;
    // state
    const [currentTab, setCurrentTab] = useState(0);
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    const { all_smart_intel } = useSelector((state) => state?.generalReducers);
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);

    const location = useLocation();
    //userguide
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const reff = useRef(null);

    const STEPS_LENGTH = 2;

    const steps = [
        {
            title: <UserGuideHeader title="My Vendors" id={1} length={STEPS_LENGTH} />,
            description: 'This displays all vendors that you’ve added',
            target: () => ref1.current,
            placement: 'leftTop',
        },
        {
            title: <UserGuideHeader title="Add Vendors" id={2} length={STEPS_LENGTH} />,
            description: 'Here you can add new vendors',
            target: () => reff.current,
            placement: 'rightTop',
        },
    ];

    //translation
    const { t } = useTranslation('vendorManagement');

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Vendor Management'),
        [all_article_header]
    );

    // logic function
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
    };

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ vendors: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, vendors: false });
        setOpenArticle(false);
    };
    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.vendors ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.vendors]);

    // smart intel

    const keywords = merchant_info?.intel_keywords?.['Third-Parties'];
    const { handleOpen } = useSmartIntelContext();
    const switch_on = merchant_info?.smart_intel;
    const intel =
        switch_on && all_smart_intel?.['Third-Parties']
            ? Object?.values(all_smart_intel?.['Third-Parties'])
                  ?.map((option) => option)
                  ?.flat()
                  ?.filter((el) => {
                      return keywords?.some(
                          (keyword) =>
                              el?.title?.toLowerCase()?.includes(keyword) ||
                              el?.market_place?.toLowerCase()?.includes(keyword)
                      );
                  })
            : [];

    useEffect(() => {
        if (location.state?.parent && location.state?.parent === 'vendors') {
            if (location.state.modalType) {
                switch (location.state.modalType) {
                    case 'acting':
                        setCurrentTab(1);
                        return;
                    case 'uploadQR':
                        setCurrentTab(1);
                        return;
                    default:
                        return;
                }
            }
        }
    }, [location]);

    return (
        <SmartIntel>
            <PageHeader browserTitle="Vendors | Smartcomply" />
            <TabLayout
                onTabChange={handleTabChange}
                tab={currentTab}
                tabs={
                    organization?.merchant_plan?.tag === '3_days_trial' ||
                    organization?.merchant_plan?.tag === 'free_version' ||
                    organization?.merchant_plan?.tag === 'Start Up'
                        ? t('tabs_', { returnObjects: true })
                        : t('tabs', { returnObjects: true })
                }
                ref1={ref1}
                ref2={ref2}
            >
                {switch_on && intel?.length ? (
                    <div className="mb-0 transition ease-in-out duration-500 px-4 mt-4">
                        <div className="bg-[#E5F1FF] rounded-full px-3 py-2 flex justify-between items-center">
                            <div className="flex items-start !h-[24px] gap-2 w-[85%]">
                                <img src={SmartIntelLogo} alt="SmartIntelLogo" className="xl:block md:hidden" />
                                <p className="bg-[#F43F5E] text-white text-[13px] rounded px-1 py-0 w-fit xl:block md:hidden">
                                    {intel?.length}
                                </p>
                                <Carousel
                                    slides={intel}
                                    handleOpen={handleOpen}
                                    type="Third-Parties"
                                    data={all_smart_intel?.['Third-Parties']}
                                    containerStyles="page-slide !h-[24px]"
                                />
                            </div>
                            <img src={ArrowRight} alt="ArrowRight" />
                        </div>
                    </div>
                ) : null}
                <AppTabPanel value={currentTab} index={0}>
                    <Vendors reff={reff} />
                </AppTabPanel>
                <AppTabPanel value={currentTab} index={1}>
                    <ActingAsAVendorLayout />
                </AppTabPanel>
            </TabLayout>
            <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} />
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Vendors"
                headerId={oneArticle?.id}
                icon={true}
            />
        </SmartIntel>
    );
};

const mapStateToProps = (state) => {
    return {
        organization: state?.generalReducers?.user_info?.organization,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.vendors,
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};

export default connect(mapStateToProps, {})(VendorLayout);
